import { gql } from '@apollo/client';

export const UPDATE_GROUP = gql`
  mutation updateGroup($group: UpdateGroupInputType!, $address: AddressInputType) {
    group {
      updateGroup(group: $group, address: $address) {
        id
        name
        created
        userCount
        smartCodes {
          code
        }
      }
    }
  }
`;
