import { IProjectGroupData, IProjectGroupDataParams } from './ProjectGroups.interfaces';

const getProjectGroupsTableData = ({ groups, onSettingsButtonClick, projectId }: IProjectGroupDataParams): IProjectGroupData[] => {
  return groups.map(({ created, id, name, smartCodes = [], userCount, code: groupCode }) => {
    return {
      id,
      avatarData: {
        name,
      },
      name,
      code: groupCode,
      smartCodes: smartCodes
        .filter(({ code }) => code)
        .map(({ code }) => code)
        .join(', '),
      userCount,
      created: created,
      settingsButtonData: {
        id,
        code: groupCode,
        projectId,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getProjectGroupsTableData };
