/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { ReactNode } from 'react';

import { NetfrontProductType } from '@netfront/common-library';
import { GroupRequestsIcon, InvitationsIcon, KeyTabIcon, NavigationItemProps, PartnersIcon, ResourcesIcon, RolesIcon, SidebarSubNavItemProps, StyleTabIcon, SupportIcon, UnitsTabIcon, UsersIcon } from '@netfront/ui-library';
import Link from 'next/link';

const SIDEBAR_LIST_MAP = Object.freeze<Record<NetfrontProductType, SidebarSubNavItemProps[]>>({
  EKARDO: [],
  KANZI: [
    {
      icon: StyleTabIcon,
      label: 'Style and layout',
      id: 'style',
    },
    {
      icon: KeyTabIcon,
      label: 'Install codes',
      id: 'integrations',
    },
    {
      icon: UnitsTabIcon,
      label: 'Reporting',
      id: 'reporting',
    },
    {
      icon: SupportIcon,
      label: 'Support',
      id: 'support',
    },
    {
      icon: ResourcesIcon,
      label: 'Resources',
      id: 'resources',
    },
  ],
  QUICKCODES: [
    {
      icon: UnitsTabIcon,
      label: 'Reporting',
      id: 'reporting',
    },
    {
      icon: SupportIcon,
      label: 'Support',
      id: 'support',
    },
    {
      icon: ResourcesIcon,
      label: 'Resources',
      id: 'resources',
    },
  ],
});

export const getSidebarNavigationItems = (config: {
  dashboardUrl: string;
  hasGroupRequest?: boolean;
  isProduct?: boolean;
  organisationId: string;
  product: NetfrontProductType;
  projectId: string;
  rootHost: string;
}): NavigationItemProps[] => {
  const { product, dashboardUrl, organisationId, projectId, rootHost, hasGroupRequest = false, isProduct = false } = config;
  const [baseUrl] = dashboardUrl.split('/dashboard');

  const sideBarUrlMap: Record<NetfrontProductType, Record<string, string>> = {
    KANZI: {
      style: `${dashboardUrl}/${organisationId}/${projectId}/style-and-layout`,
      reporting: `${dashboardUrl}/${organisationId}/${projectId}/reporting`,
      integrations: `${dashboardUrl}/${organisationId}/${projectId}/integrations`,
      resources: `${rootHost}/docs`,
      support: `${baseUrl}/contact`,
    },
    QUICKCODES: {
      reporting: `${dashboardUrl}/${organisationId}/${projectId}/reporting`,
      resources: `${rootHost}/docs`,
      support: `${baseUrl}/contact`,
    },
    EKARDO: {
      reporting: `${dashboardUrl}/${organisationId}/${projectId}/reporting`,
      resources: `${rootHost}/docs`,
      support: `${baseUrl}/contact`,
    },
  };

  const sideBarDynamicUrlMap = sideBarUrlMap[product];
  const sideBarDynamicList = SIDEBAR_LIST_MAP[product];

  const userNavItem: SidebarSubNavItemProps = {
    icon: PartnersIcon,
    label: 'Users',
    id: 'users',
    wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user`}>{children}</Link>,
  };

  const initialNavSubItems: SidebarSubNavItemProps[] = [
    {
      icon: InvitationsIcon,
      label: 'Invitations',
      id: 'invitations',
      wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user/invitations`}>{children}</Link>,
    },
    {
      icon: GroupRequestsIcon,
      label: 'Group requests',
      id: 'group-requests',
      isHidden: !hasGroupRequest,
      wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user/group-requests`}>{children}</Link>,
    },
    {
      icon: UsersIcon,
      label: 'Groups',
      id: 'groups',
      isHidden: product !== 'EKARDO',
      wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user/groups`}>{children}</Link>,
    },
    {
      icon: RolesIcon,
      label: 'Roles',
      id: 'roles',
      isHidden: product !== 'EKARDO',
      wrapperComponent: ({ children }) => <Link href={`/project/${projectId}/user/roles`}>{children}</Link>,
    },
  ];

  if (!isProduct) {
    return [userNavItem, ...initialNavSubItems];
  }

  return [
    ...sideBarDynamicList.map((item: SidebarSubNavItemProps) => {
      return {
        ...item,
        wrapperComponent: ({ children }: { children: ReactNode }) => <Link href={sideBarDynamicUrlMap[item.id]}>{children}</Link>,
      };
    }),
    {
      ...userNavItem,
      subItems: initialNavSubItems,
    },
  ];
};
