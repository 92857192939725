import { gql } from '@apollo/client';

export const GET_PAGINATED_ORGANISATION_MEMBERS = gql`
  query getPaginatedOrganisationMembers($after: String, $first: Int, $organisationId: Int!, $filter: String, $status: EUserStatus) {
    membership {
      getPaginatedOrganisationMembers(after: $after, first: $first, organisationId: $organisationId, filter: $filter, status: $status) {
        edges {
          cursor
          node {
            created
            userType {
              name
            }
            id
            group {
              name
            }
            groupId
            isProjectAdmin
            permision
            user {
              customFields {
                customField {
                  name
                  description
                }
              }
              id
              credential {
                services
                email
                phoneNumber
                phoneNumberCountryCode
              }
              communityName
              firstname
              lastname
              status
            }
            userId
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
