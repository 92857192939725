import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { useGetCustomFields, useGetProduct, useLoggedGeladaMutation, useSearchGroups, useSearchUserTypes } from '@netfront/gelada-identity-library';
import { SideBarTabSet, Spinner, Dialog, useControlledForm, ControlledForm, FormFieldProps, TabSetImperativeHandleRef, GeneralTabIcon, GroupsTabIcon, SidebarButtons, CustomFieldsIcon, IOption } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import { Control, FieldErrors } from 'react-hook-form';
import { ICustomField, IUpdatedCustomField } from 'types';
import * as yup from 'yup';

import { getInvitationDefaultValues, getCustomFieldResponses } from './ProjectUsersInvitationSidebar.helpers';
import { ProjectUsersInvitationSidebarProps } from './ProjectUsersInvitationSidebar.interfaces';

import { CREATE_PROJECT_INVITATION } from '../../../graphql';
import { 
  IInvitationCustomField, 
  useCreateGroupInvitation, 
  useCreateUser,
  useDeleteInvitation,
  useGetCountryCodes,
  useGetCustomFieldInvitationAnswers, 
  useResendInvitation, 
  useToast, 
} from '../../../hooks';
import { ISelectWithSearchItemType } from '../../Pages';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../Pages/UserManagementPages/UserManagementPages.constants';
import { IBulkInvitesUserType, InvitationsBulkInviteTab, InvitationsGeneralTab, EntityCustomFieldsTab } from '../../Tabs';

const ProjectUsersInvitationSidebar = ({
  handleOpenCloseSideBar,
  hasGroups = true,
  isSideBarOpen = false,
  selectedInvitation,
  hasAllGroupsOption = false,
  projectId,
  onSave,
}: ProjectUsersInvitationSidebarProps) => {
  const { getProduct } = useGetProduct();
  const { handleToastError, handleToastSuccess } = useToast();

  const { defaultServices, socialServices } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [allUserTypes, setAllUserTypes] = useState<ISelectWithSearchItemType[]>([]);
  const [customFields, setCustomFields] = useState<IUpdatedCustomField[]>([]);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [countryCodeOptions, setCountryCodeOptions] = useState<IOption[]>([]);
  const [groupDropdownListOptions, setGroupsDropdownItems] = useState<IDropDownListOption[]>();
  const [hasCustomFieldAnswersReturned, setHasCustomFieldAnswersReturned] = useState<boolean>(false);
  const [hasCustomFieldsReturned, setHasCustomFieldsReturned] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);

  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().label('First name').required(),
        lastName: yup.string().label('Last name').required(),
        emailUserInvited: yup.string().label('Email').email('Please enter a valid email').required(),
      }),
    ),
  });

  const handleReset = () => {
    setHasCustomFieldAnswersReturned(false);
    reset();
  };

  const handleResetAndReturn = () => {
    handleReset();
    onSave();
  };

  const handleCloseSidebar = () => {
    handleReset();
    handleOpenCloseSideBar();
  };

  const { handleCreateUser, isLoading: isCreateUserLoading = false } = useCreateUser({
    onCompleted: ({ user }) => {
      if (isEmpty(user)) {
        handleResetAndReturn();
        return;
      }

      handleToastSuccess({
        message: 'User created',
      });

      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const [createProjectInvitation, { loading: isCreateProjectInvitationLoading = false }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleResetAndReturn();

        handleToastSuccess({
          message: 'Invitation sent',
        });

      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
    mutation: CREATE_PROJECT_INVITATION,
    product: getProduct(),
  });

  const { handleCreateGroupInvitation, isLoading: isCreateGroupInvitationLoading = false } = useCreateGroupInvitation({
    onCompleted: ({ invitation: createdInvitation }) => {
      if (isEmpty(createdInvitation)) {
        handleResetAndReturn();
        return;
      }

      handleToastSuccess({
        message: 'Invitation sent',
      });

      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleResendInvitation, isLoading: isResendInvitationLoading = false } = useResendInvitation({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Invitation sent',
      });

      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product: getProduct(),
  });

  const { handleDeleteInvitation, isLoading: isDeleteInvitationLoading = false } = useDeleteInvitation({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      handleToastSuccess({
        message: 'Invitation deleted successfully',
      });
      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetCustomFields, isLoading: isGetCustomFieldsLoading = false } = useGetCustomFields({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const updatedCustomFields: IUpdatedCustomField[] = data.customFields.map((customField) => {
        const { id: customFieldId, __typename, name: customFieldName, responses = [] } = customField as unknown as ICustomField;
        const value = responses && responses.length ? responses[0].text ?? responses[0].value ?? responses[0].dateTime ?? responses[0].number : '';

        return {
          customFieldId,
          customFieldName,
          text: String(value ?? ''),
          __typename,
        };
      });
      setCustomFields(updatedCustomFields);
      setHasCustomFieldsReturned(true);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleGetCustomFieldInvitationAnswers, isLoading: isGetCustomFieldInvitationAnswersLoading = false } =
    useGetCustomFieldInvitationAnswers({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const { customFields: customFieldAnswers }: { customFields: IInvitationCustomField[] } = data;
        const updatedCustomFields: IUpdatedCustomField[] = customFields.map((customField) => {
          const invitationAnswer = customFieldAnswers.find((answer) => answer.customFieldId === customField.customFieldId);

          const value = invitationAnswer ? invitationAnswer.text ?? invitationAnswer.dateTime ?? invitationAnswer.number : '';
          return {
            ...customField,
            text: String(value ?? ''),
          } as IUpdatedCustomField;
        });

        setCustomFields(updatedCustomFields);
        setHasCustomFieldAnswersReturned(true);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    });

  const { handleSearchUserTypes, isLoading: isSearchUserTypesLoading = false } = useSearchUserTypes({
    onCompleted: ({ userTypes }) => {
      const userTypesDropdownList: ISelectWithSearchItemType[] = [...(userTypes as IBulkInvitesUserType[])].map(({ id, name }) => ({
        id,
        label: name,
      }));

      setAllUserTypes(userTypesDropdownList);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleGetCountryCodes, isLoading: isGetCountryCodesLoading = false } = useGetCountryCodes({
    onCompleted: ({ enumValues = [] }) => {
      setCountryCodeOptions(enumValues.map(({ name, description }) => (
        {
          id: name,
          name: description,
          value: name,
        }
      )));
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleSearchGroups, isLoading: isSearchGroupsLoading = false } = useSearchGroups({
    onCompleted: ({ groups }) => {
      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsDropdownItems(
        hasAllGroupsOption
          ? [
              {
                id: '0',
                label: 'All groups',
                value: '0',
              },
              ...groupsSelectSearchList,
            ]
          : groupsSelectSearchList,
      );
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  // Handle creating a user without activation
  const handleCreateUserSubmit = (item: FormFieldProps) => {
    const { 
      firstName, 
      lastName, 
      emailUserInvited, 
      phoneNumber, 
      groupId, 
      userTypeId, 
      isSocialAccessActive = false,
      phoneNumberCountryCode,
    } = item;
  
    const customFieldResponses = getCustomFieldResponses({ item, customFields });
  
    void handleCreateUser({
      email: emailUserInvited,
      firstname: firstName,
      groupId: Number(groupId),
      lastname: lastName ?? '',
      password: item.password,
      phoneNumber,
      projectId: String(projectId),
      services: isSocialAccessActive ? socialServices : defaultServices,
      userTypeId,
      customFieldResponses,
      phoneNumberCountryCode: phoneNumberCountryCode === '' ? null : phoneNumberCountryCode,
    });
  };

  // Handle creating a group invitation
  const handleGroupInvitationSubmit = (item: FormFieldProps) => {
    const { 
      firstName, 
      lastName, 
      emailUserInvited, 
      phoneNumber, 
      groupId, 
      userTypeId, 
      customMessage,
      isSocialAccessActive = false,
      phoneNumberCountryCode,
    } = item;

    const customFieldResponses = getCustomFieldResponses({ item, customFields });

    void handleCreateGroupInvitation({
      customMessage,
      firstName,
      customFields: customFieldResponses,
      groupId: Number(groupId),
      invitedUserEmail: emailUserInvited,
      lastName,
      permission: 'READ',
      services: isSocialAccessActive ? socialServices : defaultServices,
      userTypeId,
      phoneNumber,
      phoneNumberCountryCode: phoneNumberCountryCode === '' ? null : phoneNumberCountryCode,
    });
  };

  // Handle creating a project invitation
  const handleProjectInvitationSubmit = (item: FormFieldProps) => {
    const { 
      firstName, 
      lastName, 
      emailUserInvited, 
      phoneNumber,
      permissions,
      customMessage,
      phoneNumberCountryCode,
    } = item; 
    const customFieldResponses = getCustomFieldResponses({ item, customFields }); 
    void createProjectInvitation({
      variables: {
        invitation: {
          customMessage,
          firstName,
          customFields: customFieldResponses,
          invitedEmail: emailUserInvited,
          phoneNumber,
          lastName,
          permission: permissions,
          projectId: String(projectId),
          phoneNumberCountryCode: phoneNumberCountryCode === '' ? null : phoneNumberCountryCode,
        },
      },
    });
  };


  const handleSave = (item: FormFieldProps) => {
    const { isRequiresActivation = false, isTeamAdmin = false } = item;

    if (!isRequiresActivation) {
      return handleCreateUserSubmit(item);
    }

    return isTeamAdmin 
      ? handleProjectInvitationSubmit(item)
      : handleGroupInvitationSubmit(item);
  };



  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  useEffect(() => {
    if (!(projectId && isSideBarOpen)) {
      return;
    }

    void handleSearchUserTypes({
      projectId: String(projectId),
    });

    void handleGetCustomFields({
      projectId: String(projectId),
      scope: 'USER',
    });

    void handleSearchGroups({
      projectId: String(projectId),
    });

    handleGetCountryCodes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isSideBarOpen]);

  useEffect(() => {
    if (!(selectedInvitation && projectId && isSideBarOpen)) return;

    if (customFields.length === 0 || hasCustomFieldAnswersReturned) return;

    void handleGetCustomFieldInvitationAnswers({
      id: selectedInvitation.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvitation, customFields, isSideBarOpen, projectId]);

  useEffect(() => {
    if (!isSideBarOpen) {
      return;
    }

    setDefaultValues(getInvitationDefaultValues({ 
      invitation: selectedInvitation, 
      customFields,
      hasGroups,
    }));

  }, [hasGroups, selectedInvitation, hasCustomFieldAnswersReturned, hasCustomFieldsReturned, customFields, isSideBarOpen]);


  const sidebarButtons = (
    <SidebarButtons
      buttonSize="xs"
      submitButtonText={selectedInvitation ? 'Resend': 'Invite'}
      onCancel={handleCloseSidebar}
      onDelete={selectedInvitation ? () => setIsDeleteDialogOpen(true) : undefined}
      onSave={selectedInvitation ? () => {
        void handleResendInvitation({
          invitationId: selectedInvitation.id,
        }); 
      }: undefined}
      onSaveButtonType={`${selectedInvitation ? 'button' : 'submit'}`}
    />
  );

  const loadingState = [
    isResendInvitationLoading, 
    isSearchGroupsLoading, 
    isSearchUserTypesLoading, 
    isGetCustomFieldInvitationAnswersLoading, 
    isGetCustomFieldsLoading, 
    isDeleteInvitationLoading, 
    isCreateUserLoading, 
    isCreateProjectInvitationLoading, 
    isCreateGroupInvitationLoading,
    isGetCountryCodesLoading,
  ];

  const isLoading = loadingState.some(Boolean);

  return (
    <>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={() => {
            handleCloseSidebar();
          }}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <>
                  <InvitationsGeneralTab 
                    allUserTypes={allUserTypes}
                    control={control as Control<FormFieldProps>}
                    countryCodeOptions={countryCodeOptions}
                    groupDropdownListOptions={groupDropdownListOptions} 
                    hasGroups={hasGroups}
                    initialIsRequiresActivation={defaultValues?.isRequiresActivation}
                    initialIsTeamAdmin={defaultValues?.isTeamAdmin}
                    isUpdate={Boolean(selectedInvitation)}
                    setValue={setValue}
                  />
                  {sidebarButtons}
                </>
              ),
            },
            {
              icon: CustomFieldsIcon,
              id: 'id_custom_fields_tab',
              label: 'Extra info',
              view: () => (
                <>
                  <EntityCustomFieldsTab 
                    control={control as Control<FormFieldProps>}
                    customFields={customFields}
                    isDisabled={Boolean(selectedInvitation)}
                  />
                  {sidebarButtons}
                </>
              ),
            },
            {
              icon: GroupsTabIcon,
              id: 'id_bulk_invite_tab',
              label: 'Bulk invite',
              isHidden: Boolean(selectedInvitation),
              view: () => (
                <InvitationsBulkInviteTab 
                  allUserTypes={allUserTypes}
                  customFields={customFields}
                  groupDropdownListOptions={groupDropdownListOptions}
                  onClose={handleOpenCloseSideBar} 
                  onUpdate={handleResetAndReturn}
                />
              ),
            },
          ]}

          hideSideBarButtons
        />
      </ControlledForm>
      {selectedInvitation && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title="Delete invitation?"
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            setIsDeleteDialogOpen(false);
            void handleDeleteInvitation({
              invitationId: selectedInvitation.id,
            });
          }}
        />
      )}
    </>
  );
};

export { ProjectUsersInvitationSidebar };
