import { FlexContainer, ClockIcon, Spacing } from "@netfront/ui-library";

import { StepCounterProps } from "./StepCounter.interfaces";


const StepCounter = ({currentStep, totalSteps, completionTime}: StepCounterProps) => (
  // TODO: @ash - update icon id when available
  <Spacing size="x-small">
    <FlexContainer>
      <p className="c-step-counter__step">Step {currentStep} of {totalSteps}</p>

      <div className="c-step-counter__completion-time">
        <div className="c-step-counter__icon-container">
          <ClockIcon className="c-step-counter__icon"/>
        </div>
        <span className="c-step-counter__time-text">Approx. {completionTime}m</span>
      </div>
    </FlexContainer>
  </Spacing>
);

export { StepCounter };
