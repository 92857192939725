// TODO: Find a way to pass in the user from ProfilePage

import { useContext, useEffect, useState } from 'react';

import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import {
  getPerson,
  IDBUser,
  IUser,
  useDomain,
  useGetProduct,
  useIdentitySiteUrls,
  useProtectedRoute,
  useUpdateUser,
} from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { PROFILE_ACCOUNT_TAB_CONSTANTS } from './ProfileAccountTab.constants';
import styles from './ProfileAccountTab.module.css';

import { UserContext } from '../../../../context';
import { useToast } from '../../../../hooks';
import { ProfileAccountForm } from '../../../Forms/ProfileAccountForm';

const {
  cssIdentifiers: { block: blockCssId },
} = PROFILE_ACCOUNT_TAB_CONSTANTS;

export interface ProfileAccountTabProps {
  user: IUser | undefined;
}

const ProfileAccountTab = ({ user }: ProfileAccountTabProps) => {
  const { isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { setStoredUser } = useContext(UserContext);

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [loggedUser, setLoggedUser] = useState<IUser | undefined>(user);

  const product = getProduct();

  const { handleUpdateUser } = useUpdateUser({
    onCompleted: ({ isUpdateEmailCompleted, isUpdateUserCompleted }) => {
      if (isUpdateEmailCompleted && isUpdateUserCompleted) {
        if (!(loggedUser && firstName && lastName)) {
          return;
        }

        const userWithUpdatedName: IUser = {
          ...loggedUser,
          firstName,
          lastName,
        };

        const updatedUser: IUser = {
          ...userWithUpdatedName,
          person: getPerson(userWithUpdatedName as IDBUser),
        };

        setLoggedUser(updatedUser);
        setStoredUser(updatedUser);

        handleToastSuccess({
          message: 'Your profile has been updated',
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product,
  });

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    if (!isDomainReady) {
      return;
    }

    push(getLoginUrl()).catch((error) =>
      handleToastError({
        error,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady]);

  return (
    <>
      {isDomainReady && loggedUser && (
        <div className={styles[`${blockCssId}__form`]}>
          <ProfileAccountForm
            isSubmitting={false}
            user={loggedUser}
            onUpdateUserDetails={({ email, firstName: returnedFirstName, lastName: returnedLastName }) => {
              setFirstName(returnedFirstName);
              setLastName(returnedLastName);

              void handleUpdateUser({
                email,
                firstName: returnedFirstName,
                lastName: returnedLastName,
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export { ProfileAccountTab };
