import { capitalize } from 'utils';

import { IGetProjectCustomFieldsTableDataParams, IProjectCustomFieldsTableData } from './ProjectCustomFields.interfaces';


const getCustomFieldType = (type: string, multiResponseType?: string)  => {
  if (type === 'CustomFieldNumberGraphType') return 'Number';
  if (type === 'CustomFieldTextGraphType') return 'Text';
  if (type === 'CustomFieldDateGraphType') return 'Date';
  if (type === 'CustomFieldBooleanGraphType') return 'Boolean';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'RADIO') return 'Radio';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'DROP_DOWN_LIST') return 'Dropdown';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'CHECKBOX') return 'Checkbox';
  
  return '';
};


const getProjectCustomFieldsTableData = ({ customFields, onSettingsButtonClick }: IGetProjectCustomFieldsTableDataParams): IProjectCustomFieldsTableData[] => {
  return customFields.map((item): IProjectCustomFieldsTableData => {
    const { id, description, name, __typename: type, type: multiResponseType, isRequired = false, scope } = item;
    return {
      id,
      description,
      name,
      settingsButtonData: {
        item,
        onClick: onSettingsButtonClick,
      },
      isRequired,
      type: getCustomFieldType(type, multiResponseType),
      scope: capitalize(scope),
    };
  });
};

export { getProjectCustomFieldsTableData };
