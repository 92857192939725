import { gql } from '@apollo/client';

export const CREATE_GROUP_INVITATION = gql`
  mutation createGroupInvitation($invitation: CreateGroupInvitationInput!) {
    invitation {
      groupInvitation(invitation: $invitation) {
        customMessage
        firstName
        groupId
        id
        invitationEmailStatus
        emailUserInvited
        invitedUserId
        lastName
        group {
          id
          name
        }
        userType {
          id
          name
        }
      }
    }
  }
`;
