import React, { useEffect, useState } from 'react';

import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import {
  useDomain,
  useForgotPassword,
  useGetProduct,
  useIdentitySiteUrls,
  useResendActivationCode,
} from '@netfront/gelada-identity-library';
import { Button, Dialog } from '@netfront/ui-library';

import { FORGOT_PASSWORD_CONSTANTS } from './ForgotPasswordPage.constants';
import styles from './ForgotPasswordPage.module.css';

import { USER_NOT_ACTIVATED_MESSAGE } from '../../../constants';
import { useToast } from '../../../hooks';
import { ForgotPasswordForm } from '../../Forms';
import { Layout } from '../../Shared/Layout';

const {
  CSS_IDENTIFIERS: { BLOCK: blockCssId },
  DEFAULTS: { HEADER_TEXT, PAGE_DESCRIPTION },
} = FORGOT_PASSWORD_CONSTANTS;

const ForgotPasswordPage = () => {
  const { isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { handleToastError, handleToastSuccess } = useToast();

  const [loginUrl, setLoginUrl] = useState<string>();
  const [isResendActivationCodeDialogOpen, setIsResendActivationCodeDialogOpen] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>();

  const product = getProduct();

  const { handleForgotPassword, isLoading: isForgotPasswordLoading = false } = useForgotPassword({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        handleToastSuccess({
          durationInMilliseconds: 5000,
          message: 'An email will be sent containing a link to reset your password',
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      const { message } = error;

      if (message === USER_NOT_ACTIVATED_MESSAGE) {
        setIsResendActivationCodeDialogOpen(true);
        return;
      }

      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product,
  });

  const { handleResendActivationCode, isLoading: isResendActivationCodeLoading = false } = useResendActivationCode({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      setIsResendActivationCodeDialogOpen(false);

      handleToastSuccess({
        message: 'Your activation code has been sent to your email',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product,
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setLoginUrl(getLoginUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  const isLoading = isForgotPasswordLoading || isResendActivationCodeLoading;

  return (
    <Layout headerText={HEADER_TEXT} isPreloaderVisible={isLoading} pageDescription={PAGE_DESCRIPTION} title="Forgot password">
      <div className={styles[`${blockCssId}__form`]}>
        <ForgotPasswordForm
          isSubmitting={isLoading}
          onForgotPassword={({ email }) => {
            setUserEmail(email);

            void handleForgotPassword({
              email,
            });
          }}
        />

        <Dialog
          isOpen={isResendActivationCodeDialogOpen}
          title="Activate your account"
          onClose={() => setIsResendActivationCodeDialogOpen(false)}
        >
          <p>Your email hasn&#39;t been activated, click below to resend activation</p>

          <br />

          <Button
            text="Resend activation code"
            onClick={() => {
              if (!userEmail) {
                return;
              }

              void handleResendActivationCode({
                email: userEmail,
              });
            }}
          />
        </Dialog>

        {loginUrl && (
          <div className={styles[`${blockCssId}__return-to-login-container`]}>
            <span>
              <a href={loginUrl}>Return to login</a>
            </span>
          </div>
        )}
      </div>
    </Layout>
  );
};

export { ForgotPasswordPage };
