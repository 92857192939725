import React from 'react';

import { FlexContainer, Input, InputFieldWrapper, Select } from '@netfront/ui-library';
import { Controller, useFormState } from 'react-hook-form';

import { CountryCodeInputProps } from './CountryCodeInput.interfaces';

const CountryCodeInput = ({
  codesOptions,
  control,
  countryCodeName,
  id,
  phoneNumberName,
  isRequired = false,
  isDisabled = false,
  tooltipText,
  labelText = 'Phone number',
}: CountryCodeInputProps) => {
  const { errors } = useFormState({ control });

  const errorMessage = 
    (errors[countryCodeName]?.message as string) || 
    (errors[phoneNumberName]?.message as string) || 
    '';

  return (
    <InputFieldWrapper 
      id={id}
      isRequired={isRequired} 
      label={labelText}
      labelType="span"
      message={{ error: errorMessage, success: ''}}
      tooltipText={tooltipText}
      isLabelSideBySide
    >
      <FlexContainer alignItems="center" gap="x-small" justifyContent="flex-start" isFullWidth>
        <div className="c-country-code-input--small">
          <Controller
            control={control}
            name={countryCodeName}
            render={({ field }) => (
              <Select
                id={`${id}_country_input`}
                isDisabled={isDisabled}
                labelText="Country code"
                options={codesOptions}
                isLabelHidden
                {...field}
              />
            )}
          />
        </div>
        <div className="c-country-code-input--large">
          <Controller
            control={control}
            name={phoneNumberName}
            render={({ field }) => (
              <Input
                id={`${id}_phone_input`}
                isDisabled={isDisabled}
                labelText="Phone number"
                type="tel"
                isLabelHidden
                {...field}
              />
            )}
          />
        </div>
      </FlexContainer>
    </InputFieldWrapper>

  );
};

export { CountryCodeInput };
