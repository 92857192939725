import React, { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie } from '@netfront/common-library';
import {
  convertDBUser,
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  useDeleteUsers,
  useDomain,
  useGetCustomFields,
  useImpersonateUser,
  useLoggedGeladaMutation,
  useLoginWithImpersonatedToken,
  useUpdateUserAsAdmin,
  useUpdateUserService,
} from '@netfront/gelada-identity-library';
import { SideBarTabSet, Spinner, Dialog, useControlledForm, ControlledForm, FormFieldProps, TabSetImperativeHandleRef, GeneralTabIcon, SidebarButtons, Button, Input, EyeShowIcon, GroupsTabIcon, KeyTabIcon, NotesTabIcon, CustomFieldsIcon, IOption } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { Control, FieldErrors } from 'react-hook-form';
import { ICustomField, ICustomFieldResponses, IUpdatedCustomField } from 'types';
import * as yup from 'yup';

import { getUserDefaultValues, getCustomFieldResponses } from './ProjectUsersSidebar.helpers';
import { ProjectUsersSidebarProps } from './ProjectUsersSidebar.interfaces';

import { UPDATE_USER_STATUS } from '../../../graphql';
import { useToast, useAnswerCustomFields, useToggleElevateGroupMember, useGetCountryCodes } from '../../../hooks';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../Pages/UserManagementPages/UserManagementPages.constants';
import { GroupsUserTab, EntityCustomFieldsTab, UserGeneralTab, UserNotesTab, UserPasswordTab, UserPermissionsTab } from '../../Tabs';

const ProjectUsersSidebar = ({
  handleOpenCloseSideBar,
  hasGroups = false,
  impersonateUserLink = '',
  isSideBarOpen = false,
  selectedUser,
  projectId,
  onSave,
}: ProjectUsersSidebarProps) => {
  const {
    createImpersonatedUserAccessTokenCookie,
    createImpersonatedUserRefreshTokenCookie,
    createImpersonatedUserDataCookie,
    createStopImpersonationReturnUrlCookie,
    getProjectDomainCookie,
    isSecureCookie,
  } = useCookie();
  const { getDomain } = useDomain();
  const {
    push,
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { defaultServices, socialServices } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [customFields, setCustomFields] = useState<IUpdatedCustomField[]>([]);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [countryCodeOptions, setCountryCodeOptions] = useState<IOption[]>([]);
  const [userId, setUserId] = useState<number>(0);

  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().label('First name').required(),
        lastName: yup.string().label('Last name').required(),
        email: yup.string().label('Email').email('Please enter a valid email').required(),
      }),
    ),
  });

  const handleResetAndReturn = () => {
    reset();
    onSave();
  };

  const handleCloseSidebar = () => {
    reset();
    handleOpenCloseSideBar();
  };

  const [executeUpdateUserStatus, { loading: isActivationUserLoading = false }] = useLoggedGeladaMutation({
    options: {
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_USER_STATUS,
  });


  const { handleToggleElevateGroupMember, isLoading: isToggleElevateGroupMemberLoading = false } = useToggleElevateGroupMember({
    onCompleted: () => {

      onSave(false);
      handleToastSuccess({
        message: 'User admin access updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleAnswerCustomFields, isLoading: isAnswerCustomFieldsLoading = false } = useAnswerCustomFields({
    onCompleted: () => {
      handleToastSuccess({
        message: 'User custom fields updated successfully',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleDeleteUsers, isLoading: isDeleteUsersLoading = false } = useDeleteUsers({
    onCompleted: () => {
      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleGetCountryCodes, isLoading: isGetCountryCodesLoading = false } = useGetCountryCodes({
    onCompleted: ({ enumValues = [] }) => {
      setCountryCodeOptions(enumValues.map(({ name, description }) => (
        {
          id: name,
          name: description,
          value: name,
        }
      )));
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleGetCustomFields, isLoading: isGetCustomFieldsLoading = false } = useGetCustomFields({
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const updatedCustomFields: IUpdatedCustomField[] = data.customFields.map((customField) => {
        const { id: customFieldId, __typename, name: customFieldName, responses = [] } = customField as unknown as ICustomField;
        const value = responses && responses.length ? responses[0].text ?? responses[0].value ?? responses[0].dateTime ?? responses[0].number : '';

        return {
          customFieldId,
          customFieldName,
          text: String(value ?? ''),
          __typename,
        };
      });
      setCustomFields(updatedCustomFields);

      if (!selectedUser) return;
      setDefaultValues(getUserDefaultValues({ 
        user: selectedUser, 
        customFields: updatedCustomFields,
      }));
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleImpersonateUser, isLoading: isImpersonateUserLoading = false } = useImpersonateUser({
    onCompleted: ({ token }) => {
      if (impersonateUserLink) {
        window.open(`${impersonateUserLink}?t=${token}`);
        return;
      }

      void handleLoginWithImpersonatedToken({
        projectId: String(projectId),
        token,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleLoginWithImpersonatedToken, isLoading: isLoginWithImpersonatedTokenLoading = false } = useLoginWithImpersonatedToken({
    onCompleted: ({ accessToken, refreshToken, user: impersonatedUser }) => {
      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      if (accessToken) {
        createImpersonatedUserAccessTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
            }
          },
          value: accessToken,
        });
      }

      if (refreshToken) {
        createImpersonatedUserRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
            }
          },
          value: refreshToken,
        });
      }

      if (impersonatedUser) {
        const updatedImpersonatedUser = convertDBUser(impersonatedUser);

        const {
          credential: { email },
          firstName,
          id,
          lastName,
        } = updatedImpersonatedUser;

        /*
         * Note
         *  - The whole user object is too large to store in a cookie
         *  - Extract only the important user data to store in a cookie
         */

        createImpersonatedUserDataCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
            }
          },
          value: JSON.stringify({
            email,
            firstName,
            id,
            lastName,
          }),
        });
      }

      createStopImpersonationReturnUrlCookie({
        optionalCookieAttributesInput: {
          domain,
          secure: isSecure,
        },
        value: document.URL,
      });

      const projectDomain = getProjectDomainCookie();

      if (projectDomain) {
        push(`http://${projectDomain}`).catch((error) =>
          handleToastError({
            error,
          }),
        );
      }

      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleUpdateUserAsAdmin, isLoading: isUpdateUserAsAdminLoading = false } = useUpdateUserAsAdmin({
    onCompleted: () => {
      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateUserService, isLoading: isUpdateUserServiceLoading = false } = useUpdateUserService({
    onCompleted: () => {
      handleResetAndReturn();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });



  const handleSave = (item: FormFieldProps) => {
    const { 
      firstName, 
      lastName, 
      email,
      communityName,
      phoneNumber, 
      isSocialAccessActive = false,
      isUserStatusActive = false,
      hasStatusChanged = false,
      phoneNumberCountryCode,
    } = item;

    const {
      isUserStatusActive: initialIsUserStatusActive = false,
    } = defaultValues ?? {};
    const updatedCustomFields = getCustomFieldResponses({ item, customFields });

    if (hasStatusChanged && initialIsUserStatusActive !== isUserStatusActive) {
      void executeUpdateUserStatus({
        variables: {
          userIds: [userId],
          projectId,
          newUserStatus: !isUserStatusActive ? 'DEACTIVATED' : 'ACTIVE',
        },
      });
    }

    void handleUpdateUserAsAdmin({
      communityName,
      email,
      firstName,
      lastName,
      phoneNumber,
      phoneNumberCountryCode: phoneNumberCountryCode === '' ? null : phoneNumberCountryCode,
      userId: userId,
    });

    void handleAnswerCustomFields({
      projectId: String(projectId),
      responses: updatedCustomFields as ICustomFieldResponses[],
      userId: userId,
    });

    void handleUpdateUserService({
      services: isSocialAccessActive ? defaultServices : socialServices,
      userIds: [userId],
    });
  };

  const toggleElevateUserAsProjectAdmin = () => {
    handleToggleElevateGroupMember({
      projectId: String(projectId),
      userId,
      permission: 'MANAGE_USERS'
    });
  };

  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  useEffect(() => {
    if (!(selectedUser && projectId && isSideBarOpen)) return;

    handleGetCountryCodes();

    setUserId(selectedUser.id);
    void handleGetCustomFields({
      projectId: String(projectId),
      scope: 'USER',
      userId: selectedUser.id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser, isSideBarOpen, projectId]);

  return (
    <>
      <Spinner isLoading={isGetCountryCodesLoading || isGetCustomFieldsLoading || isActivationUserLoading || isLoginWithImpersonatedTokenLoading || isToggleElevateGroupMemberLoading || isAnswerCustomFieldsLoading || isUpdateUserAsAdminLoading || isUpdateUserServiceLoading || isDeleteUsersLoading || isImpersonateUserLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <SideBarTabSet
          defaultActiveTabId="id_general_tab"
          handleOpenCloseSideBar={() => {
            handleCloseSidebar();
          }}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              icon: GeneralTabIcon,
              id: 'id_general_tab',
              label: 'General',
              view: () => (
                <>
                  <UserGeneralTab
                    control={control as Control<FormFieldProps>}
                    countryCodeOptions={countryCodeOptions}
                    fullName={defaultValues?.fullName ?? ''}
                    handleToggleElevateGroupMember={toggleElevateUserAsProjectAdmin}
                    initialIsTeamAdmin={defaultValues?.isTeamAdmin ?? false}      
                    setValue={setValue}              
                  />
                  <SidebarButtons
                    additionalButton={impersonateUserLink ?
                      <Button
                        size="xs"
                        text="Impersonate"
                        variant="primary-inverse"
                        onClick={() => {
                          void handleImpersonateUser({
                            userId: userId,
                          });
                        }}
                      /> : null
                    }
                    buttonSize="xs"
                    onCancel={handleCloseSidebar}
                    onDelete={selectedUser ? () => setIsDeleteDialogOpen(true) : undefined}
                    onSaveButtonType="submit"
                  />
                </>
              ),
            },
            {
              icon: CustomFieldsIcon,
              id: 'id_custom_fields_tab',
              label: 'Extra info',
              view: () => (
                <>
                  <EntityCustomFieldsTab 
                    additionalFields={(
                      <Input
                        key={userId}
                        id="userId"
                        labelText="User Id"
                        name="userId"
                        type="text"
                        value={userId}
                        isDisabled
                        isLabelSideBySide
                        onChange={() => { return; }}
                      />
                    )}
                    control={control as Control<FormFieldProps>}
                    customFields={customFields}
                  />
                  <SidebarButtons
                    additionalButton={impersonateUserLink ?
                      <Button
                        size="xs"
                        text="Impersonate"
                        variant="primary-inverse"
                        onClick={() => {
                          void handleImpersonateUser({
                            userId: userId,
                          });
                        }}
                      /> : null
                    }
                    buttonSize="xs"
                    onCancel={handleCloseSidebar}
                    onDelete={selectedUser ? () => setIsDeleteDialogOpen(true) : undefined}
                    onSaveButtonType="submit"
                  />
                </>
              ),
            },
            {
              icon: KeyTabIcon,
              id: 'id_password_tab',
              label: 'Password',
              view: () => selectedUser ? <UserPasswordTab user={selectedUser} onClose={handleCloseSidebar} />: <></>,
            },
            {
              icon: GroupsTabIcon,
              id: 'id_groups_tab',
              label: 'Groups',
              isHidden: !hasGroups,
              view: () => selectedUser ? <GroupsUserTab isProjectAdmin={selectedUser.isProjectAdmin} userId={userId} hasAllGroupsOption/>: <></>,
            },
            {
              icon: NotesTabIcon,
              id: 'id_notes_tab',
              label: 'Notes',
              view: () => selectedUser ? <UserNotesTab userId={userId} />: <></>,
            },
            {
              icon: EyeShowIcon,
              id: 'id_permissions_tab',
              label: 'Permissions',
              isHidden: !selectedUser?.isProjectAdmin,
              view: () => selectedUser ? (
                <UserPermissionsTab
                  projectId={String(projectId)}
                  type={'PROJECT'}
                  userId={userId}
                  onClose={handleCloseSidebar}
                />
              ): <></>,
            },
          ]}
          hideSideBarButtons
        />
      </ControlledForm>
      {selectedUser && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete ${String(defaultValues?.fullName ?? 'user')}?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            if (!projectId) {
              return;
            }

            void handleDeleteUsers({
              newUserStatus: 'DELETED',
              projectId: String(projectId),
              userIds: [userId],
            });
          }}
        />
      )}
    </>
  );
};

export { ProjectUsersSidebar };
