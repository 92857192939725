import { gql } from '@apollo/client';

export const GET_GROUP = gql`
  query GetGroup($groupId: Int!) {
    group {
      getGroup(groupId: $groupId) {
        id
        name
        status
        smartCodes {
          code
          smartCodeId
        }
      }
    }
  }
`;
