import React, { useState } from 'react';

import { IDBGroup, IDBUnit } from '@netfront/gelada-identity-library';
import { ButtonIconOnly, FlexContainer, InfoIcon, InformationBox, PlusIcon, Spacing } from '@netfront/ui-library';

import { UserGroupAddUnit } from './UserGroupAddUnit';
import { UserGroupUnitForm } from './UserGroupUnitForm';
import { UserGroupUnitsTabProps } from './UserGroupUnitsTab.interfaces';

import { SidebarListItem, SidebarList, SidebarContainer } from '../../../../components/Shared';

const UserGroupUnitsTab = ({ selectedGroup }: UserGroupUnitsTabProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { unit: selectedUnits = [], id: selectedGroupId } = selectedGroup ?? ({} as IDBGroup);

  const [isAddUnitVisible, setIsAddUnitVisible] = useState<boolean>(false);
  const [isUnitFormVisible, setIsUnitFormVisible] = useState<boolean>(false);
  const [groupUnits, setGroupUnits] = useState<IDBUnit[]>(selectedUnits);
  const [selectedUnit, setSelectedUnit] = useState<IDBUnit | undefined>(undefined);

  const handleClickUnit = (unitId: number) => {
    handleToggleUnitFormVisibility();

    setSelectedUnit(groupUnits.find(({ id }) => id === unitId));
  };

  const handleUpdateGroupUnits = (units: IDBUnit[]) => {
    setGroupUnits(units);
  };

  const handleToggleAddUnitVisibility = () => {
    setIsAddUnitVisible(!isAddUnitVisible);
  };

  const handleToggleUnitFormVisibility = () => {
    setIsUnitFormVisible(!isUnitFormVisible);
  };

  return (
    <SidebarContainer>
      {isUnitFormVisible && Boolean(groupUnits.length) && (
        <UserGroupUnitForm
          selectedGroupUnits={groupUnits}
          unit={selectedUnit}
          onOpenUpdateUnits={handleToggleUnitFormVisibility}
          onUpdateGroupUnits={handleUpdateGroupUnits}
        />
      )}
      {isAddUnitVisible && (
        <UserGroupAddUnit
          selectedGroupUnits={groupUnits}
          unitGroupId={selectedGroupId}
          onOpenUpdateUnits={handleToggleAddUnitVisibility}
          onUpdateGroupUnits={handleUpdateGroupUnits}
        />
      )}
      {!isUnitFormVisible && !isAddUnitVisible && (
        <main>
          {!groupUnits.length ? (
            <Spacing>
              <InformationBox icon={InfoIcon} message="There is no unit created for this group" />
            </Spacing>
          ) : (
            <section>
              <SidebarList itemsLength={groupUnits.length} text="units" hasCount>
                {groupUnits.map(({ id, name }) => (
                  <SidebarListItem key={id} name={name} supportiveText="Update the unit" onClick={() => handleClickUnit(id)} />
                ))}
              </SidebarList>
            </section>
          )}
          <FlexContainer justifyContent="end" tag="section">
            <ButtonIconOnly icon={PlusIcon} text="Add unit" onClick={handleToggleAddUnitVisibility} />
          </FlexContainer>
        </main>
      )}
    </SidebarContainer>
  );
};

export { UserGroupUnitsTab };
