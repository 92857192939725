import { DBMembershipPermissionType } from '@netfront/gelada-identity-library';

export const USER_STATUS = Object.freeze({
  ACTIVE: true,
  INACTIVE: false,
});

export const PERMISSIONS_ITEMS: {
  id: string;
  labelText: string;
  product?: string;
  value: DBMembershipPermissionType;
}[] = [
  { labelText: 'Manage users', id: 'MANAGE_USERS', value: 'MANAGE_USERS' },
  { labelText: 'Edit', id: 'EDIT', value: 'EDIT' },
  { labelText: 'View', id: 'READ', value: 'READ' },
  { labelText: 'Limited', id: 'LIMITED', value: 'LIMITED', product: 'QUICKCODES' },
];
