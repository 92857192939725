import { format } from 'date-fns';

import { IGetGroupInvitationsTableDataParams, IGroupInvitationTableData } from '../../Pages.interfaces';


const getInvitationTableData = ({ invites, onSettingsButtonClick }: IGetGroupInvitationsTableDataParams): IGroupInvitationTableData[] => {
  return invites
    .flatMap((invitation) => {
      return [invitation];
    })
    .map(({ created, id, groupId, group = {}, firstName, lastName, emailUserInvited, invitationEmailStatus, type, permission }): IGroupInvitationTableData => {

      const isProjectAdmin = type === 'PROJECT';

      return {
        id,
        groupId: Number(groupId),
        avatarData: {
          fullName: `${String(firstName)} ${String(lastName)}`.trim(),
          firstName: String(firstName),
          isProjectAdmin,
          permission,
        },
        firstName: String(firstName),
        lastName: String(lastName),
        emailUserInvited,
        groupName: type === 'PROJECT' ? 'Admin, All groups' : String(group.name),
        invitationEmailStatus,
        created: format(new Date(String(created)), 'dd/MM/yyyy'),
        settingsButtonData: {
          firstName: String(firstName),
          id,
          onClick: onSettingsButtonClick,
        },
        type,
        isProjectAdmin,
      };
    });
};

export { getInvitationTableData };
