import { gql } from '@apollo/client';

export const APPROVE_DECLINE_GROUP_REQUEST = gql`
  mutation ApproveDeclineGroupRequest($requestId: Int!, $approved: Boolean!, $reason: String, $deleteOnDecline: Boolean, $name: String) {
    group {
      acceptOrDeclineGroupCreation(
        requestId: $requestId
        status: $approved
        reason: $reason
        deleteOnDecline: $deleteOnDecline
        name: $name
      )
    }
  }
`;
