/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { DBUserTypeStatusType, IDBUserType } from "@netfront/gelada-identity-library";
import { FormFieldProps } from "@netfront/ui-library";

const getUpsertUserTypeDefaultValues = ({ userType }: { userType?: IDBUserType }): FormFieldProps => {
  const {
    name,
    permission,
    status,
    approvalRequired: isApprovalRequired = false,
    securitySetting,
    userTypeCode,
    userFlowId,
  } = userType ?? {};

  const {
    mustHaveMfa,
    mustHaveNumber,
    mustHaveSpecialCharacters,
    mustHaveUpperCase,
    minimumLength,
    mustMatchPasswords,
  } = securitySetting ?? {};


  return {
    isApprovalRequired: isApprovalRequired || false,
    name: name || '',
    isEnabled: status === 'ENABLED',
    permission: permission || 'MANAGE_USERS',
    mustHaveMfa: mustHaveMfa || false,
    mustHaveNumber: mustHaveNumber || false,
    mustHaveSpecialCharacters: mustHaveSpecialCharacters || false,
    mustHaveUpperCase: mustHaveUpperCase || false,
    minimumLength: minimumLength || 6,
    mustMatchPasswords: mustMatchPasswords || false,
    userTypeCode: userTypeCode || '',
    userFlowId,
  }
};

const getUpsertUserTypeVariables = ({ canEditSettings = false, defaultValues, item }: { canEditSettings?: boolean; defaultValues: FormFieldProps; item: FormFieldProps; }) => {

  const { 
    mustHaveMfa: initialMustHaveMfa,
    mustHaveNumber: initialMustHaveNumber,
    mustHaveSpecialCharacters: initialMustHaveSpecialCharacters,
    mustHaveUpperCase: initialMustHaveUpperCase,
    minimumLength: initialMinimumLength,
    mustMatchPasswords: initialMustMatchPasswords,
  } = defaultValues;

  const {
    name,
    permission,
    isEnabled = false,
    isApprovalRequired = false,
    mustHaveMfa,
    mustHaveNumber,
    mustHaveSpecialCharacters,
    mustHaveUpperCase,
    minimumLength,
    mustMatchPasswords,
    userTypeCode,
    userFlowId,
  } = item;

  return {
    approvalRequired: isApprovalRequired,
    name,
    permission,
    status: (isEnabled ? 'ENABLED': 'DISABLED') as DBUserTypeStatusType,
    userTypeCode,
    userFlowId,
    securitySetting: {
      mustHaveMfa: canEditSettings ? mustHaveMfa: initialMustHaveMfa,
      mustHaveNumber: canEditSettings ? mustHaveNumber: initialMustHaveNumber,
      mustHaveSpecialCharacters: canEditSettings ? mustHaveSpecialCharacters: initialMustHaveSpecialCharacters,
      mustHaveUpperCase: canEditSettings ? mustHaveUpperCase: initialMustHaveUpperCase,
      minimumLength: Number(canEditSettings ? minimumLength : initialMinimumLength),
      mustMatchPasswords: canEditSettings ? mustMatchPasswords: initialMustMatchPasswords,
    },
  }
};



export {getUpsertUserTypeDefaultValues, getUpsertUserTypeVariables };
