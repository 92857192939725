import { gql } from '@apollo/client';

export const CREATE_ASSET = gql`
  mutation createAsset($asset: CreateProjectAssetInput!) {
    asset {
      createAsset(asset: $asset) {
        asset {
          contentType
          s3Key
        }
        signedUrl
      }
    }
  }
`;
