import { gql } from '@apollo/client';

export const CREATE_PROJECT_INVITATION = gql`
  mutation createProjectInvitation($invitation: ProjectInvitationInputType!) {
    invitation {
      projectInvitation(invitation: $invitation) {
        created
        customMessage
        id
        emailUserInvited
        invitedUserId
        firstName
        lastName
        groupId
        invitationEmailStatus
        group {
          id
          name
        }
        userType {
          id
          name
        }
      }
    }
  }
`;
