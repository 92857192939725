const RESET_PASSWORD_PAGE_CSS_IDENTIFIERS = Object.freeze({
  block: 'c-reset-password-page',
});

const RESET_PASSWORD_PAGE_DEFAULTS = Object.freeze({
  headerText: 'Reset password',
  pageDescription: 'Please enter your new password',
});

const RESET_PASSWORD_PAGE_CONSTANTS = Object.freeze({
  cssIdentifiers: RESET_PASSWORD_PAGE_CSS_IDENTIFIERS,
  defaults: RESET_PASSWORD_PAGE_DEFAULTS,
});

export { RESET_PASSWORD_PAGE_CONSTANTS };
