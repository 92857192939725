import { gql } from '@apollo/client';

export const GET_PROJECT_INVITATIONS = gql`
  query getProjectInvitations($after: String, $first: Int, $projectId: String!, $filter: String) {
    invitation {
      getProjectInvitations(after: $after, first: $first, projectId: $projectId, filter: $filter) {
        edges {
          cursor
          node {
            created
            id
            invitedUserEmail: emailUserInvited
            customMessage
            firstName
            lastName
            groupId
            invitedUserId
            phoneNumber
            invitationEmailStatus
            group {
              id
              name
            }
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
