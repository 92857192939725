import { gql } from '@apollo/client';

export const CREATE_GROUP = gql`
  mutation createGroup($group: CreateGroupInputType!) {
    group {
      createGroup(group: $group) {
        id
        name
        created
        userCount
        smartCodes {
          code
        }
      }
    }
  }
`;
