import { Dialog, Spinner } from '@netfront/ui-library';
import pluralize from 'pluralize';

import { GROUPS_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, GROUPS_BULK_ACTION_DIALOG_TITLE_MAP } from './GroupsBulkActionDialog.constants';
import { GroupsBulkActionDialogProps } from './GroupsBulkActionDialog.types';

import { useBulkDeleteGroups, useBulkUpdateGroupStatus, useToast } from '../../../../hooks';

const GroupsBulkActionDialog = ({
  bulkActionType,
  isOpen = false,
  onSave,
  handleCloseDialog,
  selectedIds,
}: GroupsBulkActionDialogProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const { handleBulkDeleteGroups, isLoading: isBulkDeleteGroupsLoading = false } = useBulkDeleteGroups({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Groups successfully deleted',
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const { handleBulkUpdateGroupStatus, isLoading: isBulkUpdateGroupStatusLoading = false } = useBulkUpdateGroupStatus({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: `Groups successfully ${bulkActionType === 'activateGroups' ? 'activated' : 'deactivated'}`,
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const updateGroupsStatus = (status: 'ACTIVE' | 'INACTIVE') => {
    void handleBulkUpdateGroupStatus({
      status,
      groupIds: selectedIds?.map(id => Number(id)) ?? [],
    });
  };
  
  const activateGroups = () => updateGroupsStatus('ACTIVE');
  const deactivateGroups = () => updateGroupsStatus('INACTIVE');

  const deleteGroups = () => {
    void handleBulkDeleteGroups({
      groupIds: selectedIds?.map(id => Number(id)) ?? [],
    })
  };


  const saveMap: { [key: string]: () => void } = {
    activateGroups: activateGroups,
    deleteGroups: deleteGroups,
    deactivateGroups: deactivateGroups,
  };


  const handleSave = () => {
    const bulkHandler = saveMap[bulkActionType];

    if (typeof bulkHandler === 'function') {
      bulkHandler();
    } else {
      console.error(`No handler found for bulk action type: ${bulkActionType}`);
    }
  };

  const isLoading = isBulkUpdateGroupStatusLoading || isBulkDeleteGroupsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Dialog
        confirmText={GROUPS_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]}
        isOpen={isOpen}
        title={GROUPS_BULK_ACTION_DIALOG_TITLE_MAP[bulkActionType]}
        onCancel={handleCloseDialog}
        onClose={handleCloseDialog}
        onConfirm={handleSave}
      >
        <p>{GROUPS_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]} {selectedIds?.length ?? 0} {pluralize('group', selectedIds?.length ?? 0)}</p>
      </Dialog>
    </>
  );
};

export { GroupsBulkActionDialog };
