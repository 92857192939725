import React, { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { IGeladaOrganisation, useGetGeladaOrganisationByKey, useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';
import { GeneralTabIcon } from '@netfront/ui-library';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { ACCOUNT_MANAGEMENT_PAGE_CONSTANTS } from '../AccountManagementPages.constants';


import { ACCOUNT_INVITATION_TABLE_COLUMNS } from './AccountInvitations.constants';
import { getOrganisationInvitationTableData } from './AccountInvitations.helpers';
import { IAccountInvitationTableData, IPaginatedInvitationsWithinProjectResponse } from './AccountInvitations.interfaces';

import { GET_ORGANISATION_INVITATIONS } from '../../../../graphql';
import { useToast } from '../../../../hooks';
import { ManageInvitationsGeneralView } from '../../../ManagementViews';
import { IInvitationEdge, IInvite } from '../../../Pages/Pages.interfaces';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';
import { TablePageTemplate } from '../../../Templates';

const { pageTitle } = ACCOUNT_MANAGEMENT_PAGE_CONSTANTS;

const AccountInvitations: NextPage = () => {
  const {
    query: { organisationKey: queryOrganisationKey },
  } = useRouter();
  const { handleToastError } = useToast();
  const { isToggled: isSideBarOpen, toggle: toggleIsSideBarOpen } = useToggle();

  const { defaultPageSize } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [organisationName, setOrganisationName] = useState<string>('');
  const [organisationId, setOrganisationId] = useState<number>();
  const [currentOrganisation, setCurrentOrganisation] = useState<IGeladaOrganisation>();
  const [filteredInvitations, setFilteredInvitations] = useState<IAccountInvitationTableData[]>();
  const [invitations, setInvitations] = useState<IAccountInvitationTableData[]>();
  const [invitationsFilter, setInvitationsFilter] = useState<string>('');
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isPaginationDisabled, setIsPaginationDisabled] = useState<boolean>(false);
  const [lastInvitationCursor, setLastInvitationCursor] = useState<string | undefined>(undefined);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [selectedInvitation, setSelectedInvitation] = useState<IInvite | undefined>();
  const [totalInvitations, setTotalInvitations] = useState<number>(0);

  // organisation
  const { handleGetGeladaOrganisationByKey, isLoading: isGetOrganisationLoading } = useGetGeladaOrganisationByKey({
    onCompleted: ({ geladaOrganisation }) => {
      setOrganisationName(geladaOrganisation.name);
      setCurrentOrganisation(geladaOrganisation);
      setOrganisationId(geladaOrganisation.id);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const [getInvitationsWithinProject, { fetchMore, loading: isGetInvitationsLoading }] = useLoggedGeladaLazyQuery<IPaginatedInvitationsWithinProjectResponse>({
    options: {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({
        invitation: {
          getOrganisationInvitations: { edges: invitationEdges, totalCount = 0},
        },
      }) => {
        if (totalCount === 0) {
          setInvitations([]);
          setFilteredInvitations([]);
          setLastInvitationCursor(undefined);
          setIsPaginationDisabled(true);
          setTotalInvitations(Number(totalCount));

          return;
        }

        const invitationItems = [...invitationEdges] as IInvitationEdge[];
        const { cursor } = invitationEdges[invitationEdges.length - 1];

        setTotalInvitations(Number(totalCount));
        setLastInvitationCursor(String(cursor));
        setIsPaginationDisabled(invitationItems.length >= totalCount || totalCount <= pageSize);

        setInvitations(
          getOrganisationInvitationTableData({
            invites: invitationItems.map((invitationItem) => invitationItem.node),
            onSettingsButtonClick: handleSettingsButtonClick,
          }),
        );
        setFilteredInvitations(
          getOrganisationInvitationTableData({
            invites: invitationItems.map((invitationItem) => invitationItem.node),
            onSettingsButtonClick: handleSettingsButtonClick,
          }),
        );
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: GET_ORGANISATION_INVITATIONS,
  });

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleOnPaginate = async () => {
    setIsLoadingMore(true);

    const data = await fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          invitation: {
            getOrganisationInvitations: { edges: currentInvitationEdges },
          },
        } = fetchMoreResult;

        if (!currentInvitationEdges.length) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return previousQueryResult;
        }

        const { invitation: previousQueryResultGroup } = previousQueryResult;
        const { getOrganisationInvitations: previousQueryResultGetPaginatedGroup } = previousQueryResultGroup;
        const { edges: previousUserEdges } = previousQueryResultGetPaginatedGroup;

        const allFetchedUserEdges = [...previousUserEdges, ...currentInvitationEdges];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          ...previousQueryResult,
          invitation: {
            ...previousQueryResultGroup,
            getOrganisationInvitations: {
              ...previousQueryResultGetPaginatedGroup,
              edges: allFetchedUserEdges,
            },
          },
        };
      },
      variables: {
        after: lastInvitationCursor,
        first: pageSize,
        isPrevious: false,
      },
    });

    const {
      data: {
        invitation: {
          getOrganisationInvitations: { edges: currentInvitationEdges },
        },
      },
    } = data;

    const currentFetchedInvitations = [...currentInvitationEdges].map(
      (currentInvitationEdge: { node: unknown }) => currentInvitationEdge.node,
    );

    const allFetchedInvitations = [...(invitations as IInvite[]), ...currentFetchedInvitations];

    setInvitations(
      getOrganisationInvitationTableData({
        invites: allFetchedInvitations as IInvite[],
        onSettingsButtonClick: handleSettingsButtonClick,
      }),
    );
    setIsLoadingMore(false);
    setIsPaginationDisabled(allFetchedInvitations.length >= totalInvitations);
    setLastInvitationCursor(currentInvitationEdges[currentInvitationEdges.length - 1].cursor);
  };

  const handleSettingsButtonClick = (selectedInvite: IInvite) => {
    setSelectedInvitation(selectedInvite);
    toggleIsSideBarOpen();
  };

  const handleAddNewInvitationClick = () => {
    setSelectedInvitation(undefined);
    toggleIsSideBarOpen();
  };

  const handleSearch = (val: string) => {
    setInvitationsFilter(val);

    void getInvitationsWithinProject({
      variables: {
        filter: val,
        first: pageSize,
        organisationId: Number(organisationKey),
      },
    });
  }

  const handleGetInvitations = () => {
    void getInvitationsWithinProject({
      variables: {
        filter: invitationsFilter,
        first: pageSize,
        organisationId: Number(organisationId),
      },
    });
  };

  useEffect(() => {
    if (!organisationId) return;
    handleGetInvitations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId]);

  useEffect(() => {
    if (!organisationKey) {
      return;
    }

    void handleGetGeladaOrganisationByKey({
      organisationKey: String(organisationKey),
      shouldIncludeOrganisationLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationKey]);

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);

  const isLoading = isGetInvitationsLoading || isLoadingMore || isGetOrganisationLoading;

  return (
    <TablePageTemplate<IAccountInvitationTableData>
      activePage="invitations"
      activeSubPage="invitations"
      columns={ACCOUNT_INVITATION_TABLE_COLUMNS}
      data={filteredInvitations ?? []}
      description={`Manage users for ${organisationName}`}
      handleAddNewClick={handleAddNewInvitationClick}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={handleOnPaginate}
      handleSearch={handleSearch}
      informationBoxMessage={<>Manage <strong>{String(organisationName)}</strong> user invitations: <strong>{totalInvitations} {pluralize('invitation', totalInvitations)}</strong></>}
      isLoading={isLoading}
      isPaginationDisabled={isPaginationDisabled}
      isSideBarOpen={isSideBarOpen}
      organisation={currentOrganisation}
      pageSize={pageSize}
      pageTitle={pageTitle}
      tableType="invitations"
      tabs={[
        {
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () => (
            <ManageInvitationsGeneralView
              organisationId={organisationId}
              selectedInvitation={selectedInvitation}
              onClose={toggleIsSideBarOpen}
              onUpdateInvitations={handleGetInvitations}
            />
          ),
        },
      ]
      }
      title={organisationName}
      toggleIsSideBarOpen={toggleIsSideBarOpen}
      totalItems={totalInvitations}
      isInternal
      isOrganisationLevel
    />
  );
};

export { AccountInvitations };
