import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IProjectRolesTableData } from './ProjectRoles.interfaces';

import { StatusIndicator } from '../../../../components/Shared';

const PROJECT_ROLES_TABLE_COLUMNS: ReadonlyArray<Column<IProjectRolesTableData>> = [
  {
    accessor: 'name',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Type</div>,
    width: '25%',
  },
  {
    accessor: 'userCount',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>User count</div>,
    width: '24%',
  },
  {
    accessor: 'status',
    Cell: ({ value }) => (
      <div>
        <StatusIndicator status={value} />
      </div>
    ),
    Header: () => <div>Status</div>,
    width: '24%',
  },
  {
    accessor: 'created',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value ? new Date(value).toLocaleDateString() : '-'}</div>,
    Header: () => <div className="text-left w-full">Created</div>,
    width: '25%',
  },

  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { name, id, onClick } }) => (
      <div className="flex justify-end">
        <SettingsButton supportiveText={`${name} settings`} onClick={() => onClick(id)} />
      </div>
    ),
    Header: <span className="h-hide-visually">Actions</span>,
    width: '2%',
  },
];

export { PROJECT_ROLES_TABLE_COLUMNS };
