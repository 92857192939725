import { gql } from '@apollo/client';

export const GET_GROUPS_WITHIN_PROJECT = gql`
  query getGroupsWithinProject($projectId: String!) {
    group {
      searchGroups(projectId: $projectId) {
        id
        name
      }
    }
  }
`;
