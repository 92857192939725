import { gql } from '@apollo/client';

export const GET_PAGINATED_PROJECTS = gql`
  query getPaginatedGeladaProjects($after: String, $first: Int, $filter: String, $organisationKey: String!) {
    project {
      organisationProjects(after: $after, first: $first, filter: $filter, organisationKey: $organisationKey) {
        totalCount
        edges {
          cursor
          node {
            logo {
              presignedUrl
            }
            id
            name
            projectAdministrators {
              firstname
              lastname
            }
            isFavourite
          }
        }
      }
    }
  }
`;
