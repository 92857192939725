import { gql } from '@apollo/client';

export const RESEND_INVITATION = gql`
  mutation resendInvitation($invitationId: Int!) {
    invitation {
      resendInvitation(invitationId: $invitationId) {
        id
        invitationEmailStatus
      }
    }
  }
`;
