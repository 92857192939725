import { gql } from '@apollo/client';

export const ADD_UNIT = gql`
  mutation AddUnit($groupId: Int!, $unitName: String!) {
    unit {
      add(groupId: $groupId, unitName: $unitName) {
        groupId
        id
        name
      }
    }
  }
`;
