import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName } from '@netfront/common-library';
import {
  FORM_ELEMENT_CSS_IDENTIFIERS,
  FORM_FIELDS,
  IPasswordValidation,
  IResetPasswordFormFields,
} from '@netfront/gelada-identity-library';
import { Button, Input, Spacing } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';


import { RESET_PASSWORD_FORM_CONSTANTS } from './ResetPasswordForm.constants';
import { ResetPasswordFormProps } from './ResetPasswordForm.interfaces';

import { getFormFieldErrorMessage } from '../../Forms.helpers';

const { cssIdentifiers, initialValues } = RESET_PASSWORD_FORM_CONSTANTS;

const ResetPasswordForm = ({
  buttonText = 'Reset password',
  isSubmitting,
  onResetPassword,
  passwordValidation,
}: ResetPasswordFormProps) => {
  const { button: buttonElementCssId, container: containerElementCssId, form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    confirmPassword: {
      id: confirmPasswordId,
      label: confirmPasswordLabel,
      placeholder: confirmPasswordPlaceholder,
      validationErrorMessage: confirmPasswordValidationErrorMessage,
    },
    password: {
      id: passwordId,
      label: passwordLabel,
      maxLength: passwordMaxLength,
      minLength: passwordMinLength,
      placeholder: passwordPlaceholder,
    },
  } = FORM_FIELDS;

  const { block: blockCssId } = cssIdentifiers;

  const { maxLength: maxPasswordLength = passwordMaxLength, minLength: minPasswordLength = passwordMinLength } =
    passwordValidation ?? ({} as IPasswordValidation);

  const { control, handleSubmit, reset } = useForm<IResetPasswordFormFields>({
    defaultValues: initialValues,
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        confirmPassword: yup
          .string()
          .label(confirmPasswordLabel)
          .max(maxPasswordLength)
          .min(minPasswordLength)
          .oneOf([yup.ref('password'), null], confirmPasswordValidationErrorMessage)
          .required(),
        password: yup.string().label(passwordLabel).max(passwordMaxLength).min(passwordMinLength).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  return (
    <form
      className={getBEMClassName(blockCssId, formElementCssId)}
      onSubmit={(event) => {
        void handleSubmit(({ password: newPassword }: IResetPasswordFormFields) => {
          onResetPassword({
            newPassword,
          });

          reset();
        })(event);
      }}
    >
      <div className="c-form__body">
        <Spacing size="small">
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(blockCssId, passwordId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={passwordId}
                isDisabled={isSubmitting}
                labelText={passwordLabel}
                placeholder={passwordPlaceholder}
                type="password"
                {...field}
              />
            )}
          />
        </Spacing>

        <Spacing size="small">
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(blockCssId, passwordId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={confirmPasswordId}
                isDisabled={isSubmitting}
                labelText={confirmPasswordLabel}
                placeholder={confirmPasswordPlaceholder}
                type="password"
                {...field}
              />
            )}
          />
        </Spacing>
      </div>

      <div className="c-form__footer">
        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          <Button text={buttonText} type="submit" />
        </div>
      </div>
    </form>
  );
};

export { ResetPasswordForm };
