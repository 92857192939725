/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBUser } from "@netfront/gelada-identity-library";
import { FormFieldProps } from "@netfront/ui-library";
import { IUpdatedCustomField } from "types";


const getUserDefaultValues = ({ user, customFields = [] }: { customFields?: IUpdatedCustomField[]; user: IDBUser; }): FormFieldProps => {
  const {
    communityName,
    credential,
    firstName,
    id: userId,
    lastName,
    isProjectAdmin = false,
    status,
  } = user;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { email = '' , phoneNumber = '', phoneNumberCountryCode = ''} = credential ?? {};

  const fullName = `${firstName} ${lastName}`.trim();

  const customFieldsObject = customFields.reduce((acc, item) => {
    const { customFieldId, text } = item;
    
    acc[`customField${customFieldId}`] = text;
    
    return acc;
  }, {} as FormFieldProps);

  return {
    firstName: firstName || '',
    lastName: lastName || '',
    email: email || '',
    communityName: communityName || '',
    userId: userId || '',
    phoneNumber: phoneNumber || '', 
    isTeamAdmin: isProjectAdmin,
    hasStatusChanged: false,
    isUserStatusActive: status === 'ACTIVE',
    isSocialAccessActive: false,
    fullName,
    phoneNumberCountryCode: phoneNumberCountryCode || '',
    ...customFieldsObject,
  };
};

const getCustomFieldResponses = ({ customFields = [], item }: { customFields?: IUpdatedCustomField[]; item: FormFieldProps }) => {

  const formattedResponses = customFields.map(({ customFieldId }) => {
    return {
      customFieldId,
      value: String(item[`customField${customFieldId}`] ?? ''),
    };
  });

  return formattedResponses;
};


export {getUserDefaultValues, getCustomFieldResponses };
