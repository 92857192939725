import { useContext, useEffect, useState } from 'react';

import { Spacing, Spinner, DialogButtons } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { QuickAddOrganisationProjectSuccessProps } from './QuickAddOrganisationProjectSuccess.interfaces';

import { CachingEntitiesContext } from '../../../../../../context';
import { useToast, useActivateProject } from '../../../../../../hooks';
import { pageDefaults } from '../../../../../Pages/RegisterFlowPage/RegisterFlowPage.constants';

const QuickAddOrganisationProjectSuccess = ({ productName = 'EKARDO', projectDetails }: QuickAddOrganisationProjectSuccessProps) => {
  const productSpecificPageDefaults = pageDefaults[productName];
  const { product } = productSpecificPageDefaults;

  const { dashboardUrl } = useContext(CachingEntitiesContext);
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [redirectUrl, setRedirectUrl] = useState('');

  const { handleActivateProject, isLoading: isActivateProjectLoading = false } = useActivateProject({
    onCompleted: () => {
      push(redirectUrl).catch((error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      });
    },
  });

  const handleActivation = () => {
    const { projectId, organisationId, projectName } = projectDetails;
    handleActivateProject({
      projectId,
      productName,
      organisationId,
      projectName,
    });
  };

  useEffect(() => {
    if (!dashboardUrl) {
      return;
    }

    const { projectId, organisationKey } = projectDetails;

    setRedirectUrl(`${String(dashboardUrl)}/${organisationKey}/${projectId}`);
  }, [dashboardUrl, projectDetails]);

  return (
    <>
      <Spinner isLoading={isActivateProjectLoading} />

      <Spacing>
        <p className="c-registration-layout__description">You&apos;re all set, now it&apos;s time to the most out of using {product}</p>
      </Spacing>

      <DialogButtons submitButtonText="Let's get started" onSave={handleActivation} onSaveButtonType="button" />
    </>
  );
};

export { QuickAddOrganisationProjectSuccess };
