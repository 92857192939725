import { useState } from 'react';

import { Dialog } from '@netfront/ui-library';

import { QuickAddOrganisationProjectForm, QuickAddOrganisationProjectSuccess } from './Components';
import { QuickAddOrganisationProjectProps } from './QuickAddOrganisationProject.interfaces';

import { ICreateProjectDetails } from '../../../../types';
import { pageDefaults } from '../../../Pages/RegisterFlowPage/RegisterFlowPage.constants';

const QuickAddOrganisationProject = ({ isOpen = true, onClose, productName = 'EKARDO' }: QuickAddOrganisationProjectProps) => {
  const [projectDetails, setProjectDetails] = useState<ICreateProjectDetails>();
  const [step, setStep] = useState<'create' | 'success'>('create');

  const onSave = (returnedProjectDetails: ICreateProjectDetails) => {
    setProjectDetails(returnedProjectDetails);
    setStep('success');
  };

  const productSpecificPageDefaults = pageDefaults[productName];
  const { projectNamingConvention } = productSpecificPageDefaults;

  return (
    <Dialog
      isOpen={isOpen}
      title={step === 'create' ? `Create your first ${projectNamingConvention}` : 'Congratulations!'}
      isNarrow
      onClose={step === 'create' ? onClose : undefined}
    >
      {step === 'create' && <QuickAddOrganisationProjectForm productName={productName} onClose={onClose} onSave={onSave} />}

      {step === 'success' && projectDetails && (
        <QuickAddOrganisationProjectSuccess productName={productName} projectDetails={projectDetails} />
      )}
    </Dialog>
  );
};

export { QuickAddOrganisationProject };
