import { IContact, IGroupUser } from './UserGroupContactsTab.interfaces';

export const getGroupContactItems = (items: IGroupUser[], groupId: number) => {
  return items
    .map(({ user }) => {
      const userGroupContactListItems: IContact = {
        id: user.id,
        groupId,
        firstname: user.firstname,
        lastname: user.lastname,
        communityName: user.communityName,
        phoneNumber: user.credential.phoneNumber,
        email: user.credential.email,
      };

      return userGroupContactListItems;
    })
    .sort((a, b) => a.firstname.localeCompare(b.firstname));
};
