import { gql } from '@apollo/client';

export const GET_PROJECT_USERS = gql`
  query GetProjectUsers($projectId: String!) {
    membership {
      getPaginatedProjectMembers(projectId: $projectId) {
        items {
          id
          groupId
          user {
            id
            firstname
            lastname
            communityName
            credential {
              email
              phoneNumber
            }
          }
        }
      }
    }
  }
`;
