import { IAcceptInvitationFormFields } from './AcceptInvitationForm.interfaces';

const ACCEPT_INVITATION_FORM_CSS_IDENTIFIERS = Object.freeze({
  block: 'c-login-form',
});

const ACCEPT_INVITATION_FORM_INITIAL_VALUES: IAcceptInvitationFormFields = {
  password: '',
  confirmPassword: '',
};

export { ACCEPT_INVITATION_FORM_CSS_IDENTIFIERS, ACCEPT_INVITATION_FORM_INITIAL_VALUES };
