/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IPlaceholderOption } from "types";

const getPlaceholderDefaultValues = ({ placeholder }: { placeholder?: IPlaceholderOption}): FormFieldProps => {
  const { 
    key,
    value,
  } = placeholder ?? {};
  
  const cleanKey = key?.replace(/[\\[\]]/g, "") || '';

  return {
    key: cleanKey,
    value: value || '',
  };

};



export { getPlaceholderDefaultValues };