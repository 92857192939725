import { CompleteVerificationProps } from './CompleteVerification.interfaces';

const CompleteVerification = ({ secret }: CompleteVerificationProps) => {
  return (
    <div className="c-complete-verification">
      <img alt="completed two step verification" className="c-complete-verification__image" src="/images/confirmation-code-icon.png" />
      <p className="c-complete-verification__text">Store your secret safely incase you lose access to your authenticator app.</p>
      <p>{secret}</p>
      <p className="c-complete-verification__text">
        From now on, you&apos;ll use a verification code from your third-party authentication app to sign in to your account.
      </p>
    </div>
  );
};

export { CompleteVerification };
