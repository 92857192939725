import { useContext, useEffect, useState } from 'react';

import { DialogButtons, Spinner } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { CreateProjectSuccessDialogProps } from './CreateProjectSuccessDialog.interfaces';

import { CachingEntitiesContext } from '../../../../context';
import { useActivateProject, useToast } from '../../../../hooks';

const CreateProjectSuccessDialog = ({ project, organisationKey, product }: CreateProjectSuccessDialogProps) => {
  const { handleToastError } = useToast();
  const { dashboardUrl } = useContext(CachingEntitiesContext);
  const { push } = useRouter();

  const [customText, setCustomText] = useState('');
  const [redirectUrl, setRedirectUrl] = useState<string>('');

  const { handleActivateProject, isLoading: isActivateProjectLoading = false } = useActivateProject({
    onCompleted: () => {
      push(redirectUrl).catch((error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      });
    },
  });

  const handleActivation = () => {

    handleActivateProject({
      projectId: project.id,
      productName: product,
      organisationId: project.organisationId,
      projectName: project.name,
    });


  };

  useEffect(() => {
    if (!(dashboardUrl)) return;
    setRedirectUrl(`${String(dashboardUrl)}/${organisationKey}/${project.id}`);
    setCustomText(product === 'QUICKCODES' ? 'space' : 'project');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUrl, project])

  return (
    <>
      <Spinner isLoading={isActivateProjectLoading} />
      <p>Let&apos;s get started configuring the new {customText}</p>
      <DialogButtons
        submitButtonText="Let's get started"
        onSave={handleActivation}
        onSaveButtonType="button"
      />
    </>
  );
};

export { CreateProjectSuccessDialog };
