const settingSections = [
  'AUTHENTICATION',
  'LIBRARIES',
  'NOTIFICATIONS',
  'MODERATION',
  'PRIVACY',
  'REPORTS',
  'SETTINGS',
  'SNIPPETS',
  'THEMES',
  'CONFIGURATION',
];

export { settingSections };