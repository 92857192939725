import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { IGeladaProject, ISetting, useDeleteGeladaProject, useGetGeladaProject, useGetSettings } from '@netfront/gelada-identity-library';
import { SideBarTabSet, Spinner, Dialog, useControlledForm, ControlledForm, FormFieldProps, TabSetImperativeHandleRef, GeneralTabIcon, KeyTabIcon, NotificationIcon } from '@netfront/ui-library';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { UpsertProjectSidebarFeaturesTab, UpsertProjectSidebarNotificationsTab } from 'components/Tabs/Project';

import { getSettingsVariables, getUpsertProjectDefaultValues } from './UpsertProjectSidebar.helpers';
import { UpsertProjectSidebarProps } from './UpsertProjectSidebar.interfaces';

import { CachingEntitiesContext, PermissionContext } from '../../../context';
import { useToast, useUpsertProject } from '../../../hooks';
import { useUpdateAccessToken, useUserIsAdmin } from '../../../utils';
import { UpsertProjectSidebarGeneralTab } from '../../Tabs/Project/UpsertProjectSidebarGeneralTab';

const UpsertProjectSidebar = ({
  selectedProjectId,
  organisationId,
  onCreateProject,
  onUpdateProject,
  onDeleteProject,
  isSideBarOpen,
  handleOpenCloseSideBar,
  onDeleteAsset,
}: UpsertProjectSidebarProps) => {
  const { isAdmin } = useUserIsAdmin();
  const { handleToastError, handleToastSuccess } = useToast();
  const { isProduct } = useContext(CachingEntitiesContext);
  const { hasPermission } = useContext(PermissionContext);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [project, setProject] = useState<IGeladaProject>();
  const [settings, setSettings] = useState<ISetting[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasEditPermission, setHasEditPermission] = useState<boolean>(true);

  const uploadedLogoRef =  useRef<{value: File | undefined}>({ value: undefined });
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset, setValue, getValues, watch } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().label('Title').required(),
        key: yup.string().label('Key').required(),
      }),
    ),
  });

  const { updateRefreshToken, isLoading: isHandleRefreshTokenLoading } = useUpdateAccessToken();

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading = false } = useGetGeladaProject({
    fetchPolicy: 'no-cache',
    onCompleted: ({ geladaProject }) => {
      setProject(geladaProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleGetSettings, isLoading: isGetSettingsLoading = false } = useGetSettings({
    fetchPolicy: 'no-cache',
    onCompleted: ({ settings: returnedSettings }) => {
      setSettings(returnedSettings);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleDeleteGeladaProject, isLoading: isDeleteGeladaProjectLoading = false } = useDeleteGeladaProject({
    onCompleted: ({ isCompleted }: { isCompleted: boolean }) => {
      setIsDeleteDialogOpen(false);

      if (isCompleted && onDeleteProject) onDeleteProject(String(selectedProjectId));

      handleToastSuccess({
        message: `Project successfully deleted`,
      });
    },
    onError: (error: ApolloError) => {
      setIsLoading(false);
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleOnCreateProject = (returnedProject: IGeladaProject) => {
    updateRefreshToken(() => {
      onCreateProject(returnedProject);
      setIsSubmitting(false);
    });

  };


  const { handleUpsertProject, isLoading: isUpsertProjectLoading = false } = useUpsertProject({
    onCreate: (returnedProject) => {
      reset();
      handleOnCreateProject(returnedProject);
    },
    onUpdate: (returnedProject) => {
      reset();
      setIsSubmitting(false);
      onUpdateProject(returnedProject);
    }
  });

  const handleSave = (item: FormFieldProps) => {
    setIsSubmitting(true);
    handleUpsertProject({
      projectId: project?.id,
      organisationId: Number(organisationId),
      uploadedFile: uploadedLogoRef.current.value,
      isProduct,
      variables: {
        projectVariables: item,
        settings: getSettingsVariables({ settings: settings as ISetting[], item })
      }
    });
  };

  const handleDelete = () => {
    void handleDeleteGeladaProject({
      projectId: String(selectedProjectId),
    });
  };

  const handleDeleteAsset = (projectId: string) => {
    if (onDeleteAsset) onDeleteAsset(projectId);
    uploadedLogoRef.current.value = undefined;
  };

  const onUploadFile = (uploadedFile: File) => {
    uploadedLogoRef.current.value = uploadedFile;
  };


  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };

  useEffect(() => {
    setIsSubmitting(false);
    setHasEditPermission(selectedProjectId ? (hasPermission(selectedProjectId, 'EDIT') || hasPermission(String(organisationId), 'EDIT')) : true);
    if (!selectedProjectId) {
      setProject(undefined);
      handleGetSettings({
        projectId: '',
      });
      return;
    }

    handleGetSettings({
      projectId: selectedProjectId,
    });

    void handleGetGeladaProject({
      projectId: selectedProjectId,
      shouldIncludeProjectLogo: true,
      shouldIncludeProjectSettings: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectId]);

  useEffect(() => { 
    if (selectedProjectId && !project) return;
    if (!settings) return;

    setDefaultValues(getUpsertProjectDefaultValues({ isProduct, project, settings }));

  }, [project, settings, isProduct, selectedProjectId]);

  return (
    <>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <SideBarTabSet
          defaultActiveTabId="id_project_general_tab"
          handleOpenCloseSideBar={() => {
            handleOpenCloseSideBar();
          }}
          hasViewPadding={false}
          isSaveButtonDisabled={!hasEditPermission}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              id: 'id_project_general_tab',
              label: 'General',
              view: () => {
                return (
                  <UpsertProjectSidebarGeneralTab
                    control={control as Control<FormFieldProps>}
                    initialAssetId={defaultValues?.assetId ?? ''}
                    isLoading={isGetGeladaProjectLoading || isUpsertProjectLoading || isHandleRefreshTokenLoading || isGetSettingsLoading}
                    isReadOnly={!hasEditPermission}
                    isSubmitting={isSubmitting}
                    projectId={selectedProjectId}
                    setValue={setValue}
                    onDeleteAsset={handleDeleteAsset}
                    onUploadFile={onUploadFile}
                  />
                );
              },
              icon: GeneralTabIcon,
            },
            {
              id: 'id_project_features_tab',
              label: 'Features',
              isHidden: !isAdmin || isProduct,
              view: () => {
                return (
                  <UpsertProjectSidebarFeaturesTab
                    getValues={getValues}
                    initialServices={defaultValues?.services ?? []}
                    initialValues={defaultValues ?? []}
                    isReadOnly={false}
                    settings={settings ?? []}
                    setValue={setValue}
                    watch={watch}
                  />
                );
              },
              icon: KeyTabIcon,
            },
            {
              id: 'id_project_notifications_tab',
              label: 'Notifications',
              isHidden: (!isAdmin || isProduct) && !selectedProjectId,
              view: () => {
                return (
                  <UpsertProjectSidebarNotificationsTab
                    projectId={selectedProjectId}
                  />
                );
              },
              icon: NotificationIcon,
            },
          ]}
          onDelete={project && hasEditPermission ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>

      {selectedProjectId && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete ${String(project?.name)}?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
        />
      )}

      <Spinner isLoading={isLoading || isGetGeladaProjectLoading || isDeleteGeladaProjectLoading || isHandleRefreshTokenLoading || isUpsertProjectLoading || isGetSettingsLoading} />
    </>
  );
};

export { UpsertProjectSidebar };
