const serviceNameMap: { [key: string]: string } = {
  GELADA: 'Identity',
  EKARDO: 'Content',
  HOWLER: 'Notifications',
  BONOBO: 'Social media',
  VERVET: 'Licensing',
  MURIQUI: 'Payments',
  CAPUCHIN: 'Scheduler',
  LEMUR: 'Orders',
  BABOON: 'Integrations',
};


export { serviceNameMap };
