

import { useState, useEffect } from 'react';

import { Spacing, Table, FlexContainer, ButtonIconOnly, InputFieldWrapper, BinIcon, CaretDownIcon, PlusIcon } from '@netfront/ui-library';
import { capitalize } from 'utils';

import { SidebarContainer } from 'components/Shared';

import { featureNameMap } from './ProjectServicesOverview.constants';
import { ProjectServicesOverviewProps } from './ProjectServicesOverview.interfaces';


const ProjectServicesOverview = ({ 
  onEditClick, 
  onRemoveClick, 
  initialServices = [],
  watch,
  isReadOnly = false,
}: ProjectServicesOverviewProps) => {
  const [items, setItems] = useState<string[]>(initialServices);

  useEffect(() => {
    const subscription = watch((value) => {
      const { services = [] } = value;
      if (services !== items) {
        setItems(services as string[]);
      }
    });
    return () => subscription.unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <SidebarContainer>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              
              {
                accessor: 'service',
                Cell: ({ value }: { value?: string }) => <span>{value ? value : '-'}</span>,
                Header: () => <div>Service</div>,
                width: '95%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleRemoveService,
                    handleEditService,
                    id,
                    isReadOnly: isServiceReadOnly = false,
                  }
                }: {
                  value: {
                    handleEditService: (id: string) => void;
                    handleRemoveService: (id: string) => void;
                    id: string;
                    isReadOnly?: boolean;
                  }
                }) => !isServiceReadOnly ? (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      icon={BinIcon}
                      isIconBorderVisible={false}
                      text="Remove service"
                      variant="danger--tertiary"
                      onClick={() => handleRemoveService(id)}
                    />
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      icon={CaretDownIcon}
                      isIconBorderVisible={false}
                      text="Edit service"
                      onClick={() => handleEditService(id)}
                    />
                  </FlexContainer>
                ): <></>,
              },
            ]}
            data={items.map((service) => {
              return {
                service: featureNameMap[service] || capitalize(String(service.replace(/_/g, ' '))),
                id: service,
                settingsButtonData: {
                  id: service,
                  handleEditService: onEditClick,
                  handleRemoveService: onRemoveClick,
                  isReadOnly: isReadOnly || !featureNameMap[service],
                },
              };
            })}
          />
        </Spacing>
      )}

      {!isReadOnly && (
        <Spacing>
          <InputFieldWrapper
            id="id_add_service"
            label="Add service"
            labelType="span"
            type="custom"
            isLabelSideBySide
          >
            <FlexContainer justifyContent="flex-end" isFullWidth>
              <ButtonIconOnly icon={PlusIcon} text="Add service" onClick={() => onEditClick(undefined)} />
            </FlexContainer>
          </InputFieldWrapper>
        </Spacing>
      )}
      
    </SidebarContainer>
  );
};



export { ProjectServicesOverview };
