/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useLoggedGeladaLazyQuery, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { ButtonList, EnterIcon, NavigationButton, Preloader, SearchFilter, Spacing } from '@netfront/ui-library';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { IProjectListMember, IProjectMember } from '../UserGroupContactsTab.interfaces';

import { IGetGroupMembersResponse, UserGroupAddContactProps } from './UserGroupAddContact.interfaces';
import styles from './UserGroupAddContact.module.css';

import { IUserEdge, IUserNode } from '../../../../../components/Pages';
import { ADD_CONTACT, GET_PAGINATED_GROUP_MEMBERS } from '../../../../../graphql';
import { useToast } from '../../../../../hooks';

const UserGroupAddContact = ({ contactGroupId, onOpenUpdateContacts, onUpdateContacts }: UserGroupAddContactProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [userSearchFilteredList, setUserSearchFilteredList] = useState<IProjectListMember[]>([]);
  const [userSearchList, setUserSearchList] = useState<IProjectMember[] | []>([]);

  const [addContact, { loading: isAddContactLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onOpenUpdateContacts();
        onUpdateContacts();
        handleToastSuccess({
          message: 'Contact added successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: ADD_CONTACT,
  });

  const [getGroupMembers] = useLoggedGeladaLazyQuery<IGetGroupMembersResponse>({
    options: {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({
        membership: {
          getPaginatedGroupMembers: { edges },
        },
      }) => {
        const groupMemberEdges: IUserEdge[] = edges;
        const groupUsers: IUserNode[] = groupMemberEdges.map(({ node }: IUserEdge) => node);

        setUserSearchList(groupUsers as unknown as IProjectMember[]);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: GET_PAGINATED_GROUP_MEMBERS,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddContact = (id: string | number, label: string) => {
    void addContact({
      variables: {
        groupId: contactGroupId,
        userId: id,
      },
    });
  };

  const handleClickClearSearch = () => {
    setSearchValue('');
    setUserSearchFilteredList([]);
  };

  const handleGetProjectUsers = () => {
    void getGroupMembers({
      variables: {
        groupId: contactGroupId,
        projectId,
      },
    });
  };

  const handleSearchInputChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (!searchValue) {
      setUserSearchFilteredList([]);

      return;
    }

    const filteredSearchList = userSearchList
      .filter(
        ({ user }: IProjectMember) =>
          user.firstName.toLocaleLowerCase().trim().includes(searchValue.trim().toLocaleLowerCase()) ||
          user.lastName.toLocaleLowerCase().trim().includes(searchValue.trim().toLocaleLowerCase()) ||
          user.credential.email.toLocaleLowerCase().trim().includes(searchValue.trim().toLocaleLowerCase()),
      )
      .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
      .map(({ user }) => ({
        id: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));

    setUserSearchFilteredList(filteredSearchList);
  }, [searchValue]);

  useEffect(() => {
    if (!projectId) return;

    handleGetProjectUsers();
  }, [projectId]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  return (
    <>
      <Preloader isLoading={isAddContactLoading} />

      <Spacing size="x-large">
        <NavigationButton
          additionalClassNames="c-sidebar-navigation-button"
          direction="previous"
          icon={EnterIcon}
          rotationCssSuffix="180"
          text="Back to contacts"
          onClick={onOpenUpdateContacts}
        />
      </Spacing>

      <section>
        <SearchFilter
          hasSearchButton={false}
          id="searchContacts"
          isLabelHidden={true}
          labelText="Search contacts"
          name="searchContacts"
          placeholder="Filter name, email"
          type="text"
          value={searchValue}
          isFullWidth
          isLabelSideBySide
          onChange={handleSearchInputChange}
          onClear={handleClickClearSearch}
          onSearch={() => {
            return;
          }}
        />
      </section>

      <section className={cx(styles['c-contact-list-items'])}>
        <ButtonList
          additionalClassNames={cx(styles['c-button-list'])}
          countText="contacts"
          itemCountThreshold={10}
          listItems={userSearchFilteredList}
          isAvatarVisible
          onListItemClick={handleAddContact}
        />
      </section>
    </>
  );
};

export { UserGroupAddContact };
