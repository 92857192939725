import React, { useEffect, useState } from 'react';

import { ISetting } from '@netfront/gelada-identity-library';
import { ControlledForm, FieldsetAccordionGroup, FormFieldProps, SidebarButtons, useControlledForm } from '@netfront/ui-library';
import { Control } from 'react-hook-form';
import { capitalize } from 'utils';

import { ProjectSettingsInput } from '../ProjectSettingsInput';
import { UpsertProjectSettingsFormConfirmationDialog } from '../UpsertProjectSettingsFormConfirmationDialog';

import { settingSections } from './UpsertProjectSettingsForm.constants';
import { getSettingsDefaultValues } from './UpsertProjectSettingsForm.helpers';
import { UpsertProjectSettingsFormProps } from './UpsertProjectSettingsForm.interfaces';



const UpsertProjectSettingsForm = ({ 
  currentService,
  initialValues,
  settings = [],
  onSave,
  onRemove,
  onCancel,
  getValues,
}: UpsertProjectSettingsFormProps) => {
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  
  const { control, handleSubmit, reset, getValues: getLocalisedValues} = useControlledForm({ defaultValues });

  const handleSave = () => {
    setIsConfirmationDialogOpen(false);
    const submitButton = document.getElementById('id_submit_setting_changes');
    submitButton?.click();
  };


  useEffect(() => {
    setDefaultValues(getSettingsDefaultValues({ settings, getValues }));

  }, [getValues, settings]);

  return (
    <>
      
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          onSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation={true}
        // eslint-disable-next-line no-console
        onSubmitError={(errs) => console.log({ errs })}
      >
        <FieldsetAccordionGroup
          data={
            settingSections.map((section) => {
              const sectionSettings: ISetting[] = settings.filter(({ section: settingSection}) => settingSection === section);
              return  {
                id: `id_${section.toLowerCase()}_accordion`,
                label: capitalize(section),
                isOpenOnLoad: true,
                isVisible: sectionSettings.length > 0,
                children: sectionSettings.map(({ inputType, type, description }) => <ProjectSettingsInput key={type} control={control as Control<FormFieldProps>} description={description} name={type} type={inputType} />),
              }
            })
          }
        />
        <button className="h-hide-visually" id="id_submit_setting_changes" type="submit"></button>
        <SidebarButtons
          buttonSize="xs"
          onCancel={() => {
            reset();
            onCancel();
          }}
          onDelete={onRemove}
          onSave={() => setIsConfirmationDialogOpen(true)}
          onSaveButtonType="button"
        />
        <UpsertProjectSettingsFormConfirmationDialog
          currentService={currentService}
          getValues={getLocalisedValues}
          initialValues={initialValues}
          isOpen={isConfirmationDialogOpen}
          settings={settings}
          onCancel={() => setIsConfirmationDialogOpen(false)}
          onSave={handleSave}
        />
      </ControlledForm>
    </>
  );
};


export { UpsertProjectSettingsForm };
