export const USER_STATUS = Object.freeze({
  ACTIVE: true,
  INACTIVE: false,
});

export const PERMISSIONS_ITEMS = [
  { labelText: 'Manage users', id: 'MANAGE_USERS', value: 'MANAGE_USERS' },
  { labelText: 'Edit', id: 'EDIT', value: 'EDIT' },
  { labelText: 'View', id: 'READ', value: 'READ' },
  { labelText: 'Limited', id: 'LIMITED', value: 'LIMITED', product: 'QUICKCODES' },
];
