import { useEffect } from 'react';

import { useCookie } from '@netfront/common-library';
import { useDomain, useIdentitySiteUrls, useLogout } from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { useRevokeSession, useToast } from '../../../hooks';

const LogoutPage = () => {
  const { getDomain, isDomainReady } = useDomain();
  const { getBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { getRefreshTokenCookie } = useCookie();

  const { handleLogout } = useLogout({
    onCompleted: () => {
      localStorage.removeItem('storedUser');
      push(getBaseUrl()).catch((error) =>
        handleToastError({
          error,
        }),
      );

    },
  });

  const { handleRevokeSession, isLoading = false } = useRevokeSession({
    onCompleted: () => {
      const domain = getDomain();
      
      handleLogout({
        domain,
      });
      
    },
  });

  useEffect(() => {
    const refreshToken = getRefreshTokenCookie();
    if (!(isDomainReady && refreshToken)) {
      return;
    }

    void handleRevokeSession({
      refreshToken
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, getRefreshTokenCookie]);

  return <Spinner isLoading={isLoading} />;
};

export { LogoutPage };
