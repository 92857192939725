import React, { useContext, useEffect, useState } from 'react';

import { DBNetfrontServiceType, ISetting } from '@netfront/gelada-identity-library';
import { FormFieldProps, IOption, Select, SidebarButtons } from '@netfront/ui-library';
import { CachingEntitiesContext } from 'context';

import { SidebarContainer } from 'components/Shared';

import { UpsertProjectSettingsForm } from '../UpsertProjectSettingsForm';

import { getServiceOptions } from './UpsertProjectService.helpers';
import { UpsertProjectServiceProps } from './UpsertProjectService.interfaces';


const UpsertProjectService = ({ 
  initialValues,
  selectedService = '',
  serviceItems = {},
  onCancel,
  onRemove,
  onUpdate,
  getValues,
}: UpsertProjectServiceProps) => {

  const { isProduct } = useContext(CachingEntitiesContext);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentService, setCurrentService] = useState<string>('');
  const [serviceOptions, setServiceOptions] = useState<IOption[]>([]);
  const [settings, setSettings] = useState<ISetting[]>([]);


  const handleSave = (item: FormFieldProps) => {
    if (!currentService) {
      setErrorMessage('Please select a service');
      return;
    }
    onUpdate(currentService as DBNetfrontServiceType, item);
  };

  useEffect(() => {
    setCurrentService(selectedService);
  }, [selectedService]);

  useEffect(() => {
    const { services } = getValues();
    setServiceOptions(getServiceOptions({currentServices: services, isProduct}));

  }, [getValues, isProduct]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!(currentService && serviceItems)) return;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    setSettings(serviceItems[currentService] || []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService, serviceItems]);

  return (
    <>
      <SidebarContainer>
        <Select
          errorMessage={errorMessage}
          id="id_service"
          isDisabled={Boolean(selectedService)}
          labelText="Service"
          name="service"
          options={serviceOptions}         
          value={currentService}
          isRequired
          onChange={({ target: { value }}) => {
            setCurrentService(value);
            setErrorMessage('');
          }}
        />
      </SidebarContainer>
      {currentService && settings.length > 0 ? (
        <UpsertProjectSettingsForm 
          currentService={currentService}
          getValues={getValues}
          initialValues={initialValues}
          settings={settings}
          onCancel={onCancel}
          onRemove={selectedService ? () => onRemove(selectedService as DBNetfrontServiceType) : undefined}
          onSave={handleSave}
        />
      ): <></>}

      {(!currentService || settings.length === 0) && (
        <SidebarButtons
          buttonSize="xs"
          onCancel={onCancel}
          onSave={(currentService && settings.length === 0 ) ? () => onUpdate(currentService as DBNetfrontServiceType, {} as FormFieldProps): undefined }
          onSaveButtonType="button"
        />
      )}
    </>
  );
};


export { UpsertProjectService };
