import { useEffect, useState } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { useLoggedGeladaMutation, useSearchUserTypes } from '@netfront/gelada-identity-library';
import { EnterIcon, Input, NavigationButton, SelectWithSearch, SidebarButtons, Spacing } from '@netfront/ui-library';

import { AddSmartCodeViewProps, IAddSmartCodeResponse } from './AddSmartCodeView.interfaces';

import { useToast } from '../../../../../hooks';
import { ADD_SMART_CODE } from '../../../../../hooks/useAddSmartCode/useAddSmartCode.graphql';

const AddSmartCodeView = ({ selectedGroup, onClose, onAdd, projectId }: AddSmartCodeViewProps) => {
  const [selectedUserTypeId, setSelectedUserTypeId] = useState<number | undefined>(undefined);
  const { handleToastError } = useToast();
  const [code, setCode] = useState<string>('');
  const [isUserTypeSearchContentVisible, setIsUserTypeSearchContentVisible] = useState<boolean>(false);
  const [userTypeDropdownListOptions, setUserTypeDropdownItems] = useState<IDropDownListOption[]>([]);

  const [addSmartCode] = useLoggedGeladaMutation<IAddSmartCodeResponse>({
    options: {
      onCompleted: ({ smartCode }) => {
        onAdd(smartCode);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
    mutation: ADD_SMART_CODE,
  });

  const { handleSearchUserTypes } = useSearchUserTypes({
    onCompleted: ({ userTypes }) => {
      setUserTypeDropdownItems(
        userTypes.map(({ id, name }) => ({
          id: String(id),
          label: name,
          value: String(id),
        })),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });
  const onAddSmartCode = () => {
    void addSmartCode({
      variables: {
        projectId: projectId,
        groups: [{ groupId: selectedGroup.id, userTypeId: selectedUserTypeId }],
        code,
      },
    });
  };
  const handleChangeSmartCode = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setCode(value);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleSearchUserTypes({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <Spacing size="x-large">
        <NavigationButton
          additionalClassNames="c-sidebar-navigation-button"
          direction="previous"
          icon={EnterIcon}
          rotationCssSuffix="180"
          text="Back to codes"
          onClick={onClose}
        />
      </Spacing>

      <Spacing size="x-large">
        <SelectWithSearch
          buttonText="All user types" 
          id="userTypes"
          isDisplaySearchContent={isUserTypeSearchContentVisible} 
          labelText="User types"
          searchList={userTypeDropdownListOptions}
          isAvatarVisible
          isLabelSideBySide
          isRequired
          onDisplaySearchContent={() => setIsUserTypeSearchContentVisible(!isUserTypeSearchContentVisible)}
          onSearchItemClick={(id) => setSelectedUserTypeId(Number(id))}
        />
      </Spacing>
      <Spacing key={`smart-code`} size="2x-large">
        <Input id={'smart-code'} labelText={'Code'} name={'Code'} type="text" value={code} isLabelSideBySide onChange={handleChangeSmartCode} />
      </Spacing>

      <SidebarButtons
        buttonSize="xs"
        isSaveButtonDisabled={!selectedUserTypeId}
        onCancel={onClose}
        onSave={() => {
          onAddSmartCode();
        }}
      />
    </>
  );
};

export { AddSmartCodeView };
