import { IGetUserTypesTableDataParams, IProjectRolesTableData } from './ProjectRoles.interfaces';

const getProjectRolesTableData = ({ userTypes, onSettingsButtonClick }: IGetUserTypesTableDataParams): IProjectRolesTableData[] => {
  return userTypes.map(({ created, id, name, status, userCount }): IProjectRolesTableData => {
    return {
      id,
      name,
      settingsButtonData: {
        id,
        name,
        onClick: onSettingsButtonClick,
      },
      userCount,
      created,
      status: status === 'ENABLED' ? 'active' : 'inactive',
    };
  });
};

export { getProjectRolesTableData };
