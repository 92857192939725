import React, { useState, useEffect, useContext } from 'react';

import { GeneralTabIcon } from '@netfront/ui-library';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';
import { IPlaceholderOption } from 'types';

import { PlaceholdersGeneralTab } from 'components/Tabs';

import { PROJECT_PLACEHOLDERS_TABLE_COLUMNS } from './ProjectPlaceholders.constants';
import { getProjectPlaceholdersTableData } from './ProjectPlaceholders.helpers';
import { IProjectPlaceholdersTableData } from './ProjectPlaceholders.interfaces';

import { CachingEntitiesContext } from '../../../../context';
import { 
  useSearchPaginatedPlaceholders, 
  useToast,
} from '../../../../hooks';
import { TablePageTemplate } from '../../../Templates/TablePageTemplate';


const ProjectPlaceholders = () => {
  const { project } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const { handleToastError } = useToast();
  
  const [allPlaceholders, setAllPlaceholders] = useState<IPlaceholderOption[]>([]);

  const [projectName, setProjectName] = useState<string>();
  const [projectId, setProjectId] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [selectedPlaceholder, setSelectedPlaceholder] = useState<IPlaceholderOption>();
  const [placeholdersTableData, setPlaceholdersTableData] = useState<IProjectPlaceholdersTableData[]>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);

  const {
    handleFetchMorePaginatedPlaceholders,
    handleSearchPaginatedPlaceholders,
    isLoading: isGetPlaceholdersLoading = false,
  } = useSearchPaginatedPlaceholders({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ placeholders: { edges, totalCount: returnedTotalCount = 0 } }) => {

      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const placeholderItems = edges.map(({ node }) => node);
      setAllPlaceholders(placeholderItems);
      setIsLoadMoreDisabled(placeholderItems.length >= returnedTotalCount || returnedTotalCount <= pageSize);
      setTotalCount(returnedTotalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });
  
  const handleSideBarClose = () => {
    setSelectedPlaceholder(undefined);
    setIsSideBarOpen(false);
  };

  const handleAddNewCustomFieldClick = () => {
    setIsSideBarOpen(true);
    setSelectedPlaceholder(undefined);
  };

  const handleUpdate = () => {
    handleSideBarClose();
    void handleSearchPaginatedPlaceholders({
      first: pageSize,
      filter: filter,
      projectId: String(projectId),
    });

  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };


  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };
  

  useEffect(() => {
    setPlaceholdersTableData(
      getProjectPlaceholdersTableData({
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedPlaceholder(id);
        },
        placeholders: allPlaceholders,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPlaceholders]);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    void handleSearchPaginatedPlaceholders({
      first: pageSize,
      filter: filter,
      projectId: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, filter, pageSize]);

  useEffect(() => {
    if (!project) {
      return;
    }
    const { name } = project;
    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);


  const isLoading = isGetPlaceholdersLoading;

  return (
    <>
      <TablePageTemplate<IProjectPlaceholdersTableData>
        activePage="placeholders"
        activeSubPage="placeholders"
        additionalBreadcrumbItems={[
          {
            key: '3',
            content: <span>Placeholders</span>,
          },
        ]}
        columns={PROJECT_PLACEHOLDERS_TABLE_COLUMNS}
        data={placeholdersTableData ?? []}
        description="Manage placeholders"
        handleAddNewClick={handleAddNewCustomFieldClick}
        handleOnPageSizeChange={handleOnPageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedPlaceholders({
            after: lastCursor,
            filter,
            first: pageSize,
            projectId: String(projectId),
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={
          <>
            Manage <strong>{String(projectName)}</strong> placeholders:{' '}
            <strong>
              {totalCount} {pluralize('placeholder', totalCount)}
            </strong>
          </>
        }
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        isSideBarOpen={isSideBarOpen}
        pageSize={pageSize}
        pageTitle="Project placeholders"
        searchPlaceholder="Name"
        tableType="placeholders"
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => (
              <PlaceholdersGeneralTab selectedPlaceholder={selectedPlaceholder} onClose={handleSideBarClose} onUpdate={handleUpdate} />
            ),
          },
        ]}
        title={String(projectName)}
        toggleIsSideBarOpen={handleSideBarClose}
        totalItems={totalCount}
        isProjectLevel
      />
    </>
  );
};

export { ProjectPlaceholders };
