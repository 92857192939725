import React, { useContext, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { SideBarTabSet, Spinner, Dialog, useControlledForm, ControlledForm, FormFieldProps, TabSetImperativeHandleRef, GeneralTabIcon, KeyTabIcon } from '@netfront/ui-library';
import { Control, FieldErrors } from 'react-hook-form';
import * as yup from 'yup';



import { getUpsertUserTypeDefaultValues, getUpsertUserTypeVariables } from './UpsertUserTypeSidebar.helpers';
import { UpsertUserTypeSidebarProps } from './UpsertUserTypeSidebar.interfaces';

import { CachingEntitiesContext } from '../../../context';
import { useDeleteUserType, useGetUserType, useToast, useUpsertUserType } from '../../../hooks';
import { useUserIsAdmin } from '../../../utils';
import { UserTypeGeneralTab, UserTypeSecurityTab } from '../../Tabs';

const UpsertUserTypeSidebar = ({
  handleSideBarClose,
  isSideBarOpen = false,
  onUpdate,
  projectId,
  selectedUserTypeId,
}: UpsertUserTypeSidebarProps) => {
  const { isAdmin } = useUserIsAdmin();
  const { handleToastError, handleToastSuccess } = useToast();
  const { isProduct } = useContext(CachingEntitiesContext);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const tabsetRef = useRef<TabSetImperativeHandleRef>(null);

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().label('Role').required(),
        permission: yup.string().label('Permission').required(),
      }),
    ),
  });

  

  const { handleGetUserType, isLoading: isGetUserTypeLoading = false } = useGetUserType({
    fetchPolicy: 'no-cache',
    onCompleted: ({ userType }) => {
      setDefaultValues(getUpsertUserTypeDefaultValues({ userType }));
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const { handleDeleteUserType, isLoading: isDeleteUserTypeLoading = false } = useDeleteUserType({
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
     
      onUpdate();
      handleToastSuccess({
        message: `Role successfully deleted`,
      });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });




  const { handleUpsertUserType, isLoading: isUpsertUserTypeLoading = false } = useUpsertUserType({
    onUpdate: () => {
      reset();
      onUpdate();
    },
  });

  const handleClose = () => {
    reset();
    handleSideBarClose();
  };

  const handleSave = (item: FormFieldProps) => {

    const variables = getUpsertUserTypeVariables({ item, defaultValues: defaultValues as FormFieldProps, canEditSettings: isAdmin && !isProduct });

    handleUpsertUserType({
      userTypeId: selectedUserTypeId,
      projectId,
      canEditSettings: isAdmin && !isProduct,
      ...variables,
    });
  };

  const handleDelete = () => {
    if (!selectedUserTypeId) return;
    void handleDeleteUserType({
      userTypeId: selectedUserTypeId,
    });
  };


  const triggerTabsOnErrorHandler = (errs: FormFieldProps) => {
    if (tabsetRef.current) {
      tabsetRef.current.handleError(errs);
    }
  };

  const triggerTabsOnSuccessHandler = () => {
    if (tabsetRef.current) {
      tabsetRef.current.handleSuccess();
    }
  };


  useEffect(() => {
    if (selectedUserTypeId) {
      void handleGetUserType({
        userTypeId: selectedUserTypeId
      });
    } else {
      setDefaultValues(getUpsertUserTypeDefaultValues({}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTypeId])


  return (
    <>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          triggerTabsOnSuccessHandler();
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        onSubmitError={(errs: FieldErrors<FormFieldProps>) => {
          triggerTabsOnErrorHandler(errs as FormFieldProps);
        }}
      >
        <SideBarTabSet
          defaultActiveTabId="id_project_general_tab"
          handleOpenCloseSideBar={handleClose}
          hasViewPadding={false}
          isSideBarOpen={isSideBarOpen}
          tabs={[
            {
              id: 'id_project_general_tab',
              label: 'General',
              view: () => {
                return (
                  <UserTypeGeneralTab control={control as Control<FormFieldProps>} selectedUserTypeId={selectedUserTypeId} />
                );
              },
              icon: GeneralTabIcon,
            },
            {
              id: 'id_project_features_tab',
              label: 'Security',
              isHidden: !isAdmin || isProduct,
              view: () => {
                return (
                  <UserTypeSecurityTab control={control as Control<FormFieldProps>} />
                );
              },
              icon: KeyTabIcon,
            },
          ]}
          onDelete={selectedUserTypeId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"
        />
      </ControlledForm>

      {selectedUserTypeId && (
        <Dialog
          isOpen={isDeleteDialogOpen}
          title={`Delete role?`}
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
        />
      )}

      <Spinner isLoading={isGetUserTypeLoading || isDeleteUserTypeLoading || isUpsertUserTypeLoading} />
    </>
  );
};

export { UpsertUserTypeSidebar };
