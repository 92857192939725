import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, Input, Spacing } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getFormFieldErrorMessage } from '../Forms.helpers';

import { PROFILE_ACCOUNT_FORM_CSS_IDENTIFIERS } from './ProfileAccountForm.constants';
import { IProfileAccountFormFields, ProfileAccountFormProps } from './ProfileAccountForm.interfaces';


const ProfileAccountForm = ({
  buttonText = 'Update',
  isSubmitting,
  onDeleteUser,
  onUpdateUserDetails,
  user,
}: ProfileAccountFormProps) => {

  const { button: buttonElementCssId, container: containerElementCssId, form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    email: { id: emailId, label: emailLabel, placeholder: emailPlaceholder, validationErrorMessage: emailValidationErrorMessage },
    firstName: { id: firstNameId, label: firstNameLabel, placeholder: firstNamePlaceholder },
    lastName: { id: lastNameId, label: lastNameLabel, placeholder: lastNamePlaceholder },
  } = FORM_FIELDS;

  const { block: blockCssId } = PROFILE_ACCOUNT_FORM_CSS_IDENTIFIERS;

  const {
    credential: { email: userEmail },
    firstName: userFirstName,
    id: userId,
    lastName: userLastName,
  } = user;

  const { control, handleSubmit } = useForm<IProfileAccountFormFields>({
    defaultValues: {
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
    },
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().label(emailLabel).email(emailValidationErrorMessage).required(),
        firstName: yup.string().label(firstNameLabel).required(),
        lastName: yup.string().label(lastNameLabel).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  return (
    <form
      className={getBEMClassName(blockCssId, formElementCssId)}
      onSubmit={(event) => {
        void handleSubmit(({ email, firstName, lastName }: IProfileAccountFormFields) => {
          onUpdateUserDetails({
            email,
            firstName,
            lastName,
          });
        })(event);
      }}
    >
      <div className="c-form__body">
        <Spacing size="small">
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(blockCssId, firstNameId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={firstNameId}
                isDisabled={isSubmitting}
                labelText={firstNameLabel}
                placeholder={firstNamePlaceholder}
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>

        <Spacing size="small">
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(blockCssId, lastNameId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={lastNameId}
                isDisabled={isSubmitting}
                labelText={lastNameLabel}
                placeholder={lastNamePlaceholder}
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>

        <Spacing size="small">
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(blockCssId, emailId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={emailId}
                labelText={emailLabel}
                placeholder={emailPlaceholder}
                type="email"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
      </div>

      <div className="c-form__footer">
        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          <div className={getBEMClassName(blockCssId, `update-${buttonElementCssId}-${containerElementCssId}`)}>
            <Button size="small" text={buttonText} type="submit"/>
          </div>

          {onDeleteUser && (
            <div className={getBEMClassName(blockCssId, `delete-${buttonElementCssId}-${containerElementCssId}`)}>
              <Button
                text="Delete"
                type="button"
                onClick={() =>
                  onDeleteUser({
                    userId,
                  })
                }
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export { ProfileAccountForm };
