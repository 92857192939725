import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, InputFieldWrapper, CaretDownIcon, PlusIcon, Pill } from '@netfront/ui-library';
import { IVerifiedEmail } from 'types';

import { VerifiedEmailOverviewProps } from './VerifiedEmailOverview.interfaces';




const VerifiedEmailOverview = ({ onEditClick, verifiedEmails }: VerifiedEmailOverviewProps) => {
  const [items, setItems] = useState<IVerifiedEmail[]>([]);

  useEffect(() => {
    setItems(verifiedEmails);
  }, [verifiedEmails]);

  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            additionalClassNames="c-communities-table"
            columns={[
              {
                accessor: 'displayName',
                Cell: ({ value }: { value: string }) => <span>{value}</span>,
                Header: () => <div>Display name</div>,
                width: '20%',
              },
              {
                accessor: 'isDefault',
                Cell: ({ value }: { value: boolean }) => <span>{value && (<Pill value="Default" variant="blue" />)}</span>,
                Header: () => <div className="h-hide-visually">Is default</div>,
                width: '15%',
              },
              {
                accessor: 'email',
                Cell: ({ value }: { value?: string}) => <span>{value}</span>,
                Header: () => <div>Email</div>,
                width: '25%',
              },
              {
                accessor: 'replyTo',
                Cell: ({ value }: { value?: string}) => <span>{value}</span>,
                Header: () => <div>Reply to</div>,
                width: '20%',
              },
              {
                accessor: 'isVerified',
                Cell: ({ value }: { value: boolean}) => <span>{<Pill value={value ? 'Verified': 'Not verified'} variant={value ? 'green': 'blue'}/>}</span>,
                Header: () => <div>Status</div>,
                width: '15%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleEdit,
                    item,
                  }
                }: {
                  value: {
                    handleEdit: (item: IVerifiedEmail) => void;
                    item: IVerifiedEmail;
                  }
                }) => (
                  <FlexContainer gap="2x-small" justifyContent="end">
                    <ButtonIconOnly
                      additionalClassNames="h-rotate-right"
                      icon={CaretDownIcon}
                      isIconBorderVisible={false}
                      text="Edit feedback"
                      onClick={() => handleEdit(item)}
                    />
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map((item, index) => ({
              id: index,
              displayName: item.displayName,
              isDefault: index === 0,
              email: item.email,
              isVerified: item.isVerified,
              replyTo: item.replyTo,
              settingsButtonData: {
                item,
                handleEdit: onEditClick,
              },
            }))}
          />
        </Spacing>
      )}
      {items.length === 0 && (
        <Spacing>
          <InputFieldWrapper
            id="id_add_email"
            label="Add email"
            labelType="span"
            type="custom"
            isLabelSideBySide
          >
            <FlexContainer justifyContent="flex-end" isFullWidth>
              <ButtonIconOnly icon={PlusIcon} text="Add email" onClick={() => onEditClick()} />
            </FlexContainer>
          </InputFieldWrapper>
        </Spacing>
      )}
    </>
  );
};


export { VerifiedEmailOverview };
