import { IPageDefaults } from './RegisterFlowPage.interfaces';

const pageDefaults = Object.freeze<IPageDefaults>({
  EKARDO: {
    description: 'Start building your next project today!',
    headerText: 'Register now!',
    introduction: 'Get started with Ekardo',
    pageDescription: 'Create an account with Ekardo',
    product: 'Ekardo',
    projectDescription: 'Start building your next project today!',
    projectNamingConvention: 'project',
    accountPlaceholder: `e.g. Ekardo, Google`,
    projectPlaceholder: 'e.g. University trial, Training program'
  },
  KANZI: {
    description: 'Free tier available for translate, text to speech, and PDF.',
    headerText: 'Website translation, speech and accessibility made easy - Register now!',
    introduction: 'Get started with Kanzi',
    pageDescription: 'Create an account with Kanzi',
    product: 'Kanzi',
    projectDescription:
      'This project will be used to configure the Kanzi tool languages and theme as well as provide access to the installation scripts.',
    projectNamingConvention: 'project',
    accountPlaceholder: `e.g. MacDonald's or Tom's account`,
    projectPlaceholder: 'e.g. Translate and Speech or www.website.com',
  },
  QUICKCODES: {
    description: 'It&apos;s free for up to <span class="h-font-weight-bold">100 scans</span> per month — no credit card needed.',
    headerText: 'Create free QR Codes with QuickCodes - Register now!',
    introduction: 'Get started with QuickCodes',
    pageDescription: 'Create an account with QuickCodes',
    product: 'QuickCodes',
    projectDescription: 'Spaces are used to group your QR codes. You can change the name later.',
    projectNamingConvention: 'space',
    accountPlaceholder: `e.g. MacDonald's or Tom's account`,
    projectPlaceholder: 'e.g. Marketing codes or Uni Assignment',
  },
});

export { pageDefaults };
