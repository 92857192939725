import NextLink from 'next/link';

import { Layout } from '../../Shared/Layout';

const ErrorPage = () => {
  return (
    <Layout headerText="It looks like we hit an issue" title="Error">
      <p className="text-center">
        It looks like the page you are trying to access has changed or moved, jump back to the{' '}
        <NextLink href="/">
          <span className="text-underline">home page</span>
        </NextLink>{' '}
        .
      </p>
    </Layout>
  );
};

export { ErrorPage };
