import { useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateGeladaOrganisation, useCreateGeladaProject } from '@netfront/gelada-identity-library';
import { Input, Spacing, Spinner, DialogButtons, UserIcon } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { projectDetailsDefaults } from './QuickAddOrganisationProjectForm.constants';
import {
  QuickAddOrganisationProjectFormProps,
  IQuickAddOrganisationProjectFormDetails,
  IQuickAddOrganisationProjectFormFields,
} from './QuickAddOrganisationProjectForm.interfaces';

import { useToast } from '../../../../../../hooks';
import { capitalize, useUpdateAccessToken, toKebabCase } from '../../../../../../utils';
import { getFormFieldErrorMessage } from '../../../../../Forms';
import { pageDefaults } from '../../../../../Pages/RegisterFlowPage/RegisterFlowPage.constants';

const QuickAddOrganisationProjectForm = ({ onClose, onSave, productName = 'EKARDO' }: QuickAddOrganisationProjectFormProps) => {
  const productSpecificPageDefaults = pageDefaults[productName];
  const { projectNamingConvention, projectDescription, accountPlaceholder, projectPlaceholder } = productSpecificPageDefaults;

  const { handleToastError } = useToast();
  const { updateRefreshToken, isLoading: isUpdateAccessTokenLoading = false } = useUpdateAccessToken();

  const projectRef = useRef<{ value: IQuickAddOrganisationProjectFormDetails }>({ value: projectDetailsDefaults });

  const { control, handleSubmit } = useForm<IQuickAddOrganisationProjectFormFields>({
    defaultValues: {
      organisationName: '',
      projectName: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        organisationName: yup.string().label('Account name').required(),
        projectName: yup.string().label(capitalize(projectNamingConvention)).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  const { handleCreateGeladaProject, isLoading: isCreateProjectLoading = false } = useCreateGeladaProject({
    onCompleted: ({ project: returnedProject }) => {
      const { organisationKey } = projectRef.current.value;

      updateRefreshToken(() => {
        onSave({
          projectId: returnedProject.id,
          organisationId: returnedProject.organisationId,
          projectName: returnedProject.name,
          organisationKey: String(organisationKey),
        });
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreateGeladaOrganisation, isLoading: isCreateOrganisationLoading = false } = useCreateGeladaOrganisation({
    onCompleted: ({ organisation: returnedOrganisation }) => {
      projectRef.current.value = {
        ...projectRef.current.value,
        organisationKey: returnedOrganisation.key,
      };

      const { projectName } = projectRef.current.value;

      void handleCreateGeladaProject({
        isCustomBuild: false,
        name: projectName,
        key: toKebabCase(projectName),
        organisationId: Number(returnedOrganisation.id),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const onSubmit = ({ organisationName, projectName }: IQuickAddOrganisationProjectFormFields) => {
    projectRef.current.value = {
      ...projectRef.current.value,
      organisationName,
      projectName,
    };

    void handleCreateGeladaOrganisation({
      shouldIncludeOrganisationLogo: true,
      description: '',
      name: organisationName,
      key: toKebabCase(organisationName),
    });
  };

  return (
    <form
      onSubmit={(event) => {
        void handleSubmit(({ organisationName, projectName }: IQuickAddOrganisationProjectFormFields) => {
          onSubmit({
            organisationName,
            projectName,
          });
        })(event);
      }}
    >
      <Spinner isLoading={isCreateOrganisationLoading || isCreateProjectLoading || isUpdateAccessTokenLoading} />

      <p className="c-registration-layout__description">{projectDescription}</p>

      <Spacing>
        <Controller
          control={control}
          name="organisationName"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              iconBefore={{
                icon: UserIcon,
              }}
              id="id_account_name"
              labelText="Account name"
              placeholder={accountPlaceholder}
              type="text"
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="projectName"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              iconBefore={{
                icon: UserIcon,
              }}
              id="id_project_name"
              labelText={capitalize(String(projectNamingConvention))}
              placeholder={projectPlaceholder}
              type="text"
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <DialogButtons submitButtonText="Create" onCancel={onClose} onSaveButtonType="submit" />
    </form>
  );
};

export { QuickAddOrganisationProjectForm };
