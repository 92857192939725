import React, { useState, useEffect, useContext } from 'react';

import { GeneralTabIcon, Select, ToggleSwitch } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { CustomFieldsGeneralTab } from 'components/Tabs';

import { PROJECT_ROLES_TABLE_COLUMNS } from './ProjectCustomFields.constants';
import { getProjectCustomFieldsTableData } from './ProjectCustomFields.helpers';
import { IProjectCustomFieldsTableData } from './ProjectCustomFields.interfaces';

import { CachingEntitiesContext } from '../../../../context';
import { ICustomFieldItem, useGetCustomFields, useToast } from '../../../../hooks';
import { TablePageTemplate } from '../../../Templates/TablePageTemplate';


const ProjectCustomFields = () => {
  const { project } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const { handleToastError } = useToast();

  const [projectName, setProjectName] = useState<string>();
  const [projectId, setProjectId] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<'ACTIVE' | 'INACTIVE'>('ACTIVE');
  const [allCustomFields, setAllCustomFields] = useState<ICustomFieldItem[]>([]);
  const [currentCustomFields, setCurrentCustomFields] = useState<ICustomFieldItem[]>([]);
  const [filteredCustomFields, setFilteredCustomFields] = useState<ICustomFieldItem[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [selectedCustomField, setSelectedCustomField] = useState<ICustomFieldItem>();
  const [customFieldsTableData, setCustomFieldsTableData] = useState<IProjectCustomFieldsTableData[]>();
  const [scopeFilter, setScopeFilter] = useState<'USER' | 'GROUP'>('USER');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);

  const { handleGetCustomFields, isLoading: isGetCustomFieldsLoading = false } = useGetCustomFields({
    onCompleted: ({ customFields }) => {
      setAllCustomFields(customFields);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const handleSideBarClose = () => {
    setSelectedCustomField(undefined);
    setIsSideBarOpen(false);
  };

  const handleAddNewCustomFieldClick = () => {
    setIsSideBarOpen(true);
    setSelectedCustomField(undefined);
  };

  const handleUpdate = () => {
    handleSideBarClose();
    void handleGetCustomFields({
      projectId: String(projectId),
      scope: scopeFilter,
    });
  };

  const handleFilterSearch = (value: string) => {
    setFilter(value);
  };

  const handleUserStatusFilterChange = () => {
    setStatusFilter(statusFilter === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE');
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setCurrentCustomFields(filteredCustomFields.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setCurrentCustomFields(filteredCustomFields.slice(0, updateIndex));
      resolve();
    });
  };



  useEffect(() => {
    setCustomFieldsTableData(
      getProjectCustomFieldsTableData({
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedCustomField(id);
        },
        customFields: currentCustomFields,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomFields]);

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetCustomFields({
      projectId: String(projectId),
      scope: scopeFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, scopeFilter]);

  useEffect(() => {
    if (!project) {
      return;
    }
    const { name } = project;
    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);


  useEffect(() => {
    
    const updatedCustomFields = allCustomFields.filter(({ status, name}) => status === statusFilter && (!filter || name.includes(filter)));
    setFilteredCustomFields(updatedCustomFields);
    setCurrentCustomFields(updatedCustomFields.slice(0, pageSize));
    setIsLoadMoreDisabled(updatedCustomFields.length <= pageSize);
    setTotalCount(updatedCustomFields.length);

  }, [allCustomFields, statusFilter, filter, pageSize])

  const isLoading = isGetCustomFieldsLoading;

  return (
    <>
      <TablePageTemplate<IProjectCustomFieldsTableData>
        activePage="custom-fields"
        activeSubPage="custom-fields"
        additionalBreadcrumbItems={[
          {
            key: '3',
            content: <span>Custom fields</span>,
          },
        ]}
        childrenEnd={
          <ToggleSwitch
            id="user-status-filter"
            isChecked={statusFilter === 'INACTIVE'}
            labelText="Show inactive"
            isInline
            onChange={() => handleUserStatusFilterChange()}
          />
        }
        childrenMiddle={
          <Select
            hasPadding={false}
            id="id_scope_filter"
            labelText="Scope"
            name="new_scope_filter"
            options={[
              {
                id: 'USER',
                name: 'User',
                value: 'USER'
              },
              {
                id: 'GROUP',
                name: 'Group',
                value: 'GROUP'
              },
            ]}
            value={scopeFilter}
            isLabelHidden
            onChange={({ target: { value }}) => setScopeFilter(value as 'USER' | 'GROUP')}
          />
        }
        columns={PROJECT_ROLES_TABLE_COLUMNS}
        data={customFieldsTableData ?? []}
        description="Manage custom fields"
        handleAddNewClick={handleAddNewCustomFieldClick}
        handleOnPageSizeChange={handleChangePageSize}
        handleOnPaginate={handlePaginate}
        handleSearch={handleFilterSearch}
        informationBoxMessage={
          <>
            Manage <strong>{String(projectName)}</strong> custom fields:{' '}
            <strong>
              {totalCount} {pluralize('custom field', totalCount)}
            </strong>
          </>
        }
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        isSideBarOpen={isSideBarOpen}
        pageSize={pageSize}
        pageTitle="Project custom fields"
        searchPlaceholder="Name"
        tableType="custom field"
        tabs={[
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => (
              <CustomFieldsGeneralTab selectedCustomField={selectedCustomField} onClose={handleSideBarClose} onUpdate={handleUpdate} />
            ),
          },
        ]}
        title={String(projectName)}
        toggleIsSideBarOpen={handleSideBarClose}
        totalItems={totalCount}
        isProjectLevel
      />
    </>
  );
};

export { ProjectCustomFields };
