import { SettingsButton } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IProjectPlaceholdersTableData } from './ProjectPlaceholders.interfaces';

const PROJECT_PLACEHOLDERS_TABLE_COLUMNS: ReadonlyArray<Column<IProjectPlaceholdersTableData>> = [
  {
    accessor: 'key',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Key</div>,
    width: '50%',
  },
  {
    accessor: 'value',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Description</div>,
    width: '45%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { item, onClick } }) => (
      <div className="flex justify-end">
        <SettingsButton supportiveText="placeholder settings" onClick={() => onClick(item)} />
      </div>
    ),
    Header: <span className="h-hide-visually">Actions</span>,
    width: '5%',
  },
];

export { PROJECT_PLACEHOLDERS_TABLE_COLUMNS };
