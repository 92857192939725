
import { ADD_GROUP_NOTE, ADD_USER_NOTE } from '../../../graphql';

export const DOCUMENT_FILE_TYPES: { [key: string]: string} = Object.freeze({
  'application/vnd.ms-excel': 'id_xlsx_icon',
  'application/msword': 'id_docx_icon',
  'application/pdf': 'id_pdf_icon',
  'image/png': 'id_image_hollow_icon',
  'image/svg+xml': 'id_image_hollow_icon',
});


export const NOTE_QUERY_STRING = Object.freeze({
  user: ADD_USER_NOTE,
  group: ADD_GROUP_NOTE,
});
