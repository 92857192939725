import { IDBUserType } from '@netfront/gelada-identity-library';

import { IOnGetTableStats, MembershipPermissionType } from '../../Pages.interfaces';

export enum BulkActionType {
  ACTIVATE = 'activateRoles',
  DEACTIVATE = 'deactivateRoles',
  DELETE = 'deleteRoles',
}

export interface IUserTypesTableProps extends IOnGetTableStats {}

export interface ICount {
  count: string;
  label: string;
}

export interface DBUserType {
  id: number;
  name: string;
  permission: MembershipPermissionType;
  status: 'ENABLED' | 'DISABLED';
  userCount: number;
  userTypeCode: string | null;
}

export interface IGetUserTypesTableDataParams {
  onSettingsButtonClick: (id: number) => void;
  userTypes: IDBUserType[];
}

export interface IProjectRolesTableData {
  created?: Date;
  id: IDBUserType['id'];
  name: IDBUserType['name'];
  settingsButtonData: {
    id: IDBUserType['id'];
    name: IDBUserType['name'];
    onClick: (id: IDBUserType['id']) => void;
  };
  status: string;
  userCount: IDBUserType['userCount'];
}
