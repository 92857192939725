import React from 'react';

import { Input } from '@netfront/ui-library';

import { SidebarContainer } from 'components/Shared';

import { GroupRequestContactTabProps } from './GroupRequestContactTab.interfaces';

const GroupRequestContactTab = ({ selectedGroupRequest }: GroupRequestContactTabProps) => {
  const { creator } = selectedGroupRequest ?? {};
  const { firstname, lastname, communityName } = creator ?? {};
  const { credential } = creator ?? {};
  const { email, phoneNumber } = credential ?? {};

  const noOP = () => {
    return;
  };

  return (
    <SidebarContainer>
      <Input id="firstname" labelText="First name" name="firstname" type="text" value={firstname} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="lastname" labelText="Last name" name="lastname" type="text" value={lastname} isDisabled isLabelSideBySide onChange={noOP} />
      <Input
        id="communityName"
        labelText="Display name"
        name="communityName"
        type="text"
        value={communityName}
        isDisabled
        isLabelSideBySide
        onChange={noOP}
      />
      <Input id="email" labelText="Email" name="email" type="text" value={email} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="phoneNumber" labelText="Phone" name="phoneNumber" type="text" value={phoneNumber} isDisabled isLabelSideBySide onChange={noOP} />
    </SidebarContainer>
  );
};

export { GroupRequestContactTab };
