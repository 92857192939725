import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName } from '@netfront/common-library';
import { FORM_FIELDS, FORM_ELEMENT_CSS_IDENTIFIERS } from '@netfront/gelada-identity-library';
import { Button, Input } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';


import { FORGOT_PASSWORD_FORM_CSS_IDENTIFIERS, FORGOT_PASSWORD_FORM_INITIAL_VALUES } from './ForgotPasswordForm.constants';
import { ForgotPasswordFormProps, IForgotPasswordFormFields } from './ForgotPasswordForm.interfaces';

import { getFormFieldErrorMessage } from '../../Forms.helpers';

const ForgotPasswordForm = ({ buttonText = 'Reset', isSubmitting, onForgotPassword }: ForgotPasswordFormProps) => {
  const { button: buttonElementCssId, container: containerElementCssId, form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    email: { id: emailId, label: emailLabel, validationErrorMessage: emailValidationErrorMessage },
  } = FORM_FIELDS;

  const { block: blockCssId } = FORGOT_PASSWORD_FORM_CSS_IDENTIFIERS;

  const { control, handleSubmit, reset } = useForm<IForgotPasswordFormFields>({
    defaultValues: FORGOT_PASSWORD_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().label(emailLabel).email(emailValidationErrorMessage).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  return (
    <form
      className={getBEMClassName(blockCssId, formElementCssId)}
      onSubmit={(event) => {
        void handleSubmit(({ email }) => {
          onForgotPassword({
            email,
          });

          reset();
        })(event);
      }}
    >
      <div className="c-form__body">
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <Input
              additionalClassNames={getBEMClassName(blockCssId, emailId)}
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={emailId}
              isDisabled={isSubmitting}
              labelText={emailLabel}
              placeholder=""
              type="email"
              {...field}
            />
          )}
        />
      </div>

      <div className="c-form__footer">
        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          <Button isDisabled={isSubmitting} text={buttonText} type="submit" />
        </div>
      </div>
    </form>
  );
};

export { ForgotPasswordForm };
