const PROFILE_PAGE_DEFAULTS = Object.freeze<Record<string, string>>({
  delete: 'Permanently delete your account',
  pageDescription: 'Edit and manage your profile',
  profile: 'Manage your profile details and avatar',
  security: 'Manage your password and add extra security to your account',
  'two-step-verification': 'Manage your password and add extra security to your account',
});

const PROFILE_PAGE_CONSTANTS = Object.freeze<Record<string, Record<string, string>>>({
  defaults: PROFILE_PAGE_DEFAULTS,
});

export { PROFILE_PAGE_CONSTANTS };
