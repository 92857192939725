import { ChangeEvent, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { useDomain, useIdentitySiteUrls, useLogout } from '@netfront/gelada-identity-library';
import { Button, Dialog, Input, Spinner, Container } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { useToast } from '../../../../hooks';
import { useDeleteAccount } from '../../../../hooks/useDeleteAccount';

const DeleteProfileTab = () => {
  const { getDomain } = useDomain();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const { handleLogout } = useLogout({
    onCompleted: () => {
      push(getLoginUrl()).catch((error) =>
        handleToastError({
          error,
        }),
      );
    },
  });

  const onDeleteAccountCompleted = () => {
    handleLogout({
      domain: getDomain(),
    });
  };

  const onDeleteAccountError = (error: ApolloError) => {
    handleToastError({ error, shouldUseFriendlyErrorMessage: true });
  };

  const { handleDeleteAccount, isLoading: isAccountDeletionLoading = false } = useDeleteAccount({
    onCompleted: onDeleteAccountCompleted,
    onError: onDeleteAccountError,
  });

  const onConfirmClick = () => {
    void handleDeleteAccount();
  };

  const onCloseClick = () => {
    setIsDialogOpen(false);
  };

  const onDeleteButtonClick = () => {
    setIsDialogOpen(true);
  };

  const handleInput = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setIsDisabled(value === 'DELETE');

  return (
    <>
      <Dialog
        isConfirmDisabled={isDisabled}
        isOpen={isDialogOpen}
        title="Delete account?"
        isNarrow
        onClose={onCloseClick}
        onConfirm={onConfirmClick}
      >
        <Spinner isLoading={isAccountDeletionLoading} />
        <p>To delete your account please type in DELETE below and confirm</p>
        <Container isNarrow>
          <Input
            id="id_delete_account_input"
            labelText="Delete account"
            name="new_delete_account"
            placeholder="DELETE"
            type="text"
            isLabelHidden
            onChange={handleInput}
          />
        </Container>
      </Dialog>
      <div className="c-delete-profile_buttons-container">
        <Button text="Delete profile" variant="danger" onClick={onDeleteButtonClick} />
      </div>
    </>
  );
};
export { DeleteProfileTab };
