import { gql } from '@apollo/client';

export const GET_PAGINATED_GROUP_REQUESTS_PER_PROJECT = gql`
  query GetGroupRequestsPerProject($projectId: String!, $first: Int, $after: String, $filter: String) {
    groupCreationRequest {
      get(projectId: $projectId, first: $first, after: $after, filter: $filter) {
        totalCount
        edges {
          cursor
          node {
            id
            name
            address
            creationDate
            city
            state
            postCode
            website
            phoneNumber
            flag
            email
            creator {
              id
              firstname
              lastname
              communityName
              credential {
                email
                phoneNumber
              }
            }
          }
        }
      }
    }
  }
`;
