/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IDBInvitation } from "@netfront/gelada-identity-library";
import { FormFieldProps } from "@netfront/ui-library";
import { IUpdatedCustomField } from "types";


const getInvitationDefaultValues = ({ invitation, customFields = [], hasGroups = false }: { customFields?: IUpdatedCustomField[]; hasGroups?: boolean; invitation?: IDBInvitation; }): FormFieldProps => {

  const {
    firstName,
    lastName,
    emailUserInvited,
    customMessage,
    userType,
    groupId,
    phoneNumber,
    phoneNumberCountryCode,
  } = invitation ?? {};

  const { id: userTypeId, name: userTypeName } = userType ?? {};

  const customFieldsObject = customFields.reduce((acc, item) => {
    const { customFieldId, text } = item;
    
    acc[`customField${customFieldId}`] = text;
    
    return acc;
  }, {} as FormFieldProps);

  return {
    firstName: firstName || '',
    lastName: lastName || '',
    emailUserInvited: emailUserInvited || '',
    customMessage: customMessage || '',
    groupId,
    phoneNumber: phoneNumber || '', 
    userTypeId: userTypeId || '',
    userTypeName: userTypeName || 'All user roles',
    password: '',
    isTeamAdmin: !hasGroups,
    isRequiresActivation: true,
    isSocialAccessActive: false,
    permissions: 'READ',
    phoneNumberCountryCode: phoneNumberCountryCode || '',
    ...customFieldsObject,
  };
};

const getCustomFieldResponses = ({ customFields = [], item }: { customFields?: IUpdatedCustomField[]; item: FormFieldProps }) => {

  const formattedResponses = customFields.map(({ customFieldId }) => {
    return {
      customFieldId,
      value: String(item[`customField${customFieldId}`] ?? ''),
    };
  });

  return formattedResponses;
};


export {getInvitationDefaultValues, getCustomFieldResponses };
