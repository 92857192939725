import React, { useEffect, useState } from 'react';

import { ArrowIcon, SidebarButtons, SidebarDialog, Spacing, Table } from '@netfront/ui-library';
import { capitalize } from 'utils';

import { SidebarContainer } from 'components/Shared';

import { serviceNameMap } from './UpsertProjectSettingsFormConfirmationDialog.constants';
import { ProjectSettingChangeDetails, UpsertProjectSettingsFormConfirmationDialogProps } from './UpsertProjectSettingsFormConfirmationDialog.interfaces';



const UpsertProjectSettingsFormConfirmationDialog = ({ 
  currentService,
  initialValues,
  isOpen = false,
  onCancel,
  getValues,
  settings = [],
  onSave,
}: UpsertProjectSettingsFormConfirmationDialogProps) => {
  const [changes, setChanges] = useState<ProjectSettingChangeDetails[]>([]);
  const [upsertType, setUpsertType] = useState<'add' | 'update'>('update');
  
  useEffect(() => {
    if (!isOpen) return;
    const { services = [] } = initialValues;

    setUpsertType((services as string[]).includes(currentService) ? 'update': 'add');

    const updatedValues = getValues();

    const formattedChanges = settings.reduce((accumulator, setting) => {
      const { type } = setting;
      const initialValue = initialValues[type];
      const updatedValue = updatedValues[type];

      if (initialValue !== updatedValue) {
        accumulator.push({
          id: type,
          current: String(initialValue),
          updated: String(updatedValue),
          value: capitalize(String(type.replace(/_/g, ' ')))
        });
      }

  
      return accumulator;
    }, [] as ProjectSettingChangeDetails[]);

    setChanges(formattedChanges);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <SidebarDialog
      isOpen={isOpen}
      onClose={onCancel}
    >
      <SidebarContainer>
        <Spacing>
          <p>You are about to {upsertType} <strong>{serviceNameMap[currentService]}</strong> service with the following values:</p>
        </Spacing>
        <Table
          additionalClassNames="c-condition-table"
          columns={[
            {
              accessor: 'value',
              Cell: ({ value }: { value: string }) => <span>{value}</span>,
              Header: () => <div>Name</div>,
              width: '40%',
            },
            {
              accessor: 'current',
              Cell: ({ value }: { value: string }) => <span>{value}</span>,
              Header: () => <div>Current value</div>,
              width: '30%',
            },
            {
              accessor: 'arrow',
              Cell: () => <ArrowIcon className="h-rotate-right"/>,
              width: '5%',
            },
            {
              accessor: 'updated',
              Cell: ({ value }: { value: string }) => <span>{value}</span>,
              Header: () => <div>New value</div>,
              width: '30%',
            },
            
          ]}
          data={changes.map(({ id, current, updated, value}) => ({
            id,
            current,
            updated,
            value,
            arrow: '->'
          }))}
          emptyTableMessage="No changes"
        />
      </SidebarContainer>
      <SidebarButtons
        buttonSize="xs"
        submitButtonText="Confirm"
        onCancel={onCancel}
        onSave={onSave}
        onSaveButtonType="button"
      />
    </SidebarDialog>
  );
};


export { UpsertProjectSettingsFormConfirmationDialog };
