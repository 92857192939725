import { gql } from '@apollo/client';

export const GET_ORGANISATION_INVITATIONS = gql`
  query getOrganisationInvitations($after: String, $first: Int, $organisationId: Int!, $filter: String) {
    invitation {
      getOrganisationInvitations(after: $after, first: $first, organisationId: $organisationId, filter: $filter) {
        edges {
          cursor
          node {
            created
            id
            invitedUserEmail: emailUserInvited
            customMessage
            firstName
            lastName
            groupId
            phoneNumber
            phoneNumberCountryCode
            invitedUserId
            invitationEmailStatus
            group {
              id
              name
            }
            userType {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
