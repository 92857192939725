import { Avatar, FlexContainer, InsightsIcon, SettingsButton } from '@netfront/ui-library';
import Link from 'next/link';
import { Column } from 'react-table';

import { IProjectGroupData } from './ProjectGroups.interfaces';



const PROJECT_GROUP_STATUS_TYPE = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

const PROJECT_GROUPS_TABLE_COLUMNS: ReadonlyArray<Column<IProjectGroupData>> = [
  {
    accessor: 'avatarData',
    Cell: ({ value: { name } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar size="small" title={name} />
        </div>
        <div className="text-left w-full word-break-all">{name}</div>
      </FlexContainer>
    ),
    Header: () => <div className="text-left w-full">Title</div>,
    width: '30%',
  },
  {
    accessor: 'smartCodes',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Codes</div>,
    width: '30%',
  },
  {
    accessor: 'userCount',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">User count</div>,
    width: '15%',
  },
  {
    accessor: 'created',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value.toString()}</div>,
    Header: () => <div className="text-left w-full w-full h-no-white-space-wrap">Registered</div>,
    width: '15%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { id, onClick, code, projectId } }) => (
      <FlexContainer gap="x-small">
        <Link href={`/project/${projectId}/group/${code}`} rel="noopener noreferrer" target="_blank" title="See more details">
          <span className="c-table__icon-container">
            <InsightsIcon className="c-table__icon" />
          </span>
        </Link>
        <SettingsButton supportiveText="Update group" onClick={() => onClick(id)} />
      </FlexContainer>
    ),
    width: '6%',
    Header: <span className="h-hide-visually">Settings</span>
  },
];

export { PROJECT_GROUPS_TABLE_COLUMNS, PROJECT_GROUP_STATUS_TYPE };
