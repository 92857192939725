import { gql } from '@apollo/client';

export const ADD_SMART_CODE = gql`
  mutation addSmartCode($projectId: String!, $groups: [SmartCodeGroupPermissionInputType]!, $code: String) {
    smartCode {
      add(projectId: $projectId, groups: $groups, code: $code) {
        id
        code
      }
    }
  }
`;
