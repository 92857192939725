import { useState } from 'react';

import { FlexContainer } from '@netfront/ui-library';

import { QrCodeStepButtons } from '../QrCodeStepButtons';

import { ScanQrCodeProps } from './ScanQrCode.interfaces';


const ScanQrCode = ({ handleNextOnClick, qrCodeImage, secret, step, totalSteps }: ScanQrCodeProps) => {
  const [isShowTextCode, setIsShowTextCode] = useState(false);
  return (
    <>
      <h3 className="c-scan-qr-code__title">Scan QR code</h3>
      <ol className="c-scan-qr-code__list">
        <li>Open your third-party authentication app.</li>
        <li>Use your app to scan the QR code below</li>
      </ol>
      <div className="c-scan-qr-code__details">
        <FlexContainer flexDirection="column" justifyContent="center">
          {
            isShowTextCode ? (
              <>
                <p className="c-scan-qr-code__secret">{secret}</p>
                <button className="c-scan-qr-code__button" type="button" onClick={() => setIsShowTextCode(false)}>Use QR code</button>
              </>
            ) : (
              <>
                {secret && <img alt="qr code" className="c-scan-qr-code__image" src={qrCodeImage}/>}
                <p className="c-scan-qr-code__text">If you can&apos;t scan the barcode, <button className="c-scan-qr-code__button" type="button" onClick={() => setIsShowTextCode(true)}>enter the text</button> code instead.</p>
              </>
            )
          }
        </FlexContainer>
      </div>
      <QrCodeStepButtons
        backButtonProps={{
          isDisabled: true,
        }}
        nextButtonProps={{
          onClick: handleNextOnClick,
        }}
        step={step}
        totalSteps={totalSteps}
      />
    </>
  );
}



export { ScanQrCode };
