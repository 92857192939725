import {
  Input,
  SingleDatePicker,
  ToggleSwitch,
} from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { SidebarContainer } from 'components/Shared';

import { EntityCustomFieldsTabProps } from './EntityCustomFieldsTab.interfaces';

const EntityCustomFieldsTab = ({
  additionalFields,
  control,
  customFields = [],
  isDisabled = false,
}: EntityCustomFieldsTabProps) => {

  return (
    <SidebarContainer>
      {additionalFields}
      {customFields.length > 0 ? (
        <>
          {customFields.map((customField) => {
            const { customFieldId, customFieldName, __typename } = customField;
            return (
              <Controller
                key={`custom-field-${customFieldId}`}
                control={control}
                name={`customField${customFieldId}`}
                render={({ field }) => (
                  <>
                    {__typename === 'CustomFieldBooleanGraphType' && (
                      <ToggleSwitch
                        id={`custom-field-${customFieldId}`}
                        isChecked={String(field.value).toLowerCase() === 'true'}
                        isDisabled={isDisabled}
                        labelText={customFieldName}
                        isLabelSideBySide
                        {...field}
                        onChange={(event) => {
                          const { target: { checked: isChecked }} = event;
                          field.onChange(isChecked ? 'True' : 'False');
                        }}
                      />
                    )}
                    {__typename === 'CustomFieldDateGraphType' && (
                      <SingleDatePicker 
                        config={{
                          maxDate: new Date('01-01-2050'),
                        }} 
                        dateInputProps={{
                          labelText: customFieldName,
                          id: `custom-field-${customFieldId}`,
                          type: 'text',
                          name: field.name,
                          isLabelSideBySide: true,
                          isDisabled,
                          
                        }}
                        isDisabled={isDisabled}
                        selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                        onSelected={(dates: Date[]) => field.onChange(dates[0]?.toISOString())} 
                      />
                    )}
                    {__typename === 'CustomFieldTextGraphType' && (
                      <Input
                        id={`custom-field-text-${customFieldId}`}
                        isDisabled={isDisabled}
                        labelText={customFieldName}
                        type="text"
                        isLabelSideBySide
                        {...field}
                      />
                    )}
                    {__typename === 'CustomFieldNumberGraphType' && (
                      <Input
                        id={`custom-field-number-${customFieldId}`}
                        isDisabled={isDisabled}
                        labelText={customFieldName}
                        type="number"
                        isLabelSideBySide
                        {...field}
                      />
                    )}
                  </>
                )}
              />
            );
          })}
        </>
      ): (
        <></>
      )}
      
    </SidebarContainer>
  );
};

export { EntityCustomFieldsTab };
