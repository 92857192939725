import { gql } from '@apollo/client';

export const GET_INVITATIONS_WITHIN_PROJECT = gql`
  query getInvitationsWithinProject($projectId: String!) {
    invitation {
      getInvitationsWithinProject(projectId: $projectId) {
        id
        invitedUserEmail: emailUserInvited
        invitedUserId
        firstName
        lastName
        groupId
        invitationEmailStatus
        group {
          id
          name
        }
        userType {
          id
          name
        }
      }
    }
  }
`;
