
import { Dialog, Spinner } from '@netfront/ui-library';
import pluralize from 'pluralize';

import { ROLES_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, ROLES_BULK_ACTION_DIALOG_TITLE_MAP } from './RolesBulkActionDialog.constants';
import { RolesBulkActionDialogProps } from './RolesBulkActionDialog.types';

import { useBulkDeleteUserTypes, useBulkUpdateUserTypeStatus, useToast } from '../../../../hooks';

const RolesBulkActionDialog = ({
  bulkActionType,
  isOpen = false,
  onSave,
  handleCloseDialog,
  selectedIds,
}: RolesBulkActionDialogProps) => {
  const { handleToastError, handleToastSuccess, handleToastCustomError } = useToast();

  const { handleBulkUpdateUserTypeStatus, isLoading: isBulkUpdateUserTypeStatusLoading = false } = useBulkUpdateUserTypeStatus({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: `Roles successfully ${bulkActionType === 'activateRoles' ? 'activated': 'deactivated'}`,
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });


  const { handleBulkDeleteUserTypes, isLoading: isBulkDeleteUserTypesLoading = false } = useBulkDeleteUserTypes({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Roles successfully deleted',
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  

  const activateRoles = () => {
    if (!selectedIds?.length) {
      handleToastCustomError({
        message: 'Please select at least one role to activate',
      });
      return;
    }
    void handleBulkUpdateUserTypeStatus({
      status: 'ENABLED',
      userTypeIds: selectedIds.map(id => Number(id)),
    })
  };

  const deactivateRoles = () => {
    if (!selectedIds?.length) {
      handleToastCustomError({
        message: 'Please select at least one role to activate',
      });
      return;
    }
    void handleBulkUpdateUserTypeStatus({
      status: 'DISABLED',
      userTypeIds: selectedIds.map(id => Number(id)),
    })
  };

  const deleteRoles = () => {
    if (!selectedIds?.length) {
      handleToastCustomError({
        message: 'Please select at least one role to activate',
      });
      return;
    }
    void handleBulkDeleteUserTypes({
      userTypeIds: selectedIds.map(id => Number(id)),
    })
  };

  const saveMap: { [key: string]: () => void } = {
    activateRoles: activateRoles,
    deactivateRoles: deactivateRoles,
    deleteRoles: deleteRoles,
  };


  const handleSave = () => {
    const bulkHandler = saveMap[bulkActionType];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    bulkHandler();
  };

  const isLoading = isBulkUpdateUserTypeStatusLoading || isBulkDeleteUserTypesLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Dialog
        aria-describedby="bulk-action-description"
        aria-label={`Confirm ${bulkActionType} roles`}
        confirmText={ROLES_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]}
        isOpen={isOpen}
        title={ROLES_BULK_ACTION_DIALOG_TITLE_MAP[bulkActionType]}
        onCancel={handleCloseDialog}
        onClose={handleCloseDialog}
        onConfirm={handleSave}
      >
        <p id="bulk-action-description">{ROLES_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]} {selectedIds?.length ?? 0} {pluralize('role', selectedIds?.length ?? 0)}</p>
      </Dialog>
    </>
  );
};

export { RolesBulkActionDialog };
