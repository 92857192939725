import { gql } from '@apollo/client';

export const ADD_GROUP_NOTE = gql`
  mutation AddGroupNote($groupId: Int!, $subject: String!, $message: String!, $attachments: [CreateNoteAttachmentAssetInputType]) {
    note {
      addGroupNote(groupId: $groupId, subject: $subject, message: $message, attachments: $attachments) {
        id
        message
        subject
        attachments {
          asset {
            contentType
            fileName
            fileSizeInBytes
            contentType
            s3Key
            presignedUrl
            presignedPutUrl
          }
          assetId
        }
      }
    }
  }
`;
