// TODO: Update disabled inputs to read only when netfront-ui inputs accept readonly prop

import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useGetProduct, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import {
  ControlledForm,
  Dialog,
  FormFieldProps,
  Input,
  IOption,
  RadioButtonGroup,
  SidebarButtons,
  Spinner,
  Textarea,
  useControlledForm,
} from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { Control, Controller, ControllerFieldState } from 'react-hook-form';
import * as yup from 'yup';

import { CountryCodeInput } from 'components/CountryCodeInput';
import { SidebarContainer } from 'components/Shared';

import { PERMISSIONS_ITEMS } from '../ManageUsersGeneralView';

import { ManageInvitationsGeneralViewProps } from './ManageInvitationsGeneralView.interfaces';

import { CREATE_ORGANISATION_INVITATION, DELETE_INVITATION, RESEND_INVITATION } from '../../../graphql';
import { useGetCountryCodes, useToast } from '../../../hooks';

const ManageInvitationsGeneralView = ({
  organisationId,
  onClose,
  onUpdateInvitations,
  selectedInvitation,
}: ManageInvitationsGeneralViewProps) => {
  const { getProduct } = useGetProduct();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [countryCodeOptions, setCountryCodeOptions] = useState<IOption[]>([]);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>({ 
    name: '', 
    lastName: '', 
    invitedUserEmail: '',
    phoneNumber: '',
    customMessage: '',
    permission: '',
    phoneNumberCountryCode: '',
  });

  const handleValidationError = ({ error, invalid }: ControllerFieldState): string => {
    if (!(error && invalid)) {
      return '';
    }

    const { message = '' } = error;

    return message;
  };

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().label('First name').required(),
        lastName: yup.string().label('Last name').required(),
        invitedUserEmail: yup.string().label('Email').email('Please enter a valid email').required(),
      }),
    ),
  });

  const { handleGetCountryCodes, isLoading: isGetCountryCodesLoading = false } = useGetCountryCodes({
    onCompleted: ({ enumValues = [] }) => {
      setCountryCodeOptions(enumValues.map(({ name, description }) => (
        {
          id: name,
          name: description,
          value: name,
        }
      )));
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const [createProjectInvitation, { loading: isCreateProjectInvitationLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        if (onUpdateInvitations) {
          onUpdateInvitations();
        }

        handleToastSuccess({
          message: 'Invitation sent',
        });

        reset();
        onClose();
      },
      onError: (error) => {
        handleToastError({
          shouldUseFriendlyErrorMessage: true,
          error,
        });
      },
    },
    mutation: CREATE_ORGANISATION_INVITATION,
    product: getProduct(),
  });

  const [resendInvitation, { loading: isResendInvitationLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        if (onUpdateInvitations) {
          onUpdateInvitations();
        }

        handleToastSuccess({
          message: 'Invitation sent',
        });

        reset();
        onClose();
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: RESEND_INVITATION,
    product: getProduct(),
  });

  const [deleteProjectInvitation, { loading: isDeleteProjectInvitationLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        if (onUpdateInvitations) {
          onUpdateInvitations();
        }

        handleToastSuccess({
          message: 'Invitation deleted',
        });

        onClose();
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: DELETE_INVITATION,
  });

  const handleCreateInvitation = (item: FormFieldProps) => {
    const { name, lastName, invitedUserEmail, phoneNumber, customMessage, permission, phoneNumberCountryCode } = item;

    void createProjectInvitation({
      variables: {
        invitation: {
          customMessage,
          phoneNumber,
          firstName: name,
          emailUserInvited: invitedUserEmail,
          lastName,
          permission,
          projectId,
          organisationId: Number(organisationId),
          phoneNumberCountryCode,
        },
      },
    });
  };

  const handleResendInvitation = async () => {
    await resendInvitation({
      variables: {
        invitationId: selectedInvitation?.id,
      },
    });
  };

  useEffect(() => {
    if (!selectedInvitation) {
      return;
    }

    setDefaultValues({
      name: String(selectedInvitation.firstName),
      lastName: selectedInvitation.lastName,
      invitedUserEmail: selectedInvitation.invitedUserEmail,
      customMessage: selectedInvitation.customMessage,
      phoneNumber: selectedInvitation.phoneNumber,
      phoneNumberCountryCode: selectedInvitation.phoneNumberCountryCode,
    });

  }, [selectedInvitation]);

  useEffect(() => {
    handleGetCountryCodes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetCountryCodesLoading || isCreateProjectInvitationLoading || isDeleteProjectInvitationLoading || isResendInvitationLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleCreateInvitation(item);
        }}
        handleSubmit={handleSubmit}
        // eslint-disable-next-line no-console
        onSubmitError={(errs) => console.log({errs})}
      >
        <Controller
          control={control as Control<FormFieldProps>}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={handleValidationError(fieldState)}
              id="id_first_name"
              isDisabled={Boolean(selectedInvitation)}
              labelText="First name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

        <Controller
          control={control as Control<FormFieldProps>}
          name="lastName"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={handleValidationError(fieldState)}
              id="id_last_name"
              isDisabled={Boolean(selectedInvitation)}
              labelText="Last name"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

        <Controller
          control={control as Control<FormFieldProps>}
          name="invitedUserEmail"
          render={({ field, fieldState }) => (
            <Input
              autoComplete="email"
              errorMessage={handleValidationError(fieldState)}
              id="id_invited_user_email"
              isDisabled={Boolean(selectedInvitation)}
              labelText="Email"
              type="email"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />

        <CountryCodeInput 
          codesOptions={countryCodeOptions} 
          control={control as Control<FormFieldProps>}
          countryCodeName="phoneNumberCountryCode"
          id="id_account_invitation_phone_number"
          isDisabled={Boolean(selectedInvitation)}
          phoneNumberName='phoneNumber'
        />

        {Boolean(!selectedInvitation) && (
          <Controller
            control={control as Control<FormFieldProps>}
            name="permission"
            render={({ field }) => (
              <RadioButtonGroup
                items={PERMISSIONS_ITEMS.filter((r) => !r.product || r.product === getProduct())}
                name={field.name}
                selectedValue={field.value}
                title="Permissions"
                isLabelSideBySide
                onChange={field.onChange}
              />
            )}
          />
        )}

        <Controller
          control={control as Control<FormFieldProps>}
          name="customMessage"
          render={({ field, fieldState }) => (
            <Textarea 
              errorMessage={handleValidationError(fieldState)}
              id="id_custom_message"
              isDisabled={Boolean(selectedInvitation)}
              labelText="Invite message"
              isLabelSideBySide
              {...field}
            />
          )}
        />

        {Boolean(selectedInvitation) && (
          <SidebarButtons
            buttonSize="xs"
            submitButtonText="Resend"
            onCancel={onClose}
            onDelete={() => setIsDeleteDialogOpen(true)}
            onSave={() => {
              void handleResendInvitation();
            }}
            onSaveButtonType="button"
          />
        )}

        <Dialog
          isOpen={isDeleteDialogOpen}
          title="Delete invitation?"
          isNarrow
          onCancel={() => setIsDeleteDialogOpen(false)}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={() => {
            void deleteProjectInvitation({
              variables: {
                invitation: selectedInvitation?.id,
              },
            });
          }}
        />

        {Boolean(!selectedInvitation) && (
          <SidebarButtons buttonSize="xs" submitButtonText="Invite" onCancel={onClose} onSaveButtonType="submit" />
        )}
      </ControlledForm>
    </SidebarContainer>
  );
};

export { ManageInvitationsGeneralView };
