import React, { useState, FormEvent } from 'react';

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { EnterIcon, Input, NavigationButton, Preloader, SidebarButtons, Spacing } from '@netfront/ui-library';



import { IContact, UserGroupContactFormProps } from '..';
import { DELETE_CONTACT, UPDATE_CONTACT } from '../../../../../graphql';
import { useToast } from '../../../../../hooks';

const UserGroupContactForm = ({ contact, onOpenUpdateContacts, onUpdateContacts }: UserGroupContactFormProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [updatedContact, setUpdatedContact] = useState(contact as IContact);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { id: userId, groupId, firstname = '', lastname = '', email = '', phoneNumber = '', communityName = '' } = updatedContact || {};

  const handleChangeContact = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedContact({
      ...updatedContact,
      [name]: value,
    });
  };

  const [deleteContact, { loading: isDeleteContactLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onOpenUpdateContacts();
        onUpdateContacts();
        handleToastSuccess({
          message: 'Contact deleted successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: DELETE_CONTACT,
  });

  const [updateContact, { loading: isUpdateContactLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onOpenUpdateContacts();
        onUpdateContacts();
        handleToastSuccess({
          message: 'Contact updated successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_CONTACT,
  });

  const handleDeleteContact = () => {
    void deleteContact({
      variables: {
        groupId,
        userId,
      },
    });
  };

  const handleUpdateContact = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    void updateContact({
      variables: {
        userId,
        firstname,
        lastname,
        email,
        phoneNumber,
        communityName,
      },
    });
  };

  const isLoading = isUpdateContactLoading || isDeleteContactLoading;

  return (
    <>
      <Preloader isLoading={isLoading} />

      <Spacing size="x-large">
        <NavigationButton
          additionalClassNames="c-sidebar-navigation-button"
          direction="previous"
          icon={EnterIcon}
          rotationCssSuffix="180"
          text="Back to contacts"
          onClick={onOpenUpdateContacts}
        />
      </Spacing>

      <form onSubmit={handleUpdateContact}>
        <Input
          id="firstame"
          labelText="First name"
          name="firstname"
          tooltipPosition="start"
          tooltipText="Contact first name"
          type="text"
          value={firstname}
          isLabelSideBySide
          isRequired
          onChange={handleChangeContact}
        />

        <Input
          id="lastname"
          labelText="Last name"
          name="lastname"
          tooltipPosition="start"
          tooltipText="Contact last name"
          type="text"
          value={lastname}
          isLabelSideBySide
          isRequired
          onChange={handleChangeContact}
        />

        <Input
          id="communityName"
          labelText="Display name"
          name="communityName"
          tooltipPosition="start"
          tooltipText="Displayed contact name"
          type="text"
          value={communityName}
          isLabelSideBySide
          onChange={handleChangeContact}
        />

        <Input
          id="email"
          labelText="Email"
          name="email"
          tooltipPosition="start"
          tooltipText="Contact email"
          type="text"
          value={email}
          isLabelSideBySide onChange={handleChangeContact}
        />

        <Input
          id="phoneNumber"
          labelText="Phone"
          name="phoneNumber"
          tooltipPosition="start" tooltipText="Contact phone number"
          type="text"
          value={phoneNumber}
          isLabelSideBySide
          onChange={handleChangeContact}
        />

        <SidebarButtons
          buttonSize="xs"
          onCancel={onOpenUpdateContacts}
          onDelete={handleDeleteContact}
          onSaveButtonType="submit"
        />
      </form>
    </>
  );
};

export { UserGroupContactForm };
