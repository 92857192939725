import { gql } from '@apollo/client';

export const GET_GROUP_TYPES = gql`
  query getGroupTypes($projectId: String!) {
    groupType {
      getGroupTypes(projectId: $projectId) {
        id
        name
      }
    }
  }
`;
