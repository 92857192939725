import { useEffect, useState } from 'react';

import { FieldsetAccordionGroup, SidebarDialog, Spinner } from '@netfront/ui-library';

import { UpsertVerifiedEmail, VerifiedEmailOverview } from './Components';
import { UpsertProjectSidebarNotificationsTabProps } from './UpsertProjectSidebarNotificationsTab.interfaces';

import { SidebarContainer } from '../../../../components';
import { useGetVerifiedEmails, useToast } from '../../../../hooks';
import { IVerifiedEmail } from '../../../../types';

const UpsertProjectSidebarNotificationsTab = ({
  projectId,
}: UpsertProjectSidebarNotificationsTabProps) => {

  const { handleToastError } = useToast();

  const [verifiedEmails, setVerifiedEmails] = useState<IVerifiedEmail[]>([]);
  const [isUpsertDialogOpen, setIsUpsertDialogOpen ] = useState<boolean>(false);
  const [selectedEmail, setSelectedEmail] = useState<IVerifiedEmail>();

  const { handleGetVerifiedEmails, isLoading = false } = useGetVerifiedEmails({
    onCompleted: ({ verifiedEmails: returnedEmails }) => {
      setVerifiedEmails(returnedEmails);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleUpsertVerifiedEmail = (item?: IVerifiedEmail) => {
    setSelectedEmail(item);
    setIsUpsertDialogOpen(true);
  };

  const cancelUpsertVerifiedEmail = () => {
    setSelectedEmail(undefined);
    setIsUpsertDialogOpen(false);
  };

  const handleVerifyEmail = () => {
    handleGetVerifiedEmails({
      projectId: String(projectId),
    });
    cancelUpsertVerifiedEmail();
  };


  useEffect(() => {
    if (!projectId) return;

    handleGetVerifiedEmails({
      projectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  return (
    <>
      <FieldsetAccordionGroup
        data={[
          {
            id: 'id_verified_emails_accordion',
            label: 'Verified emails',
            isOpenOnLoad: true,
            children: (
              <SidebarContainer>
                <Spinner isLoading={isLoading} />
                <VerifiedEmailOverview verifiedEmails={verifiedEmails} onEditClick={handleUpsertVerifiedEmail}/>
                <SidebarDialog
                  isOpen={isUpsertDialogOpen}
                  onClose={cancelUpsertVerifiedEmail}
                >
                  {projectId && (
                    <UpsertVerifiedEmail
                      projectId={projectId}
                      verifiedEmail={selectedEmail}
                      onCancel={cancelUpsertVerifiedEmail} 
                      onSave={handleVerifyEmail}
                    />
                  )}
                </SidebarDialog>
              </SidebarContainer>
            ),
          },
        ]}
      />
      </>                    
  );
};

export { UpsertProjectSidebarNotificationsTab };
