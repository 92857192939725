import { Avatar, Button, FlexContainer, InsightsIcon, SettingsButton, StarIcon, Tooltip } from '@netfront/ui-library';
import cx from 'classnames';
import { Column } from 'react-table';

import { IUsersTableData } from './ProjectUsers.types';

import { getDayMonthYear } from '../../../../utils';

const PERMISSION_MAP: {[key: string]: string} = {
  MANAGE_USERS: 'Project admin who can manage all users',
  EDIT: 'Project admin who  has edit permission',
  READ: 'Project admin who  has read permission',
  LIMITED: 'Project admin who has limited permission',
};

const PROJECT_EKARDO_USER_TABLE_COLUMNS: ReadonlyArray<Column<IUsersTableData>> = [
  {
    accessor: 'avatarData',
    Header: <div className="flex items-center w-full">First name</div>,
    Cell: ({ value: { fullName, isAdmin, isProjectAdmin, firstName, permission } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar
            roleIndicator={
              isAdmin
                ? {
                    component: (
                      <Tooltip
                        additionalClassNames={cx('c-admin-star', {
                          'c-admin-star--is-admin': isProjectAdmin,
                        })}
                        icon={StarIcon}
                        placement="right"
                        text={PERMISSION_MAP[permission ?? 'MANAGE_USERS']}
                      />
                    ),
                  }
                : undefined
            }
            size="small"
            title={fullName}
          />
        </div>

        <div className="h-word-break">{firstName}</div>
      </FlexContainer>
    ),

    width: '12%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="flex items-center w-full">{value}</div>,
    Header: <div className="flex items-center w-full">Last name</div>,
    width: '10%',
  },
  {
    accessor: 'displayName',
    Cell: ({ value }) => <div className="flex items-center w-full">{value}</div>,
    Header: <div className="flex items-center w-full">Display name</div>,
    width: '10%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="flex items-center w-full">{value}</div>,
    Header: <div className="flex items-center w-full">Email</div>,
    width: '25%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="h-word-break">{value}</div>,
    Header: <div className="flex items-center w-full">Group</div>,
    width: '10%',
  },
  {
    accessor: 'registered',
    Cell: ({ value }) => <div className="flex items-center w-full">{getDayMonthYear(value.toString())}</div>,
    Header: <div className="flex items-center w-full h-no-white-space-wrap">Registered</div>,
    width: '10%',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortType: 'datetime',
  },
  {
    accessor: 'lastLogin',
    Cell: ({ value }) => <div className="flex items-center w-full">{value ? getDayMonthYear(value.toString()): '-'}</div>,
    Header: <div className="flex items-center w-full h-no-white-space-wrap">Last login</div>,
    width: '10%',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId] ? new Date(String(rowA.values[columnId])) : null;
      const b = rowB.values[columnId] ? new Date(String(rowB.values[columnId])) : null;

      const timeA = a instanceof Date && !isNaN(a.getTime()) ? a.getTime() : 0;
      const timeB = b instanceof Date && !isNaN(b.getTime()) ? b.getTime() : 0;

      return timeA - timeB;
    },
    // accessorFn: (row: IUsersTableData) => row.lastLogin ? new Date(row.lastLogin) : null,
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { firstName, id, onClick, url } }) => (
      <FlexContainer alignItems="center" gap="x-small">
        {url && (
          <Button
            additionalClassNames="c-progress-tracking-button"
            icon={InsightsIcon}
            linkButton={{
              linkComponent: 'a',
              id: `id_user_progress_tracking_${id}`,
              url,
            }}
            size="xs"
            text="View progress tracking"
            variant="primary-inverse"
            isIconOnly
          />
        )}
        <SettingsButton additionalClassNames="h-flex-shrink-0" supportiveText={`${firstName} settings`} onClick={() => onClick(id)} />
      </FlexContainer>
    ),
    Header: <span className="h-hide-visually">Actions</span>,
    width: '7%',
  },
  
];

const PROJECT_USER_TABLE_COLUMNS: ReadonlyArray<Column<IUsersTableData>> = [
  {
    accessor: 'avatarData',
    Header: <div className="flex items-center w-full">First name</div>,
    Cell: ({ value: { fullName, isAdmin, isProjectAdmin, firstName, permission } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar
            roleIndicator={
              isAdmin
                ? {
                    component: (
                      <Tooltip
                        additionalClassNames={cx('c-admin-star', {
                          'c-admin-star--is-admin': isProjectAdmin,
                        })}
                        icon={StarIcon}
                        placement="right"
                        text={PERMISSION_MAP[permission ?? 'MANAGE_USERS']}
                      />
                    ),
                  }
                : undefined
            }
            size="small"
            title={fullName}
          />
        </div>

        <div className="flex items-center w-full">{firstName}</div>
      </FlexContainer>
    ),

    width: '19%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="flex items-center w-full">{value}</div>,
    Header: <div className="flex items-center w-full">Last name</div>,
    width: '15%',
  },
  {
    accessor: 'email',
    Cell: ({ value }) => <div className="flex items-center w-full">{value}</div>,
    Header: <div className="flex items-center w-full">Email</div>,
    width: '25%',
  },
  {
    accessor: 'registered',
    Cell: ({ value }) => <div className="flex items-center w-full">{getDayMonthYear(value.toString())}</div>,
    Header: <div className="flex items-center w-full h-no-white-space-wrap">Registered</div>,
    width: '20%',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortType: 'datetime',
  },
  {
    accessor: 'lastLogin',
    Cell: ({ value }) => <div className="flex items-center w-full">{value ? getDayMonthYear(value.toString()): '-'}</div>,
    Header: <div className="flex items-center w-full h-no-white-space-wrap">Last login</div>,
    width: '20%',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortType: (rowA, rowB, columnId) => {
      const a = rowA.values[columnId] ? new Date(String(rowA.values[columnId])) : null;
      const b = rowB.values[columnId] ? new Date(String(rowB.values[columnId])) : null;

      const timeA = a instanceof Date && !isNaN(a.getTime()) ? a.getTime() : 0;
      const timeB = b instanceof Date && !isNaN(b.getTime()) ? b.getTime() : 0;

      return timeA - timeB;
    },
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { firstName, id, onClick } }) => (
      <div className="flex items-center">
        <SettingsButton supportiveText={`${firstName} settings`} onClick={() => onClick(id)} />
      </div>
    ),
    Header: <span className="h-hide-visually">Actions</span>,
    width: '1%',
  },
];

export { PROJECT_USER_TABLE_COLUMNS, PROJECT_EKARDO_USER_TABLE_COLUMNS };
