import React, { useEffect, useState } from 'react';

import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { useDomain, useGetGeladaProjectByDomain, useGetProduct, useResetPassword } from '@netfront/gelada-identity-library';

import { RESET_PASSWORD_PAGE_CONSTANTS } from './ResetPasswordPage.constants';
import styles from './ResetPasswordPage.module.css';

import { useCodeQuerystringValidation, useToast } from '../../../hooks';
import { ResetPasswordForm } from '../../Forms';
import { Layout } from '../../Shared/Layout';

const {
  cssIdentifiers: { block: blockCssId },
  defaults: { headerText, pageDescription },
} = RESET_PASSWORD_PAGE_CONSTANTS;

const ResetPasswordPage = () => {
  const { code } = useCodeQuerystringValidation();
  const { isDomainReady, getDomain } = useDomain();
  const { getProduct } = useGetProduct();
  const { handleToastError, handleToastSuccess } = useToast();

  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [websiteUrl, setWebsiteUrl] = useState<string>();

  const product = getProduct();

  const { handleGetGeladaProjectByDomain, isLoading: isGetGeladaProjectByDomainLoading = false } = useGetGeladaProjectByDomain({
    onCompleted: ({ geladaProject: { domain: projectDomain } }) => {
      const { website } = projectDomain ?? {};
      setWebsiteUrl(website);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleResetPassword, isLoading: isResetPasswordLoading = false } = useResetPassword({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        setIsPasswordReset(true);
        handleToastSuccess({
          message: 'Your password has been successfully reset',
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product,
  });

  const isLoading = isGetGeladaProjectByDomainLoading || isResetPasswordLoading;

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    void handleGetGeladaProjectByDomain({
      domain: getDomain(),
      shouldIncludeProjectDomain: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <Layout headerText={headerText} isPreloaderVisible={isLoading} pageDescription={pageDescription} title="Reset password">
      <div className={styles[`${blockCssId}__form`]}>
        {code && !isPasswordReset && (
          <ResetPasswordForm
            isSubmitting={isResetPasswordLoading}
            onResetPassword={({ newPassword }) => {
              void handleResetPassword({
                code,
                newPassword,
              });
            }}
          />
        )}
        {isPasswordReset && (
          <div>Success! Your password has been updated. Please use this new password the next time you access the application</div>
        )}
        {websiteUrl && (
          <div className={styles[`${blockCssId}__return-to-login-container`]}>
            <span>
              <a href={websiteUrl}>Return to website</a>
            </span>
          </div>
        )}
      </div>
    </Layout>
  );
};

export { ResetPasswordPage };
