import { IResponseGroup } from "../CreateResponseSet";

const deepCompareArrays = (arr1: IResponseGroup[], arr2: IResponseGroup[]): boolean => {
  // Step 1: Check if lengths are different
  if (arr1.length !== arr2.length) {
    return false; // Arrays have different lengths
  }

  // Step 2: Compare each object in the array
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (!deepCompareObjects(obj1, obj2)) {
      return false; // Objects are different
    }
  }

  // If lengths are the same and no object differences were found
  return true;
}

const deepCompareObjects = (obj1: IResponseGroup, obj2: IResponseGroup): boolean => {
  // Step 1: Check if both are objects
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2; // If they are primitives, directly compare
  }

  // Step 2: Compare keys length
  const keys1 = Object.keys(obj1) as Array<keyof IResponseGroup>;
  const keys2 = Object.keys(obj2) as Array<keyof IResponseGroup>;


  if (keys1.length !== keys2.length) {
    return false; // Objects have different number of keys
  }

  // Step 3: Compare values of each key
  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!deepCompareObjects(value1, value2)) {
        return false; // Recursively compare objects
      }
    } else if (value1 !== value2) {
      return false; // Primitive values are different
    }
  }

  return true; // No differences found
}


export { deepCompareArrays };