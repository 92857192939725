import { useContext, useEffect, useState } from 'react';

import { useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useGetProduct, useUser } from '@netfront/gelada-identity-library';
import { Button, FlexContainer, Spacing } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { RegistrationLayout } from '../RegistrationLayout';

import { SetupCompleteProps } from './SetupComplete.interfaces';

import { CachingEntitiesContext } from '../../../context';
import { useActivateProject, useToast } from '../../../hooks';

const SetupComplete = ({ organisationId, organisationKey, productName = 'EKARDO', projectId, projectName }: SetupCompleteProps) => {
  const { dashboardUrl, refreshProjectDetails } = useContext(CachingEntitiesContext);
  const { trackEnhanceConversionsForUser, trackEvent } = useGoogleAnalytics();
  const { getGoogleAdwordsConversionId, getGoogleAdwordsSignUpConversionLabel, getProduct } = useGetProduct();
  const { push } = useRouter();
  const { handleToastError } = useToast();
  const { getUser } = useUser();

  const [redirectUrl, setRedirectUrl] = useState<string>('');

  const product = getProduct();

  const enableProjectOnCompleted = () => {
    const googleAdwordsConversionId = getGoogleAdwordsConversionId(product);
    const googleAdwordsSignUpConversionLabel = getGoogleAdwordsSignUpConversionLabel(product);

    if (googleAdwordsConversionId && googleAdwordsSignUpConversionLabel) {
      const user = getUser();

      trackEnhanceConversionsForUser(user?.email, user?.firstName, user?.lastName);

      trackEvent('conversion', {
        send_to: `${googleAdwordsConversionId}/${googleAdwordsSignUpConversionLabel}`,
      });
    }

    push(redirectUrl).catch((error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    });
  };

  const { handleActivateProject, isLoading: isActivateProjectLoading = false } = useActivateProject({
    onCompleted: enableProjectOnCompleted,
  });

  const handleActivation = () => {
    handleActivateProject({
      organisationId,
      productName,
      projectId,
      projectName,
    });
  };

  useEffect(() => {
    if (!(projectId && organisationId && projectId && organisationKey && dashboardUrl && projectName)) {
      return;
    }

    setRedirectUrl(`${dashboardUrl}/${String(organisationKey)}/${projectId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, organisationId, projectId, organisationKey, projectName, dashboardUrl]);

  useEffect(() => {
    refreshProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentStep = 5;

  return (
    <RegistrationLayout
      currentStep={currentStep}
      description={''}
      hasCtaButton={false}
      hasStepCounter={false}
      imageSrc="/images/refer-graphic.png"
      isLoading={isActivateProjectLoading}
      title="Congratulations!"
    >
      <p className="c-registration-layout__description c-registration-layout__description--centered">You&apos;re all set, now time to get the most out of using {String(product)}</p>

      <Spacing size="6x-large" />

      <FlexContainer justifyContent="center">
        <Button size="xs" text="Let's get started!" type="button" onClick={handleActivation} />
      </FlexContainer>
    </RegistrationLayout>
  );
};

export { SetupComplete };
