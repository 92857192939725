import { SettingsButton } from '@netfront/ui-library';

import { PROJECT_GROUP_REQUEST_STATUS_TYPE } from './ProjectGroupRequests.constants';
import { IProjectGroupRequestDataParams } from './ProjectGroupRequests.interfaces';

import { StatusIndicator } from '../../../../components/Shared';

const getProjectGroupRequestsTableData = ({ groupRequests, onSettingsButtonClick }: IProjectGroupRequestDataParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const rows = groupRequests.map(({ id, name, creationDate }) => {
    return {
      id,
      avatar: {
        name,
      },
      name,
      creationDate: creationDate,
      status: <StatusIndicator status={PROJECT_GROUP_REQUEST_STATUS_TYPE['REQUEST']} />,
      settings: <SettingsButton supportiveText="Update group request" onClick={() => onSettingsButtonClick(id)} />,
    };
  });

  return rows;
};

export { getProjectGroupRequestsTableData };
