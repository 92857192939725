
const GROUPS_BULK_ACTION_DIALOG_TITLE_MAP: {[key: string]: string} = Object.freeze({
  deleteGroups: 'Bulk delete groups',
  activateGroups: 'Bulk activate groups',
  deactivateGroups: 'Bulk deactivate groups',
});


const GROUPS_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP: { [key: string]: string} = Object.freeze({
  deleteGroups: 'Delete',
  activateGroups: 'Activate',
  deactivateGroups: 'Deactivate',
});

export { GROUPS_BULK_ACTION_DIALOG_TITLE_MAP, GROUPS_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP };
