import { Input, Spacing, ToggleSwitch } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { UserTypeSecurityTabProps } from './UserTypeSecurityTab.interfaces';

import { SidebarContainer } from '../../..';


const UserTypeSecurityTab = ({ control }: UserTypeSecurityTabProps) => {

  return (
    <SidebarContainer>
      <Spacing>
        <Controller
          control={control}
          name="mustMatchPasswords"
          render={({ field }) => (
            <ToggleSwitch
              id="id_password_match"
              isChecked={field.value}
              labelText="Password match"
              tooltipPosition="start"
              tooltipText="Passwords must be confirmed and matched"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="minimumLength"
          render={({ field }) => (
            <Input
              id="id_minimum_length"
              labelText="Length"
              minNumber={1}
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="mustHaveNumber"
          render={({ field }) => (
            <ToggleSwitch
              id="id_requires_number"
              isChecked={field.value}
              labelText="Numbers"
              tooltipPosition="start"
              tooltipText="Passwords must contain a number"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="mustHaveSpecialCharacters"
          render={({ field }) => (
            <ToggleSwitch
              id="id_requires_special_character"
              isChecked={field.value}
              labelText="Special characters"
              tooltipPosition="start"
              tooltipText="Passwords must contain a special character"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="mustHaveUpperCase"
          render={({ field }) => (
            <ToggleSwitch
              id="id_requires_upper_lower_case"
              isChecked={field.value}
              labelText="Upper-case / lower-case"
              tooltipPosition="start"
              tooltipText="Passwords must contain at least 1 upper and lower case character"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      <Spacing>
        <Controller
          control={control}
          name="mustHaveMfa"
          render={({ field }) => (
            <ToggleSwitch
              id="id_requires_mfa"
              isChecked={field.value}
              labelText="MFA"
              tooltipPosition="start"
              tooltipText="A user must set up multi-factor authentication in order to login"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
    </SidebarContainer>
  );
};

export { UserTypeSecurityTab };
