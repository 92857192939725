const featureNameMap: {[key: string]: string} = {
  'GELADA': 'Identity',
  'EKARDO': 'Content',
  'HOWLER': 'Notifications',
  'BONOBO': 'Social media',
  'VERVET': 'Licensing',
  'MURIQUI': 'Payments',
  'CAPUCHIN': 'Scheduler',
  'LEMUR': 'Orders',
  'BABOON': 'Integration'
};

export { featureNameMap };