import { ChangeEvent, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { DBMembershipPermissionType, useGetProduct, useUpdateGroupMembership } from '@netfront/gelada-identity-library';
import { RadioButtonGroup, SidebarButtons, Spinner } from '@netfront/ui-library';

import { SidebarContainer } from 'components/Shared';

import { UserPermissionsTabProps } from './UserPermissionsTab.interfaces';

import { useGetUserPermissionForGroup, useToast, useUpdateProjectMembershipPermission } from '../../../../hooks';
import { PERMISSIONS_ITEMS } from '../../Invitation/InvitationsGeneralTab/InvitationsGeneralTab.constants';

const UserPermissionsTab = ({ onClose, type, userId, projectId }: UserPermissionsTabProps) => {
  const { getProduct } = useGetProduct();
  const { handleToastError, handleToastSuccess } = useToast();

  const [membershipId, setMembershipId] = useState<number>(0);
  const [selectedPermission, setSelectedPermission] = useState<DBMembershipPermissionType>('NONE');

  const { handleGetUserPermissionForGroup, isLoading: isGetUserPermissionForGroupLoading = false } = useGetUserPermissionForGroup({
    onCompleted: ({ membership: { id, permission } }) => {
      setMembershipId(id);
      setSelectedPermission(permission);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateProjectMembershipPermission, isLoading: isUpdateProjectMembershipPermission = false } =
    useUpdateProjectMembershipPermission({
      onCompleted: () => {
        handleToastSuccess({
          message: 'User permissions updated successfully',
        });

        onClose();
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const { handleUpdateGroupMembership } = useUpdateGroupMembership({
    onCompleted: () => {
      handleToastSuccess({
        message: 'User permissions updated successfully',
      });

      onClose();
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleChangePermissions = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setSelectedPermission(value as DBMembershipPermissionType);
  };

  const handlePermissionUpdate = () => {
    switch (type) {
      case 'GROUP':
        void handleUpdateGroupMembership({
          request: {
            membershipId,
            permission: selectedPermission,
          },
        });
        break;
      case 'PROJECT':
        void handleUpdateProjectMembershipPermission({
          membershipId,
          permission: selectedPermission,
        });
        break;
    }
  };

  useEffect(() => {
    if (!(userId && projectId)) return;

    void handleGetUserPermissionForGroup({
      userId,
      projectId,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, projectId]);

  const isLoading = isGetUserPermissionForGroupLoading || isUpdateProjectMembershipPermission;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      <RadioButtonGroup
        items={PERMISSIONS_ITEMS.filter((r) => !r.product || r.product === getProduct())}
        name="permissions"
        selectedValue={selectedPermission}
        title="Permissions"
        isLabelSideBySide
        onChange={handleChangePermissions}
      />

      <SidebarButtons buttonSize="xs" onCancel={onClose} onSave={handlePermissionUpdate} />
    </SidebarContainer>
  );
};

export { UserPermissionsTab };
