import React, { useEffect, useState } from 'react';

import { IDBMembership, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { BinIcon, ButtonIconOnly, Dialog, FlexContainer, InfoIcon, InformationBox, PlusIcon, Preloader, Spacing } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { GroupsUserTabProps } from './GroupsUserTab.interfaces';
import { UserAddGroup } from './UserAddGroup';
import { UserUpdateInGroup } from './UserUpdateInGroup';

import { SidebarListItem, SidebarList, SidebarContainer } from '../../../../components/Shared';
import { REMOVE_USER_FROM_GROUP } from '../../../../graphql';
import { useGetGroupPermissions, useToast } from '../../../../hooks';

const GroupsUserTab = ({ hasAllGroupsOption = false, userId, isProjectAdmin = false }: GroupsUserTabProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [allMemberships, setAllMemberships] = useState<IDBMembership[]>();
  const [isAddUserToGroupVisible, setAddUserToGroupVisible] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [membershipToUpdate, setMembershipToUpdate] = useState<IDBMembership | undefined>(undefined);
  const [selectedGroupId, setSelectedGroupId] = useState<number>();

  const { handleGetGroupPermissions, isLoading: isGetGroupPermissionsLoading = false } = useGetGroupPermissions({
    onCompleted: ({ membershipConnection: { edges } }) => {
      setAllMemberships(edges.map(({ node }) => node));
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const [removeUserFromGroup, { loading: isRemoveUserFromGroupLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: 'User removed successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: REMOVE_USER_FROM_GROUP,
  });

  const handleRemoveUserFromGroup = async (groupId: number) => {
    await removeUserFromGroup({
      variables: {
        groupId,
        userId,
      },
    });
  };

  const fetchMemberships = () => {
    void handleGetGroupPermissions({
      projectId: String(projectId),
      first: 99,
      userId,
    });
  };

  const handleUpdateUserGroup = () => {
    setMembershipToUpdate(undefined);
    fetchMemberships();
  };

  const handleAddUserToGroup = () => {
    fetchMemberships();
    handleToastSuccess({
      message: 'User added to selected group successfully!',
    });
  };

  const handleConfirmRemoveUserFromGroup = async () => {
    if (!selectedGroupId) {
      return;
    }

    await handleRemoveUserFromGroup(selectedGroupId);

    setAllMemberships((currentState) => {
      if (!currentState) {
        return;
      }

      return currentState.filter(({ groupId }) => groupId !== selectedGroupId);
    });

    handleToggleModal();
  };

  const handleOpenModal = (groupId: number) => {
    setSelectedGroupId(groupId);
    handleToggleModal();
  };

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleToggleAddUserToGroupVisibility = () => {
    setAddUserToGroupVisible(!isAddUserToGroupVisible);
  };

  const handleToggleUpdateUserToGroupVisibility = () => {
    setMembershipToUpdate(undefined);
  };

  useEffect(() => {
    if (!projectId) return
    if (!isProjectAdmin) fetchMemberships();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetGroupPermissionsLoading || isRemoveUserFromGroupLoading;

  const isMembershipsVisible = !isAddUserToGroupVisible && !membershipToUpdate && allMemberships;
  return (
    <main>
      {isProjectAdmin ? (
        <SidebarContainer>
          <p>This is an admin user with full access to all groups</p>
        </SidebarContainer>
      ) : (
        <SidebarContainer>
          <Preloader isLoading={isLoading && !isProjectAdmin} />

          <Dialog
            confirmText="Confirm"
            isOpen={isModalOpen}
            title={`Are you sure?`}
            isNarrow
            onCancel={handleToggleModal}
            onClose={handleToggleModal}
            onConfirm={() => {
              handleConfirmRemoveUserFromGroup().catch((error) => {
                handleToastError({
                  error,
                });
              });
            }}
          >
            Do you really want to remove the user from the group
          </Dialog>

          {isAddUserToGroupVisible && (
            <UserAddGroup
              hasAllGroupsOption={hasAllGroupsOption}
              projectId={String(projectId)}
              userId={userId}
              onAddUserToGroup={handleAddUserToGroup}
              onOpenUpdateGroups={handleToggleAddUserToGroupVisibility}
            />
          )}
          {membershipToUpdate && (
            <UserUpdateInGroup
              membership={membershipToUpdate}
              projectId={String(projectId)}
              onOpenUpdateGroups={handleToggleUpdateUserToGroupVisibility}
              onUpdateUserGroup={handleUpdateUserGroup}
            />
          )}

          {isMembershipsVisible && (
            <>
              {!allMemberships.length ? (
                <Spacing>
                  <InformationBox icon={InfoIcon} message="User is not a member of any group" />
                </Spacing>
              ) : (
                <section>
                  <SidebarList itemsLength={allMemberships.length} text="groups" hasCount>
                    {allMemberships.map((membership: IDBMembership) => (
                      <SidebarListItem
                        key={membership.id}
                        additionalButton={
                          allMemberships.length > 1 ? (
                            <ButtonIconOnly
                              additionalClassNames="border-none"
                              icon={BinIcon}
                              text="Remove user from the group"
                              onClick={() => handleOpenModal(membership.groupId)}
                            />
                          ) : undefined
                        }
                        name={`${String(membership.group?.name)} : ${String(membership.userType?.name)}`}
                        supportiveText="Update user type"
                        title={membership.group?.name}
                        isSettingsButton
                        onClick={() => setMembershipToUpdate(membership)}
                      />
                    ))}
                  </SidebarList>
                </section>
              )}

              <FlexContainer justifyContent="end" tag="section">
                <ButtonIconOnly icon={PlusIcon} text="Open add user to group" onClick={handleToggleAddUserToGroupVisibility} />
              </FlexContainer>
            </>
          )}
        </SidebarContainer>
      )}
    </main>
  );
};

export { GroupsUserTab };
