import { ChangeEvent, useState } from 'react';

import { MfaCodeInput, Spacing } from '@netfront/ui-library';

import { QrCodeStepButtons } from '../QrCodeStepButtons';

import { ConfirmQrCodeProps } from './ConfirmQrCode.interfaces';


const ConfirmQrCode = ({ handleBackOnClick, handleNextOnClick, totalSteps, step }: ConfirmQrCodeProps) => {
  const [code, setCode] = useState<string>('');

  return (
    <>

      <div className="c-confirm-qr-code__container">
        <img alt="confirm qr code" className="c-confirm-qr-code__image" src="/images/setup-icon.png"/>
        <Spacing>
          <p className="c-confirm-qr-code__label">Enter the <strong>6-digit</strong> code you see in the app</p>
        </Spacing>
        <MfaCodeInput onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setCode(value)} />
      </div>
      <QrCodeStepButtons
        backButtonProps={{
          isDisabled: false,
          onClick: handleBackOnClick,
        }}
        nextButtonProps={{
          isDisabled: code.length !== 6,
          onClick: () => handleNextOnClick(code),
        }}
        step={step}
        totalSteps={totalSteps}
      />
    </>
  );
}



export { ConfirmQrCode };
