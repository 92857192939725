import React, { useState, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { Spacing, Input, Spinner, useControlledForm, FormFieldProps, ControlledForm, SidebarButtons } from '@netfront/ui-library';
import { useToast, useVerifyEmail } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';


import { getVerifiedEmailDefaultValues } from './UpsertVerifiedEmail.helpers';
import { UpsertVerifiedEmailProps } from './UpsertVerifiedEmail.interfaces';

import { SidebarContainer } from '../../../../../../components';

const UpsertVerifiedEmail = ({ onSave, onCancel, verifiedEmail, projectId }: UpsertVerifiedEmailProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        displayName: yup.string().label('Display name').required(),
        email: yup.string().label('Email').required(),
        replyTo: yup.string().label('Reply to').required(),
        
      }),
    ),
  });


  const { handleVerifyEmail, isLoading: isVerifyEmailLoading = false } = useVerifyEmail({
    onCompleted: () => {
      reset();
      onSave();
      handleToastSuccess({
        message: `Email submitted for verification`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSave = (item: FormFieldProps) => {
    const { displayName, email, replyTo } = item;

    void handleVerifyEmail({
      displayName,
      email,
      replyTo,
      projectId,
    });
  }


  useEffect(() => {
    setDefaultValues(getVerifiedEmailDefaultValues(verifiedEmail));
  }, [verifiedEmail]);


  const isLoading = isVerifyEmailLoading;

  if (!projectId) return <></>;

  return (
    <SidebarContainer>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isLoading} />
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="displayName"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_verified_email_display_name"
                labelText="Display name"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="email"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_verified_email_email"
                labelText="Email"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="replyTo"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_verified_email_reply_to"
                labelText="Reply to"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
          
        <SidebarButtons
          onCancel={onCancel}
          onSaveButtonType="submit"
        />
      </ControlledForm>
    </SidebarContainer>
  );
};

export { UpsertVerifiedEmail };
