import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ControlledForm,
  Dialog,
  FormFieldProps,
  Input,
  Select,
  SidebarButtons,
  SidebarDialog,
  SingleDatePicker,
  Spacing,
  Spinner,
  Textarea,
  ToggleSwitch,
  useControlledForm,
} from '@netfront/ui-library';
import { useDeleteCustomField, useToast, useUpsertCustomField } from 'hooks';
import { useRouter } from 'next/router';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { getFormFieldErrorMessage } from 'components/Forms';
import { SidebarContainer } from 'components/Shared';

import { CreateResponseSet, IResponseGroup, ResponseOptionsOverview } from './Components';
import { getCustomFieldCommonVariables, getCustomFieldDefaultValues } from './CustomFieldsGeneralTab.helpers';
import { CustomFieldsGeneralTabProps, IEditableOption } from './CustomFieldsGeneralTab.interfaces';


const CustomFieldsGeneralTab = ({
  selectedCustomField,
  onClose,
  onUpdate,
}: CustomFieldsGeneralTabProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isUpsertResponseSetDialogOpen, setIsUpsertResponseSetDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();
  const [fieldType, setFieldType] = useState<string>('');
  const [isDeleteResponseDialogOpen, setIsDeleteResponseDialogOpen] = useState<boolean>(false);
  const [selectedResponseId, setSelectedResponseId] = useState<string | undefined>(undefined);

  const { control, handleSubmit, reset, setValue, getValues, watch } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().label('Field title').required(),
        description: yup.string().label('Description').required(),
      }),
    ),
  });

  const { handleUpsertCustomField, isLoading: isUpsertCustomFieldLoading = false } = useUpsertCustomField({
    onCreate: () => {
      reset();
      onUpdate();
    },
    onUpdate: () => {
      reset();
      onUpdate();
    }
  });

  const { handleDeleteCustomField, isLoading: isDeleteCustomFieldLoading = false } = useDeleteCustomField({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) return;
      reset();
      onUpdate();
      setIsDeleteDialogOpen(false);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSaveResponse = (responseOptions: IResponseGroup[]) => {
    setValue('availableOptions', responseOptions);
    setIsUpsertResponseSetDialogOpen(false);
  };


  
  const handleRemoveResponse = (responseId: string) => {
    setIsDeleteResponseDialogOpen(true);
    setSelectedResponseId(responseId);
  };

  const cancelRemoveResponse = () => {
    setIsDeleteResponseDialogOpen(false);
    setSelectedResponseId(undefined);
  };

  const handleConfirmRemoveResponse = (responseId: string) => {
    const { availableOptions } = getValues();

    const updatedValues = (availableOptions as IEditableOption[]).filter(({ tempId}) => tempId !== responseId );

    setValue('availableOptions', updatedValues);
    cancelRemoveResponse();
  };
  

  const handleSave = (item: FormFieldProps) => {

    const variables = getCustomFieldCommonVariables({ initialValues: defaultValues as FormFieldProps, updatedValues: item });

    handleUpsertCustomField({
      projectId,
      customFieldId: selectedCustomField?.id,
      variables,
    });
  };

  useEffect(() => {
    const fieldValues = getCustomFieldDefaultValues({ customField: selectedCustomField })
    setDefaultValues(getCustomFieldDefaultValues({ customField: selectedCustomField }));
    setFieldType(fieldValues.type as string);

  }, [selectedCustomField]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isUpsertCustomFieldLoading || isDeleteCustomFieldLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
      >
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="name"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_custom_field_name"
                labelText="Field title"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="description"
            render={({ field, fieldState }) => (
              <Textarea
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_custom_field_description"
                labelText="Description"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>

        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="isRequired"
            render={({ field }) => (
              <ToggleSwitch
                additionalClassNames="c-sidebar-toggle"
                id="id_is_required"
                isChecked={field.value}
                labelText="Is required"
                name={field.name}
                isInline
                isLabelSideBySide
                onChange={field.onChange}

              />
            )}
          />
        </Spacing>

        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="scope"
            render={({ field, fieldState }) => (
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_scope_type"
                
                labelText="Scope"
                options={[
                  {
                    id: 'USER',
                    name: 'User',
                    value: 'USER'
                  },
                  {
                    id: 'GROUP',
                    name: 'Group',
                    value: 'GROUP'
                  },
                ]}
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="type"
            render={({ field, fieldState }) => (
              <Select
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_field_type"
                
                isDisabled={Boolean(selectedCustomField)}
                labelText="Field type"
                options={[
                  {
                    id: 'number',
                    name: 'Number',
                    value: 'number'
                  },
                  {
                    id: 'text',
                    name: 'Text',
                    value: 'text'
                  },
                  {
                    id: 'date',
                    name: 'Date',
                    value: 'date'
                  },
                  {
                    id: 'boolean',
                    name: 'Boolean/Switch',
                    value: 'boolean'
                  },
                  {
                    id: 'radio',
                    name: 'Radio',
                    value: 'radio'
                  },
                  {
                    id: 'dropdown',
                    name: 'Dropdown',
                    value: 'dropdown'
                  },
                  {
                    id: 'checkbox',
                    name: 'Checkbox',
                    value: 'checkbox'
                  },
                ]}
                isLabelSideBySide
                isRequired
                {...field}
                onChange={(event) => {
                  const { target: { value }} = event;
                  field.onChange(event);
                  setFieldType(value);
                }}
              />
            )}
          />
        </Spacing>

        {fieldType === 'number' && (
          <>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="minNumber"
                render={({ field, fieldState }) => (
                  <Input
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_custom_field_min_number"
                    isDisabled={Boolean(selectedCustomField)}
                    isReadOnly={Boolean(selectedCustomField)}
                    labelText="Min number"
                    type="number"
                    isLabelSideBySide
                    {...field}
                  />
                )}
              />
            </Spacing>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="maxNumber"
                render={({ field, fieldState }) => (
                  <Input
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_custom_field_max_number"
                    isDisabled={Boolean(selectedCustomField)}
                    isReadOnly={Boolean(selectedCustomField)}
                    labelText="Max number"
                    type="number"
                    isLabelSideBySide
                    {...field}
                  />
                )}
              />
            </Spacing>
          </>
        )}

        {fieldType === 'date' && (
          <>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="rangeFrom"
                render={({ field, fieldState }) => (
                  <SingleDatePicker 
                    dateInputProps={{
                      id: 'id_range_from_date',
                      errorMessage: getFormFieldErrorMessage(fieldState),
                      isLabelSideBySide: true,
                      labelText: 'Range from',
                      type: 'text',
                      name: field.name,
                      isDisabled: Boolean(selectedCustomField),
                    }}
                    {...field}
                    isDisabled={Boolean(selectedCustomField)}
                    selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                    onSelected={(dates) => setValue('rangeFrom', dates[0])} 
                  />
                )}
              />
            </Spacing>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="rangeTo"
                render={({ field, fieldState }) => (
                  <SingleDatePicker 
                    dateInputProps={{
                      id: 'id_range_to_date',
                      errorMessage: getFormFieldErrorMessage(fieldState),
                      isLabelSideBySide: true,
                      labelText: 'Range to',
                      type: 'text',
                      name: field.name,
                      isDisabled: Boolean(selectedCustomField),
                    }}
                    {...field}
                    isDisabled={Boolean(selectedCustomField)}
                    selectedDates={field.value ? [new Date(String(field.value))] : undefined} 
                    onSelected={(dates) => setValue('rangeTo', dates[0])} 
                  />
                )}
              />
            </Spacing>
          </>
        )}

        {['dropdown', 'radio', 'checkbox'].includes(fieldType) && (
          <ResponseOptionsOverview watch={watch} onDelete={handleRemoveResponse} onEditClick={() => setIsUpsertResponseSetDialogOpen(true)} />
        )}

        


        <SidebarButtons 
          onCancel={onClose} 
          onDelete={selectedCustomField ? () => setIsDeleteDialogOpen(true): undefined } 
          onSaveButtonType="submit" 
        />
      </ControlledForm>
      {['dropdown', 'radio', 'checkbox'].includes(fieldType) && (
          <SidebarDialog
            isOpen={isUpsertResponseSetDialogOpen}
            onClose={() => setIsUpsertResponseSetDialogOpen(false)}
          >
            <CreateResponseSet
              getValues={getValues}
              isOpen={isUpsertResponseSetDialogOpen}
              onCancel={() => setIsUpsertResponseSetDialogOpen(false)}
              onSave={handleSaveResponse}
            />
          </SidebarDialog>
        )}
      
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete custom field?"
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedCustomField) return;
          void handleDeleteCustomField({
            customFieldId: selectedCustomField.id,
          });
        }}
      />

      <Dialog
        isOpen={isDeleteResponseDialogOpen}
        title="Delete response?"
        isNarrow
        onCancel={() => setIsDeleteResponseDialogOpen(false)}
        onClose={() => setIsDeleteResponseDialogOpen(false)}
        onConfirm={() => handleConfirmRemoveResponse(String(selectedResponseId))}
      />
    </SidebarContainer>
    
  );
};

export { CustomFieldsGeneralTab };
