import React, { useState } from 'react';

import { BinIcon, ButtonIconOnly, EditIcon, FlexContainer, Input, Spacing, TickIcon } from '@netfront/ui-library';

import { ResponseSetItemProps } from './ResponseSetItem.interfaces';


const ResponseSetItem = ({
  item,
  index,
  handleDeleteResponseItem,
  handleUpsertResponseItem,
  isLabelHidden = false,
}: ResponseSetItemProps) => {

  const [localValue, setLocalValue] = useState<string>(item.value);
  const [isEditMode, setIsEditMode] = useState<boolean>(item.isEditableOnLoad);


  const handleSaveItem = () => {
    handleUpsertResponseItem({
      ...item,
      value: localValue,
    });
    setIsEditMode(false);
  };

  return (
    <>
      
      <Spacing>
        <FlexContainer key={index} alignItems="flex-end" gap="x-small">
          <div style={{ flexGrow: 1 }}>
            <Input
              hasPadding={false}
              id={`id_response_value_${item.tempId}`}
              isDisabled={!isEditMode}
              isLabelHidden={isLabelHidden}
              labelText="Value"
              name="response_value"
              type="text"
              value={localValue}
              isRequired
              onChange={({ target: { value } }) => {
                setLocalValue(value);
              }}
            />
          </div>
          <FlexContainer gap="2x-small">
            <ButtonIconOnly icon={BinIcon} text="Remove Response" onClick={() => handleDeleteResponseItem(item.tempId)} />
              {isEditMode
                ? <ButtonIconOnly icon={TickIcon} isDisabled={Boolean(item.id)} text="Add Response" onClick={handleSaveItem} />
                : <ButtonIconOnly icon={EditIcon} isDisabled={Boolean(item.id)} text="Edit Response" onClick={() => setIsEditMode(true)} />
              }
          </FlexContainer>
        </FlexContainer>
      </Spacing>
    </>
  );
};

export { ResponseSetItem };
