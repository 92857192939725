import { useEffect } from 'react';

import { useDomain, useIdentitySiteUrls, useLogout } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { useToast } from '../../../hooks';

const ImpersonatedUserLogoutPage = () => {
  const { getDomain, isDomainReady } = useDomain();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const { handleImpersonatedUserLogout } = useLogout({
    onCompleted: () => {
      push(getLoginUrl()).catch((error) =>
        handleToastError({
          error,
        }),
      );
    },
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    const domain = getDomain();

    handleImpersonatedUserLogout({
      domain,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return null;
};

export { ImpersonatedUserLogoutPage };
