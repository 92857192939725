/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { ICustomFieldItem } from "hooks";
import { generateRandomId } from "utils";

import { IEditableOption } from "./CustomFieldsGeneralTab.interfaces";


const getCustomFieldType = ({multiResponseType, type}: { multiResponseType?: string; type?: string;})  => {
  if (type === 'CustomFieldNumberGraphType') return 'number';
  if (type === 'CustomFieldTextGraphType') return 'text';
  if (type === 'CustomFieldDateGraphType') return 'date';
  if (type === 'CustomFieldBooleanGraphType') return 'boolean';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'RADIO') return 'radio';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'DROP_DOWN_LIST') return 'dropdown';
  if (type === 'CustomFieldMultiResponseGraphType' && multiResponseType === 'CHECKBOX') return 'checkbox';
  
  return '';
}

const getCustomFieldDefaultValues = ({ customField }: { customField?: ICustomFieldItem}): FormFieldProps => {
  const { 
    name,
    description,
    isRequired,
    scope,
    type: multiResponseType,
    __typename: type,
    minNumber,
    maxNumber,
    rangeFrom,
    rangeTo,
    availableOptions = []
  } = customField ?? {};

  return {
    name: name || '',
    description: description || '',
    isRequired: isRequired || false,
    scope: scope || '',
    type: getCustomFieldType({ type, multiResponseType }),
    minNumber,
    maxNumber,
    rangeFrom,
    rangeTo,
    availableOptions: availableOptions.map((availableOption) => (
      {
        tempId: generateRandomId(),
        ...availableOption
      }
    )),
  };

};

const getCustomFieldCommonVariables = ({ initialValues, updatedValues }: { initialValues: FormFieldProps, updatedValues: FormFieldProps }): FormFieldProps => {
  const { availableOptions: updatedOptions = [] } = updatedValues;
  const { availableOptions: initialOptions = []} = initialValues;

  const optionsToAdd = (updatedOptions as IEditableOption[]).reduce((accumulator, option) => {
    const { tempId: currentTempId } = option;
    const existingOption = (initialOptions as IEditableOption[]).find(({ tempId }) => tempId === currentTempId); 

    if (!existingOption) {
      accumulator.push(option);
    }
    
    return accumulator;
  }, [] as IEditableOption[]);

  const deletedOptions = (initialOptions as IEditableOption[]).reduce((accumulator, option) => {
    const { tempId: currentTempId, id } = option;
    const updatedOption = (updatedOptions as IEditableOption[]).find(({ tempId }) => tempId === currentTempId); 

    if (!updatedOption) {
      accumulator.push(id);
    }
    
    return accumulator;
  }, [] as number[]);

  return {
    ...updatedValues,
    availableOptions: optionsToAdd,
    deletedOptions,
  }
}



export { getCustomFieldDefaultValues, getCustomFieldCommonVariables };