import { Button, FlexContainer, Spacing } from '@netfront/ui-library';

import { StepButtonProps } from './QrCodeStepButtons.interfaces';


const QrCodeStepButtons = ({backButtonProps, nextButtonProps, step, totalSteps }: StepButtonProps) => {
  return (
    <Spacing size="5x-large">
      <FlexContainer alignItems="center" >
        <Button size="small" text="Back" {...backButtonProps} />
        <span className="c-qr-code-step-button__text">Step {step} of {totalSteps}</span>
        <Button size="small" text="Next"  {...nextButtonProps} />
      </FlexContainer>
    </Spacing>
  )
}


export { QrCodeStepButtons };
