import React, { useEffect, useState } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { IDBGroup, IDBUnit, useLoggedGeladaMutation, useSearchGroups, useSearchUserTypes } from '@netfront/gelada-identity-library';
import {
  Label,
  NavigationButton,
  Preloader,
  SelectWithSearch,
  SidebarButtons,
  Spacing,
  ToggleSwitch,
  RadioButtonGroup,
  EnterIcon,
} from '@netfront/ui-library';
import cx from 'classnames';

import { IAddUserInGroupResponse, UserAddGroupProps } from './UserAddGroup.interfaces';
import styles from './UserAddGroup.module.css';

import { ADD_USER_IN_GROUP } from '../../../../../graphql';
import { useToast } from '../../../../../hooks';

const UserAddGroup = ({
  hasAllGroupsOption,
  projectId,
  userId,
  onAddUserToGroup,
  onOpenUpdateGroups,
  onUpdateUserGroup,
}: UserAddGroupProps) => {
  const { handleToastError } = useToast();

  const [isGroupSearchContentVisible, setSearchIsGroupContentVisible] = useState<boolean>(false);
  const [isRemoveFromOtherGroups, setIsRemoveFromOtherGroups] = useState<boolean>(false);
  const [isUserTypeSearchContentVisible, setIsUserTypeSearchContentVisible] = useState<boolean>(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [selectedUserTypeId, setSelectedUserTypeId] = useState<number>();
  const [userTypeDropdownListOptions, setUserTypeDropdownItems] = useState<IDropDownListOption[]>();
  const [groupDropdownListOptions, setGroupsDropdownItems] = useState<IDropDownListOption[]>();
  const [allGroups, setAllGroups] = useState<IDBGroup[]>([]);
  const [selectedUnitId, setSelectedUnitId] = useState<number>();
  const [availableUnits, setAvailableUnits] = useState<IDBUnit[]>([]);

  const [addUserInGroup, { loading: isAddUserInGroupLoading }] = useLoggedGeladaMutation<IAddUserInGroupResponse>({
    options: {
      onCompleted: ({
        membership: {
          addUserInGroup: { group },
        },
      }) => {
        onAddUserToGroup(group);
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
    mutation: ADD_USER_IN_GROUP,
  });

  const { handleSearchGroups, isLoading: isSearchGroupsLoading = false } = useSearchGroups({
    onCompleted: ({ groups }) => {
      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsDropdownItems(
        hasAllGroupsOption
          ? [
              {
                id: '0',
                label: 'All groups',
                value: '0',
              },
              ...groupsSelectSearchList,
            ]
          : groupsSelectSearchList,
      );

      setAllGroups(groups);
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const { handleSearchUserTypes, isLoading: isSearchUserTypesLoading = false } = useSearchUserTypes({
    onCompleted: ({ userTypes }) => {
      setUserTypeDropdownItems(
        userTypes.map(({ id, name }) => ({
          id: String(id),
          label: name,
          value: String(id),
        })),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const handleSelectGroup = (id: number) => {
    setSelectedGroupId(id);
    setSelectedUnitId(undefined);

    const group = allGroups.find(({id: groupId}) => groupId === id);
    setAvailableUnits(group?.unit ?? []);
  };

  const handleAddUserInGroup = () => {
    void addUserInGroup({
      variables: {
        groupId: selectedGroupId,
        userId,
        projectId,
        userTypeId: selectedUserTypeId,
        removeFromOtherGroups: isRemoveFromOtherGroups,
        unitId: selectedUnitId,
      },
    });

    onOpenUpdateGroups();

    setTimeout(() => {
      if (!onUpdateUserGroup) {
        return;
      }

      onUpdateUserGroup();
    }, 100);
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleSearchUserTypes({
      projectId: String(projectId),
    });

    void handleSearchGroups({
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isAddUserInGroupLoading || isSearchUserTypesLoading || isSearchGroupsLoading;
  const isAddButtonDisabled = Boolean(!(selectedGroupId && selectedUserTypeId));

  return (
    <>
      <Preloader isLoading={isLoading} />

      {userTypeDropdownListOptions && (
        <>
          <Spacing size="3x-large">
            <NavigationButton
              additionalClassNames="c-sidebar-navigation-button"
              direction="previous"
              icon={EnterIcon}
              rotationCssSuffix="180"
              text="Back to groups"
              onClick={onOpenUpdateGroups}
            />
          </Spacing>
          <section>
            <Spacing size="2x-large">
              <Label forId="addUserToGroup" labelText="Add to group" tooltipText="Add user to the group" />
            </Spacing>
          </section>
          <section>
            <Spacing size="x-large">
              <SelectWithSearch
                additionalClassNames={cx(styles['c-select-with-search__user-groups'])} 
                buttonText="All groups" 
                id="groups" 
                isDisplaySearchContent={isGroupSearchContentVisible}
                labelText="Groups"
                searchList={groupDropdownListOptions ?? []}
                isAvatarVisible
                isLabelSideBySide
                isRequired
                onDisplaySearchContent={() => setSearchIsGroupContentVisible(!isGroupSearchContentVisible)}
                onSearchItemClick={(id) => handleSelectGroup(Number(id))}
              />
            </Spacing>

            <Spacing size="x-large">
              <SelectWithSearch
                additionalClassNames={cx(styles['c-select-with-search__user-groups'])} 
                buttonText="All user types" 
                id="userTypes" 
                isDisplaySearchContent={isUserTypeSearchContentVisible}
                labelText="User types"
                searchList={userTypeDropdownListOptions}
                isAvatarVisible
                isLabelSideBySide
                isRequired
                onDisplaySearchContent={() => setIsUserTypeSearchContentVisible(!isUserTypeSearchContentVisible)}
                onSearchItemClick={(id) => setSelectedUserTypeId(Number(id))}
              />
            </Spacing>
          </section>
          {selectedGroupId && selectedGroupId !== 0 && availableUnits.length > 0 && (
            <section>
              <RadioButtonGroup
              items={availableUnits.map((unit) => ({
                  labelText: unit.name,
                  id: String(unit.id),
                  value: String(unit.id)
                }))}
                name="unit"
                selectedValue={selectedUnitId ? String(selectedUnitId): ''}
                title="Select unit"
                isLabelSideBySide
                onChange={({ target: { value }}) => setSelectedUnitId(Number(value))}
              />
            </section>
          )}


          <section>
            <ToggleSwitch
              id="user-status"
              isChecked={isRemoveFromOtherGroups}
              labelText="Remove from other groups?"
              tooltipPosition="start"
              tooltipText="If this option is selected, the user will be removed from all other groups."
              isLabelSideBySide
              onChange={() => setIsRemoveFromOtherGroups(!isRemoveFromOtherGroups)}
            />
          </section>
          <SidebarButtons
            buttonSize="xs"
            isSaveButtonDisabled={isAddButtonDisabled}
            onCancel={onOpenUpdateGroups}
            onSave={handleAddUserInGroup}
          />
        </>
      )}
    </>
  );
};

export { UserAddGroup };
