import { useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateGeladaOrganisation, useCreateGeladaProject } from '@netfront/gelada-identity-library';
import { Input, Spacing, UserIcon } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { RegistrationLayout } from '../RegistrationLayout';

import { projectDetailsDefaults } from './CreateProject.constants';
import { CreateProjectProps, ICreateProjectFormDetails, ICreateProjectFormFields } from './CreateProject.interfaces';

import { useToast } from '../../../hooks';
import { capitalize, useUpdateAccessToken, toKebabCase } from '../../../utils';
import { getFormFieldErrorMessage } from '../../Forms';
import { pageDefaults } from '../../Pages/RegisterFlowPage/RegisterFlowPage.constants';

const CreateProject = ({ onCreate, productName = 'EKARDO' }: CreateProjectProps) => {
  const { handleToastError } = useToast();
  const { updateRefreshToken, isLoading: isUpdateAccessTokenLoading = false } = useUpdateAccessToken();

  const projectRef = useRef<{ value: ICreateProjectFormDetails }>({ value: projectDetailsDefaults });
  const currentStep = 3;

  const productSpecificPageDefaults = pageDefaults[productName];

  const { projectNamingConvention = 'project', projectDescription = '', accountPlaceholder, projectPlaceholder } = productSpecificPageDefaults;

  const { control, handleSubmit } = useForm<ICreateProjectFormFields>({
    defaultValues: {
      organisationName: '',
      projectName: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        organisationName: yup.string().label('Account name').required(),
        projectName: yup
          .string()
          .label(capitalize(String(projectNamingConvention)))
          .required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  const { handleCreateGeladaProject, isLoading: isCreateProjectLoading = false } = useCreateGeladaProject({
    onCompleted: ({ project: returnedProject }) => {
      updateRefreshToken(() => {
        const { organisationKey, projectName } = projectRef.current.value;
        onCreate({
          projectId: returnedProject.id,
          organisationKey: String(organisationKey),
          organisationId: returnedProject.organisationId,
          projectName,
        });
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleCreateGeladaOrganisation, isLoading: isCreateOrganisationLoading = false } = useCreateGeladaOrganisation({
    onCompleted: ({ organisation: returnedOrganisation }) => {
      projectRef.current.value = {
        ...projectRef.current.value,
        organisationKey: returnedOrganisation.key,
      };

      const { projectName } = projectRef.current.value;

      void handleCreateGeladaProject({
        isCustomBuild: false,
        name: projectName,
        key: toKebabCase(projectName),
        organisationId: Number(returnedOrganisation.id),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const onSubmit = ({ organisationName, projectName }: ICreateProjectFormFields) => {
    projectRef.current.value = {
      ...projectRef.current.value,
      organisationName,
      projectName,
    };

    void handleCreateGeladaOrganisation({
      shouldIncludeOrganisationLogo: true,
      description: '',
      name: organisationName,
      key: toKebabCase(organisationName),
    });
  };

  return (
    <form
      onSubmit={(event) => {
        void handleSubmit(({ organisationName, projectName }: ICreateProjectFormFields) => {
          onSubmit({
            organisationName,
            projectName,
          });
        })(event);
      }}
    >
      <RegistrationLayout
        currentStep={currentStep}
        description={projectDescription}
        isLoading={isCreateOrganisationLoading || isCreateProjectLoading || isUpdateAccessTokenLoading}
        title={`Create your first ${String(projectNamingConvention)}`}
      >
        <Spacing>
          <Controller
            control={control}
            name="organisationName"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                iconBefore={{
                  icon: UserIcon,
                }}
                id="id_account_name"
                labelText="Account name"
                placeholder={accountPlaceholder}
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="projectName"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                iconBefore={{
                  icon: UserIcon,
                }}
                id="id_project_name"
                labelText={capitalize(String(projectNamingConvention))}
                placeholder={projectPlaceholder}
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
      </RegistrationLayout>
    </form>
  );
};

export { CreateProject };
