import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, InputFieldWrapper, PencilIcon, PlusIcon, BinIcon } from '@netfront/ui-library';


import { IResponseGroup } from '../CreateResponseSet';

import { deepCompareArrays } from './ResponseOptionsOverview.helpers';
import { ResponseOptionsOverviewProps } from './ResponseOptionsOverview.interfaces';


const ResponseOptionsOverview = ({ onEditClick, watch, onDelete }: ResponseOptionsOverviewProps) => {
  const [items, setItems] = useState<IResponseGroup[]>([]);

  useEffect(() => {
    const subscription = watch((value) => {
      const { availableOptions = [] } = value;
      if (!deepCompareArrays(availableOptions as IResponseGroup[], items)) {
        setItems(availableOptions as IResponseGroup[]);
      }
    });
    return () => subscription.unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              
              {
                accessor: 'name',
                Cell: ({ value }: { value?: string }) => <span>{value ? value : '-'}</span>,
                Header: () => <div>Responses</div>,
                width: '95%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Cell: ({
                  value: {
                    handleDelete,
                    responseId,
                  } }: {
                  value : {
                    handleDelete: (responseId: string) => void;
                    responseId: string;
                  }
                }) => (
                  <>
                    <FlexContainer gap="2x-small" justifyContent="end">
                      <ButtonIconOnly
                        additionalClassNames=""
                        icon={BinIcon}
                        isIconBorderVisible={false}
                        text="Delete condition"
                        onClick={() => handleDelete(responseId)}
                        />
                    </FlexContainer>
                  </>
                ),
              },
            ]}
            data={items.map(({tempId, value}) => {
              return {
                name: value,
                id: tempId,
                settingsButtonData: {
                  responseId: tempId,
                  handleDelete: onDelete,
                },
              };
            })}
          />
        </Spacing>
      )}

      <Spacing>
        <InputFieldWrapper
          id="id_add_responses"
          label={`${items.length > 0 ? 'Edit':  'Add'} responses`}
          labelType="span"
          type="custom"
          isLabelSideBySide
        >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <ButtonIconOnly 
              icon={items.length > 0 ? PencilIcon: PlusIcon} text="Add transcript" onClick={() => onEditClick()} />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>
    </>
  );
};


export { ResponseOptionsOverview };
