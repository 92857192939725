import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useCookie } from '@netfront/common-library';
import {
  convertDBUser,
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  useDomain,
  useLoginWithLoginToken,
  useRegisterUser,
} from '@netfront/gelada-identity-library';
import { Input, KeyTabIcon, PasswordInput, Spacing, SuccessIcon, UserIcon } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IPasswordCriteria, PasswordCriteria, getPasswordValidationState, isPasswordInvalid } from 'components/Shared';

import { RegistrationLayout } from '../RegistrationLayout';

import { PersonalDetailsProps, IPersonalDetailsFormFields } from './PersonalDetails.interfaces';

import { CachingEntitiesContext, UserContext } from '../../../context';
import { useToast } from '../../../hooks';
import { IAddUserToEmailSubscriptionParams, addUserToEmailSubscription } from '../../../utils';
import { getFormFieldErrorMessage } from '../../Forms/Forms.helpers';

const PersonalDetails = ({ initialEmail = '', onRegisterSuccess, productName = 'EKARDO' }: PersonalDetailsProps) => {
  const currentStep = 2;

  const { externalUrl } = useContext(CachingEntitiesContext);
  const { createAccessTokenCookie, createRefreshTokenCookie, createUserDataCookie, isSecureCookie } = useCookie();
  const { getDomain } = useDomain();
  const { handleToastError } = useToast();

  const { setStoredUser } = useContext(UserContext);

  const [isPasswordCheckVisible, setIsPasswordCheckVisible] = useState<boolean>();
  const [passwordCriteriaChecks, setPasswordCriteriaChecks] = useState<IPasswordCriteria>({
    length: {
      label: 'At least 8 characters',
      isValid: false,
    },
    uppercase: {
      label: 'An uppercase character',
      isValid: false,
    },
    lowercase: {
      label: 'A lowercase character',
      isValid: false,
    },
    special: {
      label: 'A special character',
      isValid: false,
    },
  });
  const [termsUrl, setTermsUrl] = useState<string>('');

  const { control, handleSubmit } = useForm<IPersonalDetailsFormFields>({
    defaultValues: {
      firstName: '',
      lastName: '',
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().label('First name').required(),
        lastName: yup.string().label('Last name').required(),
        password: yup
          .string()
          .label('Password')
          .max(128)
          .min(8)
          .test('password-criteria', 'Password must match criteria', () => !isPasswordInvalid(passwordCriteriaChecks))
          .required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  const { handleLoginWithLoginToken, isLoading: isLoginLoading = false } = useLoginWithLoginToken({
    onCompleted: ({ accessToken, refreshToken, user }) => {
      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      if (accessToken) {
        // The createXXXCookie functions all validate that a cookie doesn't exceed the maximum cookie size
        // We only expect the access token to potentially exceed the maximum cookie size hence this is the
        // only function that will be wrapped inside a try/catch block

        try {
          createAccessTokenCookie({
            optionalCookieAttributesInput: {
              domain,
              secure: isSecure,
              expiryOptions: {
                storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
              }
            },
            value: accessToken,
          });
        } catch (error) {
          handleToastError({
            error: error as Error,
            shouldUseFriendlyErrorMessage: true,
          });

          return;
        }
      }

      if (refreshToken) {
        createRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
            }
          },
          value: refreshToken,
        });
      }

      if (user) {
        const updatedUser = convertDBUser(user);

        const {
          credential: { email: returnedEmail },
          firstName,
          id,
          lastName,
        } = updatedUser;

        setStoredUser(updatedUser);

        if (productName !== 'EKARDO') {
          let customFields: IAddUserToEmailSubscriptionParams['customFields'] = {} as IAddUserToEmailSubscriptionParams['customFields'];

          if (productName === 'KANZI') {
            customFields = {
              PDF: 'Free',
              Speech: 'Free',
              Translate: 'Free',
            };
          }

          if (productName === 'QUICKCODES') {
            customFields = {
              Plan: 'Free',
            };
          }

          addUserToEmailSubscription({
            customFields,
            emailAddress: returnedEmail,
            name: `${firstName} ${lastName}`,
            product: productName,
          });
        }

        /*
         * Note
         *  - The whole user object is too large to store in a cookie
         *  - Extract only the important user data to store in a cookie
         */

        createUserDataCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
            }
          },
          value: JSON.stringify({
            email: returnedEmail,
            firstName,
            id,
            lastName,
          }),
        });
      }

      onRegisterSuccess(currentStep + 1);
    },
  });

  const { handleRegisterUser, isLoading: isRegisterUserLoading = false } = useRegisterUser({
    onCompleted: ({ isCompleted, loginToken }) => {
      if (!(isCompleted && loginToken)) {
        return;
      }

      void handleLoginWithLoginToken({ key: loginToken, shouldIncludeUserCredential: true });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product: productName,
  });

  const handlePasswordCriteria = (value: string) => {
    const passwordValidationState = getPasswordValidationState(passwordCriteriaChecks, value);
    setIsPasswordCheckVisible(!Object.keys(passwordValidationState).every((key) => passwordValidationState[key].isValid));
    setPasswordCriteriaChecks(getPasswordValidationState(passwordCriteriaChecks, value));
  };

  const onSubmit = ({ firstName, lastName, password }: IPersonalDetailsFormFields) => {
    void handleRegisterUser({
      credential: {
        email: initialEmail,
        password,
      },
      user: {
        firstname: firstName,
        lastname: lastName,
        product: productName,
      },
      generateLoginToken: true,
    });
  };

  useEffect(() => {
    if (!externalUrl) {
      return;
    }

    setTermsUrl(`${productName === 'EKARDO' ? 'https://netfront.com.au/terms' : `${externalUrl}/terms-and-conditions`}`);
  }, [externalUrl, productName]);

  return (
    <form
      onSubmit={(event) => {
        void handleSubmit(({ firstName, lastName, password }: IPersonalDetailsFormFields) => {
          onSubmit({
            firstName,
            lastName,
            password,
          });
        })(event);
      }}
    >
      <RegistrationLayout
        currentStep={currentStep}
        description="Lets get your account set up"
        isLoading={isRegisterUserLoading || isLoginLoading}
        title="Account creation"
        hasLoginLink
        onBackClick={() => onRegisterSuccess(currentStep - 1)}
      >
        <Spacing>
          <Input
            // Apply autocomplete to ensure chrome password manager takes correct value
            autoComplete="username"
            iconBefore={{
              icon: SuccessIcon,
            }}
            id="id_new_email"
            labelText="Email"
            name="id_new_email"
            type="email"
            value={initialEmail}
            isDisabled
            isLabelHidden
            onChange={() => {
              return;
            }}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                iconBefore={{
                  icon: UserIcon,
                }}
                id="id_new_first_name"
                labelText="First name"
                placeholder="Enter your first name"
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                iconBefore={{
                  icon: UserIcon,
                }}
                id="id_new_last_name"
                labelText="Last name"
                placeholder="Enter your last name"
                type="text"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing>
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState }) => (
              <PasswordInput
                errorMessage={getFormFieldErrorMessage(fieldState)}
                iconBefore={{
                  icon: KeyTabIcon,
                }}
                id="id_set_password"
                labelText="Password"
                placeholder="Enter your password"
                isRequired
                {...field}
                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(value);
                  handlePasswordCriteria(value);
                }}
              />
            )}
          />
          {isPasswordCheckVisible && <PasswordCriteria criteria={passwordCriteriaChecks} label="Please note: Your password must include" />}
        </Spacing>
        <Spacing>
          <span className="c-registration-layout__description">
            By clicking next, you agree to the{' '}
            <a className="c-register-page__terms-link" href={termsUrl} rel="noreferrer" target="_blank">
              terms &amp; conditions
            </a>
          </span>
        </Spacing>
      </RegistrationLayout>
    </form>
  );
};

export { PersonalDetails };
