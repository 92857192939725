// TODO: Move these page scoped constants to the correct location

import { DBNetfrontServiceType } from '@netfront/gelada-identity-library';
import { EmailBouncedIcon, EmailClickedIcon, EmailComplaintIcon, EmailDeliveredIcon, EmailNotClickedIcon, EmailOpenedIcon, EmailSentIcon, IconComponentType, RejectedIcon } from '@netfront/ui-library';

const ALL_GROUPS = 'All groups';

const DEFAULT_SERVICES: DBNetfrontServiceType[] = ['EKARDO', 'HOWLER', 'VERVET'];

const EMAIL_ICONS: { [key: string]: IconComponentType } = Object.freeze({
  BOUNCE: EmailBouncedIcon,
  CLICK: EmailClickedIcon,
  COMPLAINT: EmailComplaintIcon,
  DELIVERY: EmailDeliveredIcon,
  NOT_CLICKED: EmailNotClickedIcon,
  OPEN: EmailOpenedIcon,
  REJECT: RejectedIcon,
  SENT: EmailSentIcon,
});

const EMAIL_ICON_TOOLTIPS: {[key: string]: string} = Object.freeze({
  BOUNCE: 'Bounced',
  CLICK: 'Clicked',
  COMPLAINT: 'Complaint',
  DELIVERY: 'Delivered',
  NOT_CLICKED: 'Not Clicked',
  OPEN: 'Opened',
  REJECT: 'Rejected',
  SENT: 'Sent',
});

const SOCIAL_SERVICES: DBNetfrontServiceType[] = ['BONOBO', ...DEFAULT_SERVICES];

const USER_STATUS_TYPE = Object.freeze({
  ACTIVE: 'active',
  DISABLED: 'inactive',
});

export { ALL_GROUPS, DEFAULT_SERVICES, EMAIL_ICON_TOOLTIPS, EMAIL_ICONS, SOCIAL_SERVICES, USER_STATUS_TYPE };
