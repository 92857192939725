import { useEffect, useState } from 'react';

import { useToggle } from '@netfront/common-library';
import { IDBUser, useLoggedGeladaLazyQuery, IGeladaOrganisation, useGetGeladaOrganisationByKey } from '@netfront/gelada-identity-library';
import { GeneralTabIcon, KeyTabIcon, NotesTabIcon, ToggleSwitch } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { ACCOUNT_MANAGEMENT_PAGE_CONSTANTS } from '../AccountManagementPages.constants';

import { ACCOUNT_USER_TABLE_COLUMNS } from './AccountUsers.constants';
import { getAccountUsersTableData } from './AccountUsers.helpers';
import { IAccountUserTableData, IPaginatedAccountUserUserResponse } from './AccountUsers.interfaces';

import { UserPasswordTab } from '../../../../components/Tabs';
import { GET_PAGINATED_ORGANISATION_MEMBERS } from '../../../../graphql';
import { useToast } from '../../../../hooks';
import { getStatusString } from '../../../../utils';
import { ManageInvitationsGeneralView, ManageUsersGeneralView, NotesView } from '../../../ManagementViews';
import { ISelectedUser, IUserEdge, IUserNode } from '../../../Pages/Pages.interfaces';
import { TablePageTemplate } from '../../../Templates';

const { pageTitle, defaultPageSize } = ACCOUNT_MANAGEMENT_PAGE_CONSTANTS;

const AccountUsers = () => {
  const {
    query: { organisationKey: queryOrganisationKey },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();
  const { isToggled: isSideBarOpen, toggle: toggleIsSideBarOpen } = useToggle();

  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [organisationName, setOrganisationName] = useState<string>('');
  const [currentOrganisation, setCurrentOrganisation] = useState<IGeladaOrganisation>();
  const [organisationId, setOrganisationId] = useState<number>();
  const [isAddNewUser, setIsAddNewUser] = useState<boolean>(true);
  const [isClearFilter, setIsClearFilter] = useState<boolean>(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isPaginationDisabled, setIsPaginationDisabled] = useState<boolean>(false);
  const [isUserStatusActive, setIsUserStatusActive] = useState<boolean>(true);
  const [lastUserCursor, setLastUserCursor] = useState<string | undefined>(undefined);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [selectedUser, setSelectedUser] = useState<IDBUser | ISelectedUser>();
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [userFilter, setUserFilter] = useState<string>('');
  const [userResult, setUserResult] = useState([] as IUserNode[]);
  const [users, setUsers] = useState<IAccountUserTableData[]>([]);

  // organisation
  const { handleGetGeladaOrganisationByKey, isLoading: isGetOrganisationLoading } = useGetGeladaOrganisationByKey({
    onCompleted: ({ geladaOrganisation }) => {
      setOrganisationName(geladaOrganisation.name);
      setCurrentOrganisation(geladaOrganisation);
      setOrganisationId(geladaOrganisation.id);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  // user functions
  const [getPaginatedUsers, { fetchMore, loading: isGetUsersPaginated }] = useLoggedGeladaLazyQuery<IPaginatedAccountUserUserResponse>({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: ({
        membership: {
          getPaginatedOrganisationMembers: { edges: userEdges, totalCount = 0 },
        },
      }) => {
        if (totalCount === 0) {
          setUsers([]);
          setLastUserCursor(undefined);
          setIsPaginationDisabled(true);
          setTotalUsers(Number(totalCount));

          return;
        }

        const usersItems = [...(userEdges)].map((userEdge: IUserEdge) => userEdge.node);
        const { cursor } = userEdges[userEdges.length - 1];

        setTotalUsers(Number(totalCount));
        setLastUserCursor(String(cursor));
        setIsPaginationDisabled(users.length >= totalCount || totalCount <= pageSize);
        setUserResult(usersItems);
        setUsers(getAccountUsersTableData({ users: usersItems, onSettingsButtonClick: handleSettingsButtonClick }));

        if (userFilter || isClearFilter) {
          setUsers(getAccountUsersTableData({ users: usersItems, onSettingsButtonClick: handleSettingsButtonClick }));
          setIsClearFilter(false);
        }
      },
      onError: (error) => {
        handleToastError({
          error,
        });
        setIsPaginationDisabled(true);
      },
    },
    query: GET_PAGINATED_ORGANISATION_MEMBERS,
  });

  const handleSettingsButtonClick = (user: IDBUser | ISelectedUser) => {
    setIsAddNewUser(false);
    setSelectedUser(user);
    toggleIsSideBarOpen();
  };

  const handleAddNewUserClick = () => {
    setIsAddNewUser(true);
    toggleIsSideBarOpen();
  };

  const handleUpdateUserStatus = () => {
    setIsUserStatusActive(!isUserStatusActive);

    void getPaginatedUsers({
      variables: {
        filter: userFilter,
        first: pageSize,
        organisationId: Number(organisationKey),
        status: getStatusString(!isUserStatusActive),
      },
    });
  };

  const handleSearch = (val: string) => {
    setUserFilter(val);

    void getPaginatedUsers({
      variables: {
        filter: val,
        first: pageSize,
        organisationId: Number(organisationKey),
        status: getStatusString(isUserStatusActive),
      },
    });
  };

  const handleGetUsersPaginated = () => {
    void getPaginatedUsers({
      variables: {
        filter: userFilter,
        first: pageSize,
        organisationId: Number(organisationKey),
        status: getStatusString(isUserStatusActive),
      },
    });
  };

  const handleDeletedUser = () => {
    toggleIsSideBarOpen();

    handleToastSuccess({
      message: 'User deleted successfully',
    });

    handleGetUsersPaginated()
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleOnPaginate = async () => {
    setIsLoadingMore(true);

    const data = await fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          membership: {
            getPaginatedOrganisationMembers: { edges: currentUserEdges },
          },
        } = fetchMoreResult;

        if (!currentUserEdges.length) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return previousQueryResult;
        }

        const { membership: previousQueryResultGroup } = previousQueryResult;
        const { getPaginatedOrganisationMembers: previousQueryResultGetPaginatedGroup } = previousQueryResultGroup;
        const { edges: previousUserEdges } = previousQueryResultGetPaginatedGroup;

        const allFetchedUserEdges = [...previousUserEdges, ...currentUserEdges];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          ...previousQueryResult,
          membership: {
            ...previousQueryResultGroup,
            getPaginatedOrganisationMembers: {
              ...previousQueryResultGetPaginatedGroup,
              edges: allFetchedUserEdges,
            },
          },
        };
      },
      variables: {
        after: lastUserCursor,
        filter: userFilter,
        first: pageSize,
        isPrevious: false,
        status: getStatusString(isUserStatusActive),
      },
    });

    const {
      data: {
        membership: {
          getPaginatedOrganisationMembers: { edges: currentUserEdges },
        },
      },
    } = data;

    const currentFetchedUsers = [...currentUserEdges].map((currentUserEdge: { node: unknown }) => currentUserEdge.node);

    const allFetchedUsers = [...userResult, ...currentFetchedUsers];

    setIsLoadingMore(false);
    setIsPaginationDisabled(allFetchedUsers.length >= totalUsers);
    setLastUserCursor(currentUserEdges[currentUserEdges.length - 1].cursor);
    setUsers(getAccountUsersTableData({ users: allFetchedUsers as IUserNode[], onSettingsButtonClick: handleSettingsButtonClick }));
    setUserResult(allFetchedUsers as IUserNode[]);
  };

  useEffect(() => {
    if (!organisationKey) {
      return;
    }

    void handleGetGeladaOrganisationByKey({
      organisationKey: String(organisationKey),
      shouldIncludeOrganisationLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationKey]);

  useEffect(() => {
    if (!organisationId) return;
    void getPaginatedUsers({
      variables: {
        filter: userFilter,
        first: pageSize,
        organisationId: Number(organisationId),
        status: getStatusString(isUserStatusActive),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, organisationId]);

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const isLoading = isGetOrganisationLoading || isGetUsersPaginated || isLoadingMore;

  return (
    <TablePageTemplate<IAccountUserTableData>
      activePage="users"
      activeSubPage="users"
      childrenEnd={
        <ToggleSwitch
          additionalClassNames="c-user-status-toggle"
          id="user-status-filter"
          isChecked={!isUserStatusActive}
          labelText="Show inactive"
          isInline
          onChange={handleUpdateUserStatus}
        />
      }
      columns={ACCOUNT_USER_TABLE_COLUMNS}
      data={users}
      description={`Manage users for ${organisationName}`}
      handleAddNewClick={handleAddNewUserClick}
      handleOnPageSizeChange={handleOnPageSizeChange}
      handleOnPaginate={handleOnPaginate}
      handleSearch={handleSearch}
      informationBoxMessage={
        <>
          Manage <strong>{String(organisationName)}</strong> users:{' '}
          <strong>
            {totalUsers} {pluralize('user', totalUsers)}
          </strong>
        </>
      }
      isLoading={isLoading}
      isPaginationDisabled={isPaginationDisabled}
      isSideBarOpen={isSideBarOpen}
      organisation={currentOrganisation}
      pageSize={pageSize}
      pageTitle={pageTitle}
      tabs={[
        {
          icon: GeneralTabIcon,
          id: 'id_general_tab',
          label: 'General',
          view: () => (
            <>
            {
              isSideBarOpen ? (
                <>
                  {
                    isAddNewUser ? (
                      <ManageInvitationsGeneralView
                        organisationId={organisationId}
                        onClose={toggleIsSideBarOpen}
                        onUpdateInvitations={() => null}
                      />
                    ): (
                      <ManageUsersGeneralView
                        organisationId={Number(organisationId)}
                        user={selectedUser as IDBUser}
                        onClose={toggleIsSideBarOpen}
                        onDeleted={handleDeletedUser}
                        onUpdated={handleGetUsersPaginated}
                      />
                    )
                  }
                </>
              ): <></>
            }
            </>
          ),
        },
        {
          icon: KeyTabIcon,
          id: 'id_password_tab',
          isHidden: isAddNewUser,
          label: 'Password',
          view: () => <UserPasswordTab user={selectedUser as IDBUser} isAccountLevel onClose={toggleIsSideBarOpen} />,
        },
        {
          icon: NotesTabIcon,
          id: 'id_notes_tab',
          isHidden: isAddNewUser,
          label: 'Notes',
          view: () => <NotesView userId={Number(selectedUser?.id)} />,
        },
      ]}
      title={organisationName}
      toggleIsSideBarOpen={toggleIsSideBarOpen}
      totalItems={totalUsers}
      isInternal
      isOrganisationLevel
    />
  );
};

// eslint-disable-next-line import/no-default-export
export { AccountUsers };
