import { gql } from '@apollo/client';

export const ADD_USER_IN_GROUP = gql`
  mutation addUserInGroup($userId: Int!, $groupId: Int!, $userTypeId: Int!, $removeFromOtherGroups: Boolean!, $unitId: Int) {
    membership {
      addUserInGroup(userId: $userId, groupId: $groupId, userTypeId: $userTypeId, removeFromOtherGroups: $removeFromOtherGroups, unitId: $unitId) {
        group {
          id
          name
        }
        userId
        user {
          firstname
          lastname
        }
      }
    }
  }
`;
