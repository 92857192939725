import React, { useEffect, useState } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { IDBUnit, useSearchUserTypes } from '@netfront/gelada-identity-library';
import {
  EnterIcon,
  Label,
  NavigationButton,
  Preloader,
  RadioButtonGroup,
  Select,
  SelectWithSearch,
  SidebarButtons,
  Spacing,
} from '@netfront/ui-library';
import cx from 'classnames';

import { SidebarContainer } from 'components/Shared';

import { UserUpdateInGroupProps } from './UserUpdateInGroup.interfaces';
import styles from './UserUpdateInGroup.module.css';

import { useGetGroupById, useToast, useUpdateGroupMembership } from '../../../../../hooks';

const UserUpdateInGroup = ({ membership, projectId, onOpenUpdateGroups, onUpdateUserGroup }: UserUpdateInGroupProps) => {
  const { handleToastError } = useToast();

  const { id: membershipId, group, groupId, unitId } = membership;
  const { name: groupName } = group ?? {};

  if (!group) {
    throw new Error('Group must be defined in the membership');
  }

  const [isUserTypeSearchContentVisible, setIsUserTypeSearchContentVisible] = useState<boolean>(false);
  const [selectedUserTypeId, setSelectedUserTypeId] = useState<number>();
  const [userTypeDropdownListOptions, setUserTypeDropdownItems] = useState<IDropDownListOption[]>();
  const [selectedUnitId, setSelectedUnitId] = useState<number>();
  const [allUnits, setAllUnits] = useState<IDBUnit[]>([]);

  const { handleUpdateGroupMembership, isLoading: isUpdateMembershipLoading } = useUpdateGroupMembership({
    onCompleted: () => {
      onUpdateUserGroup?.();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleGetGroupById, isLoading: isGetGroupByIdLoading = false } = useGetGroupById({
    onCompleted: ({ group: returnedGroup }) => {
      const { unit = [] } = returnedGroup;

      setAllUnits(unit);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleSearchUserTypes, isLoading: isSearchUserTypesLoading = false } = useSearchUserTypes({
    onCompleted: ({ userTypes }) => {
      setUserTypeDropdownItems(
        userTypes.map(({ id, name }) => ({
          id: String(id),
          label: name,
          value: String(id),
        })),
      );
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleSearchUserTypes({
      projectId: String(projectId),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!groupId) return;
    void handleGetGroupById({ groupId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    setSelectedUnitId(unitId);
  }, [unitId]);

  useEffect(() => {
    setSelectedUserTypeId(membership.userType?.id);
  }, [membership]);

  const isAddButtonDisabled = Boolean(!(groupId && selectedUserTypeId));
  const isLoading = Boolean(isUpdateMembershipLoading) || isSearchUserTypesLoading || isGetGroupByIdLoading;

  return (
    <>
      <Preloader isLoading={isLoading} />
      {userTypeDropdownListOptions && (
        <SidebarContainer>
          <Spacing size="3x-large">
            <NavigationButton
              additionalClassNames="c-sidebar-navigation-button"
              direction="previous"
              icon={EnterIcon}
              rotationCssSuffix="180"
              text="Back to groups"
              onClick={onOpenUpdateGroups}
            />
          </Spacing>
          <section>
            <Spacing size="2x-large">
              <Label forId="addUserToGroup" labelText="Add to group" tooltipText="Update in group" />
            </Spacing>
          
          </section>
          <section>

            <Spacing size="x-large">
              <Select
                key="groupSelectionSelect"
                additionalClassNames={cx(styles['c-select-with-search__user-groups'])}
                id="groupSelection"
                labelText="Group"
                name="groupSelection"
                options={[{ id: Number(groupId), name: String(groupName), value: String(groupId) }]}
                value={String(membership.groupId)}
                isDisabled
                isLabelSideBySide
                onChange={() => {
                  console.error('disabled input');
                }}
              />
            </Spacing>
            <Spacing size="x-large">
              <RadioButtonGroup
                items={allUnits.map((unit) => ({
                  labelText: unit.name,
                  id: String(unit.id),
                  value: String(unit.id)
                }))}
                name="unit"
                selectedValue={selectedUnitId ? String(selectedUnitId): ''}
                title="Select unit"
                isLabelSideBySide
                onChange={({ target: { value }}) => setSelectedUnitId(Number(value))}
              />
            </Spacing>
            <Spacing size="x-large">
              <SelectWithSearch
                additionalClassNames={cx(styles['c-select-with-search__user-groups'])}
                buttonText={
                  userTypeDropdownListOptions.find(({ id }) => id === String(membership.userType?.id))?.label ?? 'Select user type'
                }
                id="userTypes"
                isDisplaySearchContent={isUserTypeSearchContentVisible}
                labelText="User types"
                searchList={userTypeDropdownListOptions}
                isAvatarVisible
                isLabelSideBySide
                isRequired
                onDisplaySearchContent={() => setIsUserTypeSearchContentVisible(!isUserTypeSearchContentVisible)}
                onSearchItemClick={(id) => setSelectedUserTypeId(Number(id))}
              />
            </Spacing>
          </section>

          <SidebarButtons
            buttonSize="xs"
            isSaveButtonDisabled={isAddButtonDisabled}
            onCancel={onOpenUpdateGroups}
            onSave={() => {
              if (!selectedUserTypeId) {
                return;
              }

              void handleUpdateGroupMembership({
                request: {
                  groupId: Number(groupId),
                  membershipId,
                  userTypeId: Number(selectedUserTypeId),
                  unitId: selectedUnitId,
                },
              });
            }}
          />
        </SidebarContainer>
      )}
    </>
  );
};

export { UserUpdateInGroup };
