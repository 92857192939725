const TWO_STEP_VERIFICATION_PAGE_DEFAULTS = Object.freeze({
  scan: '2 step factor',
  code: 'Confirm',
  complete: 'You are set up!',
});

const TWO_STEP_VERIFICATION_PAGE_CONSTANTS = Object.freeze({
  defaults: TWO_STEP_VERIFICATION_PAGE_DEFAULTS,
});

export { TWO_STEP_VERIFICATION_PAGE_CONSTANTS };
