import { ISetting } from "@netfront/gelada-identity-library";
import { FormFieldProps } from "@netfront/ui-library";
import { UseFormGetValues } from "react-hook-form";



const getSettingsDefaultValues = ({getValues, settings}: { getValues: UseFormGetValues<FormFieldProps>; settings: ISetting[] }): FormFieldProps => {

  const values = getValues();

  const defaultValues = settings.reduce((accumulator, item) => {
    const { type  } = item;
    accumulator[type] = values[type];

    return accumulator;
  }, {} as FormFieldProps);

  return defaultValues;

};


export { getSettingsDefaultValues };
