import { IApolloConnectionEdge } from '@netfront/common-library';
import { IDBGroup } from '@netfront/gelada-identity-library';

import { IGetUsersTableDataParams, IUsersTableData } from './ProjectUsers.types';

const getUsersTableData = ({ users, onSettingsButtonClick, baseUrl }: IGetUsersTableDataParams): IUsersTableData[] => {
  return users.map((user): IUsersTableData => {
    const {
      activeGroups,
      communityName: displayName,
      created,
      credential,
      firstName,
      id,
      lastName,
      lastLogin,
      isProjectAdmin,
      status,
      permission,
    } = user;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const { email = '' } = credential || {};

    const isAdmin = isProjectAdmin || permission === 'MANAGE_USERS';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const groups = activeGroups?.edges.map((r: IApolloConnectionEdge<IDBGroup>) => r.node.name).join(',') ?? '';

    return {
      avatarData: {
        firstName,
        fullName: `${firstName} ${lastName}`.trim(),
        isAdmin,
        isProjectAdmin,
        permission,
      },
      displayName,
      email,
      firstName,
      lastLogin,
      groupName: isProjectAdmin ? 'Admin, All groups' : groups,
      id,
      isAdmin,
      lastName,
      registered: new Date(created),
      role: isProjectAdmin ? 'Admin' : 'User',
      settingsButtonData: {
        firstName,
        id,
        onClick: onSettingsButtonClick,
        url: baseUrl ? `${baseUrl}/?userId=${id}`: undefined
      },
      status,
    };
  });
};

export { getUsersTableData };
