// TODO: Move to @netfront/common-library

import { IOption } from '@netfront/ui-library';

const getSelectOptions = (data: readonly string[]): IOption[] => {
  return data.map((item, index) => {
    const option: IOption = {
      id: index,
      name: item,
      value: index,
    };

    return option;
  });
};

const getSelectedIndexFromTarget = (target: HTMLSelectElement): number => {
  const {
    options: { selectedIndex },
  } = target;

  return selectedIndex;
};

export { getSelectedIndexFromTarget, getSelectOptions };
