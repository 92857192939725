import { gql } from '@apollo/client';

export const GET_GROUP_CONTACTS = gql`
  query GetGroupContacts($groupId: Int!) {
    groupContact {
      get(groupId: $groupId) {
        items {
          user {
            id
            firstname
            lastname
            communityName
            credential {
              email
              phoneNumber
            }
          }
        }
      }
    }
  }
`;
