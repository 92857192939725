

import { ISetting } from "@netfront/gelada-identity-library";

import { IServiceOptions } from "./UpsertProjectSidebarFeaturesTab.interfaces";

const getServiceOptions = (settings: ISetting[]): IServiceOptions => {
  return settings.reduce((acc, item) => {
    const { service } = item as { service: keyof IServiceOptions };
    
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!acc[service]) {
      acc[service] = [];
    }
    
    acc[service].push(item);
    
    return acc;
  }, {} as IServiceOptions);
};

export { getServiceOptions };