import { gql } from '@apollo/client';

export const GET_GROUP_NOTES = gql`
  query GetGroupNotes($groupId: Int!) {
    note {
      getByGroup(groupId: $groupId) {
        id
        message
        subject
        attachments {
          asset {
            s3Key
            fileName
            fileSizeInBytes
            assetId
            contentType
          }
        }
      }
    }
  }
`;
