import { IUpdatePasswordFormFields } from './UpdatePasswordForm.interfaces';

const CSS_IDENTIFIERS = Object.freeze({
  block: 'c-update-password-form',
});

const INITIAL_VALUES: IUpdatePasswordFormFields = {
  currentPassword: '',
  newPassword: '',
};

const PASSWORD_PREFIXES = Object.freeze({
  current: 'current',
  new: 'new',
});

const UPDATE_PASSWORD_FORM_CONSTANTS = Object.freeze({
  cssIdentifiers: CSS_IDENTIFIERS,
  initialValues: INITIAL_VALUES,
  passwordPrefixes: PASSWORD_PREFIXES,
});

export { UPDATE_PASSWORD_FORM_CONSTANTS };
