import { gql } from '@apollo/client';

export const UPDATE_USER_AS_ADMIN = gql`
  mutation updateUserAsAdmin($userId: Int!, $firstname: String, $lastname: String, $communityName: String, $phoneNumber: String) {
    user {
      updateUserAsAdmin(
        userId: $userId
        firstname: $firstname
        lastname: $lastname
        communityName: $communityName
        phoneNumber: $phoneNumber
      )
    }
  }
`;
