import React, { useState, useEffect, useContext, useMemo } from 'react';

import { DBUserTypeStatusType, IDBUserType } from '@netfront/gelada-identity-library';
import { ToggleSwitch } from '@netfront/ui-library';
import { RolesBulkActionDialog, RolesBulkActionType } from 'components';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { PROJECT_ROLES_TABLE_COLUMNS } from './ProjectRoles.constants';
import { getProjectRolesTableData } from './ProjectRoles.helpers';
import { BulkActionType, IProjectRolesTableData } from './ProjectRoles.interfaces';

import { CachingEntitiesContext } from '../../../../context';
import { useToast, useGetPaginatedUserTypes } from '../../../../hooks';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';
import { UpsertUserTypeSidebar } from '../../../Sidebars/UpsertUserTypeSidebar';
import { TablePageTemplate } from '../../../Templates/TablePageTemplate';

const { defaultPageSize, pageTitle } = USER_MANAGEMENT_PAGE_CONSTANTS;

const ProjectRoles = () => {
  const { project, isProduct } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const [projectName, setProjectName] = useState<string>();
  const [projectId, setProjectId] = useState<string>('');
  const [totalRoles, setTotalRoles] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const { handleToastError } = useToast();

  const [filter, setFilter] = useState<string>();
  const [allUserTypes, setAllUserTypes] = useState<IDBUserType[]>();
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [selectedUserTypeId, setSelectedUserTypeId] = useState<number>();
  const [userTypesTableData, setUserTypesTableData] = useState<IProjectRolesTableData[]>();
  const [statusFilter, setStatusFilter] = useState<DBUserTypeStatusType>('ENABLED');

    // bulk actions
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [bulkActionType, setBulkActionType] = useState<BulkActionType | undefined>(BulkActionType.DELETE);
    const [isBulkActionDialogOpen, setIsBulkActionDialogOpen] = useState<boolean>(false);

  const {
    handleFetchMorePaginatedUserTypes,
    handleGetPaginatedUserTypes,
    isLoading: isGetPaginatedUserTypesLoading = false,
  } = useGetPaginatedUserTypes({
    fetchPolicy: 'network-only',
    onCompleted: ({ userTypesConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const userTypes = edges.map(({ node }) => node);

      setTotalRoles(totalCount);
      setIsLoadMoreDisabled(userTypes.length >= totalCount || totalCount <= pageSize);

      setAllUserTypes(userTypes);
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleAddNewUserTypeClick = () => {
    setIsSideBarOpen(true);
    setSelectedUserTypeId(undefined);
  };

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedUserTypes({
      name: value,
      first: pageSize,
      projectId: String(projectId),
      status: statusFilter,
    });

    setFilter(value);
  };

  const handleSideBarClose = () => {
    setSelectedUserTypeId(undefined);
    setIsSideBarOpen(false);
  };

  const handleUpdateUserTypes = () => {
    handleSideBarClose();
    void handleGetPaginatedUserTypes({
      name: filter,
      first: pageSize,
      projectId: String(projectId),
      status: statusFilter,
    });
  };

  const handleUserStatusFilterChange = (status: DBUserTypeStatusType) => {
    setStatusFilter(status === 'ENABLED' ? 'DISABLED' : 'ENABLED');
  };

  // bulk actions
  const handleOpenBulkActionDialog = (type: BulkActionType) => {
    setBulkActionType(type);
    setIsBulkActionDialogOpen(true);
  };

  const handleCloseBulkActionDialog = () => {
    setBulkActionType(undefined);
    setIsBulkActionDialogOpen(false);
  };

  const handleSelectedRows = (selectedIds: string[]) => {
    setSelectedRows(selectedIds);
  };

  const handleBulkActionSave = () => {
    setSelectedRows([]);
    handleCloseBulkActionDialog();
    void handleGetPaginatedUserTypes({
      name: filter,
      first: pageSize,
      projectId: String(projectId),
      status: statusFilter,
    });
  };

  const bulkActions = useMemo(() => [
    { id: 0, label: 'Activate roles', action: () => handleOpenBulkActionDialog(BulkActionType.ACTIVATE) },
    { id: 1, label: 'Deactivate roles', action: () => handleOpenBulkActionDialog(BulkActionType.DEACTIVATE) },
    { id: 2, label: 'Delete roles', action: () => handleOpenBulkActionDialog(BulkActionType.DELETE) },
  ], []);
    

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetPaginatedUserTypes({
      name: filter,
      first: pageSize,
      projectId: String(projectId),
      status: statusFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId, statusFilter]);

  useEffect(() => {
    if (!allUserTypes) {
      return;
    }

    setUserTypesTableData(
      getProjectRolesTableData({
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedUserTypeId(id);
        },
        userTypes: allUserTypes,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUserTypes]);

  useEffect(() => {
    if (!project) {
      return;
    }
    const { name } = project;
    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetPaginatedUserTypesLoading;

  return (
    <>
      <TablePageTemplate<IProjectRolesTableData>
        activePage={isProduct ? 'users' : 'roles'}
        activeSubPage="roles"
        additionalBreadcrumbItems={[
          {
            key: '3',
            content: <span>Roles</span>,
          },
        ]}
        bulkActions={bulkActions}
        childrenEnd={
          <ToggleSwitch
            additionalClassNames="c-user-status-toggle"
            id="user-status-filter"
            isChecked={statusFilter === 'DISABLED'}
            labelText="Show inactive"
            isInline
            onChange={() => handleUserStatusFilterChange(statusFilter)}
          />
        }
        columns={PROJECT_ROLES_TABLE_COLUMNS}
        data={userTypesTableData ?? []}
        description="Manage users"
        handleAddNewClick={handleAddNewUserTypeClick}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedUserTypes({
            after: lastCursor,
            name: filter,
            first: pageSize,
            projectId: String(projectId),
            status: statusFilter,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={
          <>
            Manage <strong>{String(projectName)}</strong> roles:{' '}
            <strong>
              {totalRoles} {pluralize('role', totalRoles)}
            </strong>
          </>
        }
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageTitle={pageTitle}
        searchPlaceholder="Name"
        tableType="roles"
        title={String(projectName)}
        isProjectLevel
        onSelectRows={handleSelectedRows}
      />
      {projectId && (
        <UpsertUserTypeSidebar
          handleSideBarClose={handleSideBarClose}
          isSideBarOpen={isSideBarOpen}
          projectId={projectId}
          selectedUserTypeId={selectedUserTypeId}
          onUpdate={handleUpdateUserTypes}
        />
      )}
      
      <RolesBulkActionDialog
        bulkActionType={bulkActionType as RolesBulkActionType}
        handleCloseDialog={handleCloseBulkActionDialog}
        isOpen={isBulkActionDialogOpen}
        selectedIds={selectedRows}
        onSave={handleBulkActionSave}
      />
    </>
  );
};

export { ProjectRoles };
