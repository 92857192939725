import { yupResolver } from '@hookform/resolvers/yup';
import { EmailClickedIcon, Input, Spacing } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { RegistrationLayout } from '../RegistrationLayout';

import { GetStartedProps, IGetStartedFormFields } from './GetStarted.interfaces';

import { getFormFieldErrorMessage } from '../../Forms/Forms.helpers';
import { pageDefaults } from '../../Pages/RegisterFlowPage/RegisterFlowPage.constants';

const GetStarted = ({ handleNextStep, productName = 'EKARDO' }: GetStartedProps) => {
  const productSpecificPageDefaults = pageDefaults[productName];

  // const [selectedProvider, setSelectedProvider] = useState<string>();
  // const [redirectUrl, setRedirectUrl] = useState<string>();
  // const { getBaseUrl } = useProductsSiteUrls({
  //   environment: process.env.REACT_APP_ENVIRONMENT,
  //   port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  // });

  const { control, handleSubmit } = useForm<IGetStartedFormFields>({
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().label('Email').email('Please enter a valid email').required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  const { introduction = '', description = '' } = productSpecificPageDefaults;
  const currentStep = 1;

  const onSubmit = ({ email }: IGetStartedFormFields) => {
    handleNextStep({
      email,
      updatedStep: currentStep + 1,
    });
  };

  // const handleProviderSelect = (provider: string) => {
  //   setSelectedProvider(provider);
  // };

  // const getProductSSOCredentials = (product: 'EKARDO' | 'KANZI' | 'QUICKCODES'): { clientId: string; url: string } => {
  //   const credentialMap = {
  //     KANZI: {
  //       url: process.env.REACT_KANZI_SSO_URL as string,
  //       clientId: process.env.REACT_KANZI_SSO_CLIENT_ID as string
  //     },
  //     QUICKCODES: {
  //       url: process.env.REACT_QUICKCODES_SSO_URL as string,
  //       clientId: process.env.REACT_QUICKCODES_SSO_CLIENT_ID as string
  //     },
  //     EKARDO: {
  //       url: process.env.REACT_EKARDO_SSO_URL as string,
  //       clientId: process.env.REACT_EKARDO_SSO_CLIENT_ID as string
  //     }
  //   }

  //   return credentialMap[product];
  // }

  // useEffect(() => {
  //   if (!(selectedProvider && redirectUrl)) return;

  //   const { url, clientId } = getProductSSOCredentials(productName);

  //   window.location.href = `${url}/authorize?response_type=code&identity_provider=${selectedProvider}&client_id=${clientId}&redirect_url=${redirectUrl}`

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedProvider]);

  // useEffect(() => {
  //   const url = getBaseUrl();

  //   if (!url) return;

  //   setRedirectUrl(`${url}/register`);
  // }, [getBaseUrl]);

  // useEffect(() => {
  //   if (!currentSSOCode) return;

  // }, [currentSSOCode]);

  const isLoading = false;

  return (
    <form
      onSubmit={(event) => {
        void handleSubmit(({ email }: IGetStartedFormFields) => {
          onSubmit({
            email,
          });
        })(event);
      }}
    >
      <RegistrationLayout
        currentStep={currentStep}
        description={<span dangerouslySetInnerHTML={{ __html: description }} />}
        isLoading={isLoading}
        title={introduction}
        hasLoginLink
      >
        <Spacing>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                iconBefore={{
                  icon: EmailClickedIcon,
                }}
                id="id_new_email"
                labelText="Email"
                type="email"
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>

        {/* <ul className="c-registration-method-options">
          <li className="c-registration-method__option">
            <Button text="Google" variant="secondary" onClick={() => handleProviderSelect('Google')} />
          </li>
        </ul> */}
      </RegistrationLayout>
    </form>
  );
};

export { GetStarted };
