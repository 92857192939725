/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { IDBUser } from '@netfront/gelada-identity-library';
import { SettingsButton } from '@netfront/ui-library';

import { IAccountUserTableData } from './AccountUsers.interfaces';

import { StatusIndicator } from '../../../../components/Shared';
import { getDayMonthYear } from '../../../../utils';
import { IGetUserTableDataParams, ISelectedUser } from '../../Pages.interfaces';

const getAccountUsersTableData = ({ users, onSettingsButtonClick }: IGetUserTableDataParams): IAccountUserTableData[] => {
  return users.map(({ user, created }) => {
    const { credential, firstname: firstName, id, lastname: lastName, status } = user;

    const { email, phoneNumber } = credential ?? {};

    const selectedUser: IDBUser | ISelectedUser = { email, firstName, id, lastName, phoneNumber: phoneNumber, status, credential };

    const tableData: IAccountUserTableData = {
      id,
      avatar: {
        fullName: `${String(firstName)} ${String(lastName)}`,
        firstName: String(firstName),
      },
      firstName: firstName,
      lastName: lastName,
      email,
      status: <StatusIndicator status={status === 'ACTIVE' ? 'active' : 'inactive'} />,
      created: getDayMonthYear(created),
      settings: <SettingsButton supportiveText={`${firstName} settings`} onClick={() => onSettingsButtonClick(selectedUser as IDBUser)} />,
    };

    return tableData;
  });
};

export { getAccountUsersTableData };
