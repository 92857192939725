import { useEffect, useState } from 'react';

import {
  createCustomBuildProjectGuidCookie,
  createOrganisationIdCookie,
  createProjectGuidCookie,
  createProjectLogoUrlCookie,
  createProjectNameCookie,
  ICookieOptionalAttributesInput,
  NetfrontProductType,
  useCookie,
} from '@netfront/common-library';
import {
  DEFAULT_STORAGE_EXPIRY_OPTION,
  useDomain,
  useGetGeladaProjectByDomain,
  LOCALHOST,
  useAuthentication,
  useGetProduct,
} from '@netfront/gelada-identity-library';
import { AppHeader, AppHeaderProps, EkardoIcon, PageLayoutTemplate, Spinner } from '@netfront/ui-library';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { pageDefaults } from './RegisterFlowPage.constants';

import { INTERNAL_URLS } from '../../../constants';
import { useToast } from '../../../hooks';
import { ICreateProjectDetails } from '../../../types';
import { GetStarted, CreateProject, SetupComplete, PersonalDetails } from '../../Registration';
import { Copyright, LocalDomainSelector } from '../../Shared';

const RegisterFlowPage = () => {
  const { isAuthenticated } = useAuthentication();
  const { isSecureCookie, createProjectDomainCookie } = useCookie();
  const { getDomain, isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const {
    push,
    query: {
      // code = ''
      email = '',
      step = 1,
    },
  } = useRouter();
  const { handleToastError } = useToast();

  const [currentEmail, setCurrentEmail] = useState<string>('');
  // const [currentCode, setCurrentCode] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [faviconUrl, setFaviconUrl] = useState<string>();
  const [hasLocalHostDomainSelector, setHasLocalHostDomainSelector] = useState<boolean>(false);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false);
  const [logoConfig, setLogoConfig] = useState<AppHeaderProps['logo']>();
  const [productName, setProductName] = useState<NetfrontProductType>();
  const [projectDetails, setProjectDetails] = useState<ICreateProjectDetails | undefined>();
  const [selectedLocalHostDomain, setSelectedLocalHostDomain] = useState<string>();

  const { handleGetGeladaProjectByDomain, isLoading: isGetGeladaProjectByDomainLoading = false } = useGetGeladaProjectByDomain({
    fetchPolicy: 'no-cache',
    onCompleted: ({ geladaProject: { domain: projectDomain, id, isCustomBuild, logo, name, organisationId } }) => {
      const { redirectAfterRegistration: redirectUrl, value: projectDomainValue } = projectDomain ?? {};
      const { presignedUrl } = logo ?? {};

      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      const optionalCookieAttributesInput: ICookieOptionalAttributesInput = {
        domain,
        secure: isSecure,
        expiryOptions: {
          storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
        }
      };

      if (!redirectUrl) {
        handleToastError({
          error: new Error('A redirectAfterLoginUrl is required'),
          shouldUseFriendlyErrorMessage: true,
        });
      }

      if (isCustomBuild) {
        createCustomBuildProjectGuidCookie({
          optionalCookieAttributesInput,
          value: id,
        });
      }

      if (projectDomainValue) {
        createProjectDomainCookie({
          optionalCookieAttributesInput,
          value: projectDomainValue,
        });
      }

      createOrganisationIdCookie({
        optionalCookieAttributesInput,
        value: String(organisationId),
      });

      createProjectGuidCookie({
        optionalCookieAttributesInput,
        value: id,
      });

      if (presignedUrl) {
        createProjectLogoUrlCookie({
          optionalCookieAttributesInput,
          value: encodeURIComponent(presignedUrl),
        });
      }

      createProjectNameCookie({
        optionalCookieAttributesInput,
        value: name,
      });

      const returnedProduct = getProduct() ?? 'EKARDO';

      setIsInitialLoading(false);
      setProductName(returnedProduct);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleNextStep = ({ email: registeredEmail, updatedStep }: { email: string; updatedStep: number }) => {
    setCurrentEmail(registeredEmail);
    setCurrentStep(updatedStep);
  };

  const handleCreateProject = ({
    projectId,
    organisationKey,
    organisationId,
    projectName,
  }: {
    organisationId: number;
    organisationKey: string;
    projectId: string;
    projectName: string;
  }) => {
    setProjectDetails({
      projectId,
      organisationKey,
      organisationId,
      projectName,
    });

    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    if (getDomain() === LOCALHOST) {
      setIsInitialLoading(false);
      setHasLocalHostDomainSelector(true);
    } else {
      void handleGetGeladaProjectByDomain({
        domain: getDomain(),
        shouldIncludeProjectDomain: true,
        shouldIncludeProjectLogo: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!selectedLocalHostDomain) return;

    if (getDomain() !== LOCALHOST) return;

    void handleGetGeladaProjectByDomain({
      domain: selectedLocalHostDomain,
      shouldIncludeProjectDomain: true,
      shouldIncludeProjectLogo: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocalHostDomain]);

  useEffect(() => {
    if (!productName) {
      return;
    }

    setLogoConfig({
      icon: EkardoIcon,
      imageSrc: `/images/${productName.toLowerCase()}-register-logo.svg`,
    });

    setFaviconUrl(productName.toLowerCase());
  }, [productName]);

  useEffect(() => {
    if (Number(step) === 1) {
      return;
    }

    if (email) {
      return;
    }

    setCurrentStep(1);
  }, [step, email]);

  useEffect(() => {
    if (!email) {
      return;
    }

    setCurrentEmail(email as string);
  }, [email]);

  // useEffect(() => {
  //   if (!SSOCode) return;

  //   setCurrentSSOCode(SSOCode as string);
  // }, [SSOCode]);

  useEffect(() => {
    if (!step) {
      return;
    }

    setCurrentStep(Number(step));
  }, [step]);

  useEffect(() => {
    if ([3, 4].includes(currentStep)) {
      return;
    }

    if (!isAuthenticated) {
      return;
    }

    push(INTERNAL_URLS.dashboard).catch((error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, currentStep]);

  const { organisationId, organisationKey, projectId, projectName } = projectDetails ?? {};

  const isLoading = isGetGeladaProjectByDomainLoading || isInitialLoading;

  const productSpecificPageDefaults = pageDefaults[productName ?? 'EKARDO'];

  const { headerText, pageDescription } = productSpecificPageDefaults;

  return (
    <>
      <Head>
        <title>{headerText}</title>
        <meta content={pageDescription} name="description" />
        {faviconUrl && <link href={`/${faviconUrl}.ico`} rel="icon" type="image/x-icon"></link>}
      </Head>

      <PageLayoutTemplate
        additionalClassNames="c-register-flow"
        footer={
          <div className="c-registration-flow__footer">
            <Copyright />
          </div>
        }
        header={<AppHeader additionalClassNames="c-register-flow__header" logo={logoConfig} />}
      >
        <Spinner isLoading={isLoading} />

        <main className="c-register-flow__main">
          {[1, 2].includes(currentStep) && (
            <div className="c-register-flow__domain-selector">
              <LocalDomainSelector
                isVisible={
                  ([0, 1].includes(currentStep) && hasLocalHostDomainSelector) ||
                  (currentStep === 2 && hasLocalHostDomainSelector && !selectedLocalHostDomain)
                }
                onChange={setSelectedLocalHostDomain}
              />
            </div>
          )}

          {productName && (
            <>
              {currentStep === 1 && <GetStarted handleNextStep={handleNextStep} productName={productName} />}

              {currentStep === 2 && (
                <PersonalDetails
                  initialEmail={currentEmail}
                  productName={productName}
                  onRegisterSuccess={(updatedStep: number) => setCurrentStep(updatedStep)}
                />
              )}

              {currentStep === 3 && <CreateProject productName={productName} onCreate={handleCreateProject} />}

              {currentStep === 4 && organisationId && organisationKey && projectId && projectName && (
                <SetupComplete
                  organisationId={organisationId}
                  organisationKey={organisationKey}
                  productName={productName}
                  projectId={projectId}
                  projectName={projectName}
                />
              )}
            </>
          )}
        </main>
      </PageLayoutTemplate>
    </>
  );
};

export { RegisterFlowPage };
