import { ChangeEvent, useEffect, useState } from 'react';

import { useResendActivationCode, useSendForgotPassword, useUpdateUserPasswordAsAdmin } from '@netfront/gelada-identity-library';
import { Button, FlexContainer, InputFieldWrapper, PasswordInput, SidebarButtons, Spacing, Spinner, Tooltip, TooltipIcon } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';

import { SidebarContainer } from 'components/Shared';

import { UserPasswordTabProps } from './UserPasswordTab.interfaces';

import { useToast } from '../../../../hooks';

const UserPasswordTab = ({ onClose, user, isAccountLevel = false }: UserPasswordTabProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();

  const [projectId, setProjectId] = useState<string>('');
  const [isUserStatusActive, setIsUserStatusActive] = useState(false);

  const { handleSendForgotPassword, isLoading: isSendForgotPasswordLoading = false } = useSendForgotPassword({
    onCompleted: () => {
      handleToastSuccess({
        message: 'Password reset sent',
      });

      onClose();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateUserPasswordAsAdmin, isLoading: isUpdateUserPasswordAsAdminLoading = false } = useUpdateUserPasswordAsAdmin({
    onCompleted: () => {
      
      handleToastSuccess({
        message: 'Password updated',
      });

      onClose();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleResendActivationCode, isLoading: isResendActivationCodeLoading = false } = useResendActivationCode({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) {
        return;
      }

      handleToastSuccess({
        message: 'Activation code sent',
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleConfirmPasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(value);
  };

  const handleNewPasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(value);
  };

  const handleUpdatePassword = () => {
    if (!newPassword) {
      return;
    }

    if (newPassword !== confirmPassword) {
      handleToastError({
        error: new Error('Passwords do not match'),
        shouldUseFriendlyErrorMessage: true,
      });

      return;
    }

    void handleUpdateUserPasswordAsAdmin({
      password: newPassword,
      userId: user.id,
    });
  };

  const handleResendEmailOrActivationCode = () => {
    if (!projectId) {
      return;
    }

    if (isUserStatusActive) {
      void handleSendForgotPassword({
        projectId: String(projectId),
        userId: user.id,
      });
    } else {
      const { credential } = user;

      if (isEmpty(credential)) {
        return;
      }

      const { email } = credential;

      void handleResendActivationCode({
        email,
      });
    }
  };

  useEffect(() => {
    setIsUserStatusActive(user.status === 'ACTIVE');
  }, [user]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isResendActivationCodeLoading || isSendForgotPasswordLoading || isUpdateUserPasswordAsAdminLoading;

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />

      <Spacing>
        <InputFieldWrapper id={''} label={isUserStatusActive ? 'Send password reset' : 'This account is not active'} labelType="span" isLabelSideBySide >
          <FlexContainer justifyContent="flex-end" isFullWidth>
            <Button
              size="xs"
              text={isUserStatusActive ? 'Send email' : 'Send activation code'}
              variant="primary-inverse"
              onClick={handleResendEmailOrActivationCode}
            />
          </FlexContainer>
        </InputFieldWrapper>
      </Spacing>

      {isUserStatusActive && !isAccountLevel && (
        <>
          <Tooltip additionalClassNames="absolute right-0" icon={TooltipIcon} text="Send password email reset" />

          <Spacing>
            <PasswordInput id="new-password" labelText="New password" name="newPassword" isLabelSideBySide onChange={handleNewPasswordChange} />
          </Spacing>

          <Spacing>
            <PasswordInput
              id="confirm-password"
              labelText="Confirm password"
              name="confirmPassword"
              isLabelSideBySide
              onChange={handleConfirmPasswordChange}
            />
          </Spacing>

          <SidebarButtons
            buttonSize="xs"
            isSaveButtonDisabled={!!newPassword && !!confirmPassword && newPassword !== confirmPassword}
            submitButtonText="Update password"
            onCancel={onClose}
            onSave={handleUpdatePassword}
          />
        </>
      )}
    </SidebarContainer>
  );
};

export { UserPasswordTab };
