import { DEFAULT_SERVICES, SOCIAL_SERVICES } from '../Pages.constants';
const compulsoryGroupShouldIncludes = Object.freeze({
  shouldIncludeAddress: true,
  shouldIncludeSmartCodes: true,
  shouldIncludeUnit: true,
  shouldIncludeUserType: true,
});

const USER_MANAGEMENT_PAGE_CONSTANTS = Object.freeze({
  compulsoryGroupShouldIncludes: compulsoryGroupShouldIncludes,
  defaultPageSize: 10,
  defaultServices: DEFAULT_SERVICES,
  pageDescription: 'Manage project users',
  pageTitle: 'Users',
  socialServices: SOCIAL_SERVICES,
  unassigned: 'unassigned',
});

export { USER_MANAGEMENT_PAGE_CONSTANTS };
