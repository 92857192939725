import {  Input, RadioButtonGroup, Spacing, ToggleSwitch } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';

import { UserTypeGeneralTabProps } from './UserTypeGeneralTab.interfaces';

import { getFormFieldErrorMessage, SidebarContainer } from '../../../../components';




const UserTypeGeneralTab = ({ control, selectedUserTypeId }: UserTypeGeneralTabProps) => {

  return (
    <SidebarContainer>
      <Spacing>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_user_Type_name"
              labelText="Role"
              type="text"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>
      
      <Spacing>
        <Controller
          control={control}
          name="permission"
          render={({ field, fieldState }) => (
            <RadioButtonGroup
              errorMessage={getFormFieldErrorMessage(fieldState)}
              items={[
                { labelText: 'Manage users', id: 'MANAGE_USERS', value: 'MANAGE_USERS' },
                { labelText: 'Edit', id: 'EDIT', value: 'EDIT' },
                { labelText: 'View', id: 'READ', value: 'READ' },
              ]}
              selectedValue={field.value}
              title="Permissions"
              isLabelSideBySide
              isRequired
              {...field}
            />
          )}
        />
      </Spacing>

      <Spacing>
        <Controller
          control={control}
          name="userTypeCode"
          render={({ field, fieldState }) => (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id="id_user_type_code"
              isDisabled={Boolean(selectedUserTypeId)}
              labelText="Code"
              type="text"
              isLabelSideBySide
              {...field}
            />
          )}
        />
      </Spacing>
      

      {Boolean(selectedUserTypeId) && (
        <Spacing>
          <Controller
            control={control}
            name="isEnabled"
            render={({ field }) => (
              <ToggleSwitch
                id="user-type-status"
                isChecked={field.value}
                labelText="Status"
                isLabelSideBySide
                {...field}
              />
            )}
          />
        </Spacing>
      )}
    </SidebarContainer>
  );
};

export { UserTypeGeneralTab };
