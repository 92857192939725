import { gql } from '@apollo/client';

export const CREATE_ORGANISATION_INVITATION = gql`
  mutation createOrganisationInvitation($invitation: CreateOrgInvitationInput!) {
    invitation {
      organisationInvitation(invitation: $invitation) {
        id
        invitedUserEmail: emailUserInvited
        invitedUserId
        firstName
        lastName
        groupId
        invitationEmailStatus
        group {
          id
          name
        }
        userType {
          id
          name
        }
      }
    }
  }
`;
