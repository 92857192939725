import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

import {
  IDBGroup,
  IDBGroupType,
  IGroupAddress,
  useLoggedGeladaLazyQuery,
  useLoggedGeladaMutation,
} from '@netfront/gelada-identity-library';
import {
  Avatar,
  Dialog,
  EnterIcon,
  Input,
  NavigationButton,
  Preloader,
  Select,
  SidebarButtons,
  Spacing,
  ToggleSwitch,
} from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { IUpdatedCustomField, ICustomField } from 'types';

import { SidebarContainer } from 'components/Shared';

import { GROUP_STATUS } from './UserGroupGeneralTab.constants';
import { getSelectOptions } from './UserGroupGeneralTab.helpers';
import { ICreateGroupResponse, IGetGroupCustomFieldsResponse, IGetGroupTypesResponse, UserGroupGeneralTabProps } from './UserGroupGeneralTab.interfaces';

import { CREATE_GROUP, DELETE_GROUP, GET_CUSTOM_FIELDS, GET_GROUP_TYPES, UPDATE_CUSTOM_FIELDS, UPDATE_GROUP } from '../../../../graphql';
import { useToast } from '../../../../hooks';
import { getStatusString } from '../../../../utils';

const UserGroupGeneralTab = ({ onClose, onCreated, onDeleted, onUpdated, selectedGroup }: UserGroupGeneralTabProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [customFields, setCustomFields] = useState<IUpdatedCustomField[]>([]);
  const [groupTypeIndex, setGroupTypeIndex] = useState<number>();
  const [groupTypes, setGroupTypes] = useState<IDBGroupType[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isGroupStatusActive, setIsGroupStatusActive] = useState<boolean>(false);
  const [isShowAdditionalDetails, setIsShowAdditionalDetails] = useState<boolean>(false);
  const [updatedGroup, setUpdatedGroup] = useState<IDBGroup | undefined>(selectedGroup);
  // const [userFlowAccessModeIndex, setUserFlowAccessModeIndex] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { id: groupId, address, name: groupName = '', status = undefined, website = '', phoneNumber = '', groupTypeId } = updatedGroup ?? ({} as IDBGroup);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { line1 = '', line2 = '', line3 = '', city = '', state = '', postcode = '', country = 'AU' } = address ?? ({} as IGroupAddress);

  const [getGroupTypes, { loading: isGetGroupTypesLoading }] = useLoggedGeladaLazyQuery<IGetGroupTypesResponse>({
    options: {
      onCompleted: ({ groupType: { getGroupTypes: fetchedGroupTypes } }) => {
        setGroupTypes(fetchedGroupTypes);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: GET_GROUP_TYPES,
  });

  const [getCustomFields, { loading: isGetCustomFieldsLoading }] = useLoggedGeladaLazyQuery<IGetGroupCustomFieldsResponse>({
    options: {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ customField: { getCustomFields: getGroupCustomFields } }) => {
        const groupCustomFields: ICustomField[] = getGroupCustomFields;

        const updatedCustomFields: IUpdatedCustomField[] = groupCustomFields.map((customField) => {
          const { id: customFieldId, __typename, name: customFieldName, responses = [] } = customField;

          const defaultValue = __typename === 'CustomFieldBooleanGraphType' ? 'false' : '' ;

          return {
            customFieldId,
            customFieldName,
            text: String(responses && responses.length ? responses[0].text ?? responses[0].value : defaultValue),
            __typename,
          };
        });
        setCustomFields(updatedCustomFields);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: GET_CUSTOM_FIELDS,
  });

  const [updateCustomFieldsResponse, { loading: isUpdateCustomFieldResponseLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onClose();
        handleToastSuccess({
          message: 'Updated custom field response',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_CUSTOM_FIELDS,
  });

  const [createGroup, { loading: isCreateGroupLoading }] = useLoggedGeladaMutation<ICreateGroupResponse>({
    options: {
      onCompleted: ({ group: { createGroup: createdGroup } }) => {
        if (!onCreated) {
          return;
        }

        onCreated(createdGroup);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: CREATE_GROUP,
  });

  const [deleteGroup, { loading: isDeleteGroupLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        if (!onDeleted) {
          return;
        }

        onDeleted(groupId);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: DELETE_GROUP,
  });

  const [updateGroup, { loading: isUpdateGroupLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onUpdated(updatedGroup as IDBGroup);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_GROUP,
  });

  const handleChangeGroup = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedGroup({
      ...updatedGroup,
      [name]: value,
    } as IDBGroup);
  };

  const handleChangeGroupAddress = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedGroup(
      (prevState) =>
        ({
          ...prevState,
          address: {
            ...address,
            [name]: value,
          },
        } as IDBGroup),
    );
  };

  const handleChangeGroupStatus = () => {
    setIsGroupStatusActive(!isGroupStatusActive);

    setUpdatedGroup({
      ...updatedGroup,
      status: getStatusString(!isGroupStatusActive),
    } as IDBGroup);
  };

  const handleCreateGroup = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await createGroup({
      variables: {
        group: {
          name: groupName,
          projectId,
          website,
          phoneNumber,
          groupTypeId,
          address: {
            city,
            line1,
            line2,
            line3,
            postcode,
            state,
            country,
          },
        },
      },
    });
  };

  const handleUpdateGroup = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isShowAdditionalDetails) {
      const updatedCustomFields = customFields.map(({ text, customFieldId }) => {
        return {
          customFieldId,
          value: String(text),
        };
      });

      void updateCustomFieldsResponse({
        variables: {
          responses: updatedCustomFields,
          groupId,
        },
      });
    } else {
      void updateGroup({
        variables: {
          group: {
            groupId,
            name: groupName,
            phoneNumber,
            groupTypeId,
            status,
            website,
          },
          address: {
            city,
            line1,
            line2,
            line3,
            postcode,
            state,
            country,
          },
        },
      });
    }
  };

  const handleUpdateCustomFieldBoolean = ({ target }: ChangeEvent<HTMLInputElement>, customFieldId: number, customFieldName: string) => {
    const { checked: isChecked } = target;
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: String(isChecked).charAt(0).toUpperCase() + String(isChecked).slice(1),
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };

  const handleUpdateCustomField = (
    { target: { value } }: ChangeEvent<HTMLInputElement>,
    customFieldId: number,
    customFieldName: string,
  ) => {
    const updatedFields = customFields.map((customField) => {
      const { customFieldId: currentCustomFieldId } = customField;

      if (customFieldId === currentCustomFieldId) {
        return {
          customFieldId,
          customFieldName,
          text: value,
          __typename: customField.__typename,
        };
      }

      return customField;
    });

    setCustomFields(updatedFields);
  };

  const handleToggleAdditionalDetails = () => {
    setIsShowAdditionalDetails(!isShowAdditionalDetails);
  };

  // NOTE: commented out for now until we work out how to get correct logic options.
  // const { handleGetUserFlowAccessMode, isLoading: isGetUserFlowAccessModeLoading = false } = useGetUserFlowAccessMode({
  //   onCompleted: ({ userFlowAccessMode }) => {
  //     if (!userFlowAccessMode) {
  //       return;
  //     }

  //     const currentUserFlowAccessMode = Number(MODE_INDEX_TYPES[String(userFlowAccessMode.mode)]);
  //     setUserFlowAccessModeIndex(currentUserFlowAccessMode);
  //   },
  //   onError: (error) => {
  //     handleToastError({
  //       error,
  //       shouldUseFriendlyErrorMessage: true,
  //     });
  //   },
  // });

  // const { handleUpdateUserFlowAccessMode, isLoading: isUpdateUserFlowAccessModeLoading = false } = useUpdateUserFlowAccessMode({
  //   onCompleted: ({ isCompleted }) => {
  //     if (!isCompleted) {
  //       return;
  //     }

  //     handleToastSuccess({
  //       message: 'Access mode updated',
  //     });
  //   },
  //   onError: (error) => {
  //     handleToastError({
  //       error,
  //       shouldUseFriendlyErrorMessage: true,
  //     });
  //   },
  // });

  const handleUpdateGroupType = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
    const selectedGroupType = groupTypes[Number(value)];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!selectedGroupType) {
      return;
    }

    setGroupTypeIndex(Number(value));
    setUpdatedGroup({
      ...updatedGroup,
      groupTypeId: selectedGroupType.id,
    } as IDBGroup);
  };

  // NOTE: commented out for now until we work out how to get correct logic options.
  // const handleUpdateUserFlowAccessModeSelect = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
  //   const selectedIndex = Number(value);

  //   setUserFlowAccessModeIndex(selectedIndex);

  //   void handleUpdateUserFlowAccessMode({
  //     groupId,
  //     mode: MODE_TYPES[selectedIndex],
  //   });
  // };

  useEffect(() => {
    const index = groupTypes.findIndex(({ id }) => id === groupTypeId);

    if (index === -1) {
      return;
    }

    setGroupTypeIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupTypes]);

  useEffect(() => {
    if (!selectedGroup || !status) {
      return;
    }

    setIsGroupStatusActive(Boolean(GROUP_STATUS[status]));
  }, [selectedGroup, status]);

  useEffect(() => {
    if (!(groupId && projectId)) {
      return;
    }


    // NOTE: commented out for now until we work out how to get correct logic options.
    // void handleGetUserFlowAccessMode({
    //   groupId,
    // });

    void getCustomFields({
      variables: {
        groupId,
        projectId,
        scope: 'GROUP',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, projectId]);

  useEffect(() => {
    if (!projectId) return;

    void getGroupTypes({
      variables: {
        projectId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading =
    isCreateGroupLoading ||
    isDeleteGroupLoading ||
    isGetCustomFieldsLoading ||
    isGetGroupTypesLoading ||
    // isGetUserFlowAccessModeLoading ||
    // isUpdateUserFlowAccessModeLoading ||
    isUpdateCustomFieldResponseLoading ||
    isUpdateGroupLoading;

  return (
    <SidebarContainer>
      <Preloader isLoading={isLoading} />

      <form onSubmit={groupId ? handleUpdateGroup : handleCreateGroup}>
        {isShowAdditionalDetails ? (
          <>
            <Spacing size="x-large">
              <NavigationButton
                additionalClassNames="c-sidebar-navigation-button"
                direction="previous"
                icon={EnterIcon}
                rotationCssSuffix="180"
                text="Back to groups"
                onClick={handleToggleAdditionalDetails}
              />
            </Spacing>

            {customFields.map((customField) => {
              const { customFieldId, customFieldName, text, __typename } = customField;
              return (
                <section key={`custom-field-${customFieldId}`} className="pb-8">
                  {__typename === 'CustomFieldBooleanGraphType' && (
                    <ToggleSwitch
                      id={`custom-field-${customFieldId}`}
                      isChecked={String(text).toLowerCase() === 'true'}
                      labelText={customFieldName}
                      isLabelSideBySide
                      onChange={(event) => handleUpdateCustomFieldBoolean(event, customFieldId, customFieldName)}
                    />
                  )}
                  {__typename !== 'CustomFieldBooleanGraphType' && (
                    <Input
                      id={`custom-field-${customFieldName}`}
                      labelText={customFieldName}
                      name={customFieldName}
                      type="text"
                      value={text}
                      isLabelSideBySide
                      onChange={(event) => handleUpdateCustomField(event, customFieldId, customFieldName)}
                    />
                  )}
                </section>
              );
            })}
          </>
        ) : (
          <>
            <Spacing size="large">
              <section className="flex justify-between items-center">
                <Avatar title={groupName} />

                {Boolean(groupId) && (
                  <ToggleSwitch
                    additionalClassNames="c-user-status-toggle"
                    id="group status"
                    isChecked={isGroupStatusActive}
                    labelText="Activated"
                    isInline
                    isLabelSideBySide
                    onChange={handleChangeGroupStatus}
                  />
                )}
              </section>
            </Spacing>


            <Input id="name" labelText="Name" name="name" type="text" value={groupName || ''} isLabelSideBySide isRequired onChange={handleChangeGroup} />

            <section>
              <Input id="line1" labelText="Address 1" name="line1" type="text" value={line1 || ''} isLabelSideBySide onChange={handleChangeGroupAddress} />

              <Input id="line2" labelText="Address 2" name="line2" type="text" value={line2 || ''} isLabelSideBySide onChange={handleChangeGroupAddress} />

              <Input id="city" labelText="City" name="city" type="text" value={city || ''} isLabelSideBySide onChange={handleChangeGroupAddress} />

              <Input id="state" labelText="State" name="state" type="text" value={state || ''} isLabelSideBySide onChange={handleChangeGroupAddress} />

              <Input
                id="postcode"
                labelText="Postcode / ZIP"
                name="postcode"
                type="text"
                value={String(postcode  || '')}
                isLabelSideBySide
                onChange={handleChangeGroupAddress}
              />

              <Input id="website" labelText="Website" name="website" type="text" value={String(website || '')} isLabelSideBySide onChange={handleChangeGroup} />

              <Input id="phoneNumber" labelText="Phone" name="phoneNumber" type="text" value={String(phoneNumber || '')} isLabelSideBySide onChange={handleChangeGroup} />

              {groupTypes.length > 0 && (
                <Select
                  id="group-type"
                  labelText="Type"
                  name="mode"
                  options={getSelectOptions(groupTypes.map((r) => r.name))}
                  tooltipText="Group types allow additional configuration on groups to help in reporting"
                  value={groupTypeIndex}
                  isLabelSideBySide
                  onChange={handleUpdateGroupType}
                />
              )}

              {Boolean(selectedGroup) && (
                <>

                  {/*
                    NOTE: commented out for now until we work out how to get correct logic options.
                    <Select
                    id="user-flow-access-mode"
                    labelText="Access mode"
                    name="mode"
                    options={getSelectOptions(MODE_SELECT_TYPES)}
                    tooltipText="Access mode defines if users within this group can access learning modules sequentially or in any order. If Sequential is selected, the users will have to access content in the specified sort order."
                    value={userFlowAccessModeIndex}
                    onChange={handleUpdateUserFlowAccessModeSelect}
                  /> */}

                  <Dialog
                    isOpen={isDeleteDialogOpen}
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    title={`Delete user group: ${selectedGroup?.name}?`}
                    isNarrow
                    onCancel={() => setIsDeleteDialogOpen(false)}
                    onClose={() => setIsDeleteDialogOpen(false)}
                    onConfirm={() => {
                      void deleteGroup({
                        variables: {
                          groupId,
                        },
                      });
                    }}
                  />

                  {Boolean(customFields.length) && (
                    <button
                      className="text-center p-6 w-full border-none color-green-400 text-base pointer bg-white"
                      type="button"
                      onClick={handleToggleAdditionalDetails}
                    >
                      Additional details
                    </button>
                  )}
                </>
              )}
            </section>
          </>
        )}

        <SidebarButtons
          buttonSize="xs"
          onCancel={onClose}
          onDelete={selectedGroup ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"
        />

      </form>
    </SidebarContainer>
  );
};

export { UserGroupGeneralTab };
