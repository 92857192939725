import { ChangeEvent, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IUser, useRemoveMfaKey } from '@netfront/gelada-identity-library';
import { Button, Card, Container, Dialog, FlexContainer, Input, OrganisationIcon, Spacing, Spinner, TickIcon } from '@netfront/ui-library';
import NextLink from 'next/link';

import styles from './TwoStepVerificationTab.module.css';

import { useToast } from '../../../../hooks';

const TwoStepVerificationTab = ({ user }: { user?: IUser | undefined }) => {
  const { handleToastError, handleToastSuccess } = useToast();
  const [code, setCode] = useState('');
  const [isConfigured, setIsConfigured] = useState(user?.hasMfa ?? false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const resetDialog = () => {
    setIsDialogOpen(false);
    setCode('');
  };

  const { handleRemoveMfaKey, isLoading: isRemoveMfaLoading } = useRemoveMfaKey({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Access successfully revoked',
        });
        resetDialog();
        setIsConfigured(false);
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleRevokeMfa = () => {
    setIsDialogOpen(true);
  };

  const confirmRevokeMfa = () => {
    void handleRemoveMfaKey({ code });
  };

  const isLoading = isRemoveMfaLoading;

  return (
    <>
      <Spacing size="2x-large">
        <Card padding="small" hasNoHover isFullWidth>
          <FlexContainer alignItems="start" gap="small" justifyContent="start">
            <OrganisationIcon className={styles['c-tsv__icon']} />
            <div style={{ flexShrink: 1 }}>
              <FlexContainer>
                <FlexContainer gap="x-small">
                  <h3 className={styles['c-tsv__header']}>Authentication app</h3>
                  {isConfigured && (
                    <div className={styles['c-tsv__tick-icon']}>
                      <TickIcon className="c-icon" />
                    </div>
                  )}
                </FlexContainer>
                <NextLink href="two-step-verification">
                  <span className={styles['c-tsv__link']}>{isConfigured ? 'Modify' : 'Set up'}</span>
                </NextLink>
              </FlexContainer>
              <p className={styles['c-tsv__text']}>
                Instead of waiting for text messages, get verification codes from a third- party authentication app.
              </p>
            </div>
          </FlexContainer>
        </Card>
      </Spacing>
      {isConfigured && (
        <>
          <Spacing size="2x-large">
            <Spacing>
              <h3 className={styles['c-tsv__header']}>Revoke all trusted devices</h3>
            </Spacing>
            <Spacing size="x-large">
              <p className={styles['c-tsv__text']}>
                If you have previously authorised devices to skip the two-step verification step, you can revoke that authorisation at any
                time. All devices currently signed in to your account will remain signed in.
              </p>
            </Spacing>
            <Button isDisabled={!isConfigured} text="Revoke all" variant="danger" onClick={handleRevokeMfa} />
          </Spacing>
          <Dialog
            isConfirmDisabled={code.length !== 6}
            isOpen={isDialogOpen}
            title="Revoke access?"
            isNarrow
            onClose={resetDialog}
            onConfirm={confirmRevokeMfa}
          >
            <p>Are you sure you want to revoke all access? Enter the code in your authenticator app to confirm</p>
            <Container isNarrow>
              <Input
                id="id_revoke_two_step"
                labelText="Revoke two-step verification"
                maxLength={6}
                name="revoke_two_step"
                type="text"
                isLabelHidden
                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setCode(value)}
              />
              <Spinner isLoading={isLoading ?? false} />
            </Container>
          </Dialog>
        </>
      )}
    </>
  );
};

export { TwoStepVerificationTab };
