import { useState, useEffect, useContext } from 'react';

import { IDropDownListOption } from '@netfront/ekardo-content-library';
import { DBUserStatusType, IDBUser, useGetImpersonateUserLink } from '@netfront/gelada-identity-library';
import { SelectWithSearch, ToggleSwitch } from '@netfront/ui-library';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { BulkActionDialog } from 'components/Dialogs';

import { PROJECT_USER_TABLE_COLUMNS, PROJECT_EKARDO_USER_TABLE_COLUMNS } from './ProjectUsers.constants';
import { getUsersTableData } from './ProjectUsers.helpers';
import { IUsersTableData } from './ProjectUsers.types';

import { CachingEntitiesContext, PermissionContext } from '../../../../context';
import { useToast, useGetPaginatedGroups, useGetPaginatedProjectUsers } from '../../../../hooks';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';
import { ProjectUsersInvitationSidebar, ProjectUsersSidebar } from '../../../Sidebars';
import { TablePageTemplate } from '../../../Templates/TablePageTemplate';

const { pageTitle } = USER_MANAGEMENT_PAGE_CONSTANTS;

const ProjectUsers = () => {
  const { project, hasGroups, canImpersonateUser, dashboardUrl } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { hasPermission } = useContext(PermissionContext)

  const { handleToastError, handleToastSuccess } = useToast();

  const { defaultPageSize, unassigned } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [allUsers, setAllUsers] = useState<IDBUser[]>();
  const [filter, setFilter] = useState<string>();
  const [groupsSearchListDropdownItems, setGroupsSearchListDropdownItems] = useState<IDropDownListOption[]>();
  const [impersonateUserLink, setImpersonateUserLink] = useState<string>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSearchContentVisible, setIsSearchContentVisible] = useState<boolean>(false);
  const [isAddUserSideBarOpen, setIsAddUserSideBarOpen] = useState<boolean>(false);
  const [isUpdateUserSideBarOpen, setIsUpdateUserSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [selectedGroupIdString, setSelectedGroupIdString] = useState<string>();
  const [selectedUser, setSelectedUser] = useState<IDBUser>();
  const [shouldReturnUnassignedOnly, setShouldReturnUnassignedOnly] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<DBUserStatusType>('ACTIVE');
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [usersTableData, setUsersTableData] = useState<IUsersTableData[]>();
  const [totalGroups, setTotalGroups] = useState<number>(0);
  const [isLoadMoreGroupsDisabled, setIsLoadMoreGroupsDisabled] = useState<boolean>(false);
  const [groupFilter, setGroupFilter] = useState<string>();
  const [lastGroupCursor, setLastGroupCursor] = useState<string>();
  const [selectedGroupLabel, setSelectedGroupLabel] = useState<string>('All groups');
  const [baseUrl, setBaseUrl] = useState<string>('');

  // bulk actions
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [bulkActionType, setBulkActionType] = useState<string>('add');
  const [isBulkActionDialogOpen, setIsBulkActionDialogOpen] = useState<boolean>(false);

  const { handleGetImpersonateUserLink, isLoading: isGetImpersonateUserLinkLoading = false } = useGetImpersonateUserLink({
    onCompleted: (data) => {
      setImpersonateUserLink(data.impersonateUserLink);
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const {
    handleFetchMorePaginatedProjectUsers,
    handleGetPaginatedProjectUsers,
    isLoading: isGetPaginatedGroupMembersLoading = false,
  } = useGetPaginatedProjectUsers({
    fetchPolicy: 'no-cache',
    onCompleted({ users: { edges, totalCount = 0 } }) {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const users = edges.map(({ node }) => node);

      setAllUsers(users);
      setIsLoadMoreDisabled(users.length >= totalCount || totalCount <= pageSize);
      setTotalUsers(totalCount);
    },
  });

  const { handleFetchMorePaginatedGroups, handleGetPaginatedGroups } = useGetPaginatedGroups({
    onCompleted: ({ groupConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastGroupCursor) {
        setLastGroupCursor(lastEdge.cursor);
      }

      const groups = edges.map(({ node }) => node);

      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsSearchListDropdownItems([
        {
          id: '0',
          label: 'All groups',
          value: '0',
        },
        {
          id: unassigned,
          label: 'Unassigned users',
          value: unassigned,
        },
        ...groupsSelectSearchList,
      ]);

      setIsLoadMoreGroupsDisabled(groups.length >= totalCount || totalCount <= pageSize);
      setTotalGroups(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const handleAddNewUserClick = () => {
    setIsAddUserSideBarOpen(true);
  };

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedProjectUsers({
      filter: value,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(projectId),
      status: statusFilter,
    });

    setFilter(value);
  };

  const handleGroupSearch = (value: string) => {
    void handleGetPaginatedGroups({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
    });

    setGroupFilter(value);
  };

  const handleGroupSearchItemClick = (id: string | number) => {
    const groupId = Number(id);
    const selectedId = groupId === 0 ? undefined : groupId;

    setShouldReturnUnassignedOnly(id === unassigned);
    setSelectedGroupId(selectedId);
    setSelectedGroupIdString(String(id));
  };

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const handleSideBarClose = () => {
    setIsAddUserSideBarOpen(false);
    setIsUpdateUserSideBarOpen(false);
    setSelectedUser(undefined);
  };


  const handleUpdatedUser = (shouldCloseSidebar = true) => {

    if (shouldCloseSidebar) {
      handleSideBarClose();
      handleToastSuccess({
        message: 'User updated successfully',
      });
    }
    
    void handleGetPaginatedProjectUsers({
      filter,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(projectId),
      status: statusFilter,
      shouldReturnUnassignedOnly,
    });
  };

  const handleUserStatusFilterChange = (status: DBUserStatusType) => {
    setStatusFilter(status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE');
  };

  const handleLoadMoreGroups = () => {
    void handleFetchMorePaginatedGroups({
      filter: groupFilter,
      after: lastGroupCursor,
      first: pageSize,
      projectId: String(projectId),
    });
  };

  const handleCreatedUser = () => {
    handleSideBarClose();
    void handleGetPaginatedProjectUsers({
      filter,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(projectId),
      status: statusFilter,
      shouldReturnUnassignedOnly,
    });
  };

  // bulk actions
  const handleOpenBulkActionDialog = (type: string) => {
    setBulkActionType(type);
    setIsBulkActionDialogOpen(true);
  };

  const handleCloseBulkActionDialog = () => {
    setBulkActionType('');
    setIsBulkActionDialogOpen(false);
  };

  const handleSelectedRows = (selectedIds: string[]) => {
    setSelectedRows(selectedIds);
  };

  const handleBulkActionSave = () => {
    setSelectedRows([]);
    handleCloseBulkActionDialog();
    void handleGetPaginatedProjectUsers({
      filter,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(projectId),
      status: statusFilter,
      shouldReturnUnassignedOnly,
    });
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    if (hasGroups)
      void handleGetPaginatedGroups({
        first: pageSize,
        projectId: String(projectId),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, hasGroups]);

  useEffect(() => {
    if (!project) return;
    if (!canImpersonateUser) return;


    if (!impersonateUserLink) {
      void handleGetImpersonateUserLink({
        projectId: String(project.id),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canImpersonateUser, impersonateUserLink, project]);

  useEffect(() => {
    if (!project) {
      return;
    }

    void handleGetPaginatedProjectUsers({
      filter,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(project.id),
      status: statusFilter,
      shouldReturnUnassignedOnly,
    });
    const { name } = project;

    if (!projectName) setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, project, selectedGroupId, statusFilter, shouldReturnUnassignedOnly]);

  useEffect(() => {
    if (!allUsers) {
      return;
    }

    setUsersTableData(
      getUsersTableData({
        users: allUsers,
        baseUrl,
        onSettingsButtonClick: (id) => {
          setIsUpdateUserSideBarOpen(true);
          setSelectedUser(allUsers.find(({ id: userId }) => id === userId));
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers, baseUrl]);

  useEffect(() => {
    const selectedGroup = groupsSearchListDropdownItems?.find((item) => item.id === String(selectedGroupIdString));

    setSelectedGroupLabel(selectedGroup ? selectedGroup.label : 'All groups');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsSearchListDropdownItems, selectedGroupIdString]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!(project && dashboardUrl)) return
    const { features = [], organisation, id } = project;
    
    if (features.includes('HAS_PROGRESS_TRACKER')) {
      setBaseUrl(`${dashboardUrl}/${organisation.key}/${id}/progress-tracking`)
    };
  }, [project, dashboardUrl])

  const isLoading = isGetImpersonateUserLinkLoading || isGetPaginatedGroupMembersLoading;

  const bulkActions = project?.isCustomBuild
    ? [
        { id: 0, label: 'Delete user', action: () => handleOpenBulkActionDialog('removeUsers') },
        { id: 1, label: 'Remove from group', action: () => handleOpenBulkActionDialog('removeFromGroup') },
        { id: 2, label: 'Deactivate user', action: () => handleOpenBulkActionDialog('deactivateUsers') },
      ]
    : [{ id: 1, label: 'Remove from group', action: () => handleOpenBulkActionDialog('removeFromGroup') }];

  return (
    <>
      <TablePageTemplate<IUsersTableData>
        activePage="users"
        activeSubPage="users"
        bulkActions={hasPermission(String(projectId), 'MANAGE_USERS') ? bulkActions: undefined }
        childrenEnd={
          <ToggleSwitch
            id="user-status-filter"
            isChecked={statusFilter === 'INACTIVE'}
            labelText="Show inactive"
            isInline
            onChange={() => handleUserStatusFilterChange(statusFilter)}
          />
        }
        childrenMiddle={
          hasGroups ? (
            <SelectWithSearch
              additionalClassNames="c-select-with-search__users-table"
              buttonText="All groups"
              countText="groups"
              defaultValue={selectedGroupLabel}
              hasPadding={false}
              id="id_project_groups"
              isDisplaySearchContent={isSearchContentVisible}
              labelText="Select group"
              searchList={groupsSearchListDropdownItems ?? []}
              totalCount={totalGroups}
              isAvatarVisible
              isLabelHidden
              onDisplaySearchContent={() => setIsSearchContentVisible(!isSearchContentVisible)}
              onLoadMore={!isLoadMoreGroupsDisabled ? handleLoadMoreGroups : undefined}
              onSearch={handleGroupSearch}
              onSearchItemClick={handleGroupSearchItemClick}
            />
          ) : null
        }
        columns={hasGroups ? PROJECT_EKARDO_USER_TABLE_COLUMNS : PROJECT_USER_TABLE_COLUMNS}
        data={usersTableData ?? []}
        description="Manage users"
        handleAddNewClick={handleAddNewUserClick}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedProjectUsers({
            after: lastCursor,
            filter,
            first: pageSize,
            groupId: selectedGroupId,
            projectId: String(projectId),
            status: statusFilter,
            shouldReturnUnassignedOnly,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={
          <>
            Manage <strong>{String(projectName)}</strong> users:{' '}
            <strong>
              {totalUsers} {pluralize('user', totalUsers)}
            </strong>
          </>
        }
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        pageSize={pageSize}
        pageTitle={pageTitle}
        searchPlaceholder="Name, identifier or role"
        tableType="users"
        title={String(projectName)}
        totalItems={totalUsers}
        isProjectLevel
        onSelectRows={handleSelectedRows}
      />
      {projectId && hasPermission(String(projectId), 'MANAGE_USERS') && (
        <ProjectUsersInvitationSidebar
          handleOpenCloseSideBar={handleSideBarClose}
          hasGroups={hasGroups}
          isSideBarOpen={isAddUserSideBarOpen}
          projectId={String(projectId)}
          onSave={handleCreatedUser}
        />
      )}
      {projectId && hasPermission(String(projectId), 'MANAGE_USERS') && (
        <ProjectUsersSidebar
          handleOpenCloseSideBar={handleSideBarClose}
          hasGroups={hasGroups}
          impersonateUserLink={canImpersonateUser ? impersonateUserLink: undefined}
          isSideBarOpen={isUpdateUserSideBarOpen}
          projectId={String(projectId)}
          selectedUser={selectedUser}
          onSave={handleUpdatedUser}
        />
      )}
      {projectId && hasPermission(String(projectId), 'MANAGE_USERS') && (
        <BulkActionDialog
          bulkActionType={bulkActionType}
          handleCloseDialog={handleCloseBulkActionDialog}
          isOpen={isBulkActionDialogOpen}
          projectId={String(projectId)}
          selectedIds={selectedRows}
          onSave={handleBulkActionSave}
        />
      )}
    </>
  );
};

export { ProjectUsers };
