import React, { useEffect, useState } from 'react';

import { IDBNote, useGetUserNotes } from '@netfront/gelada-identity-library';
import { ButtonIconOnly, FlexContainer, InfoIcon, InformationBox, PlusIcon, Preloader, Spacing } from '@netfront/ui-library';

import { UserNotesTabProps } from './UserNotesTab.interfaces';

import { SidebarListItem, SidebarList, SidebarContainer } from '../../../../components/Shared';
import { NoteFormView } from '../../../../components/UserManagementViews';
import { useToast } from '../../../../hooks';

const UserNotesTab = ({ userId }: UserNotesTabProps) => {
  const { handleToastError } = useToast();

  const [isAddNoteVisible, setIsAddNoteVisible] = useState<boolean>(false);
  const [userNote, setUserNote] = useState<IDBNote>();
  const [userNotes, setUserNotes] = useState<IDBNote[]>();

  const { handleGetUserNotes, isLoading: isGetUserNotesLoading = false } = useGetUserNotes({
    onCompleted: ({ notes }) => {
      setUserNote(undefined);
      setUserNotes(notes);
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const handleClickNote = (note: IDBNote) => {
    setIsAddNoteVisible(!isAddNoteVisible);
    setUserNote(note);
  };

  const handleToggleAddNoteVisibility = () => {
    setIsAddNoteVisible(!isAddNoteVisible);
    setUserNote(undefined);
  };

  useEffect(() => {
    void handleGetUserNotes({
      userId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SidebarContainer>
      <Preloader isLoading={isGetUserNotesLoading} />

      {isAddNoteVisible && (
        <NoteFormView
          noteType="user"
          selectedEntityId={userId}
          selectedNote={userNote}
          onOpenUpdateNotes={handleToggleAddNoteVisibility}
          onUpdateNotes={() => {
            void handleGetUserNotes({
              userId,
            });
          }}
        />
      )}

      {!isAddNoteVisible && userNotes && (
        <>
          {!userNotes.length ? (
            <Spacing>
              <InformationBox icon={InfoIcon} message="No notes found" />
            </Spacing>
          ) : (
            <main>
              <Spacing>
                <SidebarList itemsLength={userNotes.length} text="notes" hasCount>
                  {userNotes.map((note) => {
                    const { id, subject } = note;

                    return (
                      <SidebarListItem
                        key={`${id}_test`}
                        name={subject}
                        supportiveText="Update the note"
                        onClick={() => handleClickNote(note)}
                      />
                    );
                  })}
                </SidebarList>
              </Spacing>
            </main>
          )}

          <FlexContainer justifyContent="end" tag="section">
            <ButtonIconOnly icon={PlusIcon} text="Add note" onClick={handleToggleAddNoteVisibility} />
          </FlexContainer>
        </>
      )}
    </SidebarContainer>
  );
};

export { UserNotesTab };
