/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { useContext, useEffect, useState } from 'react';

import {
  IUser,
  useDeleteProfilePicture,
  useGetProduct,
  useUpdateBaseProfilePicture,
  useUpdateCustomisedProfilePicture,
} from '@netfront/gelada-identity-library';
import { CustomImageProps } from '@netfront/ui-library';

import { UserContext } from '../../context';
import { pushImageToAws } from '../../utils/pushImageToAws';

const useProfilePicture = () => {
  const { getProduct } = useGetProduct();

  const { profileImageUrl, setProfileImageUrl, setStoredUser: setStoredUserContextUser } = useContext(UserContext);

  const [baseImageUrl, setBaseImageUrl] = useState<string | null>();
  const [baseImageName, setBaseImageName] = useState('');
  const [customUserImage, setCustomUserImage] = useState<CustomImageProps>();
  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const [storedUser, setStoredUser] = useState<IUser>();

  const product = getProduct();

  const storeUser = (user: IUser) => {
    setStoredUser(user);
  };

  useEffect(() => {
    if (storedUser) {
      const { pictures } = storedUser;
      if (!pictures) {
        return;
      }
      const { originalPicture, profilePicture, configuration = '{}' } = pictures ?? {};
      let parsedConfig = {};

      setBaseImageUrl(originalPicture?.presignedUrl ?? '');
      setBaseImageName(originalPicture?.fileName ?? '');

      if (configuration) {
        parsedConfig = JSON.parse(configuration);
      }

      setCustomUserImage({
        ...parsedConfig,
        url: profilePicture?.presignedUrl,
      } as CustomImageProps);
    }
  }, [storedUser]);

  const { handleDeleteProfilePicture, isLoading: isDeleteProfilePictureLoading = false } = useDeleteProfilePicture({
    onCompleted: () => {
      setBaseImageUrl(null);
      setBaseImageName('');
      setProfileImageUrl('');
      setCustomUserImage(undefined);
    },
    product,
  });

  const { handleUpdateBaseProfilePicture, isLoading: isUpdateBaseProfilePictureLoading = false } = useUpdateBaseProfilePicture({
    onCompleted: ({ signedUrl, asset }) => {
      void pushImageToAws(String(signedUrl), droppedFile, () => {
        const { presignedUrl } = asset;
        setDroppedFile(null);
        setBaseImageUrl(presignedUrl);
        setStoredUserContextUser({
          ...storedUser,
          pictures: {
            configuration: '',
            originalPicture: asset,
            profilePicture: undefined,
          }
        } as IUser);
      });
    },
    product,
  });

  const { handleUpdateCustomisedProfilePicture, isLoading: isUpdateCustomisedProfilePictureLoading = false } =
    useUpdateCustomisedProfilePicture({
      onCompleted: ({ signedUrl, asset }) => {
        void pushImageToAws(String(signedUrl), droppedFile, () => {
          const { presignedUrl } = asset;
          setDroppedFile(null);
          setProfileImageUrl(String(presignedUrl));
          if (customUserImage) {
            setCustomUserImage({
              ...customUserImage,
              url: presignedUrl ?? '',
            });
          }
          const { pictures } = storedUser ?? {};

          const { originalPicture }  = pictures ?? {};

          setStoredUserContextUser({
            ...storedUser,
            pictures: {
              configuration: JSON.stringify({
                ...customUserImage,
                url: presignedUrl ?? '',
              }),
              originalPicture,
              profilePicture: asset,
            }
          } as IUser);
        });
      },
      product,
    });

  const deleteProfilePicture = () => {
    void handleDeleteProfilePicture();
  };

  const uploadProfilePicture = (file: File) => {
    const { name, type, size } = file;

    setDroppedFile(file);
    setBaseImageName(name);

    void handleUpdateBaseProfilePicture({
      contentType: type,
      fileName: name,
      fileSizeInBytes: size,
    });
  };

  const customiseProfilePicture = (file: File, updatedImage: CustomImageProps) => {
    const { type, size } = file;

    setDroppedFile(file);
    setCustomUserImage(updatedImage);
    void handleUpdateCustomisedProfilePicture({
      contentType: type,
      fileName: `${baseImageName}-customised`,
      fileSizeInBytes: size,
      configuration: JSON.stringify(updatedImage),
    });
  };

  return {
    customUserImage,
    storeUser,
    baseImageUrl,
    profileImageUrl,
    uploadProfilePicture,
    customiseProfilePicture,
    deleteProfilePicture,
    isLoading: isDeleteProfilePictureLoading || isUpdateBaseProfilePictureLoading || isUpdateCustomisedProfilePictureLoading,
  };
};

export { useProfilePicture };
