const REQUEST_ACTION_OPTIONS = [
  {
    id: 0,
    name: 'Approve group request',
    value: 'approve',
  },
  {
    id: 1,
    name: 'Decline group request',
    value: 'decline',
  },
  {
    id: 2,
    name: 'Transfer user to an existing group',
    value: 'move',
  },
];

export { REQUEST_ACTION_OPTIONS };
