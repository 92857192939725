import React from 'react';

import { ColorPickerWrapper, Input, Spacing, Textarea, ToggleSwitch } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';
import { capitalize } from 'utils';

import { getFormFieldErrorMessage } from 'components/Forms';

import { ProjectSettingsInputProps } from './ProjectSettingsInput.interfaces';


const ProjectSettingsInput = ({
  control,
  description = '',
  name,
  type,
}: ProjectSettingsInputProps) => {
  
  const labelText = capitalize(String(name.replace(/_/g, ' ')));

  return (
    <Spacing>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <>
          { ['TEXT', 'URL'].includes(type) && (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={`id_${name.toLowerCase()}`}
              labelText={labelText}
              tooltipPosition="start"
              tooltipText={description}
              type="text"
              isLabelSideBySide
              {...field}
            />
          )}

          { type === 'TOGGLE' && (
            <ToggleSwitch
              additionalClassNames={`c-sidebar-toggle`}
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={`id_${name.toLowerCase()}`}
              inlineLabelPosition="start"
              isChecked={field.value}
              labelText={labelText}
              name={field.name}
              tooltipPosition="start"
              tooltipText={description}
              isInline
              onChange={field.onChange}
            />
          )}

          { type === 'TEXT_AREA' && (
            <Textarea
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={`id_${name.toLowerCase()}`}
              labelText={labelText}
              tooltipPosition="start"
              tooltipText={description}
              isLabelSideBySide
              {...field}
            />
          )} 

          { type === 'NUMBER' && (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={`id_${name.toLowerCase()}`}
              labelText={labelText}
              tooltipPosition="start"
              tooltipText={description}
              type="number"
              isLabelSideBySide
              {...field}
            />
          )}

          { type === 'EMAIL_ADDRESS' && (
            <Input
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={`id_${name.toLowerCase()}`}
              labelText={labelText}
              tooltipPosition="start"
              tooltipText={description}
              type="email"
              isLabelSideBySide
              {...field}
            />
          )}

          {type === 'COLOR_PICKER' && (
            <ColorPickerWrapper
              color={field.value}
              errorMessage={getFormFieldErrorMessage(fieldState)}
              id={`id_${name.toLowerCase()}`}
              label={labelText}
              pickerPosition="left"
              tooltipPosition="start"
              tooltipText={description}
              type="hex"
              isLabelSideBySide
              onHexChange={field.onChange}
            />
          )}
          
          </>
        )}
      />
  </Spacing>
  );
};


export { ProjectSettingsInput };
