/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Avatar, EmailNoneIcon, FlexContainer, SettingsButton, StarIcon, Tooltip } from '@netfront/ui-library';
import { Column } from 'react-table';

import { EMAIL_ICONS, EMAIL_ICON_TOOLTIPS } from '../../Pages.constants';
import { IGroupInvitationTableData } from '../../Pages.interfaces';

const PERMISSION_MAP: {[key: string]: string} = {
  MANAGE_USERS: 'Project admin who can manage all users',
  EDIT: 'Project admin who  has edit permission',
  READ: 'Project admin who  has read permission',
  LIMITED: 'Project admin who has limited permission',
};

const PROJECT_USER_INVITATION_TABLE_COLUMNS: ReadonlyArray<Column<IGroupInvitationTableData>> = [
  {
    accessor: 'avatarData',
    Cell: ({ value: { fullName, firstName, isProjectAdmin, permission } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar
            roleIndicator={
              isProjectAdmin
                ? {
                    component: (
                      <Tooltip
                        additionalClassNames="c-admin-star c-admin-star--is-admin"
                        icon={StarIcon}
                        placement="right"
                        text={PERMISSION_MAP[permission ?? 'MANAGE_USERS']}
                      />
                    ),
                  }
                : undefined
            }
            size="small"
            title={fullName}
          />
        </div>

        <div className="text-left w-full word-break-all">{firstName}</div>
      </FlexContainer>
    ),
    Header: () => <div className="text-left w-full">First name</div>,
    width: '15%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
    width: '15%',
  },
  {
    accessor: 'emailUserInvited',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Email</div>,
    width: '20%',
  },
  {
    accessor: 'invitationEmailStatus',
    Cell: ({ value }) => (
      <div>
        <Tooltip
          additionalClassNames="c-email-status-tooltip"
          icon={EMAIL_ICONS[String(value)] ?? EmailNoneIcon}
          text={EMAIL_ICON_TOOLTIPS[String(value)] ?? 'disabled'}
        />
      </div>
    ),
    Header: () => <div className="text-left w-full">Status</div>,
    width: '5%',
  },
  {
    accessor: 'created',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Invited</div>,
    width: '15%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { firstName, id, onClick } }) => (
      <div className="flex justify-end">
        <SettingsButton supportiveText={`${firstName} settings`} onClick={() => onClick(id)} />
      </div>
    ),
    Header: <span className="h-hide-visually">Actions</span>,
    width: '2%',
  },
];

const PROJECT_EKARDO_USER_INVITATION_TABLE_COLUMNS: ReadonlyArray<Column<IGroupInvitationTableData>> = [
  {
    accessor: 'avatarData',
    Cell: ({ value: { fullName, firstName, isProjectAdmin, permission } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar
            roleIndicator={
              isProjectAdmin
                ? {
                    component: (
                      <Tooltip
                        additionalClassNames="c-admin-star c-admin-star--is-admin"
                        icon={StarIcon}
                        placement="right"
                        text={PERMISSION_MAP[permission ?? 'MANAGE_USERS']}
                      />
                    ),
                  }
                : undefined
            }
            size="small"
            title={fullName}
          />
        </div>

        <div className="text-left w-full word-break-all">{firstName}</div>
      </FlexContainer>
    ),
    Header: () => <div className="text-left w-full">First name</div>,
    width: '15%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
    width: '15%',
  },
  {
    accessor: 'emailUserInvited',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Email</div>,
  },
  {
    accessor: 'invitationEmailStatus',
    Cell: ({ value }) => (
      <div>
        <Tooltip
          additionalClassNames="c-email-status-tooltip"
          icon={EMAIL_ICONS[String(value)] ?? EmailNoneIcon}
          text={EMAIL_ICON_TOOLTIPS[String(value)] ?? 'disabled'}
        />
      </div>
    ),
    Header: () => <div className="text-left w-full">Status</div>,
    width: '5%',
  },
  {
    accessor: 'groupName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Group</div>,
    width: '15%',
  },
  {
    accessor: 'created',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Invited</div>,
    width: '15%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { firstName, id, onClick } }) => (
      <div className="flex justify-end">
        <SettingsButton supportiveText={`${firstName} settings`} onClick={() => onClick(id)} />
      </div>
    ),
    Header: <span className="h-hide-visually">Actions</span>,
    width: '2%',
  },
];

export { PROJECT_EKARDO_USER_INVITATION_TABLE_COLUMNS, PROJECT_USER_INVITATION_TABLE_COLUMNS };
