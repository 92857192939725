import React, { useEffect, useState } from 'react';

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import {
  InformationBox,
  Input,
  Preloader,
  Select,
  SelectWithSearch,
  Spacing,
  SidebarButtons,
  ErrorIcon,
} from '@netfront/ui-library';

import { SidebarContainer } from 'components/Shared';

import { REQUEST_ACTION_OPTIONS } from './GroupRequestActionsTab.constants';
import { GroupRequestActionsTabProps, IGroupRequestActionUserType } from './GroupRequestActionsTab.interfaces';

import { ADD_USER_IN_GROUP, APPROVE_DECLINE_GROUP_REQUEST, UPDATE_USER_STATUS } from '../../../../graphql';
import { useGetPaginatedUserTypes, useToast } from '../../../../hooks';
import { ISelectWithSearchItemType } from '../../../Pages';

const GroupRequestActionsTab = ({
  groups,
  projectId,
  selectedGroupRequest,
  onUpdateGroupRequests,
  onClose,
}: GroupRequestActionsTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();
  const { id: groupId, name: groupName, flag, creator } = selectedGroupRequest ?? {};
  const { id: userId } = creator ?? {};

  const [action, setAction] = useState<string>('approve');
  const [declineReason, setDeclineReason] = useState<string>('');
  const [isGroupSearchContentVisible, setSearchIsGroupContentVisible] = useState(false);
  const [isUserTypeSearchContentVisible, setIsUserTypeSearchContentVisible] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>(undefined);
  const [selectedUserTypeId, setSelectedUserTypeId] = useState<number | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(groupName as string);
  const [userTypes, setUserTypes] = useState<ISelectWithSearchItemType[]>([]);

  const [addUserInGroup, { loading: isAddUserInGroupLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: 'User added to group successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    },
    mutation: ADD_USER_IN_GROUP,
  });

  const {
    handleGetPaginatedUserTypes,
    isLoading: isSearchUserTypesLoading = false,
  } = useGetPaginatedUserTypes({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ userTypesConnection: { edges } }) => {

      const userTypesEdges = edges.map(({ node }) => node);

      const allUserTypes: ISelectWithSearchItemType[] = [...(userTypesEdges as IGroupRequestActionUserType[])].map(({ id, name }) => ({
        id,
        label: name,
      }));

      setUserTypes(allUserTypes);
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const [activateUser, { loading: isActivationUserLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: `User activated!`,
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_USER_STATUS,
  });

  const [approveDeclineGroupRequest, { loading: isApproveDeclineGroupRequest }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: `Group request ${action}d!`,
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: APPROVE_DECLINE_GROUP_REQUEST,
  });

  const handleGroupCreationRequest = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (action === 'approve') {
      await approveDeclineGroupRequest({
        variables: {
          approved: true,
          requestId: Number(groupId),
          name: title,
        },
      }).then(() => {
        onUpdateGroupRequests();
        onClose();
      });
    } else if (action === 'decline') {
      await approveDeclineGroupRequest({
        variables: {
          approved: false,
          deleteOnDecline: true,
          reason: declineReason,
          requestId: Number(groupId),
        },
      }).then(() => {
        setTimeout(() => {
          onUpdateGroupRequests();
        }, 100);
        onClose();
      });
    } else if (action === 'move') {
      await approveDeclineGroupRequest({
        variables: {
          approved: false,
          deleteOnDecline: false,
          reason: declineReason,
          requestId: Number(groupId),
        },
      });

      await activateUser({
        variables: {
          userIds: [userId],
          projectId,
          newUserStatus: 'ACTIVE',
        },
      });

      void addUserInGroup({
        variables: {
          groupId: selectedGroupId,
          projectId,
          userId,
          userTypeId: selectedUserTypeId,
          removeFromOtherGroups: true,
        },
      }).then(() => {
        setTimeout(() => {
          onUpdateGroupRequests();
        }, 100);
        onClose();
      });
    }
  };

  useEffect(() => {
    void handleGetPaginatedUserTypes({
      projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isSearchUserTypesLoading || isApproveDeclineGroupRequest || isAddUserInGroupLoading || isActivationUserLoading;
  const isDisableSubmitMoveUser = action === 'move' && Boolean(!(selectedGroupId && selectedUserTypeId));

  return (
    <SidebarContainer>
      <Preloader isLoading={isLoading} />

      <form
        onSubmit={(event) => {
          void handleGroupCreationRequest(event);
        }}
      >
        <Spacing size="x-large">
          <Spacing size="x-small">
            <Input
              id="name"
              labelText="Title"
              name="name"
              type="text"
              value={title}
              isLabelSideBySide
              onChange={({ target: { value } }) => setTitle(value)}
            />
          </Spacing>

          {flag ? <InformationBox icon={ErrorIcon} message={String(flag)} /> : null}
        </Spacing>

        <section >
          <Select
            id="page-size"
            labelText="Action"
            name="pageSize"
            options={REQUEST_ACTION_OPTIONS}
            tooltipText="Approve, decline or transfer the user who made the request to an existing group"
            value={action}
            isLabelSideBySide
            onChange={({ target: { value } }) => setAction(value)}
          />
        </section>

        {action === 'decline' && (
          <Input
            id="declineReason"
            labelText="Decline reason"
            name="declineReason"
            type="text"
            value={declineReason}
            isLabelSideBySide
            isRequired
            onChange={(e) => setDeclineReason(e.target.value)}
          />
        )}

        {action === 'move' && (
          <section>
            <SelectWithSearch
              additionalClassNames="c-select-with-search__users-table"
              buttonText="All groups"
              id="id_select_groups_search"
              isDisplaySearchContent={isGroupSearchContentVisible}
              labelText="Group"
              searchList={groups}
              isAvatarVisible
              isLabelSideBySide
              isRequired
              onDisplaySearchContent={() => setSearchIsGroupContentVisible(!isGroupSearchContentVisible)}
              onSearchItemClick={(id: string | number) => setSelectedGroupId(Number(id))}
            />

            <SelectWithSearch
              additionalClassNames="c-select-with-search__users-table"
              buttonText="All user types"
              id="id_select_user_type_search"
              isDisplaySearchContent={isUserTypeSearchContentVisible}
              labelText="User types"
              searchList={userTypes}
              isAvatarVisible
              isLabelSideBySide
              isRequired
              onDisplaySearchContent={() => setIsUserTypeSearchContentVisible(!isUserTypeSearchContentVisible)}
              onSearchItemClick={(id: string | number) => setSelectedUserTypeId(Number(id))}
            />
          </section>
        )}

        <SidebarButtons
          buttonSize="xs"
          isSaveButtonDisabled={isDisableSubmitMoveUser}
          onCancel={onClose}
          onSaveButtonType="submit"
        />
      </form>
    </SidebarContainer>
  );
};

export { GroupRequestActionsTab };
