import { ChangeEvent } from 'react';

import { Input, Textarea, ImageUpload } from '@netfront/ui-library';
import { Controller } from 'react-hook-form';
import { toKebabCase } from 'utils';

import { getFormFieldErrorMessage } from 'components/Forms';
import { SidebarContainer } from 'components/Shared';

import { ProjectSidebarGeneralTabProps } from './UpsertProjectSidebarGeneralTab.interfaces';


import { useDeleteAsset, useToast } from '../../../../hooks';

const UpsertProjectSidebarGeneralTab = ({
  control,
  setValue, 
  isLoading = false,
  onDeleteAsset,
  onUploadFile,
  isReadOnly = false, 
  isSubmitting = false,
  projectId,
  initialAssetId,
}: ProjectSidebarGeneralTabProps) => {

  const { handleToastError } = useToast();

  const { handleDeleteAsset } = useDeleteAsset({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        if (onDeleteAsset) onDeleteAsset(projectId ?? '');
      }
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleFileUpload = (uploadedFile?: File | undefined) => {
    if (uploadedFile) {
      onUploadFile(uploadedFile);
    }
  };

  return (
    <SidebarContainer>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_project_name"
            isDisabled={isSubmitting}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            labelText="Title"
            maxLength={50}
            tooltipText="Give your project a title"
            type="text"
            isLabelSideBySide
            isRequired
            {...field}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const {
                target: { value },
              } = event;
              field.onChange(event);

              if (!projectId) setValue('key', toKebabCase(String(value)));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="key"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_project_key"
            isDisabled={!!projectId || isSubmitting}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            labelText="Key"
            maxLength={50}
            tooltipText="Give your project a key"
            type="text"
            isLabelSideBySide
            isRequired
            {...field}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const {
                target: { value },
              } = event;
              setValue('key', toKebabCase(String(value)));
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <Textarea
            id="id_project_description"
            isDisabled={isSubmitting}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            labelText="Description"
            maxLength={950}
            tooltipText="Give your project a description"
            isLabelSideBySide
            {...field}
          />
          )}
        />

      <Controller
        control={control}
        name="logoUrl"
        render={({ field }) => (
          <ImageUpload
            id="id_project_logo"
            initialUrl={field.value}
            isDisabled={isSubmitting}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
            labelText="Logo"
            name={field.name}
            tooltipText="Give your project a logo"
            isLabelSideBySide
            onDelete={
              initialAssetId
                ? () => {
                    void handleDeleteAsset({
                      id: initialAssetId,
                    });
                  }
                : undefined
            }
            onDrop={handleFileUpload}
          />
        )}
      />
    </SidebarContainer>
  );
};

export { UpsertProjectSidebarGeneralTab };
