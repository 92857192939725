import { ChangeEvent, useEffect, useState } from 'react';

import { useDeleteUsers, useSearchGroups } from '@netfront/gelada-identity-library';
import { Dialog, Select, Spacing, Spinner, IOption } from '@netfront/ui-library';
import pluralize from 'pluralize';

import { BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP, BULK_ACTION_DIALOG_TITLE_MAP } from './BulkActionDialog.constants';
import { BulkActionDialogProps } from './BulkActionDialog.types';

import { useRemoveUsersFromGroup, useToast, useBulkDeleteInvitations, useBulkResendInvitations } from '../../../../hooks';

const BulkActionDialog = ({
  projectId,
  bulkActionType,
  isOpen = false,
  onSave,
  handleCloseDialog,
  selectedIds,
}: BulkActionDialogProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [selectedGroup, setSelectedGroup] = useState<number | undefined>();
  const [groupDropdownListOptions, setGroupsDropdownItems] = useState<IOption[]>();

  const { handleDeleteUsers, isLoading: isDeleteUsersLoading = false } = useDeleteUsers({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Users successfully deleted',
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const { handleBulkDeleteInvitations, isLoading: isBulkDeleteInvitationsLoading = false } = useBulkDeleteInvitations({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Invitations successfully deleted',
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const { handleBulkResendInvitations, isLoading: isBulkResendInvitationsLoading = false } = useBulkResendInvitations({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Invitations successfully resent',
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const { handleRemoveUsersFromGroup, isLoading: isRemoveUsersFromGroupLoading = false } = useRemoveUsersFromGroup({
    onCompleted: ({isCompleted}) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Users successfully removed from group',
        });
        onSave();
      };
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const { handleSearchGroups, isLoading: isSearchGroupsLoading = false } = useSearchGroups({
    onCompleted: ({ groups }) => {
      const groupsSelectSearchList = groups.map(
        ({ id, name }): IOption => ({
          id,
          name,
          value: id,
        }),
      );



      setGroupsDropdownItems(groupsSelectSearchList);

    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const removeUsers = () => {
    void handleDeleteUsers({
      newUserStatus: 'DELETED',
      projectId: String(projectId),
      userIds: selectedIds?.map(id => Number(id)) ?? [],
    })
  };

  const removeFromGroup = () => {
    void handleRemoveUsersFromGroup({
      groupId: Number(selectedGroup),
      userIds: selectedIds?.map(id => Number(id)) ?? [],
    })
  };

  const deleteInvitations = () => {
    void handleBulkDeleteInvitations({
      invitationIds: selectedIds?.map(id => Number(id)) ?? [],
    })
  };

  const resendInvitations = () => {
    void handleBulkResendInvitations({
      invitationIds: selectedIds?.map(id => Number(id)) ?? [],
    })
  };

  const deactivateUsers = () => {
    void handleDeleteUsers({
      newUserStatus: 'DEACTIVATED',
      projectId: String(projectId),
      userIds: selectedIds?.map(id => Number(id)) ?? [],
    })
  };

  const saveMap: { [key: string]: () => void } = {
    removeUsers: removeUsers,
    removeFromGroup: removeFromGroup,
    deactivateUsers: deactivateUsers,
    deleteInvitations: deleteInvitations,
    resendInvitations: resendInvitations,
  };


  const handleSave = () => {
    const bulkHandler = saveMap[bulkActionType];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    bulkHandler();
  };

  const handleUpdateInput = (event: ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = event;

    setSelectedGroup(Number(value));
  };

  useEffect(() => {
    if (!projectId && bulkActionType !== 'removeFromGroup') {
      return;
    }

    void handleSearchGroups({
      projectId: String(projectId),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);




  const isLoading = isDeleteUsersLoading || isRemoveUsersFromGroupLoading || isSearchGroupsLoading || isBulkDeleteInvitationsLoading || isBulkResendInvitationsLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <Dialog
        confirmText={BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]}
        isOpen={isOpen}
        title={BULK_ACTION_DIALOG_TITLE_MAP[bulkActionType]}
        onCancel={handleCloseDialog}
        onClose={handleCloseDialog}
        onConfirm={handleSave}
      >
        <p>{BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP[bulkActionType]} {selectedIds?.length ?? 0} {pluralize(['deleteInvitations', 'resendInvitations'].includes(bulkActionType) ? 'invitation': 'user', selectedIds?.length ?? 0)}</p>

        {bulkActionType === 'removeFromGroup' && (
          <Spacing size="x-large">
            <Select
              id="group"
              labelText="Group"
              name="group"
              options={groupDropdownListOptions ?? []}
              tooltipText="Please select a group to remove the users from"
              isRequired
              onChange={handleUpdateInput}
            />
          </Spacing>
        )}
      </Dialog>
    </>
  );
};

export { BulkActionDialog };
