import React, { useState, useEffect, useContext } from 'react';

import { useToggle } from '@netfront/common-library';
import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';
import { ContactsTabIcon, GeneralTabIcon, ITab, NotesTabIcon } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { PROJECT_GROUP_REQUESTS_TABLE_COLUMNS } from './ProjectGroupRequests.constants';
import { getProjectGroupRequestsTableData } from './ProjectGroupRequests.helpers';
import { IGroupRequest, IGroupsWithinProjectResponse, IPaginatedGroupRequestsResponse, IProjectGroupRequestData } from './ProjectGroupRequests.interfaces';

import { GroupRequestActionsTab, GroupRequestContactTab, GroupRequestGeneralTab } from '../../../../components/Tabs';
import { CachingEntitiesContext } from '../../../../context';
import { GET_GROUPS_WITHIN_PROJECT, GET_PAGINATED_GROUP_REQUESTS_PER_PROJECT } from '../../../../graphql';
import { useToast } from '../../../../hooks';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';
import { TablePageTemplate } from '../../../Templates/TablePageTemplate';
import { ISelectWithSearchItemType } from '../../Pages.interfaces';

const { pageTitle } = USER_MANAGEMENT_PAGE_CONSTANTS;

const ProjectGroupRequests = () => {
  const { project, isProduct } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const [projectName, setProjectName] = useState<string>();
  const [projectId, setProjectId] = useState<string>('');

  const { handleToastError } = useToast();

  const { defaultPageSize } = USER_MANAGEMENT_PAGE_CONSTANTS;
  const { isToggled: isSideBarOpen, toggle: toggleIsSideBarOpen } = useToggle();

  const [groupRequestsTableData, setGroupRequestsTableData] = useState<IProjectGroupRequestData[]>([]);
  const [groupsDropdownItems, setGroupsDropdownItems] = useState<ISelectWithSearchItemType[]>([]);
  const [groupRequestData, setGroupRequestData] = useState<IGroupRequest>();
  const [groupRequestsData, setGroupRequestsData] = useState<IGroupRequest[]>([]);
  const [groupRequestId, setGroupRequestId] = useState<number>();
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const [isPaginationDisabled, setIsPaginationDisabled] = useState<boolean>(false);
  const [lastGroupRequestCursor, setLastGroupRequestCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [searchValue, setSearchValue] = useState<string>('');
  const [totalGroupRequests, setTotalGroupRequests] = useState(0);

  const [getPaginatedGroupRequests, { fetchMore, loading: isGetGroupRequestsLoading }] = useLoggedGeladaLazyQuery<IPaginatedGroupRequestsResponse>({
    options: {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({
        groupCreationRequest: {
          get: { edges: groupRequestsEdges, totalCount = 0},
        },
      }) => {
        if (totalCount === 0) {
          setGroupRequestsData([]);
          setLastGroupRequestCursor(undefined);
          setIsPaginationDisabled(true);
          setTotalGroupRequests(Number(totalCount));
          handleCreateGroupRequestsTableData([]);

          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const groupRequestItems = [...groupRequestsEdges].map((groupEdge) => groupEdge.node);
        setGroupRequestsData(groupRequestItems);

        setTotalGroupRequests(Number(totalCount));

        const { cursor } = groupRequestsEdges[groupRequestsEdges.length - 1];
        setLastGroupRequestCursor(String(cursor));

        handleCreateGroupRequestsTableData(groupRequestItems);
        setIsPaginationDisabled(groupRequestItems.length >= totalCount || totalCount <= pageSize);
      },
      onError: (error) => {
        handleToastError({
          error,
          hasCloseButton: true,
        });
      },
    },
    query: GET_PAGINATED_GROUP_REQUESTS_PER_PROJECT,
  });

  const [getGroupsWithinProject, { loading: isGetGroupsWithinProjectLoading }] = useLoggedGeladaLazyQuery<IGroupsWithinProjectResponse>({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ group: { searchGroups: groups } }) => {
        const groupOptions: ISelectWithSearchItemType[] = [...groups].map(({ id, name }) => ({
          id: id,
          label: name,
        }));

        setGroupsDropdownItems([{ id: 0, label: 'All groups' }, ...groupOptions] as ISelectWithSearchItemType[]);
      },
      onError: (error) => {
        handleToastError({
          error,
          hasCloseButton: true,
        });
      },
    },
    query: GET_GROUPS_WITHIN_PROJECT,
  });

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleOnPaginate = async () => {
    setIsLoadingMore(true);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!fetchMore) {
      return;
    }

    const data = await fetchMore({
      updateQuery: (previousQueryResult, { fetchMoreResult }) => {
        const {
          groupCreationRequest: {
            get: { edges: currentGroupEdges },
          },
        } = fetchMoreResult;

        if (!currentGroupEdges.length) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return previousQueryResult;
        }

        const { groupCreationRequest: previousQueryResultGroupRequest } = previousQueryResult;
        const { get: previousQueryResultGetPaginatedGroup } = previousQueryResultGroupRequest;
        const { edges: previousUserEdges } = previousQueryResultGetPaginatedGroup;

        const allFetchedUserEdges = [...previousUserEdges, ...currentGroupEdges];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return {
          ...previousQueryResult,
          groupCreationRequest: {
            ...previousQueryResultGroupRequest,
            get: {
              ...previousQueryResultGetPaginatedGroup,
              edges: allFetchedUserEdges,
            },
          },
        };
      },
      variables: {
        after: lastGroupRequestCursor,
        first: pageSize,
        isPrevious: false,
      },
    });

    const {
      data: {
        groupCreationRequest: {
          get: { edges: currentGroupEdges },
        },
      },
    } = data;

    const currentFetchedUsers = [...currentGroupEdges].map((currentUserEdge: { node: unknown }) => currentUserEdge.node);
    const allFetchedUsers = [...groupRequestsData, ...currentFetchedUsers];
    setIsLoadingMore(false);
    setIsPaginationDisabled(allFetchedUsers.length >= totalGroupRequests);
    setLastGroupRequestCursor(currentGroupEdges[currentGroupEdges.length - 1].cursor);
    handleCreateGroupRequestsTableData(allFetchedUsers as IGroupRequest[]);
  };

  const handleCreateGroupRequestsTableData = (groupRequests: IGroupRequest[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setGroupRequestsTableData(getProjectGroupRequestsTableData({ groupRequests, onSettingsButtonClick: handleSettingsButtonClick }));
  };

  const handleCloseSidebar = () => {
    toggleIsSideBarOpen();
  };

  const handleSearch = (val: string) => {
    setSearchValue(val);
    void handleSearchRequest(val);
  };

  const handleSearchRequest = async (val: string) => {
    await getPaginatedGroupRequests({
      variables: {
        first: pageSize,
        filter: val,
        projectId,
      },
    });
  };

  const handleGetGroupRequestsPaginated = async () => {
    await getPaginatedGroupRequests({
      variables: {
        first: pageSize,
        filter: searchValue,
        projectId,
      },
    });
  };

  const handleSettingsButtonClick = (selectedId: number) => {
    setGroupRequestId(selectedId);
    toggleIsSideBarOpen();
  };

  useEffect(() => {
    if (!projectId) return;
    void handleGetGroupRequestsPaginated();

    void getGroupsWithinProject({
      variables: {
        projectId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (!groupRequestId) {
      return;
    }

    setGroupRequestData(groupRequestsData.find(({ id }) => id === groupRequestId));
  }, [groupRequestsData, groupRequestId]);

  useEffect(() => {
    if (!projectId) return;

    void getPaginatedGroupRequests({
      variables: {
        first: pageSize,
        filter: searchValue,
        projectId: projectId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId]);

  const tabs: ITab[] = [
    {
      icon: GeneralTabIcon,
      id: 'id_general_tab',
      label: 'General',
      view: () => <GroupRequestGeneralTab selectedGroupRequest={groupRequestData} />,
    },
    {
      icon: ContactsTabIcon,
      id: 'id_contact_tab',
      label: 'Contact',
      view: () => <GroupRequestContactTab selectedGroupRequest={groupRequestData} />,
    },
    {
      icon: NotesTabIcon,
      id: 'id_actions_tab',
      label: 'Actions',
      view: () => (
        <GroupRequestActionsTab
          groups={groupsDropdownItems.filter(({ id }) => id !== 0)}
          projectId={String(projectId)}
          selectedGroupRequest={groupRequestData}
          onClose={handleCloseSidebar}
          onUpdateGroupRequests={() => {
            void handleGetGroupRequestsPaginated();
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;
    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetGroupRequestsLoading || isGetGroupsWithinProjectLoading || isLoadingMore;

  return (
    <>
      <TablePageTemplate<IProjectGroupRequestData>
        activePage={isProduct ? 'users' : 'group-requests'}
        activeSubPage="group-requests"
        additionalBreadcrumbItems={[
          {
            key: '3',
            content: <span>Project group requests</span>,
          },
        ]}
        columns={PROJECT_GROUP_REQUESTS_TABLE_COLUMNS}
        data={groupRequestsTableData}
        description="Manage users"
        handleOnPageSizeChange={handleOnPageSizeChange}
        handleOnPaginate={handleOnPaginate}
        handleSearch={handleSearch}
        informationBoxMessage={
          <>
            Manage <strong>{String(projectName)}</strong> group requests:{' '}
            <strong>
              {totalGroupRequests} {pluralize('group request', totalGroupRequests)}
            </strong>
          </>
        }
        isLoading={isLoading}
        isPaginationDisabled={isPaginationDisabled}
        isSideBarOpen={isSideBarOpen}
        pageSize={pageSize}
        pageTitle={pageTitle}
        searchPlaceholder="Name"
        tableType="requests"
        tabs={tabs}
        title={String(projectName)}
        toggleIsSideBarOpen={handleCloseSidebar}
        totalItems={totalGroupRequests}
        isProjectLevel
      />
    </>
  );
};

export { ProjectGroupRequests };
