import React, { useEffect, useState, useContext } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import {
  Avatar,
  Dialog,
  FormFieldProps,
  Input,
  Spacing,
  ToggleSwitch,
} from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';

import { SidebarContainer } from 'components/Shared';

import { UserGeneralTabProps } from './UserGeneralTab.interfaces';

import { CountryCodeInput } from '../../../../components';
import { CachingEntitiesContext } from '../../../../context';

const UserGeneralTab = ({ 
  fullName = '',
  control,
  setValue,
  handleToggleElevateGroupMember,
  initialIsTeamAdmin = false,
  countryCodeOptions = [],
}: UserGeneralTabProps) => {

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { project, isProduct } = useContext(CachingEntitiesContext);
  const { services } = project ?? {};

  const [isTeamAdmin, setIsTeamAdmin] = useState(false);
  const [isUpdateToAdminDialogOpen, setIsUpdateToAdminDialogOpen] = useState<boolean>(false);

  const toggleElevateUserAsProjectAdmin = () => {
    handleToggleElevateGroupMember();
    setValue('isTeamAdmin', true);
    setIsTeamAdmin(true);
  };

  const cancelToggleElevateUserAsProjectAdmin = () => {
    setIsUpdateToAdminDialogOpen(false);
    setIsTeamAdmin(false);
    setValue('isTeamAdmin', false);
  };

  useEffect(() => {
    setIsTeamAdmin(initialIsTeamAdmin);
  }, [initialIsTeamAdmin]);

  return (
    <SidebarContainer>
      <Spacing size="large">
        <section className="flex justify-between items-center">
          <Avatar title={fullName} />

          <Controller
            control={control as Control<FormFieldProps>}
            name="isUserStatusActive"
            render={({ field }) => (
              <ToggleSwitch
                additionalClassNames="c-user-status-toggle"
                id="id_user_status"
                isChecked={field.value}
                labelText="Activated"
                isInline
                onChange={(event) => {
                  field.onChange(event);
                  setValue('hasStatusChanged', true);
                }}
              />
            )}
          />
        </section>
      </Spacing>

      <Controller
        control={control as Control<FormFieldProps>}
        name="firstName"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_user_first_name"
            labelText="First name"
            type="text"
            isLabelSideBySide
            isRequired
            {...field}
          />
        )}
      />
      
      <Controller
        control={control as Control<FormFieldProps>}
        name="lastName"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_user_last_name"
            labelText="Last name"
            type="text"
            isLabelSideBySide
            isRequired
            {...field}
          />
        )}
      />
      
      <Controller
        control={control as Control<FormFieldProps>}
        name="email"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_user_email"
            labelText="Email"
            type="text"
            isLabelSideBySide
            isRequired
            {...field}
          />
        )}
      />

      <CountryCodeInput 
        codesOptions={countryCodeOptions} 
        control={control}
        countryCodeName="phoneNumberCountryCode"
        id="id_user_phone_number"
        phoneNumberName='phoneNumber'
      />
      
      <Controller
        control={control as Control<FormFieldProps>}
        name="communityName"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_user_community_name"
            labelText="Display name"
            tooltipText="Displayed user name"
            type="text"
            isLabelSideBySide
            {...field}
          />
        )}
      />

      {services?.includes('BONOBO') && (
        <Spacing size="2x-large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="isSocialAccessActive"
            render={({ field }) => (
              <ToggleSwitch
                id="id_user_is_social_access_active"
                isChecked={field.value}
                labelText="Forum | Social access"
                isLabelSideBySide
                {...field}
              />
            )}
            />
        </Spacing>
      )}
      
      {!isProduct && (
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="isTeamAdmin"
            render={({ field }) => (
              <ToggleSwitch
                id="id_user_team_admin_access"
                isChecked={field.value}
                isDisabled={isTeamAdmin}
                labelText="Team admin access"
                tooltipPosition="start"
                tooltipText="This will enable the selected user to access and manage features within the CMS. Only select this if the user is on your project team. Once selected, this cannot be undone."
                isLabelSideBySide
                onChange={() => {
                  setIsUpdateToAdminDialogOpen(true);
                  setIsTeamAdmin(true);
                  setValue('isTeamAdmin', true);
                }}
              />
            )}
          />
        </Spacing>
      )}
      {
        !isTeamAdmin && (
          <Dialog
            confirmText="Confirm"
            isOpen={isUpdateToAdminDialogOpen}
            title="Are you sure?"
            onCancel={cancelToggleElevateUserAsProjectAdmin}
            onClose={cancelToggleElevateUserAsProjectAdmin}
            onConfirm={toggleElevateUserAsProjectAdmin}
          >
            <>
              <p>Updating this user to a project admin will give them full access to the administration area and user information. This operation can not be undone, please confirm to continue.</p>
              
            </>
          </Dialog>

        )
      }
    </SidebarContainer>
  );
};

export { UserGeneralTab };
