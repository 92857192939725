import { FORM_FIELDS, IPasswordValidation } from '@netfront/gelada-identity-library';

import { IGetValidationErrorMessage } from './UpdatePasswordForm.interfaces';

const getValidationErrorMessage = ({ passwordValidation, prefix, value }: IGetValidationErrorMessage): string => {
  const {
    password: { maxLength: passwordMaxLength, minLength: passwordMinLength },
  } = FORM_FIELDS;

  const { maxLength: maxPasswordLength = passwordMaxLength, minLength: minPasswordLength = passwordMinLength } =
    passwordValidation ?? ({} as IPasswordValidation);

  if (!value) {
    return `${prefix} is required`;
  }

  if (value.length < minPasswordLength) {
    return `${prefix} must be at least ${minPasswordLength} characters`;
  }

  if (value.length > maxPasswordLength) {
    return `${prefix} must be less than ${maxPasswordLength} characters`;
  }

  return '';
};

export { getValidationErrorMessage };
