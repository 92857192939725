/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { FormFieldProps } from "@netfront/ui-library";
import { IVerifiedEmail } from "types";


const getVerifiedEmailDefaultValues = (verifiedEmail?: IVerifiedEmail): FormFieldProps => {
  const { displayName, email, replyTo } = verifiedEmail ?? {};

  return {
    displayName: displayName || '',
    email: email || '',
    replyTo: replyTo || '',
  };
};

export { getVerifiedEmailDefaultValues };
