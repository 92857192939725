import { Avatar, FlexContainer } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IAccountInvitationTableData } from './AccountInvitations.interfaces';




const ACCOUNT_INVITATION_TABLE_COLUMNS: ReadonlyArray<Column<IAccountInvitationTableData>> = [
  {
    accessor: 'avatar',
    Cell: ({ value : { firstName, fullName }}) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar
            size="small"
            title={fullName}
          />
        </div>

        <div className="flex items-center w-full">{firstName}</div>
      </FlexContainer>
    ),
    Header: <div className="text-left w-full">First name</div>,
    width: '22%',
  },
  {
    accessor: 'lastName',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Last name</div>,
    width: '19%',
  },
  {
    accessor: 'invitedUserEmail',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Email</div>,
    width: '27%',
  },
  {
    accessor: 'invitationEmailStatus',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Status</div>,
    width: '8%',
  },
  {
    accessor: 'created',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Invited</div>,
    width: '21%',
  },
  {
    accessor: 'settings',
    Cell: ({ value }) => <div className="flex justify-end">{value}</div>,
    Header: <span className="h-hide-visually">Settings</span>,
    width: '2%',
  },
];

export { ACCOUNT_INVITATION_TABLE_COLUMNS };
