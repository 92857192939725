import { DBNetfrontServiceType } from "@netfront/gelada-identity-library";
import { IOption } from "@netfront/ui-library";

const getServiceOptions = ({ isProduct, currentServices }: { currentServices: DBNetfrontServiceType[]; isProduct: boolean;}): IOption[] => {

  const serviceOptions = [
    {
      id: 'GELADA',
      name: 'Identity',
      subName: 'Gelada',
      isAvailable: true,
      isDisabled: currentServices.includes('GELADA'),
    },
    {
      id: 'EKARDO',
      name: 'Content',
      isAvailable: !isProduct,
      isDisabled: currentServices.includes('EKARDO'),
    },
    {
      id: 'HOWLER',
      name: 'Notifications',
      isAvailable: !isProduct,
      isDisabled: currentServices.includes('HOWLER'),
    },
    {
      id: 'BONOBO',
      name: 'Social media',
      isAvailable: !isProduct,
      isDisabled: currentServices.includes('BONOBO'),
    },
    // {
    //   id: 'BONOBO_MANAGEMENT',
    //   name: 'TBC',
    //   isAvailable: !isProduct,
    //   isDisabled: serviceItems.includes('BONOBO_MANAGEMENT'),
    // },
    {
      id: 'VERVET',
      name: 'Licensing',
      isAvailable: !isProduct,
      isDisabled: currentServices.includes('BONOBO_MANAGEMENT'),
    },
    {
      id: 'MURIQUI',
      name: 'Payments',
      isAvailable: true,
      isDisabled: currentServices.includes('MURIQUI'),
    },
    {
      id: 'CAPUCHIN',
      name: 'Scheduler',
      isAvailable: !isProduct,
      isDisabled: currentServices.includes('CAPUCHIN'),
    },
    {
      id: 'LEMUR',
      name: 'Orders',
      isAvailable: !isProduct,
      isDisabled: currentServices.includes('LEMUR'),
    },
    {
      id: 'BABOON',
      name: 'Integrations',
      isDisabled: currentServices.includes('BABOON'),
    },
  ];

  return serviceOptions.filter(( { isAvailable }) => isAvailable).map(({ id, name, isDisabled }) => ({
    id,
    name,
    value: id,
    isDisabled,
  }));

};

export { getServiceOptions };
