import React, { useEffect, useState } from 'react';

import { IDBNote, useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';
import { ButtonIconOnly, FlexContainer, InfoIcon, InformationBox, PlusIcon, Preloader, Spacing } from '@netfront/ui-library';

import { IGetGroupNotesResponse, UserGroupNotesTabProps } from './UserGroupNotesTab.interfaces';

import { SidebarListItem, SidebarList, SidebarContainer } from '../../../../components/Shared';
import { GET_GROUP_NOTES } from '../../../../graphql';
import { useToast } from '../../../../hooks';
import { NoteFormView } from '../../../UserManagementViews';

const UserGroupNotesTab = ({ selectedGroupId }: UserGroupNotesTabProps) => {
  const { handleToastError } = useToast();

  const [isAddNoteVisible, setIsAddNoteVisible] = useState<boolean>(false);
  const [groupNotes, setGroupNotes] = useState<IDBNote[]>([]);
  const [groupNote, setGroupNote] = useState<IDBNote>();

  const [getGroupNotes, { loading: isGetGroupNotesLoading }] = useLoggedGeladaLazyQuery<IGetGroupNotesResponse>({
    options: {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ note: { getByGroup: selectedGroupNotes } }) => {
        setGroupNotes(selectedGroupNotes);
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: GET_GROUP_NOTES,
  });

  const handleGetGroupNotes = (groupId: number) => {
    void getGroupNotes({
      variables: {
        groupId,
      },
    });
  };

  const handleClickNote = (noteId: number) => {
    setIsAddNoteVisible(!isAddNoteVisible);
    setGroupNote(groupNotes.find(({ id }) => Number(id) === noteId));
  };

  const handleToggleAddNoteVisibility = () => {
    setIsAddNoteVisible(!isAddNoteVisible);
    setGroupNote(undefined);
  };

  useEffect(() => {
    if (!selectedGroupId) {
      return;
    }

    handleGetGroupNotes(selectedGroupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId]);

  return (
    <SidebarContainer>
      <Preloader isLoading={isGetGroupNotesLoading} />

      {isAddNoteVisible && (
        <NoteFormView
          noteType="group"
          selectedEntityId={selectedGroupId}
          selectedNote={groupNote}
          onOpenUpdateNotes={handleToggleAddNoteVisibility}
          onUpdateNotes={() => handleGetGroupNotes(selectedGroupId)}
        />
      )}
      {!isAddNoteVisible && (
        <>
          {!groupNotes.length ? (
            <Spacing>
              <InformationBox icon={InfoIcon} message="There is no note provided for this group" />
            </Spacing>
          ) : (
            <main>
              <Spacing>
                <SidebarList itemsLength={groupNotes.length} text="notes" hasCount>
                  {groupNotes.map(({ id, subject }) => (
                    <SidebarListItem key={id} name={subject} supportiveText="Update the note" onClick={() => handleClickNote(Number(id))} />
                  ))}
                </SidebarList>
              </Spacing>
            </main>
          )}
          <FlexContainer justifyContent="end" tag="section">
            <ButtonIconOnly icon={PlusIcon} text="Add note" onClick={handleToggleAddNoteVisibility} />
          </FlexContainer>
        </>
      )}
    </SidebarContainer>
  );
};

export { UserGroupNotesTab };
