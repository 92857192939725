import React, { ChangeEvent, FormEvent, ReactNode, useEffect, useState } from 'react';

import { IDBAsset } from '@netfront/common-library';
import { IDBNoteAttachment, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import {
  ButtonIconOnly,
  DropzoneFileUpload,
  Input,
  NavigationButton,
  Preloader,
  Spacing,
  Textarea,
  SidebarButtons,
  InputFieldWrapper,
  XlsxIcon,
  DocXIcon,
  PdfIcon,
  HollowImageIcon,
  BinIcon,
  EnterIcon,
} from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { DOCUMENT_FILE_TYPES, NOTE_QUERY_STRING } from './NoteFormView.constants';
import { NoteFormViewProps } from './NoteFormView.interfaces';

import { DELETE_NOTE } from '../../../graphql';
import { useCreateNote, useToast, useUpdateNote } from '../../../hooks';
import { pushImageToAws } from '../../../utils';
import { DropzoneAssetType } from '../../Pages';

const NoteFormView = ({ noteType, onOpenUpdateNotes, onUpdateNotes, selectedEntityId, selectedNote }: NoteFormViewProps) => {

  const {
    query: { organisationKey: queryOrganisationKey },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const {
    attachments: selectedAttachments = [],
    id: noteId,
    message: selectedMessage = '',
    subject: selectedSubject = '',
  } = selectedNote ?? {};

  const { asset } = (selectedAttachments as IDBNoteAttachment[])[0] ?? {};

  const {
    contentType: selectedContentType = '',
    fileName: selectedFileName = '',
    fileSizeInBytes: selectedFileSizeInBytes,
    s3Key: selectedS3Key = '',
    presignedUrl = '',
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } = asset ?? {};

  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [attachmentIconId, setAttachmentIconId] = useState<string | null>(DOCUMENT_FILE_TYPES[selectedContentType]);
  const [contentType, setContentType] = useState<string>(String(selectedContentType));
  const [droppedFile, setDroppedFile] = useState<File | null>();
  const [fileName, setFileName] = useState<string>(String(selectedFileName));
  const [filePreview, setFilePreview] = useState<string>(String(presignedUrl));
  const [fileSizeInBytes, setFileSizeInBytes] = useState<number>(Number(selectedFileSizeInBytes));
  const [fileType, setFileType] = useState<DropzoneAssetType>('DOCUMENT');
  const [isDroppedFile, setIsDroppedFile] = useState<boolean>(false);
  const [isExistingAttachment, setIsExistingAttachment] = useState<boolean>(Boolean(selectedS3Key));
  const [message, setMessage] = useState<string>(selectedMessage);
  const [subject, setSubject] = useState<string>(selectedSubject);

  const { handleCreateNote, isLoading: isAddNoteLoading = false } = useCreateNote({
    onCompleted: ({ note }) => {
      const { attachments } = note;
      attachments.map((attachment: { asset: IDBAsset }) => {
        const {
          asset: { presignedUrl: returnedPresignedUrl, presignedPutUrl: returnedPresignedPutUrl },
        } = attachment;
        void pushImageToAws(String(returnedPresignedPutUrl), droppedFile ?? null, () => {
          setFilePreview(String(returnedPresignedUrl));
        });
      });

      onUpdateNotes();
      onOpenUpdateNotes();
      handleToastSuccess({
        message: 'Note added successfully!',
      });

      setIsDroppedFile(false);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    mutation: NOTE_QUERY_STRING[noteType],
  });

  const [deleteNote, { loading: isDeleteNoteLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: 'Note deleted successfully!',
        });

        onUpdateNotes();
        onOpenUpdateNotes();
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: DELETE_NOTE,
  });

  const { handleUpdateNote, isLoading: isUpdateNoteLoading = false } = useUpdateNote({
    onCompleted: ({ note }) => {
      const { attachments } = note;
      attachments.map((attachment: { asset: IDBAsset }) => {
        const {
          asset: { presignedUrl: returnedPresignedUrl, presignedPutUrl: returnedPresignedPutUrl },
        } = attachment;
        void pushImageToAws(String(returnedPresignedPutUrl), droppedFile ?? null, () => {
          setFilePreview(String(returnedPresignedUrl));
        });
      });

      onUpdateNotes();
      onOpenUpdateNotes();
      handleToastSuccess({
        message: 'Note updated successfully!',
      });

      setIsDroppedFile(false);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleDeleteNote = () => {
    void deleteNote({
      variables: {
        noteId,
      },
    });
  };

  /* eslint-disable @typescript-eslint/no-floating-promises */
  const handleAddNote = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const attachments = [
      {
        contentType,
        fileName,
        fileSizeInBytes,
        type: fileType,
      },
    ];

    if (selectedNote) {
      void handleUpdateNote({
        attachments: droppedFile ? attachments : [],
        noteId: Number(noteId),
        subject,
        message,
      });
    } else {
      handleCreateNote({
        attachments: droppedFile ? attachments : [],
        groupId: selectedEntityId,
        userId: selectedEntityId,
        subject,
        message,
      });
    }
  };

  const handleDropFile = (acceptedFiles: File[]) => {
    const [file] = acceptedFiles;
    const { name, type, size } = file;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setAttachmentIconId(DOCUMENT_FILE_TYPES[type]);
    setContentType(String(type));
    setDroppedFile(file);
    setFileName(String(name));
    setFileSizeInBytes(Number(size));
    setFileType('DOCUMENT');
    setIsDroppedFile(true);
  };

  const handleDeleteAttachment = () => {
    setIsDroppedFile(false);
    setIsExistingAttachment(false);
  };

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);

  const isLoading = isAddNoteLoading || isDeleteNoteLoading || isUpdateNoteLoading;

  const DOCUMENT_FILE_TYPES_ICON: { [key: string]: ReactNode} = Object.freeze({
    'id_xlsx_icon': <XlsxIcon className="c-icon mr-2 w-8 h-8" />,
    'id_docx_icon': <DocXIcon className="c-icon mr-2 w-8 h-8" />,
    'id_pdf_icon': <PdfIcon className="c-icon mr-2 w-8 h-8" />,
    'id_image_hollow_icon': <HollowImageIcon className="c-icon mr-2 w-8 h-8" />,
  });
  
  return (
    <>
      <Preloader isLoading={isLoading} />

      <Spacing size="x-large">
        <NavigationButton
          additionalClassNames="c-sidebar-navigation-button"
          direction="previous"
          icon={EnterIcon}
          rotationCssSuffix="180"
          text="Back to notes"
          onClick={onOpenUpdateNotes}
        />
      </Spacing>

      <form onSubmit={handleAddNote}>
        <Spacing size="x-large">
          <Input
            id="subject"
            labelText="Name"
            name="subject"
            type="text"
            value={subject}
            isLabelSideBySide
            isRequired
            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => setSubject(value)}
          />
        </Spacing>

        <Spacing size="x-large">
          <Textarea
            id="message"
            labelText="Note"
            name="message"
            value={message}
            isLabelSideBySide
            isRequired
            onChange={({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => setMessage(value)}
          />
        </Spacing>

        <InputFieldWrapper
          id="id_note_attachments"
          isLabelHidden={false}
          label="Note attachment"
          tooltipText="Attach an optional file or picture with this note."
          type="custom"
          isLabelSideBySide
        >
          <>
            {isDroppedFile || isExistingAttachment ? (
              <>
                <div className="flex items-center py-1 px-2">
                  <a className="flex items-center" href={filePreview} rel="noreferrer" target="_blank">
                    {attachmentIconId && (
                      <>
                        {DOCUMENT_FILE_TYPES_ICON[attachmentIconId]}
                      </>
                    )}{' '}
                    {fileName}
                  </a>
                  <ButtonIconOnly
                    additionalClassNames="border-none absolute right-0"
                    icon={BinIcon}
                    text="remove user"
                    onClick={handleDeleteAttachment}
                  />
                </div>
              </>
            ) : (
              <>{!organisationKey && <DropzoneFileUpload labelText="" onDrop={handleDropFile} />}</>
            )}
          </>
        </InputFieldWrapper>

        <SidebarButtons
          buttonSize="xs"
          onCancel={onOpenUpdateNotes}
          onDelete={selectedNote ? handleDeleteNote : undefined}
          onSaveButtonType="submit"
        />
      </form>
    </>
  );
};

export { NoteFormView };
