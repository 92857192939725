import { useState, useEffect, useContext } from 'react';

import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { useGetProduct } from '@netfront/gelada-identity-library';
import {
  Input,
  Textarea,
  SelectWithSearch,
  Spacing,
  ToggleSwitch,
  PasswordInput,
  RadioButtonGroup,
  FormFieldProps,
} from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';

import { SidebarContainer } from 'components/Shared';

import { PERMISSIONS_ITEMS } from './InvitationsGeneralTab.constants';
import { InvitationsGeneralTabProps } from './InvitationsGeneralTab.interfaces';

import { CountryCodeInput } from '../../../../components';
import { CachingEntitiesContext } from '../../../../context';

const InvitationsGeneralTab = ({
  control,
  isUpdate = false,
  groupDropdownListOptions = [],
  allUserTypes = [],
  countryCodeOptions = [],
  hasGroups = true,
  setValue,
  initialIsRequiresActivation = true,
  initialIsTeamAdmin = false,
}: InvitationsGeneralTabProps) => {
  const { getProduct } = useGetProduct();
  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  const { project, isProduct } = useContext(CachingEntitiesContext);
  const { services } = project ?? {};

  const [isGroupSearchContentVisible, setSearchIsGroupContentVisible] = useState(false);
  const [isUserTypeSearchContentVisible, setIsUserTypeSearchContentVisible] = useState(false);

  const [isRequiresActivation, setIsRequiresActivation] = useState<boolean>(initialIsRequiresActivation);
  const [isTeamAdmin, setIsTeamAdmin] = useState<boolean>(initialIsTeamAdmin);

  useEffect(() => {
    setIsRequiresActivation(initialIsRequiresActivation);
  }, [initialIsRequiresActivation]);

  useEffect(() => {
    setIsTeamAdmin(initialIsTeamAdmin);
  }, [initialIsTeamAdmin]);

  return (
    <SidebarContainer>
      <Controller
        control={control as Control<FormFieldProps>}
        name="firstName"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_invitation_first_name"
            isDisabled={isUpdate}
            labelText="First name"
            type="text"
            isLabelSideBySide
            isRequired
            {...field}
          />
        )}
      />
      <Controller
        control={control as Control<FormFieldProps>}
        name="lastName"
        render={({ field, fieldState }) => (
          <Input
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_invitation_last_name"
            isDisabled={isUpdate}
            labelText="Last name"
            type="text"
            isLabelSideBySide
            isRequired
            {...field}
          />
        )}
      />

      <Controller
        control={control as Control<FormFieldProps>}
        name="emailUserInvited"
        render={({ field, fieldState }) => (
          <Input
            autoComplete="email"
            errorMessage={getFormFieldErrorMessage(fieldState)}
            id="id_invitation_email_user_invited"
            isDisabled={isUpdate}
            labelText="Email"
            type="email"
            isLabelSideBySide
            isRequired
            {...field}
          />
        )}
      />

      <CountryCodeInput 
        codesOptions={countryCodeOptions} 
        control={control}
        countryCodeName="phoneNumberCountryCode"
        id="id_invitation_phone_number"
        isDisabled={isUpdate}
        phoneNumberName='phoneNumber'
      />
      
      <section>
        {!isUpdate && hasGroups && !isProduct && (
          <Spacing>
            <Controller
              control={control as Control<FormFieldProps>}
              name="isTeamAdmin"
              render={({ field }) => (
                <ToggleSwitch
                  id="id_invitation_team_admin_access"
                  isChecked={field.value}
                  labelText="Team admin access"
                  name={field.name}
                  tooltipPosition="start"
                  tooltipText="This will enable the selected user to access and manage features within the CMS. Only select this if the user is on your project team"
                  isLabelSideBySide
                  onChange={(event) => {
                    if (!isTeamAdmin) {
                      setIsRequiresActivation(true);
                      setValue('isRequiresActivation', true);
                      setValue('groupId', '');
                      setValue('userTypeId', '');
                      setValue('userTypeName', '');
                    } else {
                      setIsRequiresActivation(false);
                      setValue('isRequiresActivation', false);
                      setValue('permission', 'READ');
                    }
                    field.onChange(event)
                    const { target: { checked: isChecked }} = event;
                    setIsTeamAdmin(isChecked);
                  }}
                />
              )}
            />
          </Spacing>
        )}

        {!isTeamAdmin && (
          <>
            <Controller
              control={control as Control<FormFieldProps>}
              name="groupId"
              render={({ field }) => (
                <SelectWithSearch
                  additionalClassNames="c-select-with-search__users-table"
                  buttonText={groupDropdownListOptions.find(({ id }) => Number(id) === Number(field.value))?.label ?? 'All groups'}
                  id="id_invitation_groups"
                  isDisplaySearchContent={isGroupSearchContentVisible}
                  isDropdownDisabled={isUpdate}
                  isLabelHidden={false}
                  labelText="Default Group"
                  searchList={groupDropdownListOptions}
                  tooltipPosition="start"
                  tooltipText="This user can belong to one or many groups, please select their default group now. Groups help filter and define different functionalities for users within your project"
                  isAvatarVisible
                  isLabelSideBySide
                  isRequired
                  onDisplaySearchContent={() => setSearchIsGroupContentVisible(!isGroupSearchContentVisible)}
                  onSearchItemClick={(id: string | number) => setValue('groupId', id)}
                />
              )}
            />

            <Controller
              control={control as Control<FormFieldProps>}
              name="userTypeName"
              render={({ field }) => (
                <SelectWithSearch
                  additionalClassNames="c-select-with-search__users-table"
                  buttonText={field.value}
                  id="id_invitation_userTypes"
                  isDisplaySearchContent={isUserTypeSearchContentVisible}
                  isDropdownDisabled={isUpdate}
                  isLabelHidden={false}
                  labelText="Default role"
                  searchList={allUserTypes}
                  tooltipPosition="start"
                  tooltipText="This user needs to be assigned to role, this helps assign permissions and access to specific features in your application"
                  isAvatarVisible
                  isLabelSideBySide
                  isRequired
                  onDisplaySearchContent={() => setIsUserTypeSearchContentVisible(!isUserTypeSearchContentVisible)}
                  onSearchItemClick={(id: string | number) => {
                    setValue('userTypeId', id);
                    setValue('userTypeName', allUserTypes.find(({ id: userTypeId }) => id  === userTypeId)?.label ?? 'All user roles');
                  }}
                />
              )}
            />
          </>
        )}

        

        {!isUpdate && services?.includes('BONOBO') && !isTeamAdmin && hasGroups && (
          <Spacing size="large">
            <Controller
              control={control as Control<FormFieldProps>}
              name="isSocialAccessActive"
              render={({ field }) => (
                <ToggleSwitch
                  id="id_invitation_is_social_access_active"
                  isChecked={field.value}
                  labelText="Forum | Social access"
                  isLabelSideBySide
                  {...field}
                />
              )}
            />
          </Spacing>
        )}
      </section>

      {!isUpdate && !isTeamAdmin && (
        <Spacing size="large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="isRequiresActivation"
            render={({ field }) => (
              <ToggleSwitch
                id="id_invitation_requires_activation"
                isChecked={field.value}
                labelText="Requires activation"
                name={field.name}
                isLabelSideBySide
                onChange={(event) => {
                  const { target: { checked: isChecked}} = event;
                  field.onChange(event);
                  setIsRequiresActivation(isChecked);
                }}
              />
            )}
          />
        </Spacing>
      )}

      {!isUpdate && isTeamAdmin && (
        <Spacing size="large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="permissions"
            render={({ field }) => (
              <RadioButtonGroup
                items={PERMISSIONS_ITEMS.filter((r) => !r.product || r.product === getProduct())}
                selectedValue={field.value}
                title="Permissions"
                isLabelSideBySide
                {...field}
              />
            )}
          />
        </Spacing>
      )}

      {isRequiresActivation && (
        <Spacing size="large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="customMessage"
            render={({ field }) => (
              <Textarea
                id="id_invitation_custom-message"
                isDisabled={isUpdate}
                labelText="Invite message"
                isLabelSideBySide
                {...field}
              />
            )}
          />
        </Spacing>
      )}

      {!isRequiresActivation && (
        <Spacing size="large">
          <Controller
            control={control as Control<FormFieldProps>}
            name="password"
            render={({ field }) => (
              <PasswordInput 
                id="id_invitation_password"
                labelText="Password" 
                isLabelSideBySide 
                {...field}
                />
            )}
          />
        </Spacing>
      )}
    </SidebarContainer>
  );
};

export { InvitationsGeneralTab };
