import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, PasswordInput, Spacing } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getFormFieldErrorMessage } from '../Forms.helpers';

import { ACCEPT_INVITATION_FORM_INITIAL_VALUES, ACCEPT_INVITATION_FORM_CSS_IDENTIFIERS } from './AcceptInvitationForm.constants';
import { IAcceptInvitationFormFields, AcceptInvitationFormProps } from './AcceptInvitationForm.interfaces';

const AcceptInvitationForm = ({ buttonText = 'Next', isSubmitting, onSubmit }: AcceptInvitationFormProps) => {
  const { button: buttonElementCssId, container: containerElementCssId, form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    confirmPassword: { id: confirmPasswordId, label: confirmPasswordLabel, validationErrorMessage: confirmPasswordValidationErrorMessage },
    password: { id, label, maxLength, minLength },
  } = FORM_FIELDS;

  const { block: blockCssId } = ACCEPT_INVITATION_FORM_CSS_IDENTIFIERS;

  const { control, handleSubmit } = useForm<IAcceptInvitationFormFields>({
    defaultValues: ACCEPT_INVITATION_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        password: yup.string().label(label).min(minLength).max(maxLength).required(),
        confirmPassword: yup
          .string()
          .label(confirmPasswordLabel)
          .min(minLength)
          .max(maxLength)
          .oneOf([yup.ref('password'), null], confirmPasswordValidationErrorMessage)
          .required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  return (
    <form
      className={getBEMClassName(blockCssId, formElementCssId)}
      onSubmit={(event) => {
        void handleSubmit(({ password, confirmPassword }) => {
          onSubmit({
            password,
            confirmPassword,
          });
        })(event);
      }}
    >
      <div className="c-form__body">
        <Spacing size="small">
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState }) => (
              <PasswordInput
                additionalClassNames={getBEMClassName(blockCssId, confirmPasswordId)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={id}
                isDisabled={isSubmitting}
                labelText={label}
                placeholder=""
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing size="small">
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field, fieldState }) => (
              <PasswordInput
                additionalClassNames={getBEMClassName(blockCssId, id)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={id}
                isDisabled={isSubmitting}
                labelText={confirmPasswordLabel}
                placeholder=""
                {...field}
              />
            )}
          />
        </Spacing>
      </div>

      <div className="c-form__footer">
        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          <Button size="small" text={buttonText} type="submit" />
        </div>
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-default-export
export { AcceptInvitationForm };
