import { gql } from '@apollo/client';

export const ADD_USER_NOTE = gql`
  mutation AddUserNote($userId: Int!, $subject: String!, $message: String!, $attachments: [CreateNoteAttachmentAssetInputType]) {
    note {
      addUserNote(userId: $userId, subject: $subject, message: $message, attachments: $attachments) {
        id
        message
        subject
        attachments {
          asset {
            contentType
            fileName
            fileSizeInBytes
            contentType
            s3Key
            presignedUrl
            presignedPutUrl
          }
          assetId
        }
      }
    }
  }
`;
