
const BULK_ACTION_DIALOG_TITLE_MAP: {[key: string]: string} = Object.freeze({
  removeUsers: 'Bulk delete users',
  removeFromGroup: 'Bulk remove users from a group',
  deactivateUsers: 'Bulk deactivate users',
  deleteInvitations: 'Bulk delete invitations',
  resendInvitations: 'Bulk resend invitations',
});


const BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP: { [key: string]: string} = Object.freeze({
  removeUsers: 'Delete',
  removeFromGroup: 'Remove',
  deactivateUsers: 'Deactivate',
  deleteInvitations: 'Delete',
  resendInvitations: 'Resend',
});

export { BULK_ACTION_DIALOG_TITLE_MAP, BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP };
