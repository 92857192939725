import { Avatar, FlexContainer } from '@netfront/ui-library';
import { Column } from 'react-table';


import { IProjectGroupRequestData } from './ProjectGroupRequests.interfaces';

import { getDayMonthYear } from '../../../../utils';

const PROJECT_GROUP_REQUEST_STATUS_TYPE = Object.freeze({
  REQUEST: 'request',
});

const PROJECT_GROUP_REQUESTS_TABLE_COLUMNS: ReadonlyArray<Column<IProjectGroupRequestData>> = [
  {
    accessor: 'avatar',
    Cell: ({ value: { name } }) => (
      <FlexContainer gap="x-small">
        <div className="c-avatar-title relative">
          <Avatar size="small" title={name} />
        </div>
        <div className="text-left w-full word-break-all">{name}</div>
      </FlexContainer>
    ),
    Header: () => <div className="text-left w-full">Title</div>,
    width: '50%',
  },
  {
    accessor: 'creationDate',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{getDayMonthYear(value.toString())}</div>,
    Header: () => <div className="text-left w-full">Requested date</div>,
    width: '24%',
  },
  {
    accessor: 'status',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Status</div>,
    width: '24%',
  },
  {
    accessor: 'settings',
    Cell: ({ value }) => <div className="flex justify-end">{value}</div>,
    width: '2%',
    Header: <span className="h-hide-visually">Settings</span>,
  },
];

export { PROJECT_GROUP_REQUESTS_TABLE_COLUMNS, PROJECT_GROUP_REQUEST_STATUS_TYPE };
