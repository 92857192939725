
import { IGetProjectPlaceholdersTableDataParams, IProjectPlaceholdersTableData } from './ProjectPlaceholders.interfaces';


const getProjectPlaceholdersTableData = ({ placeholders, onSettingsButtonClick }: IGetProjectPlaceholdersTableDataParams): IProjectPlaceholdersTableData[] => {
  return placeholders.map((item): IProjectPlaceholdersTableData => {
    const { id, key, value } = item;
    return {
      id,
      value,
      key,
      settingsButtonData: {
        item,
        onClick: onSettingsButtonClick,
      },
    };
  });
};

export { getProjectPlaceholdersTableData };
