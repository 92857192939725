import React from 'react';

import { Input } from '@netfront/ui-library';

import { SidebarContainer } from 'components/Shared';

import { GroupRequestGeneralTabProps } from './GroupRequestGeneralTab.interfaces';

const GroupRequestGeneralTab = ({ selectedGroupRequest }: GroupRequestGeneralTabProps) => {
  const { name, address, city, state, postCode, website, phoneNumber } = selectedGroupRequest ?? {};

  const noOP = () => {
    return;
  };

  return (
    <SidebarContainer>
      <Input id="name" labelText="Name" name="name" type="text" value={name} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="address" labelText="Address" name="address" type="text" value={address} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="city" labelText="City" name="city" type="text" value={city} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="state" labelText="State" name="state" type="text" value={state} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="postCode" labelText="Post code" name="postCode" type="text" value={postCode} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="website" labelText="Website" name="website" type="text" value={website} isDisabled isLabelSideBySide onChange={noOP} />
      <Input id="phoneNumber" labelText="Phone" name="phoneNumber" type="text" value={phoneNumber} isDisabled isLabelSideBySide onChange={noOP} />
    </SidebarContainer>
  );
};

export { GroupRequestGeneralTab };
