import { RolesBulkActionType } from "./RolesBulkActionDialog.types";

const ROLES_BULK_ACTION_DIALOG_TITLE_MAP: Record<RolesBulkActionType, string> = Object.freeze({
  activateRoles: 'Bulk activate roles',
  deactivateRoles: 'Bulk deactivate roles',
  deleteRoles: 'Bulk delete roles',
});


const ROLES_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP: Record<RolesBulkActionType, string> = Object.freeze({
  activateRoles: 'Activate',
  deactivateRoles: 'Deactivate',
  deleteRoles: 'Delete',
});

export { ROLES_BULK_ACTION_DIALOG_TITLE_MAP, ROLES_BULK_ACTION_DIALOG_CONFIRM_BUTTON_MAP };
