import { gql } from '@apollo/client';

export const GET_CUSTOM_FIELDS = gql`
  query getCustomFields($projectId: String!, $scope: ECustomFieldScope!, $userId: Int, $userTypeId: Int, $groupId: Int) {
    customField {
      getCustomFields(projectId: $projectId, scope: $scope, userTypeId: $userTypeId) {
        id
        name
        __typename
        responses(userId: $userId, groupId: $groupId) {
          id
          ... on CustomFieldResponseNumberGraphType {
            number
          }
          ... on CustomFieldResponseTextGraphType {
            text
          }
          ... on CustomFieldResponseDateGraphType {
            dateTime
          }
          ... on CustomFieldResponseBooleanGraphType{
            value
          }
        }
      }
    }
  }
`;
