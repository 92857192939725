import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Breadcrumbs, ButtonIconOnly, ControlledForm, FlexContainer, FormFieldProps, HomeIcon, Message, PlusIcon, SidebarButtons, Spacing, useControlledForm } from '@netfront/ui-library';
import { Control, Controller } from 'react-hook-form';
import { generateRandomId } from 'utils';
import * as yup from 'yup';


import { getFormFieldErrorMessage } from 'components/Forms';
import { SidebarContainer } from 'components/Shared';

import { IResponseGroupItem, ResponseSetItem } from '../ResponseSetItem';

import { CreateResponseSetProps } from './CreateResponseSet.interfaces';



const CreateResponseSet = ({ onCancel, onSave, getValues, isOpen = false }: CreateResponseSetProps) => {
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        responseGroup: yup.array().required().min(1, 'Please provide at least one option'),
      }),
    ),
  });


  const handleSave = (item: FormFieldProps) => {
    const { responseGroup = []} = item;
    
    const formattedResponseGroup = (responseGroup as IResponseGroupItem[]).map(({ id, tempId, value }) => ({
      value,
      id,
      tempId,
    }));

    reset();
    onSave(formattedResponseGroup);

  };

  const upsertResponseGroupItem = ({ item, responseGroup } : {item: IResponseGroupItem, responseGroup: IResponseGroupItem[]}) => {
    const editedResponseGroup = responseGroup.map((currentItem) => {
      if (currentItem.tempId === item.tempId) {
        return {
          ...currentItem,
          ...item,
          isEditableOnLoad: false,
        };
      }
      return currentItem;
    });

    setValue('responseGroup', editedResponseGroup);
  };

  const removeResponseGroupItem = (id: string, responseGroup: IResponseGroupItem[]) => {
    const editedResponseGroup = responseGroup.filter(({tempId: currentItemId}) => currentItemId !== id);
    setValue('responseGroup', editedResponseGroup);
  };

  const handleAddNewResponse = (responseGroup: IResponseGroupItem[]) => {
    const res = [
      ...responseGroup,
      {
        value: '',
        tempId: generateRandomId(),
        isEditableOnLoad: true,
      },
    ];

    setValue('responseGroup', res);
  };

  useEffect(() => {
    const { availableOptions = []} = getValues();
    setDefaultValues({
      responseGroup: availableOptions.length > 0 ? availableOptions : [
        {
          tempId: generateRandomId(),
          value: '',
          isEditableOnLoad: true,
        }
      ] as IResponseGroupItem[],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);


  return (
    <SidebarContainer hasPaddingEnds={false}>
      <Spacing size="2x-small">
        <Breadcrumbs
          itemDivider="chevron"
          items={[
            {
              key: 'question',
              content: 'Add question',
              icon: HomeIcon,
              onClick: onCancel,
            },
            {
              key: 'response_set',
              content: <span className="c-breadcrumb-item__link">Manage responses</span>,
            }
          ]}
          isContained
        />
      </Spacing>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        {defaultValues && (
          <>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="responseGroup"
                render={({ field, fieldState }) => {
                  const errorMessage = getFormFieldErrorMessage(fieldState);
                  return (
                    <>
                      {field.value && (
                        <>
                          {(field.value as IResponseGroupItem[]).map((item, index) => (
                            <ResponseSetItem
                              key={item.tempId}
                              handleDeleteResponseItem={(id: string) => removeResponseGroupItem(id, field.value as IResponseGroupItem[])}
                              handleUpsertResponseItem={(currentItem: IResponseGroupItem) => upsertResponseGroupItem({item: currentItem, responseGroup: field.value as IResponseGroupItem[]})}
                              index={item.tempId}
                              isLabelHidden={index !== 0}
                              item={item}
                            />
                          ))}
                        </>
                      )}

                      {Boolean(errorMessage) && <Message id={`id_response_group_error`} text={errorMessage} type="ERROR" />}
                      <FlexContainer justifyContent="end">
                        <ButtonIconOnly icon={PlusIcon} text="Add Response" onClick={() => handleAddNewResponse(field.value as IResponseGroupItem[])} />
                      </FlexContainer>
                    </>
                  );
                }}
              />
            </Spacing>

            <SidebarButtons
              onCancel={onCancel}
              onSaveButtonType="submit"
            />
          </>
        )}


      </ControlledForm>
    </SidebarContainer>
  );
};

export { CreateResponseSet };
