import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, Input, PasswordInput, Spacing } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { getFormFieldErrorMessage } from '../Forms.helpers';

import { ACCEPT_INVITATION_WITH_LOGIN_FORM_CSS_IDENTIFIERS } from './AcceptInvitationWithLoginForm.constants';
import { IAcceptInvitationWithLoginFormFields, AcceptInvitationWithLoginFormProps } from './AcceptInvitationWithLoginForm.interfaces';

const AcceptInvitationWithLoginForm = ({ buttonText = 'Next', isSubmitting, onSubmit, invitation }: AcceptInvitationWithLoginFormProps) => {
  const { button: buttonElementCssId, container: containerElementCssId, form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    password: { id, label, maxLength, minLength },
    email: { id: emailLabelId, label: emailLabel, placeholder: emailPlaceholder },
  } = FORM_FIELDS;

  const { block: blockCssId } = ACCEPT_INVITATION_WITH_LOGIN_FORM_CSS_IDENTIFIERS;

  const { control, handleSubmit } = useForm<IAcceptInvitationWithLoginFormFields>({
    defaultValues: {
      email: invitation.emailUserInvited,
      password: '',
    },
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().label(emailLabel).required(),
        password: yup.string().label(label).min(minLength).max(maxLength).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  return (
    <form
      className={getBEMClassName(blockCssId, formElementCssId)}
      onSubmit={(event) => {
        void handleSubmit(({ password, email }) => {
          onSubmit({
            password,
            email,
          });
        })(event);
      }}
    >
      <div className="c-form__body">
        <Spacing size="small">
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <Input
                additionalClassNames={getBEMClassName(blockCssId, id)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={emailLabelId}
                isDisabled={true}
                labelText={emailLabel}
                placeholder={emailPlaceholder}
                type="email"
                {...field}
              />
            )}
          />
        </Spacing>
        <Spacing size="small">
          <Controller
            control={control}
            name="password"
            render={({ field, fieldState }) => (
              <PasswordInput
                additionalClassNames={getBEMClassName(blockCssId, id)}
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id={id}
                isDisabled={isSubmitting}
                labelText={label}
                placeholder=""
                {...field}
              />
            )}
          />
        </Spacing>
      </div>

      <div className="c-form__footer">
        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          <Button text={buttonText} type="submit" />
        </div>
      </div>
    </form>
  );
};

// eslint-disable-next-line import/no-default-export
export { AcceptInvitationWithLoginForm };
