import { DBUserFlowAccessModeType } from '@netfront/ekardo-content-library';

export const GROUP_STATUS: { [key: string]: boolean } = Object.freeze({
  ACTIVE: true,
  INACTIVE: false,
});

export const MODE_INDEX_TYPES: Record<DBUserFlowAccessModeType, number> = Object.freeze({
  SEQUENTIAL: 0,
  BROWSING: 1,
});

export const MODE_TYPES: Record<string, DBUserFlowAccessModeType> = Object.freeze({
  0: 'SEQUENTIAL',
  1: 'BROWSING',
});

export const MODE_SELECT_TYPES = ['Sequential', 'Browsing'] as const;
