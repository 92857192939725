import { IBreadcrumbItem } from '@netfront/ui-library';

import { BUTTON_CLASSES, LinkButton } from '../../Shared/LinkButton';


const BREADCRUMB_ITEMS: IBreadcrumbItem[] = [
  {
    key: '0',
    content: <LinkButton additionalClassNames={`${BUTTON_CLASSES['subtle-link']} color-black`} text="Dashboard" url="/dashboard" />,
  },
  {
    key: '1',
    content: <span>{'Account'}</span>,
  },
];

const PAGE_TITLE = 'Account';

const ACCOUNT_MANAGEMENT_PAGE_CONSTANTS = Object.freeze({
  breadcrumbItems: BREADCRUMB_ITEMS,
  pageTitle: PAGE_TITLE,
  defaultPageSize: 10,
});

export { ACCOUNT_MANAGEMENT_PAGE_CONSTANTS };
