import React, { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getApolloErrorMessages, ICookieOptionalAttributesInput, useCookie } from '@netfront/common-library';
import {
  convertDBUser,
  DEFAULT_STORAGE_EXPIRY_OPTION,
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  IDBGeladaProject,
  useActivateAccount,
  useDomain,
  useGetGeladaProjectByDomain,
  useGetProduct,
} from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { useRouter } from 'next/router';
import { capitalize, getSingularOrgProjectRedirectUrl, useLoginRedirect } from 'utils';

import styles from './ActivationPage.module.css';

import { Layout } from '../../../components';
import { CachingEntitiesContext, UserContext } from '../../../context';
import { useCodeQuerystringValidation, useReturnUrlQuerystringValidation, useToast } from '../../../hooks';

const ActivationPage = () => {
  const { code } = useCodeQuerystringValidation();
  const { getDomain, isDomainReady } = useDomain();
  const {
    createAccessTokenCookie,
    createCustomBuildProjectGuidCookie,
    createOrganisationIdCookie,
    createProjectGuidCookie,
    createProjectLogoUrlCookie,
    createProjectNameCookie,
    createRedirectAfterLoginUrlCookie,
    createRefreshTokenCookie,
    createUserDataCookie,
    isSecureCookie,
    createProjectDomainCookie,
    getProjectGuidCookie,
  } = useCookie();
  const { getProduct } = useGetProduct();
  const { returnUrl } = useReturnUrlQuerystringValidation();
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const { setStoredUser } = useContext(UserContext);
  const { dashboardUrl: storedDashboardUrl, refreshProjectDetails } = useContext(CachingEntitiesContext);
  const { handleLoginRedirect } = useLoginRedirect();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [hasAccountActivationCompleted, setHasAccountActivationCompleted] = useState<boolean>(false);
  const [hasAccountActivationStarted, setHasAccountActivationStarted] = useState<boolean>(false);
  const [project, setProject] = useState<IDBGeladaProject>();
  const [projectName, setProjectName] = useState('');
  const [redirectUrl, setRedirectUrl] = useState<string>();
  const [shouldRenderPage, setShouldRenderPage] = useState<boolean>(false);
  const [projectWebsiteUrl, setProjectWebsiteUrl] = useState<string>();
  const [redirectIfSingularOrgProjectUrl, setRedirectIfSingularOrgProjectUrl] = useState<string>('');
  const [redirectAfterLoginUrl, setRedirectAfterLoginUrl] = useState<string>();
  const [dashboardUrl, setDashboardUrl] = useState<string>('');
  const [isProjectAdmin, setIsProjectAdmin] = useState<boolean>(false);
  const [isProjectCustomBuild, setIsProjectCustomBuild] = useState<boolean>(false);
  const [hasActivatedAccount, setHasActivatedAccount] = useState<boolean>(false);

  const { handleGetGeladaProjectByDomain } = useGetGeladaProjectByDomain({
    onCompleted: ({ geladaProject }) => {
      setProject(geladaProject);
      const { domain: projectDomain, isCustomBuild, id, logo, name, organisationId } = geladaProject;
      const { redirectAfterLogin, website, value: projectDomainValue } = projectDomain ?? {};
      const { presignedUrl } = logo ?? {};

      if (returnUrl) setRedirectUrl(returnUrl);
      if (website)  setProjectWebsiteUrl(website);
      if (redirectAfterLogin) setRedirectAfterLoginUrl(redirectAfterLogin);
      setIsProjectCustomBuild(isCustomBuild);


      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      const optionalCookieAttributesInput: ICookieOptionalAttributesInput = {
        domain,
        secure: isSecure,
        expiryOptions: {
          storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
        }
      };

      if (isCustomBuild) {
        createCustomBuildProjectGuidCookie({
          optionalCookieAttributesInput,
          value: id,
        });
      }

      if (projectDomainValue) {
        createProjectDomainCookie({
          optionalCookieAttributesInput,
          value: projectDomainValue,
        });
      }

      createOrganisationIdCookie({
        optionalCookieAttributesInput,
        value: String(organisationId),
      });

      createProjectGuidCookie({
        optionalCookieAttributesInput,
        value: id,
      });

      if (presignedUrl) {
        createProjectLogoUrlCookie({
          optionalCookieAttributesInput,
          value: encodeURIComponent(presignedUrl),
        });
      }

      createProjectNameCookie({
        optionalCookieAttributesInput,
        value: name,
      });


      if (redirectAfterLogin) {
        createRedirectAfterLoginUrlCookie({
          optionalCookieAttributesInput,
          value: encodeURIComponent(redirectAfterLogin),
        });
      }

      refreshProjectDetails();


      if (!code) {
        return;
      }

      void handleActivateAccount({
        activationCode: code,
        shouldIncludeUserMemberships: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleActivateAccount, isLoading: isActivateAccountLoading = false } = useActivateAccount({
    onCompleted: ({ loginToken, accessToken, refreshToken, user }) => {
      if (!loginToken) {
        return;
      }

      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      if (accessToken) {
        // The createXXXCookie functions all validate that a cookie doesn't exceed the maximum cookie size
        // We only expect the access token to potentially exceed the maximum cookie size hence this is the
        // only function that will be wrapped inside a try/catch block

        try {
          createAccessTokenCookie({
            optionalCookieAttributesInput: {
              domain,
              secure: isSecure,
              expiryOptions: {
                storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
              }
            },
            value: accessToken,
          });
        } catch (error) {
          handleToastError({
            error: error as Error,
            shouldUseFriendlyErrorMessage: true,
          });

          return;
        }
      }

      if (refreshToken) {
        createRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
            }
          },
          value: refreshToken,
        });
      }

      if (user) {
        const updatedUser = convertDBUser(user);

        const {
          credential: { email },
          firstName,
          id,
          lastName,
          memberships
        } = updatedUser;

        setStoredUser(updatedUser);

        const singleOrgAndProjectRedirectUrl = getSingularOrgProjectRedirectUrl(memberships);

        if (singleOrgAndProjectRedirectUrl) setRedirectIfSingularOrgProjectUrl(`${String(dashboardUrl)}/${singleOrgAndProjectRedirectUrl}`);

        const projectGuid = getProjectGuidCookie();

        if (projectGuid) {
          const isUserProjectAdmin = memberships
            .filter((r) => r.project?.guid === projectGuid)
            .some((r) => r.type === 'PROJECT' && r.permission !== 'NONE');
          setIsProjectAdmin(isUserProjectAdmin);
        }

        /*
         * Note
         *  - The whole user object is too large to store in a cookie
         *  - Extract only the important user data to store in a cookie
         */

        createUserDataCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
            }
          },
          value: JSON.stringify({
            email,
            firstName,
            id,
            lastName,
          }),
        });
      }

      setHasActivatedAccount(true);

      setHasAccountActivationCompleted(true);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });

      setErrorMessage(getApolloErrorMessages(error)[0]);
    },
    product: getProduct(),
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setHasAccountActivationStarted(true);
    setShouldRenderPage(true);

    void handleGetGeladaProjectByDomain({
      domain: getDomain(),
      shouldIncludeProjectDomain: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!hasActivatedAccount) {
      return;
    }

    const timerId = setTimeout(() => {

      if (redirectUrl) {
        window.location.href = String(redirectUrl);
      } else {
        handleLoginRedirect({
          isCustomBuild: isProjectCustomBuild,
          isUserAProjectAdmin: isProjectAdmin,
          projectWebsiteUrl,
          redirectAfterLoginUrl,
          redirectIfSingularOrgProjectUrl,
        });
      }

    }, 3000);

    setHasAccountActivationStarted(false);

    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActivatedAccount]);

  useEffect(() => {
    if (!project) return;
    setProjectName(project.name.toLowerCase());
  }, [project]);

  useEffect(() => {
    if (!(isDomainReady && storedDashboardUrl)) {
      return;
    }

    setDashboardUrl(String(storedDashboardUrl));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, storedDashboardUrl]);

  if (!shouldRenderPage) {
    return null;
  }

  const isLoading = isActivateAccountLoading;

  const ERROR_MESSAGE_MAP: Record<string, string> = {
    'User already activated': 'Your account is activated',
  };

  const SUB_ERROR_MESSAGE_MAP: Record<string, string> = {
    'User already activated': `Lucky you, your account is already activated, just jump over to login to get access to ${capitalize(
      projectName,
    )} now.`,
  };

  const onLoginClick = () => {
    push('/login').catch((error) => handleToastError({ error }));
  };

  const getTextDetails = () => {
    let description = '';
    let headerText = '';

    if (hasAccountActivationStarted && !hasAccountActivationCompleted && !errorMessage) {
      description = 'Please wait...';
      headerText = 'Activating account';
    }

    if (hasAccountActivationCompleted && !errorMessage) {
      description = 'Your account has been activated.';
      headerText = 'Thank you!';
    }

    if (errorMessage) {
      description = SUB_ERROR_MESSAGE_MAP[errorMessage];
      headerText = ERROR_MESSAGE_MAP[errorMessage];
    }

    return {
      description,
      headerText,
    };
  };

  const { headerText, description } = getTextDetails();

  return (
    <Layout headerText={headerText} isPreloaderVisible={isLoading} pageDescription={description} title="Activate account" shouldRerender>
      <div className={styles['c-activation-page']}>
        {hasAccountActivationCompleted && !errorMessage && redirectUrl && (
          <p className="c-layout__content-header-description">You will be redirected to the dashboard shortly.</p>
        )}

        {errorMessage && getProduct() !== 'EKARDO' && <Button size="small" text="Login" type="submit" onClick={onLoginClick} />}
      </div>
    </Layout>
  );
};

export { ActivationPage };

