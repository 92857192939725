import React, { useState, FormEvent } from 'react';

import { IDBUnit, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { EnterIcon, Input, NavigationButton, Preloader, SidebarButtons, Spacing } from '@netfront/ui-library';

import { UserGroupUnitFormProps } from './UserGroupUnitForm.interfaces';

import { DELETE_UNIT, UPDATE_UNIT } from '../../../../../graphql';
import { useToast } from '../../../../../hooks';

const UserGroupUnitForm = ({ onOpenUpdateUnits, onUpdateGroupUnits, selectedGroupUnits, unit }: UserGroupUnitFormProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [updatedUnit, setUpdatedUnit] = useState(unit as IDBUnit);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { id: unitId, name: unitName } = updatedUnit || {};

  const handleChangeUnit = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedUnit({
      ...updatedUnit,
      [name]: value,
    });
  };

  const [deleteUnit, { loading: isDeleteUnitLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: 'Unit deleted successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: DELETE_UNIT,
  });

  const [updateUnit, { loading: isUpdateUnitLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: 'Unit updated successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_UNIT,
  });

  const handleDeleteUnit = () => {
    void deleteUnit({
      variables: {
        unitId,
      },
    });

    const deletedGroupUnits = selectedGroupUnits.filter((item: IDBUnit) => item.id !== unit?.id);

    onUpdateGroupUnits(deletedGroupUnits);
    onOpenUpdateUnits();
  };

  const handleUpdateUnit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    void updateUnit({
      variables: {
        unitId,
        unitName,
      },
    });

    const updatedGroupUnits = selectedGroupUnits.map((item: IDBUnit) => {
      if (item.id !== unit?.id) {
        return item;
      }

      return {
        ...item,
        name: updatedUnit.name,
      };
    });

    onUpdateGroupUnits(updatedGroupUnits);
    onOpenUpdateUnits();
  };

  const isLoading = isUpdateUnitLoading || isDeleteUnitLoading;

  return (
    <>
      <Preloader isLoading={isLoading} />

      <Spacing size="x-large">
        <NavigationButton
          additionalClassNames="c-sidebar-navigation-button"
          direction="previous"
          icon={EnterIcon}
          rotationCssSuffix="180"
          text="Back to units"
          onClick={onOpenUpdateUnits}
        />
      </Spacing>

      <form onSubmit={handleUpdateUnit}>
        <Spacing size="x-large">
          <Input id="unitName" labelText="Name" name="name" type="text" value={unitName} isLabelSideBySide isRequired onChange={handleChangeUnit} />
        </Spacing>

        <SidebarButtons
          buttonSize="xs"
          onCancel={onOpenUpdateUnits}
          onDelete={handleDeleteUnit}
          onSaveButtonType="submit"
        />
      </form>
    </>
  );
};

export { UserGroupUnitForm };
