import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IGeladaAccessTokenPayload, useCookie, useJwtDecoder } from '@netfront/common-library';
import {
  IGeladaProject,
  useProtectedRoute,
  IGeladaOrganisation,
  useGetGeladaOrganisationsForConnectedUser,
  useGetGeladaPinnedProjects,
  useDomain,
  useUpdateGeladaPinnedProject,
  useGetPaginatedGeladaProjects,
  IDBGeladaProjectAdministrators,
  useGetProduct,
} from '@netfront/gelada-identity-library';
import {
  Button,
  CardListPageItems,
  CardListPageTemplate,
  CircleAddIcon,
  Container,
  Dialog,
  EmptyState,
  FlexContainer,
  SelectWithSearch,
  Spacing,
} from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { QuickAddOrganisationProject, CreateProjectSuccessDialog, PageLayout } from '../../../components';
import { CachingEntitiesContext, PermissionContext, UserContext } from '../../../context';
import { GET_PAGINATED_PROJECTS } from '../../../graphql';
import { useToast } from '../../../hooks';
import { manageObjectInArray, useLastVisitedOrganisationCookie } from '../../../utils';
import { UpsertProjectSidebar, UpsertOrganisationSidebar } from '../../Sidebars';

const DashboardPage = () => {
  const { isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { updateLastVisitedOrganisationCookie, getLastVisitedOrganisationByUserId } = useLastVisitedOrganisationCookie();
  const { isAuthenticated } = useProtectedRoute();
  const { handleToastError } = useToast();

  const { getDecodedJwt } = useJwtDecoder();
  const { getAccessTokenCookie, getRedirectAfterLoginUrlCookie } = useCookie();
  const {
    push,
  } = useRouter();


  const { hasPermission } = useContext(PermissionContext);
  const { dashboardUrl } = useContext(CachingEntitiesContext);
  const { user } = useContext(UserContext);

  // const [hasLoaded, setHasLoaded] = useState(false);
  // const [hasApiReturned, setHasApiReturned] = useState<{isOrganisationsLoaded: boolean; isPaginatedLoaded: boolean; isPinnedLoaded: boolean; }>({
  //   isOrganisationsLoaded: false,
  //   isPinnedLoaded: false,
  //   isPaginatedLoaded: false,
  // });

  const [hasRequiredPageData, setHasRequiredPageData] = useState<boolean>(false);
  const [allProjects, setAllProjects] = useState<IGeladaProject[]>([]);
  const [pinnedProjects, setPinnedProjects] = useState<IGeladaProject[]>([]);
  const [isProjectPanelOpen, setIsProjectPanelOpen] = useState<boolean>(false);
  const [isCreateProjectSuccessDialogOpen, setIsCreateProjectSuccessDialogOpen] = useState<boolean>(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string>('');
  const [newProject, setNewProject] = useState<IGeladaProject>();
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [organisationKey, setOrganisationKey] = useState<string | null>();
  const [productUrl, setProductUrl] = useState<string>('');
  const [organisationId, setOrganisationId] = useState<number | null>();
  const [currentGeladaOrganisation, setCurrentGeladaOrganisation] = useState<IGeladaOrganisation>();
  const [organisations, setOrganisations] = useState<IGeladaOrganisation[]>([]);
  const [isOrganisationPanelOpen, setIsOrganisationPanelOpen] = useState<boolean>(false);
  const [hasNoOrganisation, setHasNoOrganisation] = useState<boolean>(true);
  const [isOrganisationSelectorOpen, setIsOrganisationSelectorOpen] = useState<boolean>(false);
  const [isQuickAddOrganisationProjectDialogOpen, setIsQuickAddOrganisationProjectDialogOpen] = useState<boolean>(false);

  // Project functions
  const {
    handleGetPaginatedGeladaProjects,
    handleFetchMorePaginatedGeladaProjects,
    isLoading: isGetPaginatedGeladaProjectsForConnectedUserLoading = true,
  } = useGetPaginatedGeladaProjects({
    onCompleted: ({ geladaProjects, totalCount, cursor: returnedCursor = '' }) => {
      setCursor(returnedCursor);
      setCanLoadMore(isInitialLoad && totalCount > 12);
      setIsInitialLoad(false);
      setAllProjects(geladaProjects);

      // if (!hasLoaded) setHasApiReturned({
      //   ...hasApiReturned,
      //   isPaginatedLoaded: true
      // });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    query: GET_PAGINATED_PROJECTS,
  });

  const { handleGetGeladaPinnedProjects, isLoading: isGetPaginatedProjectsLoading = false } = useGetGeladaPinnedProjects({
    onCompleted: ({ geladaProjects }) => {
      setPinnedProjects(geladaProjects);
      // if (!hasLoaded) setHasApiReturned({
      //   ...hasApiReturned,
      //   isPinnedLoaded: true
      // });
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleUpdateGeladaPinnedProject } = useUpdateGeladaPinnedProject({
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewProject = () => {
    setSelectedProjectId(undefined);
    setIsProjectPanelOpen(true);
  };

  const handleSearch = (val: string) => {
    if (organisationKey) {
      void handleGetPaginatedGeladaProjects({
        organisationKey: organisationKey,
        filter: val,
        first: 12,
      });

      setSearchValue(val);
      setIsInitialLoad(true);
      setCursor('');
    }
  };

  const handlePinProject = (project: IGeladaProject, isPinned: boolean) => {
    const updatedProject = {
      ...project,
      isFavourite: isPinned,
    };

    setPinnedProjects(manageObjectInArray(pinnedProjects, project, 'addRemove', updatedProject, ['isFavourite']));
    setAllProjects(manageObjectInArray(allProjects, project, 'update', updatedProject, ['isFavourite']));
    void handleUpdateGeladaPinnedProject({ projectId: String(project.id) });
  };

  const handleLoadMoreProjects = () => {
    if (organisationKey) {
      void handleFetchMorePaginatedGeladaProjects({
        after: cursor,
        filter: searchValue,
        organisationKey: organisationKey,
      });
    }
  };

  const handleSelectProject = (id: string) => {
    setSelectedProjectId(id);
    setIsProjectPanelOpen(true);
  };

  const handleUpdateProject = (returnedProject: IGeladaProject) => {
    const updatedGeladaProjects = allProjects.map((project) => {
      if (project.id === returnedProject.id) {
        return returnedProject;
      }

      return project;
    });

    const updatedPinnedProjects = pinnedProjects.map((project) => {
      if (project.id === returnedProject.id) {
        return returnedProject;
      }

      return project;
    });

    setSelectedProjectId(undefined);
    setPinnedProjects(updatedPinnedProjects);
    setAllProjects(updatedGeladaProjects);
    setIsProjectPanelOpen(false);
  };

  const handleCreateProject = (returnedProject: IGeladaProject) => {
    const createdGeladaProjects = [...allProjects, returnedProject];
    setSelectedProjectId(undefined);
    setIsProjectPanelOpen(false);
    setAllProjects(createdGeladaProjects);
    setIsCreateProjectSuccessDialogOpen(true);
    setNewProject(returnedProject);
  };

  const handleDeleteProject = () => {
    setIsInitialLoad(true);
    void handleGetPaginatedGeladaProjects({
      filter: searchValue,
      organisationKey: String(organisationKey),
      first: 12,
    });

    void handleGetGeladaPinnedProjects({
      organisationKey: String(organisationKey),
    });
    setIsProjectPanelOpen(false);
    setSelectedProjectId(undefined);
  };

  const handleDeleteProjectAsset = (projectId: string) => {
    const updatedGeladaProjects = allProjects.map((project) => {
      if (project.id === projectId) {
        return {
          ...project,
          logo: undefined,
        };
      }

      return project;
    });

    const updatedPinnedProjects = pinnedProjects.map((project) => {
      if (project.id === projectId) {
        return {
          ...project,
          logo: undefined,
        };
      }

      return project;
    });

    setPinnedProjects(updatedPinnedProjects);
    setAllProjects(updatedGeladaProjects);
  };

  // Organisation functions
  const { handleGetGeladaOrganisationsForConnectedUser, isLoading: isGetGeladaOrganisationsLoading = false } =
    useGetGeladaOrganisationsForConnectedUser({
      onCompleted: ({ geladaOrganisations = [] }) => {
        let lastVisitedOrganisation = getLastVisitedOrganisationByUserId(String(user?.id ?? ''));

        if (!lastVisitedOrganisation) {
          lastVisitedOrganisation = geladaOrganisations[0]?.key;
          updateLastVisitedOrganisationCookie({ [String(user?.id)]: String(geladaOrganisations[0]?.key) });
        }

        const lastOrg = geladaOrganisations.find((org) => org.key === lastVisitedOrganisation);

        if (!lastOrg) {
          setHasNoOrganisation(true);
          setIsQuickAddOrganisationProjectDialogOpen(true);
          setHasRequiredPageData(true);
          return;
          // if (!hasLoaded) setHasApiReturned({
          //   isOrganisationsLoaded: true,
          //   isPinnedLoaded: true,
          //   isPaginatedLoaded: true,
          // });
        }

        setHasNoOrganisation(false);
        setCurrentGeladaOrganisation(lastOrg);
        setOrganisations(geladaOrganisations);
        setOrganisationKey(lastOrg.key);
        setOrganisationId(lastOrg.id);
        setHasRequiredPageData(true);
        // if (!hasLoaded) setHasApiReturned({
        //   ...hasApiReturned,
        //   isOrganisationsLoaded: true
        // });
      },
      onError: (error: ApolloError) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      },
    });

  const handleSwitchOrganisation = (selectedKey: number | string) => {
    const selectedOrganisation = organisations.find((org) => org.key === String(selectedKey));

    updateLastVisitedOrganisationCookie({ [String(user?.id)]: String(selectedOrganisation?.key) });
    setCurrentGeladaOrganisation(selectedOrganisation);
    setOrganisationKey(selectedOrganisation?.key);
    setOrganisationId(selectedOrganisation?.id);
    setIsInitialLoad(true);
  };

  const handleCreateOrganisation = (returnedOrganisation: IGeladaOrganisation) => {
    const createdGeladaOrganisations = [...organisations, returnedOrganisation];
    setIsOrganisationPanelOpen(false);
    setOrganisations(createdGeladaOrganisations);
    setCurrentGeladaOrganisation(returnedOrganisation);
    setHasNoOrganisation(false);
    setOrganisationId(returnedOrganisation.id);
    updateLastVisitedOrganisationCookie({ [String(user?.id)]: String(returnedOrganisation.key) });
    setOrganisationKey(returnedOrganisation.key);
  };

  const handleUpdateOrganisation = (returnedOrganisation: IGeladaOrganisation) => {
    const updatedGeladaOrganisations = organisations.map((organisation) => {
      if (organisation.id === returnedOrganisation.id) {
        return returnedOrganisation;
      }

      return organisation;
    });

    setCurrentGeladaOrganisation(returnedOrganisation);
    setOrganisations(updatedGeladaOrganisations);
  };

  const handleDeleteOrganisationAsset = (key: string) => {
    const updatedGeladaOrganisations = organisations.map((organisation) => {
      if (organisation.key === key) {
        return {
          ...organisation,
          logo: undefined,
        };
      }

      return organisation;
    });

    const updatedOrganisation = {
      ...currentGeladaOrganisation,
      logo: undefined,
    } as IGeladaOrganisation;

    setCurrentGeladaOrganisation(updatedOrganisation);
    setOrganisations(updatedGeladaOrganisations);
  };

  const handleDeleteOrganisation = (returnedOrganisationKey: string) => {
    const updatedGeladaOrganisations = organisations.filter((organisation) => organisation.key !== returnedOrganisationKey);
    const [defaultOrganisation] = updatedGeladaOrganisations;
    setIsOrganisationPanelOpen(false);
    setOrganisations(updatedGeladaOrganisations);
    setCurrentGeladaOrganisation(defaultOrganisation);
    setHasNoOrganisation(false);
    setOrganisationId(defaultOrganisation.id);
    updateLastVisitedOrganisationCookie({ [String(user?.id)]: String(defaultOrganisation.key) });
    setOrganisationKey(defaultOrganisation.key);
  };

  // initial loads
  useEffect(() => {
    if (!(organisationKey && isAuthenticated && isDomainReady && user)) {
      return;
    }
    void handleGetPaginatedGeladaProjects({
      organisationKey: organisationKey,
      first: 12,
    });

    void handleGetGeladaPinnedProjects({
      organisationKey: organisationKey,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, organisationKey, isDomainReady]);

  useEffect(() => {
    if (!(isAuthenticated && isDomainReady && user && dashboardUrl)) {
      return;
    }

    void handleGetGeladaOrganisationsForConnectedUser({
      shouldIncludeOrganisationLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady, dashboardUrl, user]);

  // useEffect(() => {
  //   const { isOrganisationsLoaded = false, isPaginatedLoaded = false, isPinnedLoaded = false } = hasApiReturned;

  //   if (isOrganisationsLoaded && isPaginatedLoaded && isPinnedLoaded) setHasLoaded(true);

  // }, [hasApiReturned]);

  useEffect(() => {
    if (!dashboardUrl) return;
    setProductUrl(dashboardUrl);
  }, [dashboardUrl]);

  useEffect(() => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return;
    }

    const decoded = getDecodedJwt(accessToken) as IGeladaAccessTokenPayload;
    const claim = decoded.custom_build;

    const isCustomBuild = Boolean(String(claim).toLowerCase() === 'true');

    if (!isCustomBuild) return;

    const redirectUrl = getRedirectAfterLoginUrlCookie();

    if (redirectUrl)
      push(redirectUrl).catch((error) => {
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isGetPaginatedGeladaProjectsForConnectedUserLoading || isGetPaginatedProjectsLoading || isGetGeladaOrganisationsLoading;

  return (
    <PageLayout
      additionalClassNames="c-page-layout-template--grey"
      hasPrivateLayout={isAuthenticated}
      isPreloaderVisible={isLoading}
      organisation={currentGeladaOrganisation}
      title={`${currentGeladaOrganisation ? String(currentGeladaOrganisation.name) : ''} projects`}
      isInternal
      isProtectedPage
      onDeleteOrganisation={handleDeleteOrganisation}
      onDeleteOrganisationAsset={handleDeleteOrganisationAsset}
      onUpdateOrganisation={handleUpdateOrganisation}
    >
      {hasNoOrganisation ? (
        <>
          <EmptyState
            imageUrl="/images/empty-state.webp"
            text={isLoading ? '' : 'To get started, create an account. Press add new below'}
            title={isLoading ? 'Loading projects ' : "Let's get started!"}
            onClick={() => setIsQuickAddOrganisationProjectDialogOpen(true)}
          />
          <QuickAddOrganisationProject
            isOpen={isQuickAddOrganisationProjectDialogOpen}
            productName={getProduct()}
            onClose={() => setIsQuickAddOrganisationProjectDialogOpen(false)}
          />
        </>
      ) : (
        <>
          <CardListPageTemplate
            addNewOnClick={hasPermission(String(organisationId), 'EDIT') ? handleAddNewProject : undefined}
            avatarTitle={currentGeladaOrganisation?.name ?? ''}
            breadcrumbItems={[
              {
                content: <span>Dashboard</span>,
                key: 'Dashboard',
              },
              {
                content: <span>{currentGeladaOrganisation?.name ?? ''}</span>,
                key: 'projectName',
              },
            ]}
            emptyStateImageUrl="/images/empty-state.webp"
            informationBoxMessage={
              <>Manage {currentGeladaOrganisation ? <strong>{currentGeladaOrganisation.name}</strong> : ''} projects</>
            }
            // isLoading={!hasLoaded}
            lists={[
              {
                additionalClassNames: 'h-flex-center c-card-list-page--large',
                items: pinnedProjects.map((project) => {
                  const { isFavourite = false, id, logo, name, projectAdministrators } = project;
                  const { presignedUrl } = logo ?? {};
                  return {
                    type: 'nav',
                    searchKey: name,
                    itemProps: {
                      key: String(id),
                      title: name,
                      logoUrl: presignedUrl,
                      linkUrl: `${productUrl}/${String(organisationKey)}/${id}`,
                      settingsButtonSupportiveText: 'Edit project',
                      isSelected: isFavourite,
                      onSelectClick: () => handlePinProject(project, !isFavourite),
                      settingsButtonOnClick: () => handleSelectProject(String(id)),
                      users: projectAdministrators?.map((admin: IDBGeladaProjectAdministrators) => {
                        return {
                          title: `${admin.firstname} ${admin.lastname}`,
                        };
                      }),
                    },
                  } as CardListPageItems;
                }),
              },
              {
                items: allProjects.map((project) => {
                  const { isFavourite = false, id, logo, name, projectAdministrators } = project;
                  const { presignedUrl } = logo ?? {};
                  return {
                    type: 'nav',
                    searchKey: name,
                    itemProps: {
                      key: String(id),
                      title: name,
                      logoUrl: presignedUrl,
                      linkUrl: `${String(productUrl)}/${String(organisationKey)}/${id}`,
                      settingsButtonSupportiveText: 'Edit project',
                      isSelected: isFavourite,
                      onSelectClick: () => handlePinProject(project, !isFavourite),
                      settingsButtonOnClick: () => handleSelectProject(String(id)),
                      users: projectAdministrators?.map((admin: IDBGeladaProjectAdministrators) => {
                        return {
                          title: `${admin.firstname} ${admin.lastname}`,
                        };
                      }),
                    },
                  } as CardListPageItems;
                }),
              },
            ]}
            pageTitle="Projects"
            topLevelChildren={
              <div className="c-dashboard-organisation-controller">
                <div className="c-page-container">
                  <Container size="4x-small">
                    <SelectWithSearch
                      customButtonIcon={CircleAddIcon}
                      customButtonText="Add new account"
                      defaultValue={currentGeladaOrganisation ? String(currentGeladaOrganisation.name) : ''}
                      hasPadding={false}
                      id="organisation_select"
                      isDisplaySearchContent={isOrganisationSelectorOpen}
                      isLabelHidden={false}
                      // isLoading={!hasLoaded}
                      labelFontWeight="bold"
                      labelText="Switch account"
                      searchList={organisations.map((org) => {
                        return {
                          id: org.key,
                          label: org.name,
                          size: 'x-small',
                          logoUrl: org.logo?.presignedUrl,
                        };
                      })}
                      tooltipPosition="start"
                      tooltipText="Select or create an account in the dropdown below"
                      hasLabelPadding
                      isAvatarVisible
                      onCustomButtonClick={() => setIsOrganisationPanelOpen(true)}
                      onDisplaySearchContent={() => setIsOrganisationSelectorOpen(!isOrganisationSelectorOpen)}
                      onSearchItemClick={handleSwitchOrganisation}
                    />
                  </Container>
                </div>
              </div>
            }
            hasViewSwitch
            onSearch={handleSearch}
          />
          {hasRequiredPageData && (
            <UpsertProjectSidebar
              handleOpenCloseSideBar={() => {
                setSelectedProjectId(undefined);
                setIsProjectPanelOpen(false);
              }}
              isSideBarOpen={isProjectPanelOpen}
              organisationId={organisationId}
              selectedProjectId={selectedProjectId}
              onCreateProject={handleCreateProject}
              onDeleteAsset={handleDeleteProjectAsset}
              onDeleteProject={handleDeleteProject}
              onUpdateProject={handleUpdateProject}
            />
          )}
          <Dialog
            isOpen={isCreateProjectSuccessDialogOpen}
            title={`Congratulations, your new ${getProduct() === 'QUICKCODES' ? 'space' : 'project'} is created`}
            isNarrow
          >
            {newProject ? (
              <CreateProjectSuccessDialog
                organisationKey={String(organisationKey)}
                product={getProduct() ?? 'EKARDO'}
                project={newProject}
              />
            ) : (
              <></>
            )}
          </Dialog>

          {canLoadMore && (
            <Spacing size="2x-large">
              <FlexContainer justifyContent="center">
                <Button
                  // isPageLoading={!hasLoaded}
                  text="load more"
                  onClick={handleLoadMoreProjects}
                />
              </FlexContainer>
            </Spacing>
          )}
        </>
      )}

      {hasRequiredPageData && (
        <UpsertOrganisationSidebar
          handleOpenCloseSideBar={() => setIsOrganisationPanelOpen(false)}
          isSideBarOpen={isOrganisationPanelOpen}
          onCreateOrganisation={handleCreateOrganisation}
        />
      )}
    </PageLayout>
  );
};

export { DashboardPage };
