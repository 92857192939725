import { IForgotPasswordFormFields } from './ForgotPasswordForm.interfaces';

const FORGOT_PASSWORD_FORM_CSS_IDENTIFIERS = Object.freeze({
  block: 'c-forgot-password-form',
});

const FORGOT_PASSWORD_FORM_INITIAL_VALUES: IForgotPasswordFormFields = {
  email: '',
};

export { FORGOT_PASSWORD_FORM_CSS_IDENTIFIERS, FORGOT_PASSWORD_FORM_INITIAL_VALUES };
