import { gql } from '@apollo/client';

export const GET_PAGINATED_GROUPS = gql`
  query getPaginatedGroups($after: String, $first: Int, $projectId: String!, $filter: String, $status: [EGroupStatus]) {
    group {
      getPaginatedGroups(after: $after, first: $first, projectId: $projectId, filter: $filter, status: $status) {
        edges {
          cursor
          node {
            id
            name
            created
            address {
              line1
              line2
              city
              state
              postCode
            }
            smartCodes {
              code
              smartCodeId
              userType {
                name
              }
            }
            status
            userCount
            website
            phoneNumber
            unit {
              id
              groupId
              name
            }
          }
        }
        totalCount
      }
    }
  }
`;
