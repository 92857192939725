// TODO: Find a way to pass in the user from ProfilePage

import { useEffect, useState } from 'react';

import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import {
  getPerson,
  IDBUser,
  IUser,
  useDomain,
  useGetProduct,
  useIdentitySiteUrls,
  useProtectedRoute,
  useUpdateUser,
} from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { PROFILE_ACCOUNT_PAGE_CONSTANTS } from './ProfileAccountPage.constants';
import styles from './ProfileAccountPage.module.css';

import { useToast } from '../../../hooks';
import { ProfileAccountForm } from '../../Forms/ProfileAccountForm';

const {
  cssIdentifiers: { block: blockCssId },
} = PROFILE_ACCOUNT_PAGE_CONSTANTS;

export interface ProfileAccountPageProps {
  user: IUser | undefined;
}

const ProfileAccountPage = ({ user }: ProfileAccountPageProps) => {
  const { isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [loggedUser, setLoggedUser] = useState<IUser | undefined>(user);

  const product = getProduct();

  const { handleUpdateUser } = useUpdateUser({
    onCompleted: ({ isUpdateEmailCompleted, isUpdateUserCompleted }) => {
      if (isUpdateEmailCompleted && isUpdateUserCompleted) {
        setLoggedUser((currentUser) => {
          if (!(currentUser && firstName && lastName)) {
            return undefined;
          }

          const userWithUpdatedName: IUser = {
            ...currentUser,
            firstName,
            lastName,
          };

          const updatedUser: IUser = {
            ...userWithUpdatedName,
            person: getPerson(userWithUpdatedName as IDBUser),
          };

          return updatedUser;
        });

        handleToastSuccess({
          message: 'Your profile has been updated',
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product,
  });

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    if (!isDomainReady) {
      return;
    }

    push(getLoginUrl()).catch((error) =>
      handleToastError({
        error,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady]);

  return (
    <>
      {isDomainReady && loggedUser && (
        <div className={styles[`${blockCssId}__form`]}>
          <ProfileAccountForm
            isSubmitting={false}
            user={loggedUser}
            onUpdateUserDetails={({ email, firstName: returnedFirstName, lastName: returnedLastName }) => {
              setFirstName(returnedFirstName);
              setLastName(returnedLastName);

              void handleUpdateUser({
                email,
                firstName: returnedFirstName,
                lastName: returnedLastName,
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export { ProfileAccountPage };
