import { gql } from '@apollo/client';

export const UPDATE_UNIT = gql`
  mutation UpdateUnit($unitId: Int!, $unitName: String!) {
    unit {
      update(unitId: $unitId, name: $unitName) {
        groupId
        id
        name
      }
    }
  }
`;
