import { gql } from '@apollo/client';

export const GET_IMPERSONATE_USER_LINK = gql`
  query GetImpersonateUserLink($projectId: String!) {
    project {
      getProject(projectId: $projectId) {
        settings {
          impersonateUserLink
        }
      }
    }
  }
`;
