/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';

import {
  useDomain,
  useGetGeladaProjectByDomain,
  useGetLoggedUser,
  useGetProduct,
  useIdentitySiteUrls,
  useLogout,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { Button, ITab, ProfileImageEditor, TabSet } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { PROFILE_PAGE_CONSTANTS } from './ProfilePage.constants';
import styles from './ProfilePage.module.css';

import { INTERNAL_URLS } from '../../../constants';
import { UserContext } from '../../../context';
import { useToast } from '../../../hooks';
import { useProfilePicture } from '../../../hooks/useProfilePicture/useProfilePicture';
import { Layout } from '../../Shared/Layout';
import { DeleteProfileTab, ProfileAccountTab, TwoStepVerificationTab, UpdatePasswordTab } from '../../Tabs';

const { defaults } = PROFILE_PAGE_CONSTANTS;

const ProfilePage = () => {
  const { getDomain, isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { baseImageUrl, customUserImage, customiseProfilePicture, deleteProfilePicture, storeUser, uploadProfilePicture } =
    useProfilePicture();
  const {
    push,
    query: { initialTab = 'profile' },
  } = useRouter();
  const { handleToastError } = useToast();

  const { user, fullName: userFullName, setStoredUser } = useContext(UserContext);

  const [currentTab, setCurrentTab] = useState<string>(String(initialTab));
  const [pageDescription, setPageDescription] = useState<string>(defaults[String(initialTab)]);

  const product = getProduct();

  const { handleGetGeladaProjectByDomain } = useGetGeladaProjectByDomain();

  const { handleGetLoggedUser, isLoading: isGetLoggedUserLoading = false } = useGetLoggedUser({
    onCompleted: ({ user: returnedUser }) => {
      setStoredUser(returnedUser);
    },
    product,
  });

  const { handleLogout } = useLogout({
    onCompleted: () => {
      push(getLoginUrl()).catch((error) =>
        handleToastError({
          error,
        }),
      );
    },
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    if (!isAuthenticated) {
      void push(INTERNAL_URLS.login);
    }

    void handleGetLoggedUser({ shouldIncludeUserPictures: true });

    void handleGetGeladaProjectByDomain({
      domain: getDomain(),
      shouldIncludeProjectDomain: true,
      shouldIncludeProjectLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!user) return;
    storeUser(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const logout = () => {
    if (isDomainReady)
      handleLogout({
        domain: getDomain(),
      });
  };

  const onTabSelect = (value: string) => {
    setPageDescription(defaults[value]);
    setCurrentTab(value);
  };

  const tabs: ITab[] = [
    {
      id: 'profile',
      label: 'Profile',
      view: () => <ProfileAccountTab user={user} />,
    },
    {
      id: 'security',
      label: 'Security',
      view: () => <UpdatePasswordTab />,
    },
    {
      id: 'two-step-verification',
      label: 'Two-Step Verification',
      view: () => <TwoStepVerificationTab user={user} />,
    },
    {
      id: 'delete',
      label: 'Delete profile',
      view: () => <DeleteProfileTab />,
    },
  ];

  useEffect(() => {
    setCurrentTab(initialTab as string);
  }, [initialTab]);

  return (
    <Layout
      dashboardUrl={currentTab !== 'delete' ? INTERNAL_URLS.dashboard : ''}
      hasContentPadding={false}
      headerAddOn={
        <ProfileImageEditor
          image={customUserImage}
          imageUrl={baseImageUrl ?? ''}
          title={userFullName ?? ''}
          onDelete={deleteProfilePicture}
          onEditSave={customiseProfilePicture}
          onUploadSave={uploadProfilePicture}
        />
      }
      headerText={userFullName}
      isPreloaderVisible={isGetLoggedUserLoading}
      pageDescription={pageDescription}
      title="User profile"
      hasSmallHeaderText
      shouldRerender
    >
      {user && (
        <>
          <div className="h-align-top-right">
            <Button size="xs" text="Logout" type="button" variant="secondary" onClick={logout} />
          </div>
          <div className={styles['c-profile-page__tabset']}>
            <TabSet defaultActiveTabId={currentTab} tabs={tabs} theme="text-only" onTabSelect={onTabSelect} />
          </div>
        </>
      )}
    </Layout>
  );
};

export { ProfilePage };
