import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  ControlledForm,
  Dialog,
  FormFieldProps,
  Input,
  SidebarButtons,
  Spacing,
  Spinner,
  useControlledForm,
} from '@netfront/ui-library';
import { camelCase } from 'change-case';
import { useDeletePlaceholder, useToast, useUpsertPlaceholder } from 'hooks';
import { useRouter } from 'next/router';
import { Control, Controller } from 'react-hook-form';
import { capitalize } from 'utils';
import * as yup from 'yup';

import { getFormFieldErrorMessage } from 'components/Forms';
import { SidebarContainer } from 'components/Shared';

import { getPlaceholderDefaultValues } from './PlaceholdersGeneralTab.helpers';
import { PlaceholdersGeneralTabProps } from './PlaceholdersGeneralTab.interfaces';


const PlaceholdersGeneralTab = ({
  selectedPlaceholder,
  onClose,
  onUpdate,
}: PlaceholdersGeneralTabProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { control, handleSubmit, reset } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        key: yup.string().label('Key').required(),
        value: yup.string().label('Value').required(),
      }),
    ),
  });

  const { handleUpsertPlaceholder, isLoading: isUpsertPlaceholderLoading = false } = useUpsertPlaceholder({
    onUpdate: () => {
      reset();
      onUpdate();
    },
  });

  const { handleDeletePlaceholder, isLoading: isDeletePlaceholderLoading = false } = useDeletePlaceholder({
    onCompleted: ({ isCompleted }) => {
      if (!isCompleted) return;
      reset();
      onUpdate();
      setIsDeleteDialogOpen(false);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleSave = (item: FormFieldProps) => {

    const { key, value } = item;
    handleUpsertPlaceholder({
      projectId,
      placeholderId: selectedPlaceholder?.id,
      key: `[${String(capitalize(camelCase(String(key ?? '').replace(/[|]/g, ""))))}]`, 
      value,
    });
  };

  useEffect(() => {
    setDefaultValues(getPlaceholderDefaultValues({ placeholder: selectedPlaceholder }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlaceholder]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isUpsertPlaceholderLoading || isDeletePlaceholderLoading;


  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
      >
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="key"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_placeholder_key"
                labelText="Key"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
        
        <Spacing>
          <Controller
            control={control as Control<FormFieldProps>}
            name="value"
            render={({ field, fieldState }) => (
              <Input
                errorMessage={getFormFieldErrorMessage(fieldState)}
                id="id_placeholder_value"
                labelText="Value"
                type="text"
                isLabelSideBySide
                isRequired
                {...field}
              />
            )}
          />
        </Spacing>
            
        <SidebarButtons 
          onCancel={() => {
            reset();
            onClose();
          }} 
          onDelete={selectedPlaceholder ? () => setIsDeleteDialogOpen(true): undefined } 
          onSaveButtonType="submit" 
        />
      </ControlledForm>
  
      
      <Dialog
        isOpen={isDeleteDialogOpen}
        title="Delete placeholder?"
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!selectedPlaceholder) return;
          void handleDeletePlaceholder({
            placeholderId: selectedPlaceholder.id,
          });
        }}
      />
    </SidebarContainer>
    
  );
};

export { PlaceholdersGeneralTab };
