const FORGOT_PASSWORD_PAGE_CSS_IDENTIFIERS = Object.freeze({
  BLOCK: 'c-forgot-password-page',
});

const FORGOT_PASSWORD_PAGE_DEFAULTS = Object.freeze({
  HEADER_TEXT: 'Forgot your password?',
  PAGE_DESCRIPTION: `Enter your email below and we'll send you a link to reset your password`,
});

const FORGOT_PASSWORD_CONSTANTS = Object.freeze({
  CSS_IDENTIFIERS: FORGOT_PASSWORD_PAGE_CSS_IDENTIFIERS,
  DEFAULTS: FORGOT_PASSWORD_PAGE_DEFAULTS,
});

export { FORGOT_PASSWORD_CONSTANTS };
