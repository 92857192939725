import React, { useState, useEffect, useContext } from 'react';

import { DBGroupStatusType, IDBGroup } from '@netfront/gelada-identity-library';
import { ContactsTabIcon, GeneralTabIcon, ITab, KeyTabIcon, NotesTabIcon, ToggleSwitch, UnitsTabIcon } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { PROJECT_GROUPS_TABLE_COLUMNS } from './ProjectGroups.constants';
import { getProjectGroupsTableData } from './ProjectGroups.helpers';
import { IProjectGroupData } from './ProjectGroups.interfaces';

import { GroupsBulkActionDialog, GroupsBulkActionType } from '../../../../components';
import {
  UserGroupContactsTab,
  UserGroupGeneralTab,
  UserGroupNotesTab,
  UserGroupSmartCodesTab,
  UserGroupUnitsTab,
} from '../../../../components/Tabs';
import { CachingEntitiesContext } from '../../../../context';
import { useToast, useGetPaginatedGroups } from '../../../../hooks';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';
import { TablePageTemplate } from '../../../Templates/TablePageTemplate';

const ProjectGroups = () => {
  const { project, isProduct, hasUnits } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const [projectName, setProjectName] = useState<string>();
  const [projectId, setProjectId] = useState<string>('');

  const { handleToastError, handleToastSuccess } = useToast();

  const { pageTitle, defaultPageSize, compulsoryGroupShouldIncludes } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [allGroups, setAllGroups] = useState<IDBGroup[]>();
  const [filter, setFilter] = useState<string>();
  const [groupId, setGroupId] = useState<number>();
  const [groupsTableData, setGroupsTableData] = useState<IProjectGroupData[]>([]);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [selectedGroup, setSelectedGroup] = useState<IDBGroup>();
  const [statusFilter, setStatusFilter] = useState<DBGroupStatusType>('ACTIVE');
  const [totalGroups, setTotalGroups] = useState(0);

  // bulk actions
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [bulkActionType, setBulkActionType] = useState<GroupsBulkActionType | ''>('activateGroups');
  const [isBulkActionDialogOpen, setIsBulkActionDialogOpen] = useState<boolean>(false);

  const {
    handleFetchMorePaginatedGroups,
    handleGetPaginatedGroups,
    isLoading: isGetPaginatedGroupsLoading = false,
  } = useGetPaginatedGroups({
    fetchPolicy: 'no-cache',
    onCompleted: ({ groupConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const groups = edges.map(({ node }) => node);
      setAllGroups(groups);
      setIsLoadMoreDisabled(groups.length >= totalCount || totalCount <= pageSize);
      setTotalGroups(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedGroups({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
      status: statusFilter,
      ...compulsoryGroupShouldIncludes,
    });

    setFilter(value);
  };

  const handleOnPageSizeChange = (changedPageSize: number) => {
    setPageSize(Number(changedPageSize));
  };

  const handleSideBarClose = () => {
    setIsSideBarOpen(false);
    setSelectedGroup(undefined);
  };

  const handleAddNewGroup = () => {
    setIsSideBarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSideBarOpen(false);
    setGroupId(undefined);
  };

  const handleAddGroup = () => {
    void handleGetPaginatedGroups({
      first: pageSize,
      filter: filter,
      projectId: String(projectId),
      status: statusFilter,
      ...compulsoryGroupShouldIncludes,
    });

    handleSideBarClose();
    handleToastSuccess({
      message: 'Group successfully added',
    });
  };

  const handleUpdatedGroup = () => {
    handleSideBarClose();
    handleToastSuccess({
      message: 'Group updated successfully',
    });

    void handleGetPaginatedGroups({
      first: pageSize,
      filter: filter,
      projectId: String(projectId),
      status: statusFilter,
      ...compulsoryGroupShouldIncludes,
    });
  };

  const handleDeleteGroup = () => {
    handleSideBarClose();
    handleToastSuccess({
      message: 'Group successfully deleted',
    });

    void handleGetPaginatedGroups({
      first: pageSize,
      filter: filter,
      projectId: String(projectId),
      status: statusFilter,
      ...compulsoryGroupShouldIncludes,
    });
  };

  const handleGroupStatusFilterChange = (status: DBGroupStatusType) => {
    setStatusFilter(status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE');
  };
  const onDeleteSmartCode = (group: IDBGroup) => {
    setSelectedGroup(group);
    setAllGroups((currentState): IDBGroup[] => {
      if (!currentState) {
        return [];
      }

      return currentState.map((currentGroup): IDBGroup => {
        return currentGroup.id === group.id ? group : currentGroup;
      });
    });
  };

  const onAddSmartCode = () => {
    handleCloseSidebar();
    void handleGetPaginatedGroups({
      first: pageSize,
      filter: filter,
      projectId: String(projectId),
      status: statusFilter,
      ...compulsoryGroupShouldIncludes,
    });
  };


    // bulk actions
    const handleOpenBulkActionDialog = (type: GroupsBulkActionType) => {
      setBulkActionType(type);
      setIsBulkActionDialogOpen(true);
    };
  
    const handleCloseBulkActionDialog = () => {
      setBulkActionType('');
      setIsBulkActionDialogOpen(false);
    };
  
    const handleSelectedRows = (selectedIds: string[]) => {
      setSelectedRows(selectedIds);
    };
  
    const handleBulkActionSave = () => {
      setSelectedRows([]);
      handleCloseBulkActionDialog();
      void handleGetPaginatedGroups({
        first: pageSize,
        filter: filter,
        projectId: String(projectId),
        status: statusFilter,
        ...compulsoryGroupShouldIncludes,
      });
    };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    void handleGetPaginatedGroups({
      first: pageSize,
      filter: filter,
      projectId: String(projectId),
      status: statusFilter,
      ...compulsoryGroupShouldIncludes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, projectId, statusFilter]);

  useEffect(() => {
    if (!allGroups) {
      return;
    }

    setGroupsTableData(
      getProjectGroupsTableData({
        groups: allGroups,
        projectId: String(projectId),
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedGroup(allGroups.find(({ id: selectedGroupId }) => id === selectedGroupId));
          setGroupId(id);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const { name } = project;
    setProjectName(name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const tabs: ITab[] =
    groupId && selectedGroup
      ? [
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => (
              <UserGroupGeneralTab
                selectedGroup={selectedGroup}
                onClose={handleCloseSidebar}
                onDeleted={handleDeleteGroup}
                onUpdated={handleUpdatedGroup}
              />
            ),
          },
          {
            icon: ContactsTabIcon,
            id: 'id_contacts_tab',
            label: 'Contacts',
            view: () => <UserGroupContactsTab selectedGroupId={groupId} />,
          },
          {
            icon: NotesTabIcon,
            id: 'id_notes_tab',
            label: 'Notes',
            view: () => <UserGroupNotesTab selectedGroupId={groupId} />,
          },
          {
            icon: UnitsTabIcon,
            id: 'id_units_tab',
            label: 'Units',
            isHidden: !hasUnits,
            view: () => (!isEmpty(selectedGroup) ? <UserGroupUnitsTab selectedGroup={selectedGroup} /> : null),
          },
          {
            icon: KeyTabIcon,
            id: 'id_codes_tab',
            label: 'Codes',
            view: () => (
              <UserGroupSmartCodesTab
                projectId={String(projectId)}
                selectedGroup={selectedGroup}
                onAddSmartCode={onAddSmartCode}
                onClose={handleCloseSidebar}
                onDeleteSmartCode={onDeleteSmartCode}
                onUpdated={handleUpdatedGroup}
              />
            ),
          },
        ]
      : [
          {
            icon: GeneralTabIcon,
            id: 'id_general_tab',
            label: 'General',
            view: () => (
              <UserGroupGeneralTab
                onClose={handleCloseSidebar}
                onCreated={handleAddGroup}
                onUpdated={handleUpdatedGroup}
              />
            ),
          },
        ];

  const isLoading = isGetPaginatedGroupsLoading;
  return (
    <>
      <TablePageTemplate<IProjectGroupData>
        activePage={isProduct ? 'users' : 'groups'}
        activeSubPage="groups"
        additionalBreadcrumbItems={[
          {
            key: '3',
            content: <span>Project groups</span>,
          },
        ]}
        bulkActions={[
          { id: 0, label: 'Activate groups', action: () => handleOpenBulkActionDialog('activateGroups') },
          { id: 1, label: 'Deactivate groups', action: () => handleOpenBulkActionDialog('deactivateGroups') },
          { id: 2, label: 'Delete groups', action: () => handleOpenBulkActionDialog('deleteGroups') },
        ]}
        childrenEnd={
          <ToggleSwitch
            additionalClassNames="c-user-status-toggle"
            id="user-status-filter"
            isChecked={statusFilter === 'INACTIVE'}
            labelText="Show inactive"
            isInline
            onChange={() => handleGroupStatusFilterChange(statusFilter)}
          />
        }
        columns={PROJECT_GROUPS_TABLE_COLUMNS}
        data={groupsTableData}
        description="Manage users"
        handleAddNewClick={handleAddNewGroup}
        handleOnPageSizeChange={handleOnPageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedGroups({
            after: lastCursor,
            filter,
            first: pageSize,
            projectId: String(projectId),
            status: statusFilter,
            ...compulsoryGroupShouldIncludes,
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={
          <>
            Manage <strong>{String(projectName)}</strong> groups:{' '}
            <strong>
              {totalGroups} {pluralize('group', totalGroups)}
            </strong>
          </>
        }
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        isSideBarOpen={isSideBarOpen}
        pageSize={pageSize}
        pageTitle={pageTitle}
        searchPlaceholder="Group name"
        tableType="groups"
        tabs={tabs}
        title={String(projectName)}
        toggleIsSideBarOpen={handleSideBarClose}
        totalItems={totalGroups}
        isProjectLevel
        onSelectRows={handleSelectedRows}
      />
      {projectId && (
        <GroupsBulkActionDialog
          bulkActionType={bulkActionType as GroupsBulkActionType}
          handleCloseDialog={handleCloseBulkActionDialog}
          isOpen={isBulkActionDialogOpen}
          selectedIds={selectedRows}
          onSave={handleBulkActionSave}
        />
      )}
    </>
  );
};

export { ProjectGroups };
