import { useEffect } from 'react';

import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { useDomain, useGetProduct, useIdentitySiteUrls, useProtectedRoute, useUpdatePassword } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { UPDATE_PASSWORD_PAGE_CONSTANTS } from './UpdatePasswordPage.constants';
import styles from './UpdatePasswordPage.module.css';

import { useToast } from '../../../hooks';
import { UpdatePasswordForm } from '../../Forms/Authentication/UpdatePasswordForm';

const {
  cssIdentifiers: { block: blockCssId },
} = UPDATE_PASSWORD_PAGE_CONSTANTS;

const UpdatePasswordPage = () => {
  const { isDomainReady } = useDomain();
  const { getProduct } = useGetProduct();
  const { getLoginUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const product = getProduct();

  const { handleUpdatePassword } = useUpdatePassword({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        handleToastSuccess({
          message: 'Your password has been updated',
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
    product,
  });

  useEffect(() => {
    if (isAuthenticated || !isDomainReady) {
      return;
    }

    push(getLoginUrl()).catch((error) =>
      handleToastError({
        error,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isDomainReady]);

  return (
    <div className={styles[`${blockCssId}__form`]}>
      <UpdatePasswordForm
        isSubmitting={false}
        onUpdatePassword={({ currentPassword, newPassword }) => {
          void handleUpdatePassword({
            currentPassword,
            newPassword,
          });
        }}
      />
    </div>
  );
};

export { UpdatePasswordPage };
