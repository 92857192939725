import { HourGlassIcon, SettingsButton, TickIcon } from '@netfront/ui-library';
import { Column } from 'react-table';

import { IProjectCustomFieldsTableData } from './ProjectCustomFields.interfaces';

const PROJECT_ROLES_TABLE_COLUMNS: ReadonlyArray<Column<IProjectCustomFieldsTableData>> = [
  {
    accessor: 'name',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Title</div>,
    width: '30%',
  },
  {
    accessor: 'description',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Description</div>,
    width: '35%',
  },
  {
    accessor: 'scope',
    Cell: ({ value }) => <div className="text-left w-full word-break-all">{value}</div>,
    Header: () => <div className="text-left w-full">Scope</div>,
    width: '10%',
  },
  {
    accessor: 'isRequired',
    Cell: ({ value: isRequired }) => (
      <div className={`c-custom-field-icon-wrapper ${!isRequired ? 'c-custom-field-icon-wrapper--not-required': ''}`}>
        {
          isRequired 
          ? <TickIcon className="c-custom-field-icon" />
          : <HourGlassIcon className="c-custom-field-icon" />
        }
      </div>
    ),
    Header: () => <div className="text-left w-full">Is required</div>,
    width: '10%',
  },
  {
    accessor: 'type',
    Cell: ({ value }) => <div>{value}</div>,
    Header: () => <div>Data type</div>,
    width: '10%',
  },
  {
    accessor: 'settingsButtonData',
    Cell: ({ value: { item, onClick } }) => (
      <div className="flex justify-end">
        <SettingsButton supportiveText="Custom field settings" onClick={() => onClick(item)} />
      </div>
    ),
    Header: <span className="h-hide-visually">Actions</span>,
    width: '5%',
  },
];

export { PROJECT_ROLES_TABLE_COLUMNS };
