import { useEffect, useState } from 'react';

import { DBNetfrontServiceType,  } from '@netfront/gelada-identity-library';
import { Dialog, SidebarDialog, FormFieldProps } from '@netfront/ui-library';

import { ProjectServicesOverview, UpsertProjectService } from './Components';
import { getServiceOptions } from './UpsertProjectSidebarFeaturesTab.helpers';
import { IServiceOptions, ProjectSidebarFeaturesTabProps } from './UpsertProjectSidebarFeaturesTab.interfaces';

const UpsertProjectSidebarFeaturesTab = ({ 
  isReadOnly = false,
  setValue,
  settings = [],
  initialServices = [],
  initialValues,
  getValues,
  watch,
}: ProjectSidebarFeaturesTabProps) => {

  const [serviceItems, setServiceItems] = useState<IServiceOptions>();
  const [selectedService, setSelectedService] = useState<string>();
  const [isUpsertMode, setIsUpsertMode] = useState<boolean>(false);
  const [removeServiceId, setRemoveServiceId] = useState<string>();
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);

  const handleUpsertService = (id?: string) => {
    setSelectedService(id);
    setIsUpsertMode(true);
  };

  const handleRemoveService = (id: string) => {
    setRemoveServiceId(id);
    setIsRemoveDialogOpen(true);
  };

  const cancelRemoveService = () => {
    setRemoveServiceId(undefined);
    setIsRemoveDialogOpen(false);
  }

  const cancelUpsertService = () => {
    setSelectedService(undefined);
    setIsUpsertMode(false);
  };

  const handleUpdate = (id: DBNetfrontServiceType, values: FormFieldProps) => {
    const { services } = getValues();
    if (!(services as DBNetfrontServiceType[]).includes(id)) setValue('services', [id, ...services]);

    Object.keys(values).forEach((key: string) => setValue(key, values[key]));

    cancelRemoveService();
    cancelUpsertService();
    
  }

  useEffect(() => {
    if (settings.length === 0) return;

    setServiceItems(getServiceOptions(settings));

  }, [settings]);

  return (
    <>
      <ProjectServicesOverview
        initialServices={initialServices}
        isReadOnly={isReadOnly}
        watch={watch}
        onEditClick={handleUpsertService}
        onRemoveClick={handleRemoveService}
      />
      <SidebarDialog
        isOpen={isUpsertMode}
        onClose={cancelUpsertService}
      >
        {isUpsertMode ? (
          <UpsertProjectService 
            getValues={getValues}
            initialValues={initialValues}
            selectedService={selectedService}
            serviceItems={serviceItems}
            onCancel={cancelUpsertService}
            onRemove={handleRemoveService}
            onUpdate={handleUpdate}
          />
        ): <></>}
        
      </SidebarDialog>
      
        
      {removeServiceId && (
        <Dialog
          isOpen={isRemoveDialogOpen}
          title={`Remove service?`}
          isNarrow
          onCancel={cancelRemoveService}
          onClose={cancelRemoveService}
          onConfirm={() => {
            const { services } = getValues();
            setValue('services', (services as DBNetfrontServiceType[]).filter((id) => id !== removeServiceId) );
            cancelRemoveService();
          }}
        />
      )}
    </>
  );
};

export { UpsertProjectSidebarFeaturesTab };
