/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { IGeladaProject, DBNetfrontServiceType, ISetting } from "@netfront/gelada-identity-library";
import { FormFieldProps } from "@netfront/ui-library";

const getUpsertProjectDefaultValues = ({isProduct = false, project, settings }: {isProduct: boolean; project?: IGeladaProject; settings: ISetting[]}): FormFieldProps => {

  const {
    description = '',
    services,
    logo,
    id,
    isCustomBuild,
    organisationId,
    name,
  } = project ?? {};

  const { assetId, presignedUrl } = logo ?? {};


  const values = settings.reduce((accumulator, item) => {
    const { value = '', type, dataType } = item;
    accumulator[type] = dataType === 'Boolean' ? (value || '').toLowerCase() === 'true' : value || '';

    return accumulator;
  }, {} as FormFieldProps);

  const defaultServices = !isProduct ? (['EKARDO', 'HOWLER', 'GELADA'] as DBNetfrontServiceType[]) : (['GELADA'] as DBNetfrontServiceType[]);

  return {
    assetId: assetId || '',
    description: description || '',
    services: services || defaultServices,
    id: id || '',
    initialLogoUrl: presignedUrl || '',
    isCustomBuild: isCustomBuild || false,
    key: id || '',
    organisationId: organisationId || undefined,
    name: name || '',
    ...values
  }
};

const getSettingsVariables = ({item, settings }: {item: FormFieldProps; settings: ISetting[]}): FormFieldProps => {
  const values = settings.reduce((accumulator, setting) => {
    const { type, inputType } = setting;
    const value = item[type];
    const fallbackValue = inputType === 'TOGGLE' ? 'false' : '';

    const formattedValue = value || fallbackValue;
    accumulator[type] = String(formattedValue);

    return accumulator;
  }, {} as FormFieldProps);

  return values;
};

export {getUpsertProjectDefaultValues, getSettingsVariables };
