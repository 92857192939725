import { useMediaQuery } from "@netfront/common-library";
import { Button, FlexContainer, Spinner } from "@netfront/ui-library";
import cx from 'classnames';

import { StepCounter } from "../StepCounter";

import { RegistrationLayoutProps } from "./RegistrationLayout.interfaces";

import { INTERNAL_URLS } from '../../../constants';


const RegistrationLayout = ({
  currentStep,
  totalSteps = 3,
  completionTime = 1,
  title,
  description,
  hasLoginLink = false,
  children,
  isSubmitDisabled = false,
  isLoading = false,
  hasCtaButton = true,
  hasStepCounter = true,
  imageSrc = '',
  onBackClick,
}: RegistrationLayoutProps) => {
  const isMobile = useMediaQuery({size: 'small'});
  return (
    <div className={
      cx('c-registration-layout', {
        'c-registration-layout--mobile': isMobile,
        'c-registration-layout--fade-up': currentStep !== 1,
      })}>
      <Spinner isLoading={isLoading} />
      {hasStepCounter && (<StepCounter completionTime={completionTime} currentStep={currentStep} totalSteps={totalSteps}/>)}
      {imageSrc && (
        <img alt="registration" className="c-registration-layout__image" src={imageSrc}/>
      )}
      <h1 className={`c-registration-layout__title ${imageSrc ? 'c-registration-layout__title--centered' : ''}`}>{title}</h1>
      <p className="c-registration-layout__description">{description}</p>
        {children}
      {
        (hasCtaButton || onBackClick) && (
          <FlexContainer justifyContent={onBackClick ? 'space-between': 'end'}>
            {onBackClick && <button className="c-registration-layout__back-button" type="button" onClick={onBackClick}>Previous</button>}
            {hasCtaButton && < Button isDisabled={isSubmitDisabled} size="xs" text="Next" type="submit"/>}
          </FlexContainer>
        )
      }

      {hasLoginLink && (
        <div className="c-registration-layout__login-container">
          <span>
            Already have an account? <a href={INTERNAL_URLS.login}>Login</a>
          </span>
        </div>
      )}
    </div>
  );
};

export { RegistrationLayout };
