/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { EmailNoneIcon, SettingsButton, Tooltip } from '@netfront/ui-library';

import { IAccountInvitationTableData, IGetAccountInvitationsTableDataParams } from './AccountInvitations.interfaces';

import { getDayMonthYear } from '../../../../utils';
import { EMAIL_ICONS, EMAIL_ICON_TOOLTIPS } from '../../../Pages/Pages.constants';
import { IInvite } from '../../../Pages/Pages.interfaces';

const getOrganisationInvitationTableData = ({ invites, onSettingsButtonClick }: IGetAccountInvitationsTableDataParams): IAccountInvitationTableData[] => {
  return invites.map(
    ({ created = '', id, customMessage, firstName, lastName, invitedUserEmail, invitedUserId, invitationEmailStatus, phoneNumber }) => {
      const invitation: IInvite = {
        id,
        customMessage,
        firstName,
        lastName,
        invitedUserEmail,
        phoneNumber,
        invitedUserId,
        invitationEmailStatus,
      };

      const tableData: IAccountInvitationTableData = {
        id,
        avatar: {
          firstName: String(firstName),
          fullName: `${String(firstName)} ${String(lastName)}`,
        },
        firstName,
        lastName,
        invitedUserEmail,
        invitationEmailStatus: (
          <Tooltip
            additionalClassNames="c-email-status-tooltip"
            icon={EMAIL_ICONS[String(invitationEmailStatus)] ?? EmailNoneIcon}
            text={EMAIL_ICON_TOOLTIPS[String(invitationEmailStatus)] ?? 'disabled'}
          />
        ),
        created: getDayMonthYear(created),
        settings: <SettingsButton supportiveText={`${String(firstName)} settings`} onClick={() => onSettingsButtonClick(invitation)} />,
      };

      return tableData;
    },
  );
};

export { getOrganisationInvitationTableData };
