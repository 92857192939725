import React, { ChangeEvent, FormEvent, useState } from 'react';

import { getBEMClassName } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, PasswordInput, Spacing } from '@netfront/ui-library';
import { capitalize } from 'utils';

import { PasswordCriteria } from 'components/Shared';

import { UPDATE_PASSWORD_FORM_CONSTANTS } from './UpdatePasswordForm.constants';
import { getValidationErrorMessage } from './UpdatePasswordForm.helpers';
import { IFormFieldState, IUpdatePasswordFormState, UpdatePasswordFormProps } from './UpdatePasswordForm.interfaces';


const UpdatePasswordForm = ({
  buttonText = 'Update',
  isSubmitting,
  onUpdatePassword,
  passwordValidation,
}: UpdatePasswordFormProps) => {
  const { button: buttonElementCssId, container: containerElementCssId, form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    password: { id: passwordId, label: passwordLabel },
  } = FORM_FIELDS;

  const {
    cssIdentifiers: { block: blockCssId },
    initialValues: { currentPassword: currentPasswordInitialValue, newPassword: newPasswordInitialValue },
    passwordPrefixes: { current: currentPasswordPrefix, new: newPasswordPrefix },
  } = UPDATE_PASSWORD_FORM_CONSTANTS;

  const [passwordCriteriaChecks , setPasswordCriteriaChecks] = useState({
    length: {
      label: 'Atleast 8 characters',
      isValid: false,
    },
    uppercase: {
      label: 'An uppercase character',
      isValid: false,
    },
    lowercase: {
      label: 'A lowercase character',
      isValid: false,
    },
    special: {
      label: 'A special character',
      isValid: false,
    },
  });
  const [formState, setFormState] = useState<IUpdatePasswordFormState>({
    currentPassword: {
      errorMessage: '',
      isValid: false,
      value: currentPasswordInitialValue,
    },
    newPassword: {
      errorMessage: '',
      isValid: false,
      value: newPasswordInitialValue,
    },
  });

  const handleCurrentPasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFormState((currentState) => {
      const { currentPassword: currentPasswordState } = currentState;

      return {
        ...currentState,
        currentPassword: {
          ...currentPasswordState,
          value,
        },
      };
    });
  };

  const handlePasswordCriteria = (value: string) => {

    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[^a-zA-Z0-9]/;

    setPasswordCriteriaChecks((currentState) => {
      const {length, uppercase, lowercase, special} = currentState;

      return {
        length: { ...length, isValid: value.length >= 8 },
        uppercase: { ...uppercase, isValid: uppercaseRegex.test(value) } ,
        lowercase: { ...lowercase, isValid: lowercaseRegex.test(value) } ,
        special: { ...special, isValid: specialCharRegex.test(value) } ,
      }
    });
  }

  const handleNewPasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {

    handlePasswordCriteria(value);

    setFormState((currentState) => {
      const { newPassword: newPasswordState } = currentState;

      return {
        ...currentState,
        newPassword: {
          ...newPasswordState,
          value,
        },
      };
    });
  };

  const handleFormValidation = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { currentPassword: currentPasswordState, newPassword: newPasswordState } = formState;
    const { value: currentPasswordValue } = currentPasswordState;
    const { value: newPasswordValue } = newPasswordState;

    const currentPasswordErrorMessage = getValidationErrorMessage({
      passwordValidation,
      prefix: 'Current password',
      value: currentPasswordValue,
    });

    const newPasswordErrorMessage = getValidationErrorMessage({
      passwordValidation,
      prefix: 'New password',
      value: newPasswordValue,
    });

    const isCurrentPasswordValid = !currentPasswordErrorMessage;
    const isNewPasswordValid = !newPasswordErrorMessage;

    const updatedFormState: IUpdatePasswordFormState = {
      ...formState,
      currentPassword: {
        ...currentPasswordState,
        errorMessage: currentPasswordErrorMessage,
        isValid: isCurrentPasswordValid,
      },
      newPassword: {
        ...newPasswordState,
        errorMessage: newPasswordErrorMessage,
        isValid: isNewPasswordValid,
      },
    };

    setFormState(updatedFormState);

    const isFormStateValid = Object.keys(updatedFormState).reduce((accumulator, key) => {
      const { isValid }: IFormFieldState<string> = updatedFormState[key as keyof IUpdatePasswordFormState];

      return accumulator && isValid;
    }, true);

    if (!isFormStateValid) {
      return;
    }

    onUpdatePassword({
      currentPassword: currentPasswordValue,
      newPassword: newPasswordValue,
    });
  };

  return (
    <form className={getBEMClassName(blockCssId, formElementCssId)} onSubmit={handleFormValidation}>
      <div className="c-form__body">
        <Spacing size="small">
          <PasswordInput
            additionalClassNames={getBEMClassName(blockCssId, `${currentPasswordPrefix}-${passwordId}`)}
            errorMessage={formState.currentPassword.errorMessage}
            id={`${currentPasswordPrefix}-${passwordId}`}
            isDisabled={isSubmitting}
            labelText={`${capitalize(currentPasswordPrefix)} ${passwordLabel.toLowerCase()}`}
            name={`${currentPasswordPrefix}-${passwordId}`}
            isRequired
            onChange={handleCurrentPasswordChange}
          />
        </Spacing>

        <Spacing size="small">
          <PasswordInput
            additionalClassNames={getBEMClassName(blockCssId, `${newPasswordPrefix}-${passwordId}`)}
            errorMessage={formState.newPassword.errorMessage}
            id={`${newPasswordPrefix}-${passwordId}`}
            isDisabled={isSubmitting}
            labelText={`${capitalize(newPasswordPrefix)} ${passwordLabel.toLowerCase()}`}
            name={`${newPasswordPrefix}-${passwordId}`}
            isRequired
            onChange={handleNewPasswordChange}
          />
        </Spacing>
        <PasswordCriteria criteria={passwordCriteriaChecks} label="Please note: Your password must include" />
      </div>

      <div className="c-form__footer">

        <div className={getBEMClassName(blockCssId, `${buttonElementCssId}s-${containerElementCssId}`)}>
          <Button size="small" text={buttonText} type="submit"/>
        </div>
      </div>
    </form>
  );
};

export { UpdatePasswordForm };
