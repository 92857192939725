import { gql } from '@apollo/client';

export const GET_GROUPS_FOR_USER = gql`
  query GetGroupsForUser($userId: Int!) {
    group {
      getGroupsForUser(userId: $userId) {
        edges {
          node {
            name
            id
          }
        }
      }
    }
  }
`;
