import React, { useState, FormEvent, useEffect } from 'react';

import { IDBGroup, IDBSmartCode, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { BinIcon, ButtonIconOnly, FlexContainer, Input, PlusIcon, Preloader, SidebarButtons, Spacing } from '@netfront/ui-library';
import cx from 'classnames';

import { SidebarContainer } from 'components/Shared';

import { AddSmartCodeView } from './AddSmartCodeView';
import { UserGroupSmartCodesTabProps } from './UserGroupSmartCodesTab.interfaces';
import styles from './UserGroupSmartCodesTab.module.css';

import { UPDATE_SMART_CODES } from '../../../../graphql';
import { DELETE_SMART_CODE, useToast } from '../../../../hooks';

const UserGroupSmartCodesTab = ({
  selectedGroup,
  onClose,
  onUpdated,
  projectId,
  onAddSmartCode,
  onDeleteSmartCode,
}: UserGroupSmartCodesTabProps) => {
  const { handleToastError, handleToastSuccess } = useToast();

  const [groupSmartCodes, setGroupSmartCodes] = useState<IDBSmartCode[]>([]);
  const [isAddCodeVisible, setIsAddCodeVisible] = useState<boolean>(false);

  const [deleteSmartCode, { loading: isDeleteSmartCodeLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        handleToastSuccess({
          message: 'Smart code deleted successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: DELETE_SMART_CODE,
  });

  const [updateSmartCodes, { loading: isUpdateSmartCodesLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        const updatedGroup: IDBGroup = {
          ...selectedGroup,
          smartCodes: groupSmartCodes,
        };

        onUpdated(updatedGroup);

        handleToastSuccess({
          message: 'Smart codes updated successfully!',
        });
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_SMART_CODES,
  });

  const handleChangeSmartCode = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setGroupSmartCodes((currentSmartCodes) =>
      currentSmartCodes.map((smartCode) =>
        String(smartCode.smartCodeId) === name
          ? {
              ...smartCode,
              code: value,
            }
          : smartCode,
      ),
    );
  };

  const onDeleteSmartCodeClick = (id: number) => {
    void deleteSmartCode({ variables: { id } });
    const updatedSmartCodes = groupSmartCodes.filter((r) => Number(r.smartCodeId) !== Number(id));
    setGroupSmartCodes(updatedSmartCodes);
    const updatedGroup: IDBGroup = {
      ...selectedGroup,
      smartCodes: updatedSmartCodes,
    };

    onDeleteSmartCode(updatedGroup);
  };

  const handleUpdateSmartCodes = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void updateSmartCodes({
      variables: {
        smartCodes: groupSmartCodes.map(({ code, smartCodeId }) => ({
          code,
          id: smartCodeId,
        })),
      },
    });
  };
  useEffect(() => {
    const { smartCodes } = selectedGroup;

    setGroupSmartCodes(smartCodes);
  }, [selectedGroup]);

  const isLoading = isDeleteSmartCodeLoading || isUpdateSmartCodesLoading;
  return (
    <SidebarContainer>
      <Preloader isLoading={isLoading} />

      {isAddCodeVisible ? (
        <>
          <AddSmartCodeView
            projectId={projectId}
            selectedGroup={selectedGroup}
            onAdd={onAddSmartCode}
            onClose={() => {
              setIsAddCodeVisible(false);
            }}
          />
        </>
      ) : (
        <form onSubmit={handleUpdateSmartCodes}>
          {Boolean(groupSmartCodes.length) && (
            <section>
              {groupSmartCodes.map((smartCode) => {
                const { code, userType, smartCodeId } = smartCode;
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                const { name: useTypeName } = userType ?? {};

                return (
                  <Spacing key={`smart-code-${String(smartCodeId)}`} size="2x-large">
                    <Input
                      id={String(smartCodeId)}
                      labelText={useTypeName}
                      name={String(smartCodeId)}
                      type="text"
                      value={code}
                      isLabelSideBySide
                      onChange={handleChangeSmartCode}
                    />
                    <div className="flex mt-2 ">
                      <ButtonIconOnly
                        additionalClassNames={cx(styles['c-smart-code-list-item__delete'])}
                        icon={BinIcon}
                        text="remove user"
                        onClick={() => {
                          onDeleteSmartCodeClick(smartCodeId);
                        }}
                      />
                    </div>
                  </Spacing>
                );
              })}
            </section>
          )}
          <FlexContainer justifyContent="end" tag="section">
            <ButtonIconOnly
              icon={PlusIcon}
              text="Add code"
              onClick={() => {
                setIsAddCodeVisible(true);
              }}
            />
          </FlexContainer>

          <SidebarButtons buttonSize="xs" onCancel={onClose} onSaveButtonType="submit" />
        </form>
      )}
    </SidebarContainer>
  );
};

export { UserGroupSmartCodesTab };
