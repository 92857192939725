import { IResetPasswordFormFields } from './ResetPasswordForm.interfaces';

const CSS_IDENTIFIERS = Object.freeze({
  block: 'c-reset-password-form',
});

const INITIAL_VALUES: IResetPasswordFormFields = {
  confirmPassword: '',
  password: '',
};

const PASSWORD_PREFIXES = Object.freeze({
  confirm: 'confirm',
});

const RESET_PASSWORD_FORM_CONSTANTS = Object.freeze({
  cssIdentifiers: CSS_IDENTIFIERS,
  initialValues: INITIAL_VALUES,
  passwordPrefixes: PASSWORD_PREFIXES,
});

export { RESET_PASSWORD_FORM_CONSTANTS };
