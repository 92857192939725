import { gql } from '@apollo/client';

export const GET_USER_NOTES = gql`
  query GetUserNotes($userId: Int!) {
    note {
      getByUser(userId: $userId) {
        id
        message
        subject
        attachments {
          asset {
            contentType
            s3Key
            fileName
            fileSizeInBytes
            assetId
          }
        }
      }
    }
  }
`;
