// TODO: Move this to @netfront/gelada-identity-library

import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { DELETE_ACCOUNT } from './useDeleteAccount.graphql';
import { IDeleteAccountMutationGraphQLResponse, IUseDeleteAccountOptions, IUseDeleteAccount } from './useDeleteAccount.interfaces';

const useDeleteAccount = (options?: IUseDeleteAccountOptions): IUseDeleteAccount => {
  const { onCompleted, onError, token } = options ?? ({} as IUseDeleteAccountOptions);

  const [executeDeleteAccount, { loading: isLoading }] = useLoggedGeladaMutation<IDeleteAccountMutationGraphQLResponse>({
    mutation: DELETE_ACCOUNT,
    options: {
      onCompleted: ({ user: { deleteMyAccount: isCompleted } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleDeleteAccount = async () => {
    await executeDeleteAccount();
  };

  return {
    handleDeleteAccount,
    isLoading,
  };
};

export { useDeleteAccount };
